import { useEffect, useState } from "react";
import _ from "underscore";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import DeviceExplorerConstant from "./DeviceExplorerConstant";
import ButtonSwitch from "./ButtonSwitch";
import ShareService from "./ShareService";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

export default function Destination(props) {
    const { t, i18n } = useTranslation();
    const [checkedMqtt, setCheckedMqtt] = useState(false);
    const [checkedHttp, setCheckedHttp] = useState(false);
    const [checkedIotCore, setCheckedIotCore] = useState(false);
    const [checkedEsri, setCheckedEsri] = useState(false);
    const [checkedSqlServer, setCheckedSqlServer] = useState(false);
    const [checkedIcicle, setCheckedIcicle] = useState(false);

    const [disableMqtt, setDisableMqtt] = useState(true);
    const [disableHttp, setDisableHttp] = useState(true);
    const [disableIotCore, setDisableIotCore] = useState(true);
    const [disableEsri, setDisableEsri] = useState(true);
    const [disableSqlServer, setDisableSqlServer] = useState(true);
    const [disableIcicle, setDisableIcicle] = useState(true);

    useEffect(() => {
        ShareService.checkFlowTarget(DeviceExplorerConstant.toForwardKey(DeviceExplorerConstant.MENU_MQTT), setCheckedMqtt, setDisableMqtt);
        ShareService.checkFlowTarget(DeviceExplorerConstant.toForwardKey(DeviceExplorerConstant.MENU_HTTP), setCheckedHttp, setDisableHttp);
        ShareService.checkFlowTarget(DeviceExplorerConstant.toForwardKey(DeviceExplorerConstant.MENU_IOT_CORE), setCheckedIotCore, setDisableIotCore);
        ShareService.checkFlowTarget(DeviceExplorerConstant.toForwardKey(DeviceExplorerConstant.MENU_ESRI_VELOCITY), setCheckedEsri, setDisableEsri);
        ShareService.checkFlowTarget(DeviceExplorerConstant.toForwardKey(DeviceExplorerConstant.MENU_SQL_SERVER), setCheckedSqlServer, setDisableSqlServer);
        ShareService.checkFlowTarget(DeviceExplorerConstant.toForwardKey(DeviceExplorerConstant.MENU_ICICLE), setCheckedIcicle, setDisableIcicle);
    }, [checkedMqtt]);

    const clickButton = (id) => {
        switch (id) {
            case DeviceExplorerConstant.MENU_MQTT:
                props.callback(id, checkedMqtt);
                break;
            case DeviceExplorerConstant.MENU_HTTP:
                props.callback(id, checkedHttp);
                break;
            case DeviceExplorerConstant.MENU_IOT_CORE:
                props.callback(id, checkedIotCore);
                break;
            case DeviceExplorerConstant.MENU_ESRI_VELOCITY:
                props.callback(id, checkedEsri);
                break;
            case DeviceExplorerConstant.MENU_SQL_SERVER:
                props.callback(id, checkedSqlServer);
                break;
            case DeviceExplorerConstant.MENU_ICICLE:
                props.callback(id, checkedIcicle);
                break;
            default:
                break;
        }
    };

    const clickSwitch = (name, enable) => {
        switch (name) {
            case "MQTT":
                ShareService.enableFlowFlowTargetByCompany(DeviceExplorerConstant.toForwardKey(DeviceExplorerConstant.MENU_MQTT), enable).then(() => {
                    // console.log(enable);
                    setCheckedMqtt(enable);
                });
                break;
            case "ESRI Velocity":
                ShareService.enableFlowFlowTargetByCompany(DeviceExplorerConstant.toForwardKey(DeviceExplorerConstant.MENU_ESRI_VELOCITY), enable).then(() => {
                    // console.log(enable);
                    setCheckedEsri(enable);
                });
                break;
            case "SQL Server":
                ShareService.enableFlowFlowTargetByCompany(DeviceExplorerConstant.toForwardKey(DeviceExplorerConstant.MENU_SQL_SERVER), enable).then(() => {
                    // console.log(enable);
                    setCheckedSqlServer(enable);
                });
                break;
            case "HTTP":
                ShareService.enableFlowFlowTargetByCompany(DeviceExplorerConstant.toForwardKey(DeviceExplorerConstant.MENU_HTTP), enable).then(() => {
                    // console.log(enable);
                    setCheckedHttp(enable);
                });
                break;
            case "IOT Core":
                ShareService.enableFlowFlowTargetByCompany(DeviceExplorerConstant.toForwardKey(DeviceExplorerConstant.MENU_IOT_CORE), enable).then(() => {
                    // console.log(enable);
                    setCheckedIotCore(enable);
                });
                break;
            case "ICICLE":
                ShareService.enableFlowFlowTargetByCompany(DeviceExplorerConstant.toForwardKey(DeviceExplorerConstant.MENU_ICICLE), enable).then(() => {
                    // console.log(enable);
                    setCheckedIcicle(enable);
                });
                break;
            default:
                break;
        }
    };

    return (
        <Paper elevation={6} spacing={2} style={{ height: "300px", width: "100%", position: "relative" }}>
            <Box sx={{ flexGrow: 1, gap: 2, display: "flex", flexDirection: "row", flexWrap: "wrap", p: 3 }}>
                <ButtonSwitch
                    id={DeviceExplorerConstant.MENU_MQTT}
                    name="MQTT"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedMqtt}
                    disableSwitch={disableMqtt}
                />
                <ButtonSwitch
                    id={DeviceExplorerConstant.MENU_HTTP}
                    name="Http(s)"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedHttp}
                    disableSwitch={disableHttp}
                />
                <ButtonSwitch
                    id={DeviceExplorerConstant.MENU_IOT_CORE}
                    name="AWS IoT core"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedIotCore}
                    disableSwitch={disableIotCore}
                />
                <ButtonSwitch
                    id={DeviceExplorerConstant.MENU_ESRI_VELOCITY}
                    name="ESRI Velocity"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedEsri}
                    disableSwitch={disableEsri}
                />
                <ButtonSwitch
                    id={DeviceExplorerConstant.MENU_SQL_SERVER}
                    name="SQL Server"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedSqlServer}
                    disableSwitch={disableSqlServer}
                />
                <ButtonSwitch
                    id={DeviceExplorerConstant.MENU_ICICLE}
                    name="ICICLE"
                    clickButton={clickButton}
                    clickSwitch={clickSwitch}
                    checked={checkedIcicle}
                    disableSwitch={disableIcicle}
                />
            </Box>
            <Box sx={{ position: "absolute", bottom: 4 }}>
                <Alert severity="info">
                    {t("de.contact_us")} <a href="mailto:support@pilot-things.com">support@pilot-things.com</a>
                </Alert>
            </Box>
        </Paper>
    );
}
