import { useState, useEffect, useRef } from 'react'

import style from './style.module.css';

import DashboardList from 'Components/applications/DashboardList';

const DashboardMenu = (props) => {
	const [isLoading, setLoading] = useState(true)
	const [dashboards, setDashboards] = useState([])

	// //same as componentDidMount
	useEffect(() => {

		if (dashboards.length === 0)
			loadDashboards()
				.then(({ data }) => {
					setDashboards(data)
					setLoading(false)
				})
				.catch(e => console.log("error during dashboard loading"))

	}, [])

	return (
		<div className={style.home}>
			<h2>Dashboards :</h2>
			{!props.hasOwnProperty('id') && <DashboardList dashboards={dashboards} isLoading={isLoading}></DashboardList>}
		</div>
	)
};

const loadDashboards = () => {
	return fetch(`/api/dashboards`)
		.then((r) => r.json())
}

export default DashboardMenu;
