import React from "react";
import { GridRowModes, GridActionsCellItem } from "@mui/x-data-grid-pro";
import DocumentIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AwsS3Service from "./AwsS3Service";
import MaintService from "./MaintService";
import moment from "moment";
import { Box } from "@mui/material";

export function getColumns(t, rowModesModel, handleSaveClick, openConfirmDelete, handleCancelClick, handleEditClick, setReload, handleFileUpload) {
    const handleDownload = (m) => {
        // Create a new anchor element
        const link = document.createElement("a");
        link.href = m.location;
        link.download = m.fileName;

        // Append the anchor to the document body
        document.body.appendChild(link);

        // Trigger the download by simulating a click event
        link.click();

        // Clean up by removing the anchor element
        document.body.removeChild(link);
    };

    const handleRemove = (maintDoc) => {
        const { id, docKey } = maintDoc;

        AwsS3Service.deleteDoc(docKey).then(() => {
            MaintService.deleteMaintDoc(id).then(() => setReload(moment()));
        });
    };

    return [
        { field: "id", headerName: "Id", width: 180, editable: false },
        {
            field: "creationDate",
            headerName: "Date",
            type: "date",
            width: 130,
            editable: true,
        },
        { field: "description", headerName: "Description", flex: 1, align: "left", headerAlign: "left", editable: true },
        {
            field: "maintDoc",
            headerName: "Document",
            flex: 1,
            type: "actions",
            renderCell: (params) => {
                const { row } = params;
                const { maintDoc } = row;

                return (
                    <Box display="flex" alignItems="center">
                        {maintDoc &&
                            maintDoc.map((m) => (
                                <Box
                                    key={m.id}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        justifyItems: "center",
                                        gap: 0,
                                    }}
                                >
                                    <GridActionsCellItem icon={<DocumentIcon />} label="Document" onClick={() => handleDownload(m)} color="inherit" />
                                    <GridActionsCellItem
                                        icon={<RemoveCircleOutlineIcon style={{ color: "red" }} />}
                                        label="Remove"
                                        onClick={() => handleRemove(m)}
                                        color="inherit"
                                    />
                                </Box>
                            ))}
                        <GridActionsCellItem
                            icon={<AddCircleOutlineIcon />}
                            label="Add"
                            onClick={() => handleFileUpload(params.id)}
                            color="inherit"
                            sx={{ marginLeft: maintDoc?.length ? 1 : 0 }} // Adds space if there are existing icons
                        />
                    </Box>
                );
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (props) => {
                // console.log(props);
                const { id, row } = props;

                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: "primary.main",
                            }}
                            onClick={handleSaveClick(row)}
                        />,
                        <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
                    ];
                }

                return [
                    <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
                    <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => openConfirmDelete(props)} color="inherit" />,
                ];
            },
        },
    ];
}
