import React, { useState } from "react";
import { Button, Box, TextField } from "@mui/material";
import AddrService from "../../components/addr/AddrService";

function TestSecret() {
    const [textValue, setTextValue] = useState("");

    const handleClick = () => {
        console.log("Button clicked!");
        console.log("Text value:", textValue);
        // Add your desired functionality here

        AddrService.verifyAddress(textValue).then((r) => {
            console.log(r);
        });
    };

    const handleTextChange = (event) => {
        setTextValue(event.target.value);
    };

    return (
        <Box sx={{ flexGrow: 1, pt: 10, ml: 2, mr: 2, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <TextField label="Enter text" variant="outlined" value={textValue} onChange={handleTextChange} margin="normal" sx={{ width: 200 }} />
            <Button variant="contained" color="primary" onClick={handleClick}>
                Click Me
            </Button>
        </Box>
    );
}

export default TestSecret;
