import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';

export default function CompareYearsDialog(props) {
    const { firstYear, secondYear, changeValues, onClose, title, ...other } = props;

    const [firstYearState, setFirstYearState] = useState(firstYear);
    const [secondYearState, setSecondYearState] = useState(secondYear);
  
    const handleCancel = () => {
        setFirstYearState(firstYear);
        setSecondYearState(secondYear);

        onClose();
    };
  
    const handleOk = () => {
        changeValues(firstYearState, secondYearState);

        onClose();
    };
  
    return (
        <Dialog {...other}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="first-year"
                    label="First year"
                    type="number"
                    value={firstYearState}
                    onChange={e => setFirstYearState(e.target.value)} />
                <TextField
                    margin="dense"
                    id="second-year"
                    label="Second year"
                    type="number"
                    value={secondYearState}
                    onChange={e => setSecondYearState(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">Cancel</Button>
                <Button onClick={handleOk} color="primary">Ok</Button>
            </DialogActions>
        </Dialog>
    );
}