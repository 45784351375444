import React, { useEffect, useState, useCallback, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import ThresholdDialog from "Components/ui/ThresholdDialog";
import ThresholdDialogV2 from "./ThresholdDialogV2";
import AppContext from "Components/AppContext";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import AddchartIcon from "@mui/icons-material/Addchart";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import Alerts from "../table/alerts/Alerts";
import CompareChart from "./CompareChart";
import CompareMMChart from "./CompareMMChart";
import MasterDetailChart from "./MasterDetailChart";
import CompareYearsDialog from "../dialogs/CompareYearsDialog";
import CompareMMDialog from "../dialogs/CompareMMDialog";
import DetailService from "./DetailService";
import ThresholdService from "./ThresholdService";
import UtilService from "Services/utilService";
import UserService from "Services/userService";
import DashboardService from "Routes/smartAgriculture/table/DashboardService";
import { grey, blue } from "@mui/material/colors";
import Constants from "../Constants";
import Typography from "@mui/material/Typography";
import style from "./style";
import store from "store";
import { useTranslation } from "react-i18next";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Details() {
    const { t, i18n } = useTranslation();
    const query = useQuery(); // todo: sensor should be a URL parameter, not part of the query string

    let params = useParams();

    const { thingId, thingName, attributeId, attributeName, clusterName, fixedName, tabIndex } = params;
    let name = DashboardService.getLabel(attributeName);

    let { unit } = params;
    if (unit == "percent") {
        unit = "%";
    } else if (unit == "no") {
        unit = "";
    }

    const [openSidePanel, setOpenSidePanel] = useState(false);
    const [yearCompare, setYearCompare] = useState(undefined);
    const [attrCompare, setAttrCompare] = useState(undefined);

    const [thLow, setThLow] = useState(5);
    const [thHigh, setThHigh] = useState(30);
    const [thRate, setThRate] = useState(10);
    const [type, setType] = useState("spline");

    const [chLow, setChLow] = useState(false);
    const [chHigh, setChHigh] = useState(false);
    const [chRate, setChRate] = useState(false);

    const ctx = useContext(AppContext);
    ctx.updateTitle("Smart Agriculture");

    const setting = useContext(SettingContext);

    const [isOpenCompareModal, setIsOpenCompareModal] = useState(false);
    const [isOpenCompareMonthModal, setIsOpenCompareMonthModal] = useState(false);
    const [isOpenThresholdModal, setIsOpenThresholdModal] = useState(false);

    const [chartData, setChartData] = useState([]);
    const [entityThreshold, setEntityThreshold] = useState({});

    const [chartDailyData, setChartDailyData] = useState([]);

    const [selected, setSelected] = useState(false);
    const [selectedTerm, setSelectedTerm] = useState("M"); // M or Y/Y or M/M
    const [rows, setRows] = useState([]); // all cluster and their attributes
    const [tabName, setTabName] = useState("");
    const [periodIdx, setPeriodIdx] = useState(3);

    const [isSupportNewThreshold, setIsSupportNewThreshold] = useState(false);
    const [cursor, setCursor] = useState("default");

    const componentRef = useRef();

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            // console.log(user);
            if (user.authorities.indexOf("SUPER_ADMIN") != -1 || user.authorities.indexOf("APPLICATION_SMART_AGRICULTURE_PLUS") != -1) {
                setIsSupportNewThreshold(true);
            }
        });

        componentRef.current.chart.showLoading();

        let promises = [];

        if (periodIdx == 0) {
            promises.push(DetailService.requestHistoryAverage(thingName, thingId, attributeId, attributeName, handleChartData, "hour"));
        } else if (periodIdx == 1) {
            promises.push(DetailService.requestHistoryAverage(thingName, thingId, attributeId, attributeName, handleChartData, "day"));
        } else if (periodIdx == 2) {
            promises.push(DetailService.requestHistoryAverage(thingName, thingId, attributeId, attributeName, handleChartData, "month"));
        } else if (periodIdx == 3) {
            promises.push(DetailService.requestHistoryAll(thingName, thingId, attributeId, attributeName, handleChartData, "none"));
        }

        Promise.all(promises).then(() => {
            if (componentRef.current) {
                componentRef.current.chart.hideLoading();
            }
        });

        ThresholdService.requestThreshold(thingId, attributeId, callBackRequestThreshold);

        let storedConnectivity = store.get(Constants.KEY_CONNECTIVITY) == undefined ? "LORA" : store.get(Constants.KEY_CONNECTIVITY);
        DashboardService.requestAllClustersThings(setRows, storedConnectivity);

        setting.setGlobalSetting({
            appId: AppId.SMART_AGRICULTURE,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        switch (tabIndex) {
            case "0":
                setTabName("HEAD");
                break;
            case "1":
                setTabName("GROWTH MEDIUM");
                break;
            case "2":
                setTabName("BOTTOM");
                break;
            default:
                break;
        }

        const chOptions = store.get(attributeId);
        if (chOptions) {
            setChLow(chOptions.chLow);
            setChHigh(chOptions.chHigh);
            setChRate(chOptions.chRate);
        }
    }, [isOpenThresholdModal, chartDailyData, cursor]);

    const handleChartData = (chartData) => {
        setChartData(chartData);
    };

    const callBackRequestThreshold = (entityThreshold) => {
        setEntityThreshold(() => {
            entityThreshold.attributeName = attributeName;
            return entityThreshold;
        });

        setThLow(entityThreshold.thLow.value);
        setThHigh(entityThreshold.thHigh.value);
        setThRate(entityThreshold.thRate.value);
    };

    function handlePoint(event) {
        const selectedDate = event.point.category;
        DetailService.requestDailyHistory(thingName, attributeId, selectedDate, handleDailyChartData);
    }

    const handleDailyChartData = (data) => {
        setOpenSidePanel(() => {
            setChartDailyData(data);
            return true;
        });
    };

    const handleHide = () => {
        setOpenSidePanel(false);
    };

    const handleThreshold = () => {
        setIsOpenThresholdModal(true);

        setThHigh((value) => {
            setIsOpenThresholdModal(true);

            return value;
        });
    };

    const handleCompare = (type) => {
        // console.log("handleCompare...");
        if (type == "Year") {
            setIsOpenCompareModal(true);
        } else if (type == "Month") {
            setIsOpenCompareMonthModal(true);
        } else {
            setIsOpenCompareModal(false);
            setIsOpenCompareMonthModal(false);

            setYearCompare(undefined);
            setAttrCompare(undefined);
        }
    };

    const handleChartType = () => {
        setSelected(!selected);

        if (selected) {
            setType("spline");
        } else {
            setType("column");
        }
    };

    const handleTerm = (type) => {
        setSelectedTerm(type);
    };

    const changeThresholdValues = useCallback((thLow, thHigh, thRate) => {
        // console.log("changeThresholdValues......");
        setThLow(thLow);
        setThHigh(thHigh);
        setThRate(thRate);

        setEntityThreshold((entity) => {
            if (!UtilService.isEmpty(entity)) {
                entity.thLow.value = thLow;
                entity.thHigh.value = thHigh;
                entity.thRate.value = thRate;

                ThresholdService.handleThreshold(entity);
            }

            return entity;
        });
    }, []);

    const changeCheckBoxes = useCallback((chLow, chHigh, chRate) => {
        setChLow(chLow);
        setChHigh(chHigh);
        setChRate(chRate);

        store.set(attributeId, { chLow: chLow, chHigh: chHigh, chRate: chRate });
    }, []);

    const changeCompareYearValues = useCallback((firstYear, secondYear) => {
        // console.log("changeCompareYearValues......");
        setYearCompare({ first: firstYear, second: secondYear });
    }, []);

    const changeCompareMMValues = useCallback((thingName, attributeId, attributeName, fixedName, unit, thingId) => {
        // console.log("changeCompareMMValues......");
        setAttrCompare({ thingName: thingName, attributeId: attributeId, attributeName: attributeName, fixedName: fixedName, unit: unit, thingId: thingId });
    }, []);

    const handlePeriod = (periodIdx) => {
        setPeriodIdx(periodIdx);
        setCursor("wait");

        if (periodIdx == 0) {
            DetailService.requestHistoryAverage(thingName, thingId, attributeId, attributeName, handleChartData, "hour").then(() => {
                setCursor("default");
            });
        } else if (periodIdx == 1) {
            DetailService.requestHistoryAverage(thingName, thingId, attributeId, attributeName, handleChartData, "day").then(() => setCursor("default"));
        } else if (periodIdx == 2) {
            DetailService.requestHistoryAverage(thingName, thingId, attributeId, attributeName, handleChartData, "month").then(() => setCursor("default"));
        } else if (periodIdx == 3) {
            DetailService.requestHistoryAll(thingName, thingId, attributeId, attributeName, handleChartData, "none").then(() => setCursor("default"));
        }
    };

    return (
        <React.Fragment>
            <div className="container-fluid" style={{ marginTop: "76px", paddingLeft: "0px", paddingRight: "0px", cursor: cursor }}>
                <div className="row" style={{ marginTop: "5px", marginLeft: "10px", marginBottom: "10px" }}>
                    <div className="col">
                        <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>
                            {clusterName} - {name} ({tabName})
                        </span>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "5px", marginLeft: "10px" }}>
                    <div className="col">
                        <span style={{ fontFamily: "Days One", fontSize: "20px", fontWeight: "normal", color: "#9B9B9B" }}>from {fixedName}</span>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "5px", marginLeft: "10px", marginBottom: "10px" }}>
                    <div className="col">
                        {/* Select a graph type */}
                        <ToggleButton
                            value="check"
                            selected={selectedTerm == "M" ? true : false}
                            onChange={() => handleTerm("M")}
                            size="small"
                            style={style.tg_btn}
                            onClick={() => handleCompare("default")}
                        >
                            <ShowChartIcon />
                        </ToggleButton>
                        <ToggleButton
                            value="check"
                            selected={selectedTerm == "Y/Y" ? true : false}
                            onChange={() => handleTerm("Y/Y")}
                            onClick={() => handleCompare("Year")}
                            size="small"
                            style={style.tg_btn}
                        >
                            <StackedLineChartIcon />
                        </ToggleButton>
                        <ToggleButton
                            value="check"
                            selected={selectedTerm == "M/M" ? true : false}
                            onChange={() => handleTerm("M/M")}
                            onClick={() => handleCompare("Month")}
                            size="small"
                            style={style.tg_btn}
                        >
                            <AddchartIcon />
                        </ToggleButton>

                        {/* Chart type : Line or Bar */}
                        <ToggleButton value="check" selected={!selected} onChange={() => handleChartType()} size="small" style={{ marginLeft: "10px" }}>
                            <TimelineIcon />
                        </ToggleButton>
                        <ToggleButton value="check" selected={selected} onChange={() => handleChartType()} size="small">
                            <EqualizerIcon />
                        </ToggleButton>

                        <Button variant="contained" color="primary" style={{ textTransform: "none", marginLeft: "10px" }} onClick={() => handleThreshold()}>
                            {t("btn.threshold")}
                        </Button>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "-49px", marginLeft: "10px", marginBottom: "10px" }}>
                    <div className="col">
                        <div className="d-flex justify-content-center" style={{ top: "-30px" }}>
                            <Typography variant="h6" component="h6" style={{ color: "#9B9B9B" }}>
                                Group By
                            </Typography>
                            <ButtonGroup variant="contained" aria-label="outlined primary button group" style={{ marginLeft: "10px" }}>
                                <Button
                                    onClick={() => handlePeriod(0)}
                                    style={{ textTransform: "none", backgroundColor: periodIdx == 0 ? blue[700] : grey[400], cursor: cursor }}
                                >
                                    Hour
                                </Button>
                                <Button
                                    onClick={() => handlePeriod(1)}
                                    style={{
                                        textTransform: "none",
                                        marginLeft: "2px",
                                        backgroundColor: periodIdx == 1 ? blue[700] : grey[400],
                                        cursor: cursor,
                                    }}
                                >
                                    Day
                                </Button>
                                <Button
                                    onClick={() => handlePeriod(2)}
                                    style={{
                                        textTransform: "none",
                                        marginLeft: "2px",
                                        backgroundColor: periodIdx == 2 ? blue[700] : grey[400],
                                        cursor: cursor,
                                    }}
                                >
                                    Month
                                </Button>
                                <Button
                                    onClick={() => handlePeriod(3)}
                                    style={{
                                        textTransform: "none",
                                        marginLeft: "2px",
                                        backgroundColor: periodIdx == 3 ? blue[700] : grey[400],
                                        cursor: cursor,
                                    }}
                                >
                                    None
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={openSidePanel ? "col-9" : "col"}>
                        {yearCompare && (
                            <CompareChart
                                type={type}
                                thLow={thLow}
                                thHigh={thHigh}
                                thRate={thRate}
                                handlePoint={handlePoint}
                                year1={yearCompare.first}
                                year2={yearCompare.second}
                                name={`${clusterName} - ${name}`}
                                componentRef={componentRef}
                            />
                        )}
                        {attrCompare && (
                            <CompareMMChart
                                type={type}
                                thLow={thLow}
                                thHigh={thHigh}
                                thRate={thRate}
                                handlePoint={handlePoint}
                                chartData={chartData}
                                attrCompare={attrCompare}
                                attributeName={name}
                                unit={unit}
                                chLow={chLow}
                                chHigh={chHigh}
                                chRate={chRate}
                                fixedName={fixedName}
                                periodIdx={periodIdx}
                                componentRef={componentRef}
                            />
                        )}
                        {!yearCompare && !attrCompare && (
                            <MasterDetailChart
                                type={type}
                                thLow={thLow}
                                thHigh={thHigh}
                                thRate={thRate}
                                chartData={chartData}
                                handlePoint={handlePoint}
                                attributeName={name}
                                unit={unit}
                                chLow={chLow}
                                chHigh={chHigh}
                                chRate={chRate}
                                fixedName={fixedName}
                                periodIdx={periodIdx}
                                componentRef={componentRef}
                            />
                        )}
                    </div>
                    {openSidePanel && (
                        <div className="col-3">
                            <Button variant="contained" color="primary" style={{ textTransform: "none", margin: "5px" }} onClick={() => handleHide()}>
                                Hide
                            </Button>
                            <br></br>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Value</th>
                                        <th scope="col">Time of Day</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chartDailyData.map((row) => (
                                        <tr>
                                            <th scope="row">{row[1]}</th>
                                            <td>{row[0]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>

                <Alerts thingId={thingId} attributeId={attributeId} />
            </div>

            {/* Old threshold feature that uses the custom_field table. 
				The name of custom_field MUST start with TH_ .
				CENGN uses this feature and the others use the new feature (ThresholdDialogV2) */}
            {!isSupportNewThreshold && (
                <ThresholdDialog
                    onClose={() => setIsOpenThresholdModal(false)}
                    thLow={thLow}
                    thHigh={thHigh}
                    thRate={thRate}
                    chLow={chLow}
                    chHigh={chHigh}
                    chRate={chRate}
                    changeValues={changeThresholdValues}
                    changeCheckBoxes={changeCheckBoxes}
                    open={isOpenThresholdModal}
                    title="Alarms Thresholds"
                />
            )}

            {/* New threshold feature that uses the alert_threshold table */}
            {isSupportNewThreshold && (
                <ThresholdDialogV2
                    onClose={() => setIsOpenThresholdModal(false)}
                    open={isOpenThresholdModal}
                    thingId={thingId}
                    attributeId={attributeId}
                    title="Alert's Thresholds"
                    attributeName={name}
                />
            )}

            <CompareYearsDialog
                onClose={() => setIsOpenCompareModal(false)}
                firstYear={2020}
                secondYear={2021}
                changeValues={changeCompareYearValues}
                open={isOpenCompareModal}
                title="Select years to compare"
            />
            <CompareMMDialog
                onClose={() => setIsOpenCompareMonthModal(false)}
                firstYear={2020}
                secondYear={2021}
                changeValues={changeCompareMMValues}
                open={isOpenCompareMonthModal}
                rows={rows}
            />
        </React.Fragment>
    );
}
