import React, { useEffect, useContext } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import Alerts from "./details/Alerts";
import _ from "underscore";

export default function EmeraAlerts(props) {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.EMERA_V2,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });
        ctx.updateTitle(ApplicationData.find((a) => a.id == AppId.EMERA_V2).title);

        return () => {
            ctx.updateTitle(null);
        };
    }, []);

    return (
        <div style={{ marginTop: "76px" }}>
            <Alerts {...props} />
        </div>
    );
}
