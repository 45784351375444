import React, { useEffect, useState, useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ThresholdDialog from "Components/ui/ThresholdDialog";
import AppContext from "Components/AppContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import ToggleButton from "@mui/material/ToggleButton";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import Alerts from "../table/alerts/Alerts";
import CompareChart from "./CompareChart";
import MasterDetailLightMulti from "./MasterDetailLightMulti";
import CompareYearsDialog from "../dialogs/CompareYearsDialog";
import DetailService from "./DetailService";
import ThresholdService from "./ThresholdService";
import UtilService from "Services/utilService";
import moment from "moment-timezone";
import { grey, blue } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function DetailMulti() {
    const { t, i18n } = useTranslation();
    const query = useQuery(); // todo: sensor should be a URL parameter, not part of the query string

    let params = useParams();
    let name = "Light";

    const { clusterName, tabIndex } = params;
    const { fixedName_1, thingName_1, thingId_1, attributeId_1 } = params;
    const { fixedName_2, thingName_2, thingId_2, attributeId_2 } = params;
    // const { fixedName_3, thingName_3, thingId_3, attributeId_3 } = params;

    let things = [];
    if (thingName_1 != "no") {
        things.push({ fixedName: fixedName_1, thingName: thingName_1, thingId: thingId_1, attributeId: attributeId_1 });
    }
    if (thingName_2 != "no") {
        things.push({ fixedName: fixedName_2, thingName: thingName_2, thingId: thingId_2, attributeId: attributeId_2 });
    }

    const [openSidePanel, setOpenSidePanel] = useState(false);
    const [yearCompare, setYearCompare] = useState(undefined);

    const [thLow, setThLow] = useState(5);
    const [thHigh, setThHigh] = useState(30);
    const [thRate, setThRate] = useState(10);
    const [type, setType] = useState("spline");

    const [chLow, setChLow] = useState(false);
    const [chHigh, setChHigh] = useState(false);
    const [chRate, setChRate] = useState(false);

    const ctx = useContext(AppContext);
    ctx.updateTitle("Smart Agriculture");

    const setting = useContext(SettingContext);

    const [isOpenCompareModal, setIsOpenCompareModal] = useState(false);
    const [isOpenThresholdModal, setIsOpenThresholdModal] = useState(false);

    const [entityThreshold, setEntityThreshold] = useState([]);

    const [chartDailyData, setChartDailyData] = useState([]);

    const [data, setData] = useState([]);
    const [seriesData, setSeriesData] = useState([]);
    const [chkName, setChkName] = useState([]);
    const [selected, setSelected] = useState(false);
    const [tabName, setTabName] = useState("");
    const [periodIdx, setPeriodIdx] = useState(3);

    const makeSeries = ({ fixedName, thingName, thingId, attributeId }, periodIdx) => {
        let start = 1634688000000;
        if (periodIdx == 0) {
            // start = moment().subtract(1, 'days').valueOf();
        } else if (periodIdx == 1) {
            // start = moment().subtract(1, 'months').valueOf();
        } else if (periodIdx == 2) {
            start = moment().subtract(1, "years").valueOf();
        } else if (periodIdx == 3) {
            start = 1634688000000;
        }

        if (periodIdx == 0 || periodIdx == 1) {
            return DetailService.requestHistoryAverage(
                thingName,
                attributeId,
                undefined,
                (res) => {
                    let temp = [];
                    res.forEach((r) => {
                        temp.push(r);
                    });

                    data.push({
                        fixedName: fixedName,
                        thingName: thingName,
                        thingId: thingId,
                        attributeId: attributeId,
                        series: temp,
                    });
                },
                periodIdx == 0 ? "days" : "months"
            );
        } else {
            return DetailService.requestHistory(
                thingName,
                attributeId,
                undefined,
                (res) => {
                    let temp = [];
                    res.forEach((r) => {
                        temp.push(r);
                    });

                    data.push({
                        fixedName: fixedName,
                        thingName: thingName,
                        thingId: thingId,
                        attributeId: attributeId,
                        series: temp,
                    });
                },
                start
            );
        }
    };

    useEffect(() => {
        var promises = [];
        var checkedName = [];
        var thresholdEntity = [];

        for (const thing of things) {
            promises.push(makeSeries(thing, periodIdx));
            checkedName.push({ fixedName: thing.fixedName, checked: true });

            promises.push(
                ThresholdService.requestThreshold(thing.thingId, thing.attributeId, (entity) => {
                    thresholdEntity.push(entity);
                })
            );
        }

        Promise.all(promises).then(() => {
            setSeriesData(data);
            setChkName(checkedName);
            setEntityThreshold(thresholdEntity);
        });

        setting.setGlobalSetting({
            appId: AppId.SMART_AGRICULTURE,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        switch (tabIndex) {
            case "0":
                setTabName("HEAD");
                break;
            case "1":
                setTabName("GROWTH MEDIUM");
                break;
            case "2":
                setTabName("BOTTOM");
                break;
            default:
                break;
        }
    }, [isOpenThresholdModal, chartDailyData, data]);

    function handlePoint(event) {
        const { thingName, attributeId, pointStart } = event.point.series.userOptions;

        DetailService.requestDailyHistory(thingName, attributeId, pointStart, handleDailyChartData);

        if (event == undefined || event.point == undefined) return;

        // setOpenSidePanel(true);
    }

    const handleDailyChartData = (data) => {
        setOpenSidePanel(() => {
            setChartDailyData(data);
            return true;
        });
    };

    const handleHide = () => {
        setOpenSidePanel(false);
    };

    const handleThreshold = () => {
        setIsOpenThresholdModal(true);

        setThHigh((value) => {
            setIsOpenThresholdModal(true);

            return value;
        });
    };

    const handleCompare = () => {
        // console.log("handleCompare...");
        setIsOpenCompareModal(true);
    };

    const handleChartType = () => {
        setSelected(!selected);

        if (selected) {
            setType("spline");
        } else {
            setType("column");
        }
    };

    const changeThresholdValues = useCallback((thLow, thHigh, thRate) => {
        setThLow(thLow);
        setThHigh(thHigh);
        setThRate(thRate);

        let promises = [];
        let entities = [];

        setEntityThreshold((entityThreshold) => {
            entityThreshold.forEach((entity) => {
                if (!UtilService.isEmpty(entity)) {
                    entity.thLow.value = thLow;
                    entity.thHigh.value = thHigh;
                    entity.thRate.value = thRate;
                    entity.attributeName = "light";

                    promises.push(ThresholdService.handleThreshold(entity));
                    entities.push(entity);
                }
            });
        });

        Promise.all(promises).then(() => {
            setEntityThreshold(entities);
        });
    }, []);

    const changeCheckBoxes = useCallback((chLow, chHigh, chRate) => {
        setChLow(chLow);
        setChHigh(chHigh);
        setChRate(chRate);
    }, []);

    const changeCompareValues = useCallback((firstYear, secondYear) => {
        setYearCompare({ first: firstYear, second: secondYear });
    }, []);

    const changeLight = (e, name) => {
        let chkList = [];
        chkName.forEach((c) => {
            if (c.fixedName == name) {
                c.checked = e.nativeEvent.target.checked;
            }
            chkList.push(c);
        });
        setChkName(chkList);
    };

    const handlePeriod = (periodIdx) => {
        setPeriodIdx(periodIdx);

        setData([]);
        setSeriesData([]);

        var promises = [];

        for (const thing of things) {
            promises.push(makeSeries(thing, periodIdx));
        }

        Promise.all(promises).then(() => {
            setSeriesData(data);
        });
    };

    return (
        <React.Fragment>
            <div className="container-fluid" style={{ marginTop: "76px", paddingLeft: "0px", paddingRight: "0px" }}>
                <div className="row" style={{ marginTop: "5px", marginLeft: "10px", marginBottom: "10px" }}>
                    <div className="col">
                        <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>
                            {clusterName} - {name} ({tabName})
                        </span>
                    </div>
                </div>

                {!yearCompare && (
                    <div className="row" style={{ marginTop: "5px", marginLeft: "10px" }}>
                        <div className="col">
                            <FormGroup row>
                                {things.map((thing) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="chkOne"
                                                    value={thing.fixedName}
                                                    color="primary"
                                                    defaultChecked
                                                    onChange={(e) => changeLight(e, thing.fixedName)}
                                                />
                                            }
                                            label={thing.fixedName}
                                        />
                                    );
                                })}
                            </FormGroup>
                        </div>
                    </div>
                )}
                <div className="row" style={{ marginTop: "5px", marginLeft: "10px", marginBottom: "10px" }}>
                    <div className="col">
                        {/* Chart type : Line or Bar */}
                        <ToggleButton value="check" selected={!selected} onChange={() => handleChartType()} size="small">
                            <ShowChartIcon />
                        </ToggleButton>
                        <ToggleButton value="check" selected={selected} onChange={() => handleChartType()} size="small">
                            <EqualizerIcon />
                        </ToggleButton>
                        <Button variant="contained" color="primary" style={{ textTransform: "none", margin: "5px" }} onClick={() => handleThreshold()}>
                            {t("btn.threshold")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ textTransform: "none", margin: "5px" }}
                            onClick={() => (yearCompare ? setYearCompare(undefined) : handleCompare())}
                        >
                            {yearCompare ? "Return to overall view" : "Select years to compare"}
                        </Button>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "-49px", marginLeft: "10px", marginBottom: "10px" }}>
                    <div className="col">
                        <div class="d-flex justify-content-center" style={{ top: "-30px" }}>
                            <ButtonGroup variant="contained" aria-label="outlined primary button group" style={{ marginLeft: "10px" }}>
                                <Button onClick={() => handlePeriod(0)} style={{ backgroundColor: periodIdx == 0 ? blue[700] : grey[400] }}>
                                    1D
                                </Button>
                                <Button onClick={() => handlePeriod(1)} style={{ marginLeft: "2px", backgroundColor: periodIdx == 1 ? blue[700] : grey[400] }}>
                                    1M
                                </Button>
                                <Button onClick={() => handlePeriod(2)} style={{ marginLeft: "2px", backgroundColor: periodIdx == 2 ? blue[700] : grey[400] }}>
                                    1Y
                                </Button>
                                <Button onClick={() => handlePeriod(3)} style={{ marginLeft: "2px", backgroundColor: periodIdx == 3 ? blue[700] : grey[400] }}>
                                    ALL
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={openSidePanel ? "col-9" : "col"}>
                        {yearCompare ? (
                            <CompareChart
                                type={type}
                                thLow={thLow}
                                thHigh={thHigh}
                                thRate={thRate}
                                handlePoint={handlePoint}
                                year1={yearCompare.first}
                                year2={yearCompare.second}
                                name={`${clusterName} - ${name}`}
                            />
                        ) : (
                            <MasterDetailLightMulti
                                type={type}
                                thLow={thLow}
                                thHigh={thHigh}
                                thRate={thRate}
                                handlePoint={handlePoint}
                                attributeName={name}
                                seriesData={seriesData}
                                chkName={chkName}
                                chLow={chLow}
                                chHigh={chHigh}
                                chRate={chRate}
                                periodIdx={periodIdx}
                            />
                        )}
                    </div>
                    {openSidePanel && (
                        <div className="col-3">
                            <Button variant="contained" color="primary" style={{ textTransform: "none", margin: "5px" }} onClick={() => handleHide()}>
                                Hide
                            </Button>
                            <br></br>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Value</th>
                                        <th scope="col">Time of Day</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chartDailyData.map((row) => (
                                        <tr>
                                            <th scope="row">{row[1]}</th>
                                            <td>{row[0]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>

                <Alerts things={things} />
            </div>
            <ThresholdDialog
                onClose={() => setIsOpenThresholdModal(false)}
                thLow={thLow}
                thHigh={thHigh}
                thRate={thRate}
                changeValues={changeThresholdValues}
                changeCheckBoxes={changeCheckBoxes}
                open={isOpenThresholdModal}
                title="Alarms Thresholds"
            />
            <CompareYearsDialog
                onClose={() => setIsOpenCompareModal(false)}
                firstYear={2020}
                secondYear={2021}
                changeValues={changeCompareValues}
                open={isOpenCompareModal}
                title="Select years to compare"
            />
        </React.Fragment>
    );
}
