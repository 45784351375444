import { useState } from 'react';
import { useHistory } from "react-router-dom";

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import style from './style.module.css';

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.paper,
        width: 500,
    },
}));

export default function TabMenu(props) {
    let history = useHistory();

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(props.value);

    const handleChange = (event, newValue) => {
        setValue(newValue);

        if (newValue == 0) {
            history.push(`${process.env.PUBLIC_URL}/device_scan/scan`);
        } else {
            history.push(`${process.env.PUBLIC_URL}/device_scan/list`);
        }
    };

    return (
        <div className={classes.root} style={{ width: "100%" }}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Scan" />
                    <Tab label="Devices" />
                    {/* <Tab label="Scan" {...a11yProps(0)} />
					<Tab label="Devices" {...a11yProps(1)} /> */}
                </Tabs>
            </AppBar>
        </div>
    );
}

