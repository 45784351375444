const EmeraConstants = {
    thingId: "c23d3bab-cdd8-4ebc-a5d4-88550d512824",
    co2AttributeId: "2804556f-b002-4a49-99e0-eecfae4c7ef1",
    tempAttributeId: "2348c851-91aa-40a4-8d4f-55550d720026",
    humidityAttributeId: "d43d8bc4-53a5-4afa-b538-23cd21f8ea1f",

    toColor: async (value) => {
        if (value < 600) {

        }
    }
}

export default EmeraConstants