import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { CustomButton } from "Components/ui/CustomButton";
import Stack from "@mui/material/Stack";
import GraphService from "./GraphService";
import moment from "moment";
import UtilService from "Services/utilService";
import { GraphMainOption } from "./GraphMainOption";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import { useTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { size } from "underscore";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

HighchartsExporting(Highcharts);

export default function GraphMain({ selectedThingId, user }) {
    const [startDate, setStartDate] = useState(new Date());
    const [options, setOptions] = useState({});

    const [type, setType] = useState("column");
    const [attributeName, setAttributeName] = useState("volume");
    const [interval, setInterval] = useState("day");

    const theme = useTheme();
    const [screenSize, setScreenSize] = useState("md");

    useEffect(() => {
        // console.log("uef in GraphMain");
        // console.log(selectedThingId)
        // console.log(user);

        GraphService.requestHourlyDeltaVolume(selectedThingId, interval, startDate, user).then((data) => {
            let categories = [];

            if (interval === "day") {
                let sDate = moment(startDate).startOf("day");
                for (let i = 0; i < 24; i++) {
                    categories.push(UtilService.formateH(sDate.valueOf()));
                    sDate.add(1, "hour");
                }
            } else if (interval === "week") {
                let sDate = moment(startDate).startOf("week");
                for (let i = 0; i < 7; i++) {
                    categories.push(UtilService.formatDo(sDate.valueOf()));
                    sDate.add(1, "day");
                }
            } else if (interval === "month") {
                let sDate = moment(startDate).startOf("month");
                const sDateMonth = sDate.format("MM");
                for (let i = 0; i < 31; i++) {
                    categories.push(UtilService.formatDo(sDate.valueOf()));
                    sDate.add(1, "day");

                    if (sDate.format("MM") != sDateMonth) {
                        break;
                    }
                }
            } else if (interval === "year") {
                let sDate = moment(startDate).startOf("year");
                const sDateYear = sDate.format("YYYY");

                for (let i = 0; i < 12; i++) {
                    categories.push(UtilService.formatM(sDate.valueOf()));
                    sDate.add(1, "month");

                    if (sDate.format("YYYY") != sDateYear) {
                        break;
                    }
                }
            }

            const chartData = [];

            categories.forEach((cat) => {
                let value = 0;

                if (data.filter((d) => d[0] == cat).length > 0) {
                    const entity = data.filter((d) => d[0] == cat)[0];
                    value = entity[1];
                }

                // chartData.push([value, unit, timestamp]);
                chartData.push(value);
            });

            let unit = "wh";
            setOptions(GraphMainOption(attributeName, type, unit, chartData, interval, categories, data, []));
        });

        const handleResize = () => {
            const breakpoints = theme.breakpoints;
            let currentSize = "xs";

            if (window.innerWidth >= breakpoints.values.sm) {
                currentSize = "sm";
            }
            if (window.innerWidth >= breakpoints.values.md) {
                currentSize = "md";
            }
            if (window.innerWidth >= breakpoints.values.lg) {
                currentSize = "lg";
            }
            if (window.innerWidth >= breakpoints.values.xl) {
                currentSize = "xl";
            }
            setScreenSize(currentSize);
        };
        handleResize();
    }, [attributeName, interval, selectedThingId, startDate, theme.breakpoints, type, user]);

    const handleButtons = (interval) => {
        // console.log("handleButtons....." + interval);
        setInterval(interval);
    };

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} sx={{ pl: 5, pr: 5 }}>
                <Grid item md={12} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: "left", width: "100%", mb: 2 }}>
                    <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>Consumption</span>
                </Grid>
                <Grid item md={4} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: "left", width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date"
                            value={startDate}
                            inputFormat="MM/dd/yyyy"
                            onChange={(newValue) => {
                                // console.log(newValue);
                                setStartDate(newValue);
                            }}
                            showTodayButton
                            slotProps={{
                                textField: {
                                    size: "small",
                                },
                            }}
                            maxDate={moment()}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={8} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: screenSize == "xs" ? "center" : "left", width: "100%" }}>
                    <Stack spacing={1} direction={"row"}>
                        <CustomButton name="Hour" callback={() => handleButtons("day")} toggled={interval == "day" ? true : false} />
                        {/* <CustomButton name="Week" callback={() => handleButtons("week")} toggled={interval == "week" ? true : false} /> */}
                        <CustomButton name="Day" callback={() => handleButtons("month")} toggled={interval == "month" ? true : false} />
                        <CustomButton name="Month" callback={() => handleButtons("year")} toggled={interval == "year" ? true : false} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    {/* <HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} options={options} /> */}
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </Grid>
            </Grid>
        </Box>
    );
}
