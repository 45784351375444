import { useEffect } from "react";
import { Link } from "react-router-dom";
import style from "./style.module.css";
import ImageLogo from "Assets/icons/logo.png";
import IconColdChain from "Assets/icons/IconColdChain.svg";
import IconEmera from "Assets/icons/IconEmera.svg";
import IconSmartAgriculture from "Assets/icons/IconSmartAgriculture.svg";
import IconWaterManagement from "Assets/icons/IconWaterManagement.svg";
import IconDeviceExplorer from "Assets/icons/Device_Explorer_logo.png";
import { AppId } from "Components/applications/ApplicationData";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";

const ItemList = ({ elems, getTitle, getUrl }) => {
    useEffect(() => {
        // console.log(elems);
    }, [elems]);

    return <Box sx={{ display: "flex", flexWrap: "wrap" }}>{drawCards(elems, getTitle, getUrl)}</Box>;
};

function drawCards(elems, getTitle, getUrl) {
    const renderCardImage = (id) => {
        switch (id) {
            case AppId.TEMPERATURE_MONITORING:
                return <img className={`card-img-top ${style.cardImage}`} src={IconColdChain} alt="Pilot Things" />;
            case AppId.EMERA:
            case AppId.EMERA_V2:
                return <img className={`card-img-top ${style.cardImage}`} src={IconEmera} alt="Pilot Things" />;
            case AppId.SMART_AGRICULTURE:
                return <img className={`card-img-top ${style.cardImage}`} src={IconSmartAgriculture} alt="Pilot Things" />;
            case AppId.WATER_MANAGEMENT:
                return <img className={`card-img-top ${style.cardImage}`} src={IconWaterManagement} alt="Pilot Things" />;
            case AppId.DEVICE_EXPLORER:
                return <img className={`card-img-top ${style.cardImage}`} src={IconDeviceExplorer} alt="Pilot Things" />;
            default:
                return <img className={`card-img-top ${style.cardImage}`} src={ImageLogo} alt="Pilot Things" />;
        }
    };

    return elems.map((elem) => {
        if (elem.hide) {
            return null;
        }

        return (
            <Link to={getUrl(elem)} title={getTitle(elem)} key={elem.id} style={{ textDecoration: "none" }}>
                {/* <div className={`card ${style.card}`}>
                    <div className={`rounded ${style.cardContent}`}>{renderCardImage(elem.id)}</div>
                    <div className={`card-body ${style.cardBody}`}>
                        <div className={`card-text ${style.cardText}`}>{getTitle(elem)}</div>
                    </div>
                </div> */}
                <Paper elevation={3} sx={{ m: 2 }}>
                    <Stack direction={"row"}>
                        <Box sx={{ p: 1 }}>{renderCardImage(elem.id)}</Box>
                        <Box sx={{ p: 2, display: "flex", alignItems: "center", width: 210 }}>{getTitle(elem)}</Box>
                    </Stack>
                </Paper>
            </Link>
        );
    });
}

export default ItemList;
