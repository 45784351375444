import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Skeleton from '@mui/material/Skeleton';

export default function BasicUI(props) {

    const [alertCount, setAlertCount] = useState(0);
    const [fixedName, setFixedName] = useState('');

    // let fixedName = '';

    // if (props.entity != undefined) {
    //     fixedName = props.entity.fixedName;
    // }

    useEffect(() => {
        if (props.entity) {
            setAlertCount(props.entity.alertCount);
            setFixedName(props.entity.fixedName ? props.entity.fixedName : '');
        }
    }, []);

    return (
        <div className="container m-0 p-0">
            {/* Default view */}
            {props.default &&
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                    <Skeleton variant="rectangular" width={80} height={80} />
                </div>
            }
            {/* Data view */}
            {props.entity &&
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', position: 'relative' }}>
                    <Tooltip title={fixedName}>
                        <div style={{ position: 'absolute', zIndex: '1' }}>
                            {isNaN(props.entity.value) &&
                                <span style={{ fontSize: '15px' }}>{props.entity.value}</span>
                            }
                            {!isNaN(props.entity.value) &&
                                <span style={{ fontSize: '15px' }}>{parseFloat(props.entity.value).toFixed(1)} {props.entity.unit}</span>
                            }
                            <br></br>
                            <span style={{ fontSize: '12px' }}>{props.entity.date}</span>
                        </div>
                    </Tooltip>
                    <div style={{ position: 'absolute', zIndex: '2', paddingTop: '0px', marginTop: '-60px', marginLeft: '60px' }}>
                        <Badge overlap="rectangular" badgeContent={alertCount} max={999} color="primary"></Badge>
                    </div>
                </div>
            }
        </div>
    );
};