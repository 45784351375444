import ApiService from "Services/apiService";
import UtilService from "Services/utilService";
import _ from "underscore";

const ThresholdServiceV2 = {
    requestThreshold: async (thingId, attributeId, page, pageSize, setRows, setRowCount, sortQuery) => {
        let filter = `filter=thing.id:eq_${thingId},attribute.id:eq_${attributeId}`;
        // let sort = '&sort=alert_threshold.update_date&dir=DESC';
        let sort = sortQuery === "" || sortQuery == undefined ? "&alert_threshold.update_date-" : sortQuery;
        let pagination = `&page=${page}&pageSize=${pageSize}`;

        let requestOptions = {
            url: `/api/alerts/threshold?${filter}${sort}${pagination}`,
        };

        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                let data = [];
                let totalSize = response.totalSize;

                response.data.forEach((ele) => {
                    // console.log(ele);

                    let obj = {
                        id: ele.id,
                        thingId: ele.thingId,
                        attributeId: ele.attributeId,
                        startTime: ele.startTime == 0 ? "—" : UtilService.formateHMA(ele.startTime),
                        endTime: ele.endTime == 0 ? "—" : UtilService.formateHMA(ele.endTime),
                        isAllTime: ele.allTime,
                        lowValue: ele.lowValue,
                        highValue: ele.highValue,
                        updateDate: ele.updateDate,
                    };

                    data.push(obj);
                });

                setRowCount(totalSize);
                setRows(data);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    insertThreshold: async (thingId, attributeId, isAllTime, timeStart, timeEnd, txtLow, txtHigh) => {
        let requestOptions = {
            url: `/api/alerts/threshold`,
            data: {
                thingId: thingId,
                attributeId: attributeId,
                startTime: timeStart,
                endTime: timeEnd,
                isAllTime: isAllTime,
                lowValue: txtLow,
                highValue: txtHigh,
            },
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    deleteThreshold: async (selectedIds) => {
        let requestOptions = {
            url: `/api/alerts/threshold`,
            data: {
                ids: selectedIds,
            },
        };
        // console.log(requestOptions);

        return ApiService.deleteData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default ThresholdServiceV2;
