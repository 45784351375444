import React, { useEffect, useState, useContext } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "../../providers/SettingContext";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import RoomContainer from "./RoomContainer";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import { useTranslation } from "react-i18next";
import SiteBox from "./SiteBox";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import SiteDialogue from "Components/site/SiteDialogue";
import dayjs from "dayjs";
highchartsMore(Highcharts);

export default function Dashboard() {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    const { t, i18n } = useTranslation();

    const [selectedSiteId, setSelectedSiteId] = useState(0);
    const [moyenne, setMoyenne] = useState(false);
    const [alignment, setAlignment] = useState("calendar");
    const [openSite, setOpenSite] = useState(false);
    const [reload, setReload] = useState();

    useEffect(() => {
        setting.setGlobalSetting({ appId: AppId.EMERA_V2 });
        ctx.updateTitle(ApplicationData.find((a) => a.id === AppId.EMERA_V2).title);
    }, []);

    const handleAlignment = (event, newAlignment) => {
        // console.log(newAlignment);
        setAlignment(newAlignment);

        if (newAlignment) {
            if (newAlignment == "calendar") {
                setMoyenne(false);
            }
            if (newAlignment == "clock") {
                setMoyenne(true);
            }
        }
    };

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2 }}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} style={{ flexGrow: "1", display: "flex", flexDirection: "row" }}>
                    <SiteBox selectedSiteId={selectedSiteId} callback={setSelectedSiteId} />
                    <IconButton aria-label="delete" size="large" variant="contained" onClick={() => setOpenSite(true)}>
                        <MoreHorizIcon fontSize="inherit" />
                    </IconButton>
                    <Box sx={{ display: "flex", alignItems: "center", pl: 1, pr: 1 }}>
                        <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment} aria-label="text alignment">
                            <ToggleButton value="calendar" aria-label="btn-calandar" sx={{ width: 50 }}>
                                <i className="fa fa-calendar" aria-hidden="true"></i>
                            </ToggleButton>

                            <ToggleButton value="clock" aria-label="btn-cock" sx={{ width: 50 }}>
                                <i className="fa fa-clock-o" aria-hidden="true"></i>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <RoomContainer selectedSiteId={selectedSiteId} moyenne={moyenne} setMoyenne={setMoyenne} parentReload={reload} />
                </Grid>
            </Grid>

            <SiteDialogue
                open={openSite}
                setOpen={setOpenSite}
                // selectedThingId={selectedThingId}
                setReloadParent={(siteId) => {
                    // console.log(siteId);
                    setSelectedSiteId(siteId);
                    setReload(dayjs());
                }}
            />
        </Box>
    );
}
