import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import moment from "moment";
import UtilService from "Services/utilService";
require("highcharts/modules/exporting")(Highcharts);

const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(0),
        minWidth: 80,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    // noLabel: {
    // 	marginTop: theme.spacing(3),
    // },
}));

export default function DailyConsumption(props) {
    const { t, i18n } = useTranslation();

    const classes = useStyles();
    const [month, setMonth] = useState("");

    useEffect(() => { }, []);

    // Configuration for ReactHighcharts
    const configChart = {
        title: {
            text: `<div style="font-family: 'Days One', sans-serif; font-size: 24px; font-weight: normal; color: #9B9B9B">${t("w_mgt.consumption")}</div>`,
            floating: false,
            useHTML: true,
        },
        yAxis: [
            {
                title: {
                    text: "",
                },
                offset: 20,
                labels: {
                    formatter: function () {
                        return this.value + " m3";
                    },
                    x: 10,
                    style: {
                        color: "#000",
                        position: "absolute",
                    },
                    align: "right",
                    padding: 5,
                },
                opposite: false,
            },
        ],
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            dateTimeLabelFormats: {
                hour: "%H:%M",
            },
        },
        plotOptions: {
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3,
                    },
                },
                marker: {
                    enabled: false,
                },
            },
        },
        chart: {
            type: "spline",
            // height: 380,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
            styledMode: false,
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            type: "datetime",
            tickInterval: 1000 * 3600 * 24,
            title: {
                text: null,
            },
            labels: {
                overflow: "allow",
                formatter: function () {
                    return UtilService.formatDay(this.value);
                },
            },
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false,
        },
        series: [
            {
                name: "Cumulated",
                type: "spline",
                data: [
                    [1680364800000, 10],
                    [1680451200000, 16],
                    [1680537600000, 30],
                    [1680624000000, 40],
                    [1680710400000, 43],
                    [1680796800000, 55],
                    [1680883200000, 70],
                    [1680969600000, 81],
                    [1681056000000, 96],
                    [1681142400000, 110],
                    [1681228800000, 112],
                    [1681315200000, 126],
                    [1681401600000, 139],
                    [1681488000000, 147],
                    [1681574400000, 151],
                    [1681660800000, 152],
                    [1681747200000, 160],
                    [1681833600000, 166],
                    [1681920000000, 180],
                    [1682006400000, 189],
                    [1682092800000, 193],
                    [1682179200000, 198],
                    [1682265600000, 211],
                    [1682352000000, 226],
                    [1682438400000, 238],
                    [1682524800000, 247],
                    [1682611200000, 251],
                    [1682697600000, 257],
                    [1682784000000, 260],
                    [1682870400000, 265],
                ],
            },
            {
                name: "Daily",
                type: "column",
                data: [
                    [1680364800000, 11],
                    [1680451200000, 11],
                    [1680537600000, 10],
                    [1680624000000, 9],
                    [1680710400000, 14],
                    [1680796800000, 15],
                    [1680883200000, 10],
                    [1680969600000, 10],
                    [1681056000000, 11],
                    [1681142400000, 9],
                    [1681228800000, 10],
                    [1681315200000, 10],
                    [1681401600000, 10],
                    [1681488000000, 13],
                    [1681574400000, 15],
                    [1681660800000, 10],
                    [1681747200000, 14],
                    [1681833600000, 12],
                    [1681920000000, 11],
                    [1682006400000, 10],
                    [1682092800000, 12],
                    [1682179200000, 10],
                    [1682265600000, 12],
                    [1682352000000, 7],
                    [1682438400000, 10],
                    [1682524800000, 13],
                    [1682611200000, 12],
                    [1682697600000, 9],
                    [1682784000000, 12],
                    [1682870400000, 15],
                ],
            },
        ],
        navigator: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download",
                },
            },
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    const handleChangeYear = (event) => {
        setMonth(event.target.value);
    };

    const handleChangeMonth = (event) => {
        setMonth(event.target.value);
    };

    return (
        <Box>
            <Paper elevation={3} sx={{ p: 2, height: "100%" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <HighchartsReact highcharts={Highcharts} options={configChart}></HighchartsReact>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}
