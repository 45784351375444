import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import backImg from "Assets/images/agri_water_map.png";
import LightCard from "./LightCard";
import GeneralCard from "./GeneralCard";
import ThresholdDialogV2 from "./details/ThresholdDialogV2";
import ArgiScheduleDialog from "./details/schedule/ArgiScheduleDialog";

export default function AgriWaterMain() {
    const history = useHistory();
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    ctx.updateTitle("Agriculture Water");

    const [isOpenThresholdModal, setIsOpenThresholdModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState();
    const [reload, setReload] = useState();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.AGRICULTURE_WATER,
        });
    }, [reload, open]);

    const handleClick = (type, thing, attribute) => {
        // console.log("handleClick::" + type);
        if (type == "threshold") {
            // let selectedCard = getParam(title, light);
            // setSelectedCard(selectedCard);

            if (thing && attribute) {
                setSelectedCard({
                    thingId: thing.id,
                    attributeId: attribute.id,
                    attributeName: attribute.name,
                });
            } else {
                setSelectedCard();
            }

            setIsOpenThresholdModal(true);
        } else if (type == "schedule") {
            setOpen(true);
        }
    };

    return (
        <div className="container-fluid" style={{ display: "flex", justifyContent: "center", padding: "56px 0 0 0", height: "100vh" }}>
            {/* <MapInteractionCustom showControls={false} disableZoom={true}> */}
            <div style={{ marginTop: "0px", width: "901px", height: "936px", zIndex: 1 }}>
                <img src={backImg} style={{ width: "901px" }} />
            </div>
            <div style={{ marginTop: "0px", width: "901px", height: "936px", zIndex: 2, position: "absolute" }}>
                {/* Top */}
                <div style={{ position: "relative", top: 20, left: 20 }}>
                    <LightCard wid="WID_LIGHT" title="Light radiation" alert={999} callback={handleClick} reload={reload} setReload={setReload} />
                </div>

                <div style={{ position: "absolute", top: 20, right: 180 }}>
                    <GeneralCard wid="WID_AIR_TEMP" title="Air temperature" fullwidth callback={handleClick} reload={reload} setReload={setReload} />
                </div>
                <div style={{ position: "absolute", top: 20, right: 20 }}>
                    <GeneralCard wid="WID_HUMIDITY" title="Humidity" callback={handleClick} reload={reload} setReload={setReload} />
                </div>

                <div style={{ position: "absolute", top: 140, right: 20 }}>
                    <GeneralCard wid="WID_CO2" title="Co2" callback={handleClick} reload={reload} setReload={setReload} />
                </div>

                {/* Top */}
                <div style={{ position: "absolute", top: 645, left: 20 }}>
                    <GeneralCard wid="WID_EC" title="EC" callback={handleClick} reload={reload} setReload={setReload} />
                </div>

                <div style={{ position: "absolute", top: 645, right: 20 }}>
                    <GeneralCard wid="WID_SOIL_TEMP" title="Soil temperature" fullwidth callback={handleClick} reload={reload} setReload={setReload} />
                </div>

                <div style={{ position: "absolute", top: 800, left: 150 }}>
                    <GeneralCard wid="WID_WATER" title="Water content" fullwidth callback={handleClick} reload={reload} setReload={setReload} />
                </div>
            </div>

            {/* </MapInteractionCustom > */}

            {selectedCard && (
                <ThresholdDialogV2
                    onClose={() => setIsOpenThresholdModal(false)}
                    open={isOpenThresholdModal}
                    thingId={selectedCard.thingId}
                    attributeId={selectedCard.attributeId}
                    title="Alert's Thresholds"
                    attributeName={selectedCard.attributeName}
                />
            )}

            <ArgiScheduleDialog open={open} setOpen={setOpen} />
        </div>
    );
}
