import ApiService from "Services/apiService";
import _ from "underscore";

const CatalogService = {
    requestManufacturers: async (page, pageSize, setRows, setRowCount, keyword) => {
        let filter = "";
        if (keyword != undefined) {
            let idsQuery = [];
            idsQuery.push(`{"property":["name", "product.name"],"operator":"ilike","value":"${keyword}"}`);

            let concated = idsQuery.join(",");
            filter += encodeURI(`orFilter=[${concated}]`);
        }

        let requestOptions = {
            url: `/api/manufacturers?${filter}&page=${page}&pageSize=${pageSize}&sort=name&dir=ASC`,
        };

        setRows([]);

        const result = await ApiService.getData(requestOptions);

        if (result) {
            let data = [];
            let totalSize = result.totalSize;

            result.data.forEach((ele) => {
                let obj = {
                    id: ele.id,
                    link: ele.link,
                    name: ele.name,
                };

                data.push(obj);
            });

            setRowCount(totalSize);
            setRows(data);
        }
    },

    requestProducts: async (page, pageSize, setRows, setRowCount, selectedManufacturer, keyword) => {
        let filter = "";
        let manufacturer;
        if (!_.isEmpty(selectedManufacturer)) {
            manufacturer = selectedManufacturer.id;
        }

        // console.log("manufacturer:" + manufacturer);

        let idsQuery = [];
        if (manufacturer) {
            idsQuery.push(`{"property":["product.manufacturer.id"],"operator":"eq","value":"${manufacturer}"}`);
        }

        if (keyword) {
            idsQuery.push(`{"property":["name","product.manufacturer.name","reference"],"operator":"ilike","value":"${keyword}"}`);
        }

        if (Array.isArray(idsQuery) && idsQuery.length > 0) {
            let concated = idsQuery.join(",");
            filter += encodeURI(`orFilter=[${concated}]`);
        }

        let requestOptions = {
            url: `/api/products?${filter}&page=${page}&pageSize=${pageSize}&sort=name&dir=ASC&detailed=true`,
        };

        setRows([]);

        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);

        if (result) {
            let data = [];
            let totalSize = result.totalSize;

            result.data.forEach((ele) => {
                let eleTag = [];

                if (ele.tags && Array.isArray(ele.tags)) {
                    ele.tags.forEach((e) => {
                        eleTag.push(e.tag);
                    });
                }

                let model = ele.model ? ele.model.name : "";
                let manufacturerCategory = ele.manufacturerCategory ? ele.manufacturerCategory : "";
                let connectivityTypes = ele.connectivityTypes ? ele.connectivityTypes.join("_") : "";

                let obj = {
                    id: ele.id,
                    productId: ele.id,
                    name: ele.manufacturer.name + " - " + ele.name,
                    connectivityTypes: CatalogService.convertConnectivity(connectivityTypes),
                    tag: eleTag.join(","),
                    commercialName: ele.name,
                    reference: ele.reference,
                    description: ele.description,
                    manufacturer: ele.manufacturer.name,
                    infoLink: ele.infoLink,
                    model: model,
                    manufacturerCategory: manufacturerCategory,
                };
                data.push(obj);
            });

            setRowCount(totalSize);
            setRows(data); 
        }
    },

    convertConnectivity: (name) => {
        switch (name) {
            case "LORA":
                return "LoRaWAN";
            default:
                return name;
        }
    },
};

export default CatalogService;
