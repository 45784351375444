import UtilService from 'Services/utilService';
import ApiService from 'Services/apiService';
import UserService from 'Services/userService';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const CropService = {

    requestCrop: async (page, pageSize, setRows, setRowCount, status, setSelectedCropGrowthIds) => {

        const company = await UserService.getAuthenticatedUser().then(user => user.company);

        let filter = '';
        if (status !== undefined) {
            filter = `&filter=crop.status:eq_${status},company.name:eq_${company}`;
        } else {
            filter = `&filter=company.name:eq_${company}`;
        }

        let requestOptions = {
            url: `/api/crop?pageSize=${pageSize}&page=${page + 1}&sort=start_date&dir=DESC${filter}`
        };

        // console.log(requestOptions)

        return ApiService.getData(requestOptions)
            .then((response) => {
                let data = [];
                let totalSize = response.totalSize;

                let firstCropGrowthId;

                response.data.forEach(ele => {

                    let obj = {
                        id: ele.id,
                        name: ele.name,
                        startDate: UtilService.shortestFormateDateMDY(ele.startDate * 1000),
                        endDate: UtilService.shortestFormateDateMDY(ele.endDate * 1000),
                        status: ele.status,
                        creationDate: UtilService.shortestFormateDateMDY(ele.creationDate * 1000)
                    }

                    data.push(obj);

                    if (firstCropGrowthId == undefined) {
                        firstCropGrowthId = ele.id;
                    }
                })

                if (setSelectedCropGrowthIds !== undefined) {
                    setSelectedCropGrowthIds(() => [firstCropGrowthId]);
                }

                setRowCount(totalSize)
                setRows(data);

                return firstCropGrowthId;

            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestActiveCrop: (setCrop) => {

        let requestOptions = {
            url: `/api/crop/active`
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                if (response.id !== undefined) {
                    setCrop(response);
                    return response;
                } else {
                    return undefined;
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestActiveFinishedCrop: (setCrop, setSelectedCropId) => {

        let requestOptions = {
            url: `/api/crop/active_finished`
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                if (response.id !== undefined) {
                    setCrop(response);
                    setSelectedCropId(response.id);
                    return response.id;
                } else {
                    console.dir(response);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    insertCrop: (name, startDate, setReloadHistory) => {

        let requestOptions = {
            url: `/api/crop`,
            data: {
                name: name,
                startDate: moment(startDate).unix() * 1000,
                status: "ACTIVE"
            }
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.log("updateGatewayStatus...Response");
                // console.log(response);

                setReloadHistory();
            })
            .catch((e) => {
                console.error(e);
            });
    },


    updateCrop: (id, reload) => {

        let requestOptions = {
            url: `/api/crop/${id}`,
            data: {
                status: "FINISHED"
            }
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                reload();
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestAssets: (setCluster) => {

        let requestOptions = {
            url: `/api/asset/by_company`
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                let data = [];
                response.forEach(ele => {

                    let obj = {
                        id: ele.id,
                        name: ele.name,
                        shortName: ele.shortName,
                        connectivityType: ele.connectivityType
                    }

                    data.push(obj);
                })

                setCluster(data);

            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestDefaultAsset: async () => {

        let requestOptions = {
            url: `/api/asset/default`
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // return response.id; // asset_id
                return {
                    id: response.id,
                    label: response.name,
                    shortName: response.shortName
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

}


export default CropService;