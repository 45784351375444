import { useEffect, useState, useContext } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import ClusterContainer from "./ClusterContainer";

export default function SensDc(props) {
    const [settingCelsius, setSettingCelsius] = useState(props.settingCelsius);
    const ctx = useContext(AppContext);
    // ctx.updateTitle("SensDC")

    const setting = useContext(SettingContext);

    useEffect(() => {
        let currentSetting = setting.globalSetting;

        setting.setGlobalSetting({
            appId: AppId.SENS_DC,
            sensDc: {
                celsius: currentSetting.temperatureMonitoring.celsius,
                language: currentSetting.temperatureMonitoring.language,
            },
        });
        setSettingCelsius(props.settingCelsius);

        ctx.updateTitle(ApplicationData.find((a) => a.id == AppId.SENS_DC).title);

        // return () => { ctx.updateTitle(null) }
    }, []);
    return (
        <div className="container" style={{ marginTop: "55px", paddingLeft: "0px", paddingRight: "0px" }}>
            <ClusterContainer />
        </div>
    );
}
