import Button from "@mui/material/Button";
import style from "./style.module.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export function GatewayColumns(t, handleClick) {
    return [
        {
            field: "id",
            headerName: t("col.id"),
            flex: 1,
            editable: false,
            sortable: false,
            hide: true,
        },
        {
            field: "company.name",
            headerName: t("col.company"),
            flex: 1,
            editable: false,
            sortable: true,
            // headerAlign: "center",
        },
        {
            field: "name",
            headerName: t("col.name"),
            flex: 1,
            editable: true,
            sortable: true,
            // headerAlign: "center",
        },
        {
            field: "gatewayEui",
            headerName: t("col.gateway_eui"),
            flex: 1,
            editable: false,
            sortable: true,
            // headerAlign: "center",
        },
        {
            field: "rfRegion",
            headerName: t("col.rf_region"),
            flex: 0.5,
            editable: false,
            sortable: true,
            // headerAlign: "center",
        },
        {
            field: "connectionStatus",
            headerName: t("col.status"),
            flex: 0.6,
            editable: false,
            sortable: true,
            renderCell: (params) => {
                return <span className={"badge " + (params.value == "ACTIVE" ? style.active : style.inactive)}>{params.value}</span>;
            },
            // headerAlign: "center",
        },
        {
            field: "type",
            headerName: t("col.type"),
            flex: 0.5,
            editable: false,
            sortable: false,
            // headerAlign: "center",
        },
        {
            field: "lastUpdated",
            headerName: t("col.last_updated"),
            flex: 1,
            editable: false,
            sortable: true,
            // headerAlign: "center",
        },
        {
            field: "",
            headerName: t("col.update"),
            sortable: false,
            flex: 0.6,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                if (cellValues.row.type == "SensingNetwork") {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => {
                                handleClick(event, cellValues);
                            }}
                        >
                            <i className="fa fa-refresh" aria-hidden="true"></i>
                        </Button>
                    );
                } else {
                    return (
                        <Button variant="contained" disabled>
                            <i className="fa fa-refresh" aria-hidden="true"></i>
                        </Button>
                    );
                }
            },
        },
        {
            field: "manufacturer",
            headerName: t("catalog.mfr"),
            flex: 0.5,
            editable: false,
            sortable: false,
            // headerAlign: "center",
        },
        {
            field: "model",
            headerName: t("catalog.model"),
            flex: 0.5,
            editable: false,
            sortable: false,
            // headerAlign: "center",
            renderCell: (params) => {
                // console.log("params.value", params.value);
                return (
                    <div
                        style={{ cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", display: "flex", alignItems: "center" }}
                    >
                        {params.value ? params.value : <AddCircleOutlineIcon style={{ fontSize: 20, color: "gray" }} />}
                    </div>
                );
            },
            align: "center",
        },
    ];
}
