import { useEffect, useState } from 'react'

import { AuthenticatedUser } from 'Providers/Context'

import UserService from 'Services/userService'

const AuthProvider = ({ children, onUserLoaded }) => {
    const [user, setUser] = useState()

    useEffect(() => {
        UserService.getAuthenticatedUser()
            .then(user => {
                setUser(user);
            })
    }, [])

    useEffect(() => {
        if (user) onUserLoaded(user)
    }, [user])

    return (
        <AuthenticatedUser.Provider value={user}>
            {children}
        </AuthenticatedUser.Provider>
    )
}

export default AuthProvider