
import React, { useEffect, useContext } from 'react';

export default function TooltipText({ data }) {

    useEffect(() => {
        // do nothing
    }, []);

    return (
        <>
            <div style={{ whiteSpace: 'pre-line' }}>
                {`${data.floor} - ${data.name}
                    Co2 : ${data.co2.value} ${data.co2.unit}
                    Temperature : ${data.temperature.value} ${data.temperature.unit}
                    Humidity : ${data.humidity.value} ${data.humidity.unit}
                    ${data.co2.date}
                    `}
            </div >
        </>
    )
};
