import { useState } from "react";
import Button from "@mui/material/Button";
import ThingList from "./ThingList";
import ThingService from "./ThingService";
import AlertDialog from "./AlertDialog";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/ui/BootstrapDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export default function ThingsDialog({ selectedClusterId, openDialog, setOpenDialog, callback, selectedClusterName }) {
    const [selectionModel, setSelectionModel] = useState();
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setOpenDialog(false);
    };

    const saveClose = () => {
        // console.log("saveClose().....");
        // console.log(selectedClusterId);
        // console.log(selectionModel); // thingIds[]

        ThingService.validateThingInCluster(selectedClusterId, selectionModel).then((in_use) => {
            // console.log("....in_use....");
            // console.log(in_use);

            if (in_use) {
                setIsOpen(true);
            } else {
                ThingService.addThingInCluster(selectedClusterId, selectionModel).then(() => {
                    handleClose();
                    callback();
                });
            }
        });
    };

    const handleUserConfirm = (userConfirmed) => {
        setIsOpen(false); // close the confirmation dialog

        if (userConfirmed) {
            ThingService.addThingInCluster(selectedClusterId, selectionModel).then(() => {
                handleClose();
                callback();
            });
        }
    };

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openDialog} maxWidth="md" fullWidth>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Sensors
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <ThingList callback={setSelectionModel} appId={"SENS_DC"}></ThingList>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={saveClose} color="primary" style={{ textTransform: "unset" }}>
                        Add
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <AlertDialog open={isOpen} handleUserConfirm={handleUserConfirm} rackName={selectedClusterName}></AlertDialog>
        </div>
    );
}
