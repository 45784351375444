import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import store from 'store';
import Constants from '../Constants';

const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
}));


export default function CompareMMDialog(props) {
    const { firstMonth, secondMonth, changeValues, onClose, rows, ...other } = props;

    const [thingName, setThingName] = useState('');
    const [attributeId, setAttributeId] = useState('');
    const [attributeName, setAttributeName] = useState('');
    const [fixedName, setFixedName] = useState('');
    const [unit, setUnit] = useState('');
    const [thingId, setThingId] = useState('');
    let storedConnectivity = store.get(Constants.KEY_CONNECTIVITY) == undefined ? 'LORA' : store.get(Constants.KEY_CONNECTIVITY);

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        changeValues(thingName, attributeId, attributeName, fixedName, unit, thingId);

        onClose();
    };
    const classes = useStyles();


    const handleSelect = (e) => {
        const keys = e.target.value.split(",");

        // console.log("handleSelect in CompareMMDialog");
        // console.log(keys);

        setThingName(keys[0]);
        setAttributeId(keys[1]);
        setAttributeName(keys[2]);
        setFixedName(keys[3]);
        setUnit(keys[4]);
        setThingId(keys[5]);
    }

    function extractKeys(obj) {
        if (obj && obj.thingName) {
            return obj.thingName + "," + obj.attributeId + "," + obj.attributeName + "," + obj.fixedName + "," + obj.unit + "," + obj.thingId;
        }
    }

    return (
        <Dialog {...other}>
            <DialogTitle>Compare to 2<sup>nd</sup> measurement</DialogTitle>
            <DialogContent>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="grouped-native-select">Measurements</InputLabel>
                    <Select native defaultValue="" id="grouped-native-select" onChange={(e) => handleSelect(e)}>
                        <option aria-label="None" value="" />
                        {storedConnectivity == 'LORA' &&
                            rows.map(row => {
                                return (
                                    <optgroup label={row.name} key={row.name}>
                                        {/* HEAD */}
                                        {row.light_001 &&
                                            <option value={extractKeys(row.light_001)}>HEAD - Light 1</option>}
                                        {row.light_003 &&
                                            <option value={extractKeys(row.light_003)}>HEAD - Light 2</option>}
                                        {row.photosynthetically_active_radiation_002 &&
                                            <option value={extractKeys(row.photosynthetically_active_radiation_002)}>HEAD - PAR</option>}
                                        {row.temperature_003 &&
                                            <option value={extractKeys(row.temperature_003)}>HEAD - Temperature</option>}
                                        {row.humidity_003 &&
                                            <option value={extractKeys(row.humidity_003)}>HEAD - Humidity</option>}
                                        {row.o2perc_003 &&
                                            <option value={extractKeys(row.o2perc_003)}>HEAD - O2</option>
                                        }
                                        {/* {!row.o2perc_003 &&
                                            <option value={extractKeys(-1)} disabled>HEAD - O2</option>
                                        } */}
                                        {row.co2_003 &&
                                            <option value={extractKeys(row.co2_003)}>HEAD - CO2</option>}
                                        {row.pressure_003 &&
                                            <option value={extractKeys(row.pressure_003)}>HEAD - Pressure</option>}
                                        {/* GROWTH MEDIUM */}
                                        {row.soil_temp_004 &&
                                            <option value={extractKeys(row.soil_temp_004)}>GROWTH MEDIUM - Temperature</option>}
                                        {row.soil_moisture_raw_004 &&
                                            <option value={extractKeys(row.soil_moisture_raw_004)}>GROWTH MEDIUM - Moisture</option>}
                                        {row.ec_005 &&
                                            <option value={extractKeys(row.ec_005)}>GROWTH MEDIUM - EC</option>}
                                        {row.temperature_005 &&
                                            <option value={extractKeys(row.temperature_005)}>GROWTH MEDIUM - Temperature</option>}
                                        {row.humidity_005 &&
                                            <option value={extractKeys(row.humidity_005)}>GROWTH MEDIUM - Humidity</option>}

                                        {row.phvalue_006 &&
                                            <option value={extractKeys(row.phvalue_006)}>GROWTH MEDIUM - PH</option>}

                                        {/* BOTTOM */}
                                        {row.ambient_light_004 &&
                                            <option value={extractKeys(row.ambient_light_004)}>BOTTOM - Light</option>}
                                        {row.ambient_temp_004 &&
                                            <option value={extractKeys(row.ambient_temp_004)}>BOTTOM - Temperature</option>}
                                        {row.ambient_humidity_004 &&
                                            <option value={extractKeys(row.ambient_humidity_004)}>BOTTOM - Humidity</option>}
                                    </optgroup>
                                );
                            })
                        }
                        {storedConnectivity != 'LORA' &&
                            rows.map(row => {
                                return (
                                    <optgroup label={row.name} key={'KEY_NOT_LORA'}>
                                        {/* HEAD */}
                                        {row.light_001 &&
                                            <option value={extractKeys(row.light_001)}>HEAD - Light</option>
                                        }
                                        {row.temperature_003 &&
                                            <option value={extractKeys(row.temperature_003)}>HEAD - Air temperature</option>
                                        }
                                        {row.humidity_003 &&
                                            <option value={extractKeys(row.humidity_003)}>HEAD - Humidity</option>
                                        }
                                        {row.o2perc_003 &&
                                            <option value={extractKeys(row.o2perc_003)}>HEAD - O2</option>
                                        }
                                        {row.co2_003 &&
                                            <option value={extractKeys(row.co2_003)}>HEAD - CO2</option>
                                        }
                                        {row.pressure_003 &&
                                            <option value={extractKeys(row.pressure_003)}>HEAD - Barometric presure</option>
                                        }

                                        {/* BOTTOM */}
                                        {/* <option value={extractKeys(row.ambient_light_004)}>BOTTOM - Moisture</option>
                                        <option value={extractKeys(row.ambient_temp_004)}>BOTTOM - pH</option>  */}
                                    </optgroup>
                                );
                            })
                        }
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">Cancel</Button>
                <Button onClick={handleOk} color="primary">Ok</Button>
            </DialogActions>
        </Dialog>
    );
}