import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialog } from "../../../components/ui/BootstrapDialog";
import { CustomButton } from "../../../components/ui/CustomButton";
import { TextField } from "@mui/material";
import UserService from "Services/userService";
import validator from "validator";
import ClusterService from "./ClusterService";
import { useTranslation } from "react-i18next";

export default function ClusterCreateDialog({ open, setOpen, callback }) {
    const { t, i18n } = useTranslation();
    const [clusterName, setClusterName] = useState("");
    const [companyId, setCompanyId] = useState();
    const [errClusterName, setErrClusterName] = useState(false);

    useEffect(() => {
        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u);
            setCompanyId(u.companyId);
        });
    }, []);

    useEffect(() => {
        // console.log("Language changed:", i18n.language);
    }, [i18n.language]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleAdd = () => {
        if (validator.isEmpty(clusterName)) {
            setErrClusterName(true);
            return;
        }
        setErrClusterName(false);

        ClusterService.createCluster(companyId, clusterName).then(() => {
            callback();
            setOpen(false);
        });
    };

    return (
        <React.Fragment>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {t("tm.group")}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <TextField
                        label={t("tm.group_name")}
                        variant="outlined"
                        fullWidth
                        value={clusterName}
                        onChange={(e) => setClusterName(e.target.value)}
                        error={errClusterName}
                    />
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <CustomButton name={t("btn.cancel")} autoFocus callback={handleClose} />
                    <CustomButton name={t("btn.add")} callback={handleAdd} />
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
