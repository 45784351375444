import _ from "underscore";
import UtilService from "Services/utilService";
import moment from "moment";

export function GraphMainOption(attributeName, type, unit, chartData, interval, categories, data, chartDataLastWeek) {
    return {
        chart: {
            type: "column",
        },
        title: {
            text: "",
        },
        subtitle: {
            text: "",
        },
        xAxis: {
            categories: categories,
            crosshair: true,
        },
        yAxis: {
            min: 0,
            title: {
                // text: "Consumption (gallons)",
                text: `Consumption (${unit})`,
            },
        },
        tooltip: {
            shared: true,
            useHTML: true,
            formatter: function () {
                // console.log("///------points---")
                // console.log("key=====" + this.x)
                // console.log(this.points)
                // console.log(chartData)
                // console.log(data)
                // console.log(chartDataLastWeek)

                const firstKey = this.points[0].key;
                const secondKey = this.points[1] ? this.points[1].key : "";

                let tabStr = "";
                const filterdFirstData = data
                    .filter((d) => d[0] == firstKey)
                    .map((d) => {
                        let formattedDate = "";

                        if (interval == "day") {
                            formattedDate = UtilService.formatMD(d[3]) + " - " + UtilService.toFromToHouly(d[3]);
                        } else if (interval == "month") {
                            formattedDate = UtilService.formatMD(d[3]);
                        } else {
                            formattedDate = UtilService.formatM(d[3]);
                        }

                        return formattedDate + `</br>Consumption: ` + d[1] + " " + unit;
                    });

                if (filterdFirstData.length > 0) {
                    tabStr = filterdFirstData[0];
                }

                const filterdSecondData = chartDataLastWeek
                    .filter((d) => d[0] == secondKey)
                    .map((d) => {
                        let formattedDate = "";

                        if (interval == "day") {
                            formattedDate = UtilService.formatMD(d[3]) + UtilService.toFromToHouly(d[3]);
                        } else if (interval == "month") {
                            formattedDate = UtilService.formatMD(d[3]);
                        } else {
                            formattedDate = UtilService.formatM(d[3]);
                        }

                        return formattedDate + `</br>Consumption: ` + d[1] + " " + unit;
                    });

                // console.log("filterdSecondData.....")
                // console.log(filterdSecondData)
                if (filterdSecondData.length > 0) {
                    if (tabStr != "") {
                        tabStr += "</br>";
                    }
                    tabStr += filterdSecondData[0];
                }

                return tabStr;
            },
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: interval == "week" ? true : false,
        },
        exporting: {
            enabled: false,
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
        series: [
            {
                // name: `${attributeName}`,
                name: `current week`,
                unit: `${unit == undefined ? "" : unit}`,
                data: chartData,
            },
            {
                // name: `${attributeName}`,
                name: `Past`,
                unit: `${unit == undefined ? "" : unit}`,
                data: chartDataLastWeek,
            },
        ],
    };
}
