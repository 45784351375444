import ApiService from 'Services/apiService';

const SensorService = {

    putTitle: async (thing, name) => {
        let requestOptions = {
            url: "/api/things/" + thing.id,
            data: {
                id: thing.id,
                name: thing.name,
                fixedName: name,
                status: thing.status,
                lastActivityDate: thing.lastActivityDate,
                lastMessageDate: thing.lastMessageDate
            }
        };

        ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            }).catch((e) => console.log(e));
    },

    putTemp: async (clusterId, thing, index, low, high) => {

        var newCustomField = {
            "thingId": thing.id,
            "name": (index == 0 ? 'TH_LOW_TEMP' : 'TH_HIGH_TEMP'),
            "value": (index == 0 ? low.celsius : high.celsius),
            "attributeId": "57dd6bb3-a68b-4643-9255-cb2fed334c3a" // tundra's temperature
        };

        let requestOptions = {
            url: "/api/cluster/" + clusterId + "/custom_field",
            data: newCustomField
        };

        ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            }).catch((e) => console.log(e));
    }
}

export default SensorService;