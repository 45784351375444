import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import HighchartsHeatmap from "highcharts/modules/heatmap";

function options(chartData, subtitle) {
    return {
        chart: {
            type: 'heatmap'
        },

        boost: {
            useGPUTranslations: true
        },

        title: {
            text: 'Co2 variation',
            align: 'left',
            x: 40
        },

        subtitle: {
            // text: 'Siege-SalleA',
            text: subtitle,
            align: 'left',
            x: 40
        },

        xAxis: {
            type: 'datetime',
            min: Date.UTC(2022, 0, 1),
            max: Date.UTC(2022, 11, 31, 23, 59, 59),
            labels: {
                align: 'left',
                x: 5,
                y: 14,
                format: '{value:%B}' // long month
            },
            showLastLabel: false,
            tickLength: 16
        },

        yAxis: {
            title: {
                text: null
            },
            labels: {
                format: '{value}:00'
            },
            minPadding: 0,
            maxPadding: 0,
            startOnTick: false,
            endOnTick: false,
            tickPositions: [0, 6, 12, 18, 24],
            tickWidth: 1,
            min: 0,
            max: 23,
            reversed: true
        },

        colorAxis: {
            stops: [
                [0, '#70AD47'],
                [0.5, '#FFC000'],
                [1, '#FF0000']
            ],
            min: 0,
            max: 1800,
            startOnTick: false,
            endOnTick: false,
            labels: {
                format: '{value} ppm'
            }
        },

        series: [{
            boostThreshold: 100,
            borderWidth: 0,
            nullColor: '#EFEFEF',
            colsize: 24 * 36e5, // one day
            tooltip: {
                headerFormat: 'Co2<br/>',
                pointFormat: '{point.x:%e %b, %Y} {point.y}:00: <b>{point.value} ppm</b>'
            },
            turboThreshold: Number.MAX_VALUE,
            data: chartData
        }],
    };
}

HighchartsHeatmap(Highcharts);

export default function HeatChart({ chartData, subtitle }) {

    useEffect(() => {
        // do nothing
    }, [chartData]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options(chartData, subtitle)} />
        </Box >
    )
};
