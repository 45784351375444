import { useState, useEffect } from 'react'

import Button from '@mui/material/Button';
import imgQuestionMark from '../../../assets/images/question_mark.png';

import style from './style.module.css';

import ApiService from '../../../services/apiService';
import TabMenu from '../tab/index';
import { useHistory } from "react-router-dom";

let imgUrl = "";


const handleActivate = (deveui, setCode, setTitle, setMessage) => {
    activateThings(deveui, setCode, setTitle, setMessage);
}

const onImageError = () => {
    imgUrl = imgQuestionMark;
}

export default function SensorFound(props) {
    let history = useHistory();

    const [code, setCode] = useState(props.code);
    const [title, setTitle] = useState(props.title);
    const [message, setMessage] = useState(props.message);
    const [deveui, setDeveui] = useState(props.deveui);

    let imgUrl = "/api/products/" + props.pid + "/image.png";

    useEffect(() => {
    }, [imgUrl, title, message]);

    const gotoScan = () => {
        history.push(`${process.env.PUBLIC_URL}/device_scan/scan`);
    }

    return (
        <div className="container" style={{ marginTop: "55px" }}>
            <div className="row" >
                <TabMenu value={0}></TabMenu>
            </div>
            <div className="row mt-2">
                <div className="col" style={{ textAlign: "center", display: "inline-block" }}>
                    <img src={imgUrl} onError={onImageError} style={{ height: "150px" }}></img>
                </div>
            </div>
            <div className="row mt-1 mb-1">
                <div className="col">
                    <span className="mt-0 mb-0" style={{ width: "100%", fontSize: "20px", textAlign: "center", display: "inline-block" }}>Scan Successful !</span>
                    <span className="mt-0 mb-1" style={{ width: "100%", fontSize: "15px", textAlign: "center", display: "inline-block" }}>{title}</span>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <span style={{ width: "100%", fontSize: "15px", textAlign: "center", display: "inline-block" }}>{message}</span>
                    {
                        code.indexOf("ACTIVE") == -1 &&
                        <Button variant="contained" style={{ width: "100%", backgroundColor: "rgb(126, 201, 217)", color: "rgb(255,255,255)", textTransform: "none" }} onClick={() => handleActivate(deveui, setCode, setTitle, setMessage)} >Connect to gateway</Button>
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col">
                    <Button variant="contained" style={{ width: "100%", backgroundColor: "rgb(126, 201, 217)", color: "rgb(255,255,255)", textTransform: "none" }} onClick={gotoScan} >New scan</Button>
                </div>
            </div>
        </div>
    )
};

function activateThings(deveui, setCode, setTitle, setMessage) {

    let requestOptions = {
        url: "/api/app/devicescan/activate?filter=thing.name:eq_" + deveui + "&detailed=true"
    };

    ApiService.getData(requestOptions)
        .then((response) => {
            setCode(response.CODE);
            setTitle(response.TITLE);
            setMessage(response.MESSAGE);
        })
        .catch((e) => console.log(e));
}