import { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import moment from "moment";

HighchartsExporting(Highcharts);

export default function ConsumptionHistory(props) {
    const { t, i18n } = useTranslation();

    useEffect(() => { }, []);

    // Configuration for ReactHighcharts
    const configChart = {
        title: {
            text: `<div style="font-family: 'Days One', sans-serif; font-size: 24px; font-weight: normal; color: #9B9B9B">${t(
                "w_mgt.global_consumption"
            )}</div>`,
            align: "center",
            floating: false,
            useHTML: true,
        },
        xAxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            crosshair: true,
        },
        yAxis: [
            {
                title: {
                    text: "",
                },
                offset: 20,
                labels: {
                    formatter: function () {
                        return this.value + " m3";
                    },
                    x: 10,
                    style: {
                        color: "#000",
                        position: "absolute",
                    },
                    align: "right",
                    padding: 5,
                },
                opposite: true,
            },
        ],
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            dateTimeLabelFormats: {
                hour: "%e. %b %H:%M",
            },
        },
        chart: {
            type: "column",
            height: 448,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
            styledMode: false,
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: true,
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false,
        },
        series: [
            {
                name: "2021",
                data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
            },
            {
                name: "2022",
                data: [83.6, 78.8, 98.5, 93.4, 106.0],
            },
        ],
        navigator: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download",
                },
            },
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <HighchartsReact highcharts={Highcharts} options={configChart}></HighchartsReact>
            </Grid>
        </Grid>
    );
}
