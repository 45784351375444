import React, { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import CloudUserService from "./CloudUserService";
import _ from "underscore";
import { Box } from "@mui/material";

export default function RoleCheckBoxes({ username, role }) {
    const [roles, setRoles] = useState(["ADMIN", "USER"]);
    const [selectedRole, setSelectedRole] = useState(["ADMIN"]);

    useEffect(() => {
        // console.log("uef in RoleCheckBoxes")
        // console.log(username)
        // console.log(role)
        setSelectedRole(role);
    }, []);

    const handleChange = (event) => {
        const value = event.target.value;

        // setSelectedRole(typeof value === "string" ? value.split(",") : value);
        setSelectedRole(value);
        // console.log(value);
        CloudUserService.changeRole(username, value);
    };

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <FormControl sx={{ display: "flex", flexShrink: 1 }} size="small">
                <InputLabel id="chk-label"></InputLabel>
                <Select
                    labelId="chk-label"
                    id="chk"
                    multiple={false}
                    value={selectedRole}
                    onChange={(e) => handleChange(e)}
                    input={<OutlinedInput label="" />}
                    renderValue={(selected) => {
                        // console.log("selected...")
                        // return selected.filter(c => c !== '').join(", ");
                        return <div style={{ color: "#7B7B7B", paddingLeft: 5, fontFamily: "Open Sans", fontSize: 14 }}>{selected}</div>;
                    }}
                    sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                    }}
                >
                    {roles.map((name) => (
                        <MenuItem key={name} value={name} style={{ paddingLeft: 5, fontFamily: "Open Sans", fontSize: 14, color: "#7B7B7B" }}>
                            {/* {selectedRole &&
                                <CustomSwitch checked={selectedRole.indexOf(name) > -1} />
                            } */}
                            <ListItemText primary={name} style={{ marginLeft: 5, fontFamily: "Open Sans", fontSize: 14 }} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
