import React, { useState, useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import { Box, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/ui/BootstrapDialog";
import DialogContent from "@mui/material/DialogContent";
import { CustomButton } from "../../../components/ui/CustomButton";
import GatewayService from "./GatewayService";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function UpdateGatewayDialog({ open, setOpen, setReload, selectedGwId }) {
    const { t } = useTranslation();

    const [manufacturer, setManufacturer] = useState("");
    const [modelId, setModelId] = useState("");

    const [loading, setLoading] = useState(false);
    const [manufacturers, setManufacturers] = useState([]);
    const [models, setModels] = useState([]);

    const [errMfr, setErrMfr] = useState(false);
    const [errModel, setErrModel] = useState(false);

    useEffect(() => {
        // console.log("uef in UpdateGatewayDialog");
        GatewayService.findManufacturers()
            .then((data) => setManufacturers(data))
            .then(() => {
                GatewayService.requestGateway(selectedGwId).then((g) => {
                    // console.log("g", g);
                    if (g?.gatewayModel) {
                        const gModel = g.gatewayModel;
                        setModelId(gModel.id);
                        setManufacturer(gModel.gatewayManufacturer.id);
                    }
                });
            });

        if (manufacturer && manufacturer !== "") {
            GatewayService.findModels(manufacturer).then((data) => setModels(data));
        }
    }, [open, selectedGwId]);

    useEffect(() => {
        // Fetch models when manufacturer changes
        if (manufacturer && manufacturer !== "") {
            GatewayService.findModels(manufacturer).then((data) => setModels(data));
        }
    }, [manufacturer]); // Depends on manufacturer

    const handleClose = () => {
        setManufacturer("");
        setModelId("");
        setOpen(false);
        setLoading(false);
    };

    const handleSubmit = () => {
        // console.log("manufacturer", manufacturer === "");
        if (!manufacturer || manufacturer === "") {
            setErrMfr(true);
            return;
        }
        setErrMfr(false);

        if (modelId === "") {
            setErrModel(true);
            return;
        }
        setErrModel(false);

        setLoading(true);
        // console.log("selectedGwId", selectedGwId);
        // console.log("modelId", modelId);

        GatewayService.updateModel(selectedGwId, modelId).then(() => {
            setReload(moment().unix());
            setLoading(false);
            handleClose();
        });
    };

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {t("de.gw_update")}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={{ pt: 2, pl: 5, pr: 5, pb: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="lbl-manufacturer">{t("catalog.mfr")}</InputLabel>
                                    <Select
                                        labelId="sel-manufacturer"
                                        id="select-manufacturer"
                                        value={manufacturer}
                                        label="Manufacturer"
                                        onChange={(e) => setManufacturer(e.target.value)}
                                        variant="standard"
                                        disabled={loading}
                                        error={errMfr}
                                    >
                                        {manufacturers.map((m) => (
                                            <MenuItem key={m.id} value={m.id}>
                                                {m.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="lbl-model">{t("catalog.model")}</InputLabel>
                                    <Select
                                        labelId="sel-model"
                                        id="select-model"
                                        value={modelId}
                                        label="Model"
                                        onChange={(e) => setModelId(e.target.value)}
                                        variant="standard"
                                        disabled={loading}
                                        error={errModel}
                                    >
                                        {models &&
                                            models !== "" &&
                                            models.map((m) => (
                                                <MenuItem key={m.id} value={m.id}>
                                                    {m.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <CustomButton name={t("btn.update")} callback={handleSubmit} />
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
