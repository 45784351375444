import { useEffect, useState } from "react";
import _ from "underscore";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import AttributeComboBox from "./AttributeComboBox";
import StartEndDate from "./StartEndDate";
import Columns from "./columns";
import MeasurementService from "./MeasurementService";
import dayjs from "dayjs";
import { frFR } from "@mui/x-data-grid/locales";
import {
    DataGridPro,
    LicenseInfo,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function MeasuresMain({ selectedThingId }) {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });
    const [sort, setSort] = useState([{ field: "timestamp", sort: "desc" }]);
    const [filterModel, setFilterModel] = useState({});

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const { t, i18n } = useTranslation();

    const [selectedAttributeId, setSelectedAttributeId] = useState("all");

    const currentDate = dayjs();
    const oneMonthBefore = currentDate.subtract(1, "month");

    const [start, setStart] = useState(oneMonthBefore);
    const [end, setEnd] = useState(currentDate);

    const columns = Columns.getColumns(t);

    useEffect(() => {
        // console.log("useEffect in MeasuresGrid");
        // console.log("start:" + start);
        // console.log("end:" + end);

        MeasurementService.requestMeasurements(
            paginationModel.page,
            paginationModel.pageSize,
            setRows,
            selectedThingId,
            sort,
            selectedAttributeId,
            setRowCount,
            start.unix(),
            end.unix()
        );
    }, [paginationModel.page, paginationModel.pageSize, sort, selectedAttributeId, selectedThingId, filterModel, start, end]);

    const handleSelectedAttributeId = (id) => {
        // console.log(id);
        setSelectedAttributeId(id);
    };

    const setAllAttributes = (attributes) => {
        // console.log(attributes);
        setSelectedAttributeId("all");
    };

    const handleCellClick = (params) => {
        if (params.field == "__check__") {
            return;
        }
    };

    return (
        <Box sx={{ mt: 3 }}>
            <Grid container spacing={0}>
            
                <Grid item xs={12} sx={{ display: "flex", gap: 2, mb: 2 }}>
                    <StartEndDate
                        initStart={start}
                        initEnd={end}
                        callback={(s, e) => {
                            setStart(s);
                            setEnd(e);
                        }}
                    />
                </Grid>

                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                    <AttributeComboBox
                        selectedThingId={selectedThingId}
                        open={true}
                        callbackSelectedAttributeId={handleSelectedAttributeId}
                        callbackAllAttributes={setAllAttributes}
                    ></AttributeComboBox>
                </Grid>

                <Grid item xs={12}>
                    <DataGridPro
                        columns={columns}
                        rows={rows}
                        rowCount={rowCount}
                        // disableColumnFilter
                        pagination={true}
                        paginationMode="server"
                        sortingMode="server"
                        sortModel={sort}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[20, 50]}
                        onSortModelChange={setSort}
                        slots={{
                            toolbar: CustomToolbar,
                            // loadingOverlay: LinearProgress,
                        }}
                        checkboxSelection={false}
                        // onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                        density="compact"
                        onCellClick={handleCellClick}
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        sx={{
                            "& .MuiTablePagination-selectLabel": {
                                marginTop: 2,
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginTop: 2,
                            },
                        }}
                        columnVisibilityModel={{
                            id: false,
                        }}
                        // onColumnOrderChange={handleColumnOrderChange}
                        localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                        onFilterModelChange={setFilterModel}
                        style={{ height: "calc(100vh - 390px)" }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <GridToolbarFilterButton />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}
