import _ from "underscore";
import ApiService from "Services/apiService";

const TagService = {
    requestAllTags: async (receiveAllTags) => {
        let requestOptions = {
            url: `/api/tags?sort=tag&dir=ASC&page=1&start=0&limit=25`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                receiveAllTags(response.data);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateTags: async (thingId, selectedCompany, updatedTags) => {
        let requestOptions = {
            url: `/api/tags/tag/${thingId}`,
            data: updatedTags.map((ele) => {
                return {
                    id: ele.id,
                    tag: ele.label,
                    company: selectedCompany,
                };
            }),
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default TagService;
