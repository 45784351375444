import { useEffect } from "react";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

export default function AlertDialog({ message, changeValues, onClose, title, label, ...other }) {
    const { t, i18n } = useTranslation();
    const [comment, setComment] = useState(message);

    const handleCancel = () => {
        setComment("");

        onClose();
    };

    const handleOk = () => {
        changeValues(comment);
        setComment("");

        onClose();
    };

    useEffect(() => {
        if (message !== comment) {
            setComment(message);
        }
    }, [message]);

    return (
        <Dialog fullWidth {...other}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="comment"
                    label={label}
                    multiline
                    type="text"
                    fullWidth
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    {t("btn.cancel")}
                </Button>
                <Button onClick={handleOk} color="primary">
                    {t("btn.validate")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
