import ApiService from "Services/apiService";
import UtilService from "Services/utilService";
import _ from "underscore";
import clsx from "clsx";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const AlarmService = {
    requestData: async (t, thingId, setColumns, setRows, lastActivityDate) => {
        const since = moment(lastActivityDate * 1000)
            .subtract(1, "day")
            .valueOf(); // 24 elements
        // const since = moment().subtract(4, "hours").valueOf(); // 24 elements
        // const since = moment().subtract(1, "day").valueOf(); // 24 elements

        let requestOptions = {
            url: `/v2/measure_range/${thingId}?since=${since}`,
        };

        // console.log(thingId + ", " + moment(lastActivityDate * 1000).format("LLLL"));

        return ApiService.getData(requestOptions)
            .then((r) => {
                // console.log("requestModel...Response");
                // console.log(r);

                if (!r.message) {
                    let column = [
                        {
                            field: "name",
                            headerName: t("col.name"),
                            // flex: 1,
                            minWidth: 120,
                            editable: false,
                            sortable: false,
                            filterable: false,
                        },
                    ];

                    r.header.forEach((e) => {
                        // console.log(e.value)
                        column.push({
                            field: e.field,
                            // headerName: UtilService.formatDMYHM(e.value * 1000),
                            renderHeader: (params) => {
                                return (
                                    <div style={{ textAlign: "center", lineHeight: "1.5" }}>
                                        {UtilService.formatDMY(params.field * 1000)}
                                        <br />
                                        {UtilService.formateHM(params.field * 1000)}
                                    </div>
                                );
                            },
                            // flex: 1,
                            minWidth: 100,
                            editable: false,
                            sortable: false,
                            filterable: false,
                            cellClassName: (params) => {
                                // console.log(params);

                                const f = params.field;
                                const color = params.row.bgColor && params.row.bgColor.find((e) => Object.keys(e).includes(f));
                                // console.log(color[f])

                                if (color) {
                                    // There is a Box in AttributeGrid.js, a style should be one of the box's css style
                                    return clsx("super-app", {
                                        RED: color[f] === "RED",
                                        ORANGE: color[f] === "ORANGE",
                                        YELLOW: color[f] === "YELLOW",
                                        GREEN: color[f] === "GREEN",
                                        BLUE: color[f] === "BLUE",
                                        WHITE: color[f] === "WHITE",
                                    });
                                } else {
                                    return clsx("super-app", {
                                        RED: false,
                                        ORANGE: false,
                                        YELLOW: false,
                                        GREEN: false,
                                        BLUE: false,
                                        WHITE: false,
                                    });
                                }
                            },
                        });
                    });

                    let rows = r.content.map((c) => {
                        let row = {};
                        let bgColor = [];
                        let marks = [];
                        let trackColor = [];

                        c.col.forEach((e) => {
                            row[e.field] = e.value;

                            if (e.bgColor) bgColor.push({ [e.field]: e.bgColor });
                            if (e.marks) marks.push({ [e.field]: e.marks });
                            if (e.trackColor) trackColor.push({ [e.field]: e.trackColor });
                        });

                        return {
                            ...row,
                            bgColor,
                            marks,
                            trackColor,
                        };
                    });

                    setColumns(column);
                    // setRows(rows);
                    // console.log(rows);

                    // Add if they are not exsited.
                    const requiredNames = ["Conductivité", "pH", "Redox"];
                    requiredNames.forEach((name) => {
                        if (!rows.some((element) => element.name === name)) {
                            rows.push({ id: uuidv4(), name });
                        }
                    });

                    const priorityNames = ["Conductivité", "pH", "Redox"];
                    let rearrangedRows = [
                        ...rows.filter((row) => priorityNames[0].includes(row.name)),
                        ...rows.filter((row) => priorityNames[1].includes(row.name)),
                        ...rows.filter((row) => priorityNames[2].includes(row.name)),
                        ...rows.filter((row) => !priorityNames.includes(row.name)),
                    ];

                    setRows(rearrangedRows);
                } else {
                    // no data
                    // console.log(r.message);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestThing: async (thingId) => {
        // console.log("requestThing...");
        // console.log(thingId);

        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        return ApiService.getData(requestOptions).then((r) => {
            return r;
        });
    },
};

export default AlarmService;
