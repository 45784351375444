import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useEffect, useState } from 'react';
import moment from 'moment';
export default function CropChart(props) {

    const chartData = props.chartData;

    const [detailOptions, setDetailOptions] = useState({});
    // const [masterOptions, setMasterOptions] = useState({});
    // const [filterBegin, setFilterBegin] = useState(0);
    // const [filterEnd, setFilterEnd] = useState(9999999999999);


    // Configuration for ReactHighcharts
    const detailChart = {
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: `Number of Days`
            },
            min: 0
        },
        yAxis: [{
            title: {
                text: `Height Zones`
            },
            min: 0
        },],
        tooltip: {
            formatter: function () {
                let cropAndcluster = this.series.userOptions.name;

                return cropAndcluster + '<br/>' +
                    'Height Zone:' + this.y;
            }
        },
        plotOptions: {
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3
                    }
                },
                marker: {
                    enabled: false
                }
            }
        },
        chart: {
            type: 'line',
            height: 500,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1
            },
        },
        credits: {
            enabled: false
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false
        },
        series: chartData,
        // series: [{
        // 	// unit: `${unit}`,
        // 	data: chartData
        // 	// data: chartData.filter(point => point[0] > filterBegin && point[0] < filterEnd)
        // }],
        navigator: {
            enabled: false
        },
        legend: {
            enabled: true,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download"
                }
            }
        },

        time: {
            timezone: moment.tz.guess(),
            useUTC: false
        },
    };


    useEffect(() => {
        setDetailOptions(detailChart);
    }, [chartData]);

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [], h = (h / 3) - 2;
        path = path.concat(this.circle(w - h, y, h, h),
            this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return <div>
        <HighchartsReact highcharts={Highcharts} options={detailOptions} />
    </div>;
}