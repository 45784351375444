import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "underscore";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import makeStyles from "@mui/styles/makeStyles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import UserService from "Services/userService";
import UtilService from "Services/utilService";
import { useTranslation } from "react-i18next";
import ImportTool from "../../deviceExplorer/toolBar/ImportTool";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ListAltIcon from "@mui/icons-material/ListAlt";
import GridViewIcon from "@mui/icons-material/GridView";
import { Box } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    // menuButton: {
    // 	marginRight: theme.spacing(2),
    // },
    title: {
        display: "none",
        // [theme.breakpoints.up('sm')]: {
        // 	display: 'block',
        // },
    },
    search: {
        position: "relative",
        // borderRadius: theme.shape.borderRadius,
        // backgroundColor: alpha(theme.palette.common.black, 0.15),
        // '&:hover': {
        // 	backgroundColor: alpha(theme.palette.common.black, 0.25),
        // },
        // marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        // [theme.breakpoints.up('sm')]: {
        // 	marginLeft: theme.spacing(3),
        // 	width: 'auto',
        // },
        color: "#424242",
    },
    searchIcon: {
        // padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "grey",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        // padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        // transition: theme.transitions.create('width'),
        width: "100%",
        // [theme.breakpoints.up('md')]: {
        // 	width: '20ch',
        // },
    },
    sectionDesktop: {
        display: "none",
        // [theme.breakpoints.up('md')]: {
        // 	display: 'flex',
        // },
    },
    sectionMobile: {
        display: "flex",
        // [theme.breakpoints.up('md')]: {
        // 	display: 'none',
        // },
    },
}));

export default function TopToolBar({ callbackKeyword, callbackMode }) {
    const { t, i18n } = useTranslation();
    let history = useHistory();

    const classes = useStyles();

    const [isAdmin, setIsAdmin] = useState(false);
    const [company, setCompany] = useState("");
    const [isManager, setIsManager] = useState(false);

    const [enableShare, setEnableShare] = useState(false);
    const [openImport, setOpenImport] = useState(false);
    const [viewMode, setViewMode] = useState("grid");

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            if (user != undefined && !UtilService.isEmpty(user.authorities)) {
                let role = user.authorities;

                if (role.toUpperCase() == "TENANTS") {
                    setIsAdmin(false);
                } else if (role.toUpperCase() == "MANAGER") {
                    setIsManager(true);
                } else {
                    // SUPER_ADMIN, ADMIN, CLIENT_ADMIN
                    setIsAdmin(true);
                }

                setCompany(user.company);
            }
        });
    }, [enableShare]);

    const handleKeyword = (e) => {
        if (e instanceof KeyboardEvent) {
            if (e.key != "Enter") {
                return;
            }
        }
        callbackKeyword(e.target.value);
    };

    const handleAssign = () => {
        history.push(`/apps/water_metering/assign/things`);
    };

    return (
        <>
            <AppBar position="static" style={{ backgroundColor: "#FFFFFF" }}>
                <Toolbar>
                    <Grid container>
                        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                            <div className={classes.search} style={{ width: "400px" }}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder="Search by name, tag, site"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ "aria-label": "search" }}
                                    // onBlur={(e) => handleKeyword(e)}
                                    onChange={(e) => handleKeyword(e)}
                                    sx={{ ml: 3 }}
                                    fullWidth
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                            <Stack spacing={1} direction={"row"}>
                                {isAdmin && (
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Button
                                            variant="contained"
                                            startIcon={<i className="fa fa-upload" aria-hidden="true" style={{ color: "rgb(255,255,255)" }}></i>}
                                            style={{ width: "100px", backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none", marginRight: "10px" }}
                                            onClick={() => setOpenImport(true)}
                                        >
                                            {t("btn.import")}
                                        </Button>
                                    </Box>
                                )}

                                <Box sx={{ justifyContent: "right", alignItems: "center" }}>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={viewMode}
                                        onChange={(e, newMode) => {
                                            setViewMode(newMode);
                                            callbackMode(newMode);
                                        }}
                                        exclusive
                                        aria-label="Platform"
                                    >
                                        <ToggleButton value="grid" size="large">
                                            <ListAltIcon />
                                        </ToggleButton>
                                        <ToggleButton value="widget" size="large">
                                            <GridViewIcon />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <ImportTool openImport={openImport} setOpenImport={setOpenImport} defaultCompany={"FLOWINC"}></ImportTool>
        </>
    );
}
