import React, { useEffect, useState, useContext } from "react";
import SuperviotConstants from "../../SuperviotConstants";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import { Box, Grid, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MeasuresMain from "../gird/MeasuresMain";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";

export default function SideManu({ name, callback }) {
    const history = useHistory();
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    const [entity, setEntity] = useState();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        // console.log("uef in SideManu");
        // console.log(name);

        setting.setGlobalSetting({
            appId: AppId.SUPERVIOT,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        if (name) {
            const e = SuperviotConstants.positions.find((d) => d.name == name);
            // console.log(e);
            setEntity(e);
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [name]);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
        if (!newOpen) {
            setEntity(null);
            callback();
        }
    };

    const handleMore = () => {
        history.push(`/apps/superviot/charts/${entity.thingId}`);
    };

    return (
        <>
            {entity && (
                <Box p={2} maxWidth={600} sx={{ borderLeft: "1px dotted", borderColor: "primary.main" }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={4}>
                            <IconButton onClick={toggleDrawer(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                                {name}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button variant="outlined" onClick={() => handleMore()} sx={{textTransform: 'none'}}>
                                    Details...
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <MeasuresMain selectedThingId={entity.thingId} />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}
