import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Item from "Components/ui/Item";
import Typography from "@mui/material/Typography";
import FavoriteWrapper from "./FavoriteWrapper";
import UserService from "Services/userService";
import FavoriteService from "./FavoriteService";
import { useTranslation } from "react-i18next";

export default function MeasurementMain() {
    const { t, i18n } = useTranslation();

    const [lastMeasure, setLastMeasure] = useState([]);
    const [reload, setReload] = useState();
    const [user, setUser] = useState();

    useEffect(() => {
        // console.log("uef in MeasurementMain");

        UserService.getAuthenticatedUser()
            .then((u) => {
                // console.log(u)
                // setUsername(u.cognitoUserName);
                setUser(u);
                return u.cognitoUserName;
            })
            .then((uname) => {
                FavoriteService.requestLast(uname, setLastMeasure);
            });
    }, [reload]);

    return (
        <Box sx={{ m: 8 }}>
            {lastMeasure.length == 0 && (
                <Item elevation={0}>
                    <Box sx={{ m: 1 }}>
                        <Typography variant="body1"> {t("de.no_attributes")} </Typography>
                    </Box>
                </Item>
            )}
            {lastMeasure.length != 0 && <FavoriteWrapper reload={reload} user={user} lastMeasure={lastMeasure} setReload={setReload} />}
        </Box>
    );
}
