export const columns = [
    {
        field: "id",
        headerName: "ID",
        flex: 1,
        editable: false,
        sortable: true,
        filterable: false,
    },
    {
        field: "fixedName",
        headerName: "Name",
        flex: 1,
        editable: false,
        sortable: true,
        filterable: false,
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        editable: false,
        sortable: true,
        filterable: false,
    },
    // {
    //     field: 'attributes.id',
    //     headerName: 'attributeId',
    //     flex: 1,
    //     editable: false,
    //     sortable: true,
    //     filterable: false,
    //     hide: true
    // },
    {
        field: "attributes.name",
        headerName: "Attribute",
        flex: 1,
        editable: false,
        sortable: true,
        filterable: false,
    },
];
