import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { BootstrapDialog, BootstrapDialogTitle } from "../ui/BootstrapDialog";
import Grid from "@mui/material/Grid";
import SiteList from "./SiteList";
import SiteForm from "./SiteForm";
import moment from "moment";

export default function SiteDialogue({ open, setOpen, selectedThingId, setReloadParent }) {
    const [selectedSite, setSelectedSite] = useState("");
    const [reload, setReload] = useState("");
    const [openSiteForm, setOpenSiteForm] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setOpenSiteForm(false);
        setReloadParent();
    };

    const callbackNew = () => {
        // setOpenSiteForm(!openSiteForm);
        // console.log("callbackNew....")
        setOpenSiteForm(true);
        setSelectedSite(undefined);
    };

    const handleDetail = (e) => {
        setSelectedSite(e);
        setOpenSiteForm(true);
    };

    const handleCompleteSelected = (siteId) => {
        // console.log("handleCompleteSelected", siteId)
        setOpen(false);
        setOpenSiteForm(false);
        setReloadParent(siteId);
    };

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={openSiteForm ? "md" : "sm"} fullWidth>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Sites
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={0}>
                        <Grid item xs={openSiteForm ? 6 : 12}>
                            <SiteList
                                selectedThingId={selectedThingId}
                                callbackDetail={(e) => handleDetail(e)}
                                reload={reload}
                                setReload={() => setReload(moment())}
                                callbackNew={callbackNew}
                                handleCompleteSelected={handleCompleteSelected}
                            />
                        </Grid>
                        {openSiteForm && (
                            <Grid item xs={6}>
                                <SiteForm selectedSite={selectedSite} setReload={() => setReload(moment())} />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
