import React, { useEffect, useContext, useState } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "../../providers/SettingContext";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Highcharts from "highcharts/highstock";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { AppId } from "Components/applications/ApplicationData";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import Button from "@mui/material/Button";
import ThresholdDialogV2 from "../smartAgriculture/details/ThresholdDialogV2";
import EmeraConstants from "./EmeraConstants";
import moment from "moment";
import UtilService from "Services/utilService";
highchartsMore(Highcharts);

var ranges = [
        [1625101200000, 1060, 1340],
        [1625104800000, 1240, 1560],
        [1625108400000, 1290, 1570],
        [1625112000000, 1260, 1540],
        [1625115600000, 1230, 1550],
        [1625119200000, 1240, 1520],
        [1625122800000, 510, 790],
        [1625126400000, 255, 545],
        [1625130000000, 1760, 2040],
        [1625133600000, 645, 955],
        [1625137200000, 605, 915],
        [1625140800000, 634, 966],
        [1625144400000, 734, 1066],
        [1625148000000, 700, 980],
        [1625151600000, 710, 990],
        [1625155200000, 760, 1040],
        [1625158800000, 800, 1200],
        [1625162400000, 760, 1040],
        [1625166000000, 740, 1140],
        [1625169600000, 730, 1130],
        [1625173200000, 750, 1050],
        [1625176800000, 840, 1120],
        [1625180400000, 830, 1110],
    ],
    averages = [
        [1625101200000, 1200],
        [1625104800000, 1400],
        [1625108400000, 1430],
        [1625112000000, 1400],
        [1625115600000, 1390],
        [1625119200000, 1380],
        [1625122800000, 650],
        [1625126400000, 400],
        [1625130000000, 1900],
        [1625133600000, 800],
        [1625137200000, 760],
        [1625140800000, 800],
        [1625144400000, 900],
        [1625148000000, 840],
        [1625151600000, 850],
        [1625155200000, 900],
        [1625158800000, 1000],
        [1625162400000, 900],
        [1625166000000, 940],
        [1625169600000, 930],
        [1625173200000, 900],
        [1625176800000, 980],
        [1625180400000, 970],
    ];

function options(title, unit, type) {
    return {
        chart: {
            type: type,
        },
        title: {
            text: title,
        },

        xAxis: {
            type: "datetime",
            labels: {
                overflow: "justify",
                formatter: function () {
                    return UtilService.formatHour("%H", this.value);
                },
            },
        },

        yAxis: {
            title: {
                text: null,
            },
        },

        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: unit,
        },

        series: [
            {
                name: title,
                data: averages,
                zIndex: 1,
                marker: {
                    fillColor: "white",
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[0],
                },
                showInLegend: false,
            },
            {
                name: "Range",
                data: ranges,
                type: "arearange",
                lineWidth: 0,
                linkedTo: ":previous",
                color: Highcharts.getOptions().colors[0],
                fillOpacity: 0.3,
                zIndex: 0,
                marker: {
                    enabled: false,
                },
            },
        ],

        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function ChartCo2() {
    const { t, i18n } = useTranslation();
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    ctx.updateTitle("Emera");

    const [selected, setSelected] = useState(false);
    const [type, setType] = useState("spline");
    const [periodIdx, setPeriodIdx] = useState(3);
    const [cursor, setCursor] = useState("default");
    const [isOpenThresholdModal, setIsOpenThresholdModal] = useState(false);

    const [name, setName] = useState("Co2");
    const [thingId, setThingId] = useState(EmeraConstants.thingId);
    const [attributeId, setAttributeId] = useState(EmeraConstants.co2AttributeId);

    useEffect(() => {
        // console.log("useEffect")

        setting.setGlobalSetting({
            appId: AppId.EMERA,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        return () => {
            ctx.updateTitle(null);
        };
    }, []);

    const handleChartType = () => {
        setSelected(!selected);

        if (selected) {
            setType("spline");
        } else {
            setType("column");
        }
    };

    const handlePeriod = (periodIdx) => {
        setPeriodIdx(periodIdx);
        // setCursor('wait');
    };

    const handleThreshold = () => {
        setIsOpenThresholdModal(true);
    };

    return (
        <Box sx={{ flexGrow: 1, marginTop: 15 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginLeft: 5 }}>
                    <ToggleButton value="check" selected={!selected} onChange={() => handleChartType()} size="small">
                        <ShowChartIcon />
                    </ToggleButton>
                    <ToggleButton value="check" selected={selected} onChange={() => handleChartType()} size="small">
                        <EqualizerIcon />
                    </ToggleButton>
                    <Button variant="contained" color="primary" style={{ textTransform: "none", margin: "5px" }} onClick={() => handleThreshold()}>
                        {t("btn.threshold")}
                    </Button>
                </Grid>
                {/* <Grid item xs={7}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="h6" component="h6" style={{ color: "#9B9B9B" }}>Group By</Typography>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group" style={{ marginLeft: '10px' }}>
                            <Button onClick={() => handlePeriod(0)}
                                style={{ textTransform: "none", backgroundColor: (periodIdx == 0 ? blue[700] : grey[400]), cursor: cursor }}>Hour</Button>
                            <Button onClick={() => handlePeriod(1)}
                                style={{ textTransform: "none", marginLeft: '2px', backgroundColor: (periodIdx == 1 ? blue[700] : grey[400]), cursor: cursor }}>Day</Button>
                            <Button onClick={() => handlePeriod(2)}
                                style={{ textTransform: "none", marginLeft: '2px', backgroundColor: (periodIdx == 2 ? blue[700] : grey[400]), cursor: cursor }}>Month</Button>
                            <Button onClick={() => handlePeriod(3)}
                                style={{ textTransform: "none", marginLeft: '2px', backgroundColor: (periodIdx == 3 ? blue[700] : grey[400]), cursor: cursor }} >None</Button>
                        </ButtonGroup>
                    </Box>
                </Grid> */}
                <Grid item xs={12}>
                    <HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} options={options("CO²", "ppm", type)} />
                </Grid>
            </Grid>

            <ThresholdDialogV2
                onClose={() => setIsOpenThresholdModal(false)}
                open={isOpenThresholdModal}
                thingId={thingId}
                attributeId={attributeId}
                title="Alert's Thresholds"
                attributeName={name}
            />
        </Box>
    );
}
