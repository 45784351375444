import React, { useEffect, useState, useContext } from "react";
import RiotappConstants from "../../RiotappConstants";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import { Drawer, Box, Grid, IconButton, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MeasuresMain from "../gridmeasures/MeasuresMain";
import { useHistory } from "react-router-dom";

export default function SideBar({ name, callback }) {
    const history = useHistory();
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    const [entity, setEntity] = useState();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.RIOTAPP,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        if (name) {
            const e = RiotappConstants.positions.find((d) => d.name === name);
            setEntity(e);
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [name]);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
        if (!newOpen) {
            setEntity(null);
            callback();
        }
    };

    const ButtonDetailsClick = () => {
        history.push(`/apps/riotapp/thingsdetail/${entity.thingId}`);
    };

    return (
        <Drawer
            anchor="right" // Position of the drawer on the right side
            open={open}
            onClose={toggleDrawer(false)}
            sx={{ width: 600, flexShrink: 0 }}
        >
            <Box p={2} sx={{ width: 600, height: "100%" }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={4}>
                        <IconButton onClick={toggleDrawer(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="h6" gutterBottom>
                            {name}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button variant="outlined" onClick={() => ButtonDetailsClick()} sx={{ textTransform: "none" }}>
                                Details...
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <MeasuresMain selectedThingId={entity?.thingId} />
                    </Grid>
                </Grid>
            </Box>
        </Drawer>
    );
}
