import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SearchBox from "../../../components/ui/SearchBox";
import Item from "../../../components/ui/Item";
import Stack from "@mui/material/Stack";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CloudUserService from "./CloudUserService";
import { LicenseInfo } from "@mui/x-license-pro";
import CompanyService from "./CompanyService";
import UserService from "../../../services/userService";
import Alert from "@mui/material/Alert";
import ThingListColumns from "./ThingListColumns";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomConfirmDialog from "../../../components/ui/CustomConfirmDialog";
import { frFR } from "@mui/x-data-grid/locales";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function ThingList({ selectedUserId, selectedCompany, selectedUserRole, selectedCompanyId, isCompanyPage, selectedIsSubCompany, parentReload }) {
    const { t, i18n } = useTranslation();
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [selectionModel, setSelectionModel] = useState([]);
    const [tempSelectionModel, setTempSelectionModel] = useState([]);
    const [reload, setReload] = useState();
    const [isClientAdmin, setIsClientAdmin] = useState();

    const [open, setOpen] = useState(false);
    const columns = ThingListColumns.getColumn(t);

    useEffect(() => {
        // console.log("uef in ThingList");
        // console.log("selectedUserId: " + selectedUserId);
        // console.log("selectedUserRole: " + selectedUserRole);
        // console.log("selectedCompany: " + selectedCompany);
        // console.log("selectedCompanyId: " + selectedCompanyId);
        // console.log("isCompanyPage: " + isCompanyPage);

        // case 1) selectedUserId & selectedCompany -> When selecting a user
        if (selectedUserId) {
            // console.log(selectedUserId);

            UserService.getAuthenticatedUser().then((u) => {
                setSelectionModel([]);
                setIsClientAdmin(u.authorities === "CLIENT_ADMIN" ? true : false);

                if (u.isRootCompany) {
                    CloudUserService.findThingsBySelectedCompany(
                        keyword,
                        selectedCompany,
                        paginationModel.pageSize,
                        paginationModel.page,
                        setRows,
                        setRowCount
                    ).then(() => {
                        // find assigned things by user
                        CloudUserService.requestThingByCloudUserId(selectedUserId).then((assignedThings) => {
                            const selectedThingIds = assignedThings.map((a) => a.thing.id);
                            setSelectionModel(selectedThingIds);
                        });
                    });
                } else {
                    CloudUserService.findAssignedThingsByCompanyId(
                        keyword,
                        paginationModel.pageSize,
                        paginationModel.page,
                        setRows,
                        setRowCount,
                        u.companyId,
                        undefined
                    ).then(() => {
                        // find assigned things by user
                        CloudUserService.requestThingByCloudUserId(selectedUserId).then((assignedThings) => {
                            const selectedThingIds = assignedThings.map((a) => a.thing.id);
                            setSelectionModel(selectedThingIds);
                        });
                    });
                }
            });
        }

        // case 2) selectedCompanyId -> When selecting a group in Group assignement page
        if (selectedCompanyId) {
            UserService.getAuthenticatedUser().then((u) => {
                // find things by logged-in user, this user has CLIENT_ADMIN, see all of the user company's thing
                // CloudUserService.requestThings(keyword, pageSize, page, setRows, setRowCount).then(() => {
                // console.log(u)
                setSelectionModel([]);

                if (u.isRootCompany) {
                    CloudUserService.findThingsBySelectedCompany(
                        keyword,
                        u.company,
                        paginationModel.pageSize,
                        paginationModel.page,
                        setRows,
                        setRowCount,
                        selectedCompanyId
                    ).then(() => {
                        // find thigns by selected group Id (sub company).
                        CompanyService.requestThingByCompanyId(selectedCompanyId).then((assignedThings) => {
                            const selectedThingIds = assignedThings.map((a) => a.thing.id);
                            setSelectionModel(selectedThingIds);
                        });
                    });
                } else {
                    // console.log(u);
                    const userCompanyId = u.companyId;

                    CloudUserService.findAssignedThingsByCompanyId(
                        keyword,
                        paginationModel.pageSize,
                        paginationModel.page,
                        setRows,
                        setRowCount,
                        userCompanyId,
                        selectedCompanyId
                    ).then(() => {
                        CompanyService.requestThingByCompanyId(selectedCompanyId).then((assignedThings) => {
                            // console.log(assignedThings);

                            const selectedThingIds = assignedThings.filter((c) => c && c.thing).map((c) => c.thing.id);

                            setSelectionModel(selectedThingIds);
                        });
                    });
                }
            });
        }
    }, [paginationModel.page, paginationModel.pageSize, keyword, selectedUserId, selectedCompanyId, selectedCompany, reload, parentReload]);

    const handleCell = (param, event) => {
        if (param.field == "__check__") {
            let model = selectionModel;

            if (event.target.checked) {
                model.push(param.id);
            } else {
                model = model.filter((m) => m !== param.id);
            }

            setSelectionModel(model);
            if (selectedUserId) {
                CloudUserService.assignThings(selectedUserId, model, model).then(() => setReload(moment()));
            }
            if (selectedCompanyId) {
                CompanyService.assignThings(selectedCompanyId, model, model).then(() => setReload(moment()));
            }
        }
    };

    const handleUserConfirm = () => {
        setSelectionModel(tempSelectionModel);
        const rowIds = rows.map((r) => r.id);

        if (selectedUserId) {
            CloudUserService.assignThings(selectedUserId, tempSelectionModel, rowIds).then(() => setReload(moment()));
        }
        if (selectedCompanyId) {
            CompanyService.assignThings(selectedCompanyId, tempSelectionModel, rowIds).then(() => setReload(moment()));
        }
    };

    const handleSearch = (k) => {
        setKeyword(k);
    };

    const isRowSelectable = (params) => {
        // Define your conditions to disable rows here
        if (selectedCompanyId) {
            return params.row.selectable;
        } else {
            return true;
        }
    };

    const SearchAndGridSection = () => (
        <Stack spacing={0} sx={{ ml: 2, pt: 7, flexGrow: 1 }}>
            <Item sx={{ height: 60, display: "flex", alignItems: "center" }} elevation={0}>
                <SearchBox callback={handleSearch} />
            </Item>
            <DataGridPro
                rows={rows}
                rowCount={rowCount}
                columns={columns}
                disableColumnFilter
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pagination={true}
                paginationMode="server"
                sortingMode="server"
                pageSizeOptions={[10, 20]}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(newSelection) => setSelectionModel(newSelection)}
                onCellClick={handleCell}
                density="compact"
                disableSelectionOnClick
                disableRowSelectionOnClick
                checkboxSelection
                style={{ maxHeight: "calc(100vh - 150px)", overflow: "hidden" }}
                // headerHeight={0}
                hideFooterSelectedRowCount
                // onRowClick={handleRowClick}
                isRowSelectable={isRowSelectable}
                sx={{
                    "& .MuiTablePagination-selectLabel": {
                        marginTop: 2,
                    },
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: 2,
                    },
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            assigned_users: !isCompanyPage ? true : false,
                            assigned_groups: isCompanyPage ? true : false,
                            status: false,
                        },
                    },
                }}
                localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
            />
            {/* </Item> */}
        </Stack>
    );

    return (
        <Stack direction={"row"} spacing={0} sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
            {selectedUserRole == "ADMIN" && (
                <Box sx={{ ml: 2, pt: 18, height: 50, width: 600, textAlign: "center" }}>
                    <Alert severity="info">{t("de.see_all")}</Alert>
                </Box>
            )}
            {selectedIsSubCompany && selectedUserRole === "USER" && isClientAdmin && <SearchAndGridSection />}
            {!selectedIsSubCompany && selectedUserRole !== "ADMIN" && <SearchAndGridSection />}
            <CustomConfirmDialog open={open} setOpen={setOpen} title={t("de.remove_sensor")} message={t("de.remove_sensor_msg")} callback={handleUserConfirm} />
        </Stack>
    );
}
