import { useState, useEffect, useContext } from "react";
import _ from "underscore";
import style from "./style.module.css";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import SettingContext from "Providers/SettingContext";
import CatalogService from "./CatalogService";
// import imgFront from 'Assets/images/camera_front.png';
import Typography from "@mui/material/Typography";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

export default function ProductList(props) {
    const setting = useContext(SettingContext);
    const [pageSize, setPageSize] = useState(100);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        CatalogService.requestProducts(page, pageSize, setRows, props.selectedManufacturer, props.keyword);
    }, [page, pageSize, props.selectedManufacturer, props.keyword]);

    const handlePageChange = (param) => {
        setPage(param);
    };

    const handlePageSizeChange = (param) => {
        setPageSize(param);
    };

    const handleRowClick = (params) => {
        let selectedRow = rows.find(function (el) {
            return el.id == params.id;
        });
        props.callback(selectedRow);
    };

    const handleSelectionModel = (selectionModel) => {
        setSelectionModel(selectionModel);
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            editable: false,
            sortable: false,
            hide: true,
        },
        {
            field: "productIcon",
            headerName: "ProductIcon",
            flex: 0.2,
            editable: false,
            sortable: false,
            align: "center",
            renderCell: (params) => {
                return (
                    // <img width="auto" height="auto" class={style.manufacturer_image} src={imgFront} />
                    <img width="auto" height="auto" class={style.manufacturer_image} src={`/static/catalog/products/${params.id}/image.png`} alt="Product" />
                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            flex: 0.8,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div style={{ width: "100%" }}>
                        <Typography variant="subtitle2" align="center" style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                            {params.row.name}
                        </Typography>
                        <Typography variant="body2" align="center">
                            {params.row.reference}
                        </Typography>
                        <Typography variant="body2" align="center" style={{ fontWeight: "bold" }}>
                            {params.row.connectivityTypes}
                        </Typography>
                        <Typography variant="body2" align="center">
                            {params.row.tag}
                        </Typography>
                    </div>
                );
            },
        },
        {
            field: "connectivity",
            headerName: "Connectivity",
            flex: 1,
            editable: false,
            sortable: false,
            hide: true,
        },
    ];

    return (
        <DataGridPro
            columns={columns}
            rows={rows}
            disableColumnFilter
            page={page}
            pageSize={pageSize}
            pagination={true}
            paginationMode="server"
            sortingMode="server"
            rowsPerPageOptions={[100]}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
            density="comfortable"
            // onCellClick={handleCellClick}
            disableSelectionOnClick
            style={{ height: "100%" }}
            headerHeight={0}
            rowHeight={75}
            onRowClick={handleRowClick}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
        />
    );
}
