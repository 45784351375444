import ApiService from "Services/apiService";

const FavoriteService = {
    requestLast: async (username, setLastMeasure) => {
        let requestOptions = {
            url: `/api/favorite/${username}/last`,
        };

        // console.log("requestLast in FavoriteService");
        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((r) => {
                // console.log("measures...");
                const groupedData = r.reduce((groups, point) => {
                    const thingId = point.thing.id;
                    const displayName = point.thing.displayName;

                    if (!groups[thingId]) {
                        groups[thingId] = {
                            name: point.thing.name,
                            displayName: displayName,
                            dataPoints: [],
                        };
                    }
                    groups[thingId].dataPoints.push(point);
                    return groups;
                }, {});

                const groupedArray = Object.keys(groupedData).map((thingId) => {
                    return {
                        thingId: thingId,
                        thingName: groupedData[thingId].name,
                        displayName: groupedData[thingId].displayName,
                        dataPoints: groupedData[thingId].dataPoints,
                    };
                });
                setLastMeasure(groupedArray);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    removeFavorite: async (cognitoUserName, thingId) => {
        let requestOptions = {
            url: `/api/favorite/delete`,
            data: {
                username: cognitoUserName,
                thingId: thingId,
            },
        };
        // console.log(requestOptions);

        return ApiService.deleteData(requestOptions)
            .then((response) => {
                // console.log(response);
                return;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    removeFavoriteByAttribute: async (cognitoUserName, thingId, attributeId) => {
        let requestOptions = {
            url: `/api/favorite/delete`,
            data: {
                username: cognitoUserName,
                thingId: thingId,
                attributes: [attributeId],
            },
        };

        // console.log(requestOptions);

        return ApiService.deleteData(requestOptions)
            .then((response) => {
                // console.log(response);
                return;
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default FavoriteService;
