const StaticValues = {
    getHourly: () => {
        return {
            category: [
                "00",
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
            ],
            data: [
                ["00", 137, "gallons", 1672549200000],
                ["01", 136, "gallons", 1672552800000],
                ["02", 194, "gallons", 1672556400000],
                ["03", 172, "gallons", 1672560000000],
                ["04", 114, "gallons", 1672563600000],
                ["05", 114, "gallons", 1672567200000],
                ["06", 130, "gallons", 1672570800000],
                ["07", 132, "gallons", 1672574400000],
                ["08", 165, "gallons", 1672578000000],
                ["09", 118, "gallons", 1672581600000],
                ["10", 140, "gallons", 1672585200000],
                ["11", 166, "gallons", 1672588800000],
                ["12", 195, "gallons", 1672592400000],
                ["13", 117, "gallons", 1672596000000],
                ["14", 118, "gallons", 1672599600000],
                ["15", 173, "gallons", 1672603200000],
                ["16", 192, "gallons", 1672606800000],
                ["17", 150, "gallons", 1672610400000],
                ["18", 159, "gallons", 1672614000000],
                ["19", 190, "gallons", 1672617600000],
                ["20", 124, "gallons", 1672621200000],
                ["21", 120, "gallons", 1672624800000],
                ["22", 140, "gallons", 1672628400000],
                ["23", 102, "gallons", 1672632000000],
            ],
        };
    },

    getDaily: () => {
        return {
            category: [
                "1st",
                "2nd",
                "3rd",
                "4th",
                "5th",
                "6th",
                "7th",
                "8th",
                "9th",
                "10th",
                "11th",
                "12th",
                "13th",
                "14th",
                "15th",
                "16th",
                "17th",
                "18th",
                "19th",
                "20th",
                "21st",
                "22nd",
                "23rd",
                "24th",
                "25th",
                "26th",
                "27th",
                "28th",
                "29th",
                "30th",
                "31st",
            ],
            data: [
                ["1st", 127, "gallons", 1672549200000],
                ["2nd", 175, "gallons", 1672635600000],
                ["3rd", 190, "gallons", 1672722000000],
                ["4th", 116, "gallons", 1672808400000],
                ["5th", 174, "gallons", 1672894800000],
                ["6th", 152, "gallons", 1672981200000],
                ["7th", 196, "gallons", 1673067600000],
                ["8th", 138, "gallons", 1673154000000],
                ["9th", 178, "gallons", 1673240400000],
                ["10th", 166, "gallons", 1673326800000],
                ["11th", 140, "gallons", 1673413200000],
                ["12th", 109, "gallons", 1673499600000],
                ["13th", 128, "gallons", 1673586000000],
                ["14th", 147, "gallons", 1673672400000],
                ["15th", 104, "gallons", 1673758800000],
                ["16th", 109, "gallons", 1673845200000],
                ["17th", 111, "gallons", 1673931600000],
                ["18th", 108, "gallons", 1674018000000],
                ["19th", 145, "gallons", 1674104400000],
                ["20th", 188, "gallons", 1674190800000],
                ["21st", 167, "gallons", 1674277200000],
                ["22nd", 184, "gallons", 1674363600000],
                ["23rd", 199, "gallons", 1674450000000],
                ["24th", 145, "gallons", 1674536400000],
                ["25th", 181, "gallons", 1674622800000],
                ["26th", 184, "gallons", 1674709200000],
                ["27th", 104, "gallons", 1674795600000],
                ["28th", 125, "gallons", 1674882000000],
                ["29th", 148, "gallons", 1674968400000],
                ["30th", 176, "gallons", 1675054800000],
                ["31st", 143, "gallons", 1675141200000],
            ],
        };
    },

    getMontly: () => {
        return {
            category: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            data: [
                ["Jan", 159, "gallons", 1672549200000],
                ["Feb", 189, "gallons", 1675227600000],
                ["Mar", 192, "gallons", 1677646800000],
                ["Apr", 125, "gallons", 1680321600000],
                ["May", 176, "gallons", 1682913600000],
                ["Jun", 159, "gallons", 1685592000000],
                ["Jul", 131, "gallons", 1688184000000],
                ["Aug", 195, "gallons", 1690862400000],
                ["Sep", 122, "gallons", 1693540800000],
                ["Oct", 144, "gallons", 1696132800000],
                ["Nov", 136, "gallons", 1698811200000],
                ["Dec", 166, "gallons", 1701406800000],
            ],
        };
    },
};

export default StaticValues;
