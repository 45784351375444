import { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import ThingList from "./ThingList";
import ThingService from "./ThingService";

const styles = (theme) => ({
    root: {
        margin: 0,
        // padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    // root: {
    //     padding: theme.spacing(2),
    // },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function ThingsDialog({ open, setOpen, meterThingId, callback }) {
    const [selectedDisplay, setSelectedDisplay] = useState();
    const [displayThingList, setDisplayThingList] = useState();

    const handleClose = () => {
        setOpen(false);
    };

    const handleThing = () => {
        ThingService.associateMeterDisplay(meterThingId, selectedDisplay, displayThingList).then(() => {
            callback();
            setOpen(false);
        });
    };

    const handleSelection = (selectionModel, displayThingList) => {
        // console.log(selectionModel);
        // console.log(displayThingList);

        setSelectedDisplay(selectionModel);
        setDisplayThingList(displayThingList);
    };

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="md">
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Display
                </DialogTitle>
                <DialogContent dividers>
                    <ThingList callback={handleSelection} selectedClusterId={meterThingId}></ThingList>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleThing} color="primary" style={{ textTransform: "unset" }}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
