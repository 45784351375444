import React, { useEffect, useState } from "react";
import ItemList from "Components/applications/ItemList";
import UserService from "Services/userService";

const DashboardList = ({ dashboards }) => {
    const [userClientDashBoard, setUserClientDashBoard] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            // console.log(user);

            switch (user.authorities) {
                case "SUPER_ADMIN":
                    setIsSuperAdmin(true);
                    break;
                default:
                    break;
            }

            if (user.client_dashboard) {
                setUserClientDashBoard(user.client_dashboard);
            }
        });
    }, []);

    return userClientDashBoard || isSuperAdmin ? <ItemList elems={dashboards} getTitle={getTitle} getUrl={getUrl} /> : <></>;
};

const getTitle = (dashboard) => {
    return dashboard.title;
};

const getUrl = (dashboard) => {
    return `${process.env.PUBLIC_URL}/dashboards/${dashboard.id}`;
};

export default DashboardList;
