import _ from "underscore";
import style from "./style.module.css";
import UtilService from "Services/utilService";

const FixedColumns = [
    {
        field: "company",
        headerName: "Company",
        width: 150,
        sortable: false,
    },
    {
        field: "fixedName",
        headerName: "Name",
        width: 200,
        sortable: false,
    },
    {
        field: "lastMessageDate",
        headerName: "Last Message",
        width: 200,
        renderCell: (params) => {
            if (params.value) {
                return UtilService.formateDate(params.value * 1000);
            }
        },
        sortable: false,
    },
    {
        field: "manufacturer",
        headerName: "Vendor",
        width: 150,
        sortable: false,
    },
    {
        field: "productName",
        headerName: "Product",
        width: 150,
        sortable: false,
    },
    {
        field: "connectivityType",
        headerName: "Technology",
        width: 200,
        sortable: false,
    },
    {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: (params) => {
            let classStyle = style.error;

            switch (params.value) {
                case "ACTIVE":
                case "CONNECTED":
                    classStyle = style.active;
                    break;
                case "INACTIVE":
                case "NOT_CONNECTED":
                case "JOIN_RECEIVED":
                    classStyle = style.inactive;
                    break;
                default:
                    break;
            }

            return <span className={"badge " + classStyle}>{params.value}</span>;
        },
        sortable: false,
    },
    {
        field: "tags",
        headerName: "Tags",
        width: 110,
        sortable: false,
        renderCell: (params) => {
            return params.value && Array.isArray(params.value) ? params.value.map((p) => p.name).join(",") : "";
        },
    },
    {
        field: "site",
        headerName: "Site",
        width: 110,
        sortable: false,
        renderCell: (params) => {
            if (params.value) {
                return params.value.name;
            }
        },
    },
];

const Coordinator = {
    getFixeColumns: () => {
        return FixedColumns;
    },
};

export default Coordinator;
