import { useState, useEffect } from "react";
import _ from "underscore";
import style from "./style.module.css";
import * as React from "react";
import CatalogService from "./CatalogService";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { frFR } from "@mui/x-data-grid/locales";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function ProductList({ selectedManufacturer, keyword, callback }) {
    const { t, i18n } = useTranslation();
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100,
    });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
        CatalogService.requestProducts(paginationModel.page, paginationModel.pageSize, setRows, setRowCount, selectedManufacturer, keyword);
    }, [selectedManufacturer, keyword, paginationModel.page, paginationModel.pageSize]);

    const handleRowClick = (params) => {
        let selectedRow = rows.find(function (el) {
            return el.id == params.id;
        });
        callback(selectedRow);
    };

    const columns = [
        {
            field: "id",
            headerName: t("col.id"),
            flex: 1,
            editable: false,
            sortable: false,
        },
        {
            field: "productIcon",
            headerName: t("col.product_icon"),
            flex: 0.2,
            editable: false,
            sortable: false,
            align: "center",
            renderCell: (params) => {
                return (
                    <img
                        width="auto"
                        height="auto"
                        className={style.manufacturer_image}
                        src={`/static/catalog/products/${params.id}/image.png`}
                        alt="Product"
                    />
                );
            },
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: t("col.name"),
            flex: 0.8,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div style={{ width: "100%" }}>
                        <Typography variant="subtitle2" align="center" style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                            {params.row.name}
                        </Typography>
                        <Typography variant="body2" align="center">
                            {params.row.reference}
                        </Typography>
                        <Typography variant="body2" align="center" style={{ fontWeight: "bold" }}>
                            {params.row.connectivityTypes}
                        </Typography>
                        <Typography variant="body2" align="center">
                            {params.row.tag}
                        </Typography>
                    </div>
                );
            },
            headerAlign: "center",
        },
        {
            field: "connectivity",
            headerName: t("col.connectivity"),
            flex: 1,
            editable: false,
            sortable: false,
            hide: true,
        },
    ];

    return (
        <DataGridPro
            columns={columns}
            rows={rows}
            disableColumnFilter={true}
            disableColumnMenu={true}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowCount={rowCount}
            pagination={true}
            paginationMode="server"
            sortingMode="server"
            pageSizeOptions={[100]}
            // onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
            density="comfortable"
            // onCellClick={handleCellClick}
            disableSelectionOnClick
            style={{ height: "calc(100vh - 160px)" }}
            headerHeight={0}
            rowHeight={75}
            onRowClick={handleRowClick}
            sx={{
                "& .MuiTablePagination-selectLabel": {
                    marginTop: 2,
                },
                "& .MuiTablePagination-displayedRows": {
                    marginTop: 2,
                },
                "& .MuiDataGrid-columnHeaders": {
                    display: "none",
                },
            }}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        connectivity: false,
                    },
                },
            }}
            hideFooterSelectedRowCount
            localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
        />
    );
}
