import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DetailService from "./DetailService";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomSwitch from "../../../../components/ui/CustomSwitch";
import CloudUserService from "../common/CloudUserService";
import Box from "@mui/material/Box";
import AlertBar from "./bar/AlertBar";
import { Stack } from "@mui/material";
import SiteDialogue from "./site/SiteDialogue";
import moment from "moment";
import LastMeterRead from "./LastMeterRead";
import UserService from "../../../../services/userService";
import CheckOutDialog from "./tenant/CheckOutDialog";
import TenantService from "./tenant/TenantService";
import StateCard from "./StateCard";
import Grid from "@mui/material/Grid";
import BatteryIcon from "../../../../components/ui/BatteryIcon";
import Chip from "@mui/material/Chip";
import Tags from "../tag/Tags";
import DisplayCard from "./DisplayCard";

export default function TabMeterSummary({ selectedThingId }) {
    let defaltError = {
        size: false,
        radio: false,
        owner: false,
        location: false,
        unit: false,
        email: false,
        message: "",
    };
    const [errors, setError] = useState(defaltError);

    const [size, setSize] = useState(0);
    const [radio, setRadio] = useState(false);
    const [owner, setOwner] = useState("");
    const [location, setLocation] = useState("");
    const [unit, setUnit] = useState(""); // Unit #No, This is not unit for attribute or measurement.
    const [email, setEmail] = useState("");

    const [openSite, setOpenSite] = useState(false);
    const [thing, setThing] = useState();
    const [reload, setReload] = useState();
    const [user, setUser] = useState();

    // const [age, setAge] = useState('');

    // const [isSaving, setIsSaving] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState();

    const [openCheckOut, setOpenCheckOut] = useState(false);
    const [checkIn, setCheckIn] = useState();
    const [attUnits, setAttUnits] = useState();
    const [thingName, setThingName] = useState("");
    const [thingDetail, setThingDetail] = useState();
    const [isSaved, setIsSaved] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [thingTags, setThingTags] = useState([]);
    const [sinceInactive, setSinceInactive] = useState();

    useEffect(() => {
        // console.log("uef in MonthlyConsumption");

        DetailService.requestThing(selectedThingId, setThing).then((t) => {
            // console.log("uef in MeterSummary");
            // console.log(t);
            setThingDetail(t);
            setThingName(t.displayName);
            setSelectedCompany(t.company);

            if (t.tags) {
                setThingTags(
                    t.tags.map((ele) => {
                        return {
                            id: ele.id,
                            label: ele.tag,
                        };
                    })
                );
            }

            if (t.site) {
                setLocation(t.site.name);
            } else {
                setLocation("");
            }
            if (t.lastMeasurements && t.lastMeasurements.units) {
                setAttUnits(t.lastMeasurements.units);
            }
            setSelectedProduct(t.product);

            if (t.status === "INACTIVE") {
                const now = moment();
                const creationDate = moment.unix(t.creationDate);

                if (t.lastActivityDate) {
                    const lastAciveDate = moment.unix(t.lastActivityDate);
                    if (now.diff(lastAciveDate, "hours") < 24) {
                        setSinceInactive("Since " + now.diff(lastAciveDate, "hours") + " hours");
                    } else {
                        setSinceInactive("Since " + now.diff(lastAciveDate, "days") + " days");
                    }
                } else {
                    setSinceInactive("Since " + now.diff(creationDate, "days") + " days");
                }
            }
        });

        DetailService.getWaterMeteringAttributes(selectedThingId, setSize, setRadio, setUnit);

        CloudUserService.requestTenant(selectedThingId).then((r) => {
            const name = `${r.firstname || ""} ${r.lastname || ""}`.trim();

            setOwner(name);
            setEmail(r && r.email ? r.email : "");
        });

        UserService.getAuthenticatedUser().then((u) => {
            const editableAuthorities = ["MANAGER", "ADMIN", "SUPER_ADMIN"];
            if (editableAuthorities.includes(u.authorities)) {
                setIsEditable(true);
            }

            if (["CLIENT_ADMIN", "SUPER_ADMIN"].includes(u.authorities)) {
                setIsAdmin(true);
            }
            setUser(u);
        });

        TenantService.requestLastCheckIn(selectedThingId)
            .then((r) => {
                // console.log(r)
                setCheckIn(r);
            })
            .catch((e) => console.error(e));
    }, [, reload, selectedThingId]);

    const handleValue = (e) => {
        if (e.target.name == "selSize") {
            setSize(e.target.value);

            DetailService.updateWaterMeteringAttributes(selectedThingId, [{ name: "size", value: e.target.value }]);
        } else if (e.target.name == "txtRadio") {
            setRadio(e.target.checked);

            DetailService.updateWaterMeteringAttributes(selectedThingId, [{ name: "radio", value: e.target.checked }]);
        }
    };

    const handleChange = (event) => {
        setThingName(event.target.value);
        setIsSaved(true);

        DetailService.updateName(selectedThingId, event.target.value, thingDetail);
    };

    return (
        <Box>
            <Paper elevation={3} sx={{ p: 1 }}>
                <Grid container>
                    <Grid item xs={4} sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                        {thingDetail && thingDetail.product && (
                            <img
                                src={`/static/catalog/products/${thingDetail.product.id}/image.png`}
                                style={{ display: "block", margin: "auto", maxHeight: "150px", height: "auto" }}
                                alt="Product"
                            ></img>
                        )}
                        <Stack direction={"row"} spacing={0} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                            {getStatus(thingDetail)}
                            <Typography variant="caption" sx={{ pl: 1 }}>
                                {sinceInactive}
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", flexGrow: 1, pr: 1 }}>
                                <BatteryIcon thingDetail={thingDetail} />
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={8} sx={{ gap: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                        <TextField id="standard-basic" variant="standard" fullWidth value={thingName} onChange={handleChange} />
                        <Tags selectedThingId={selectedThingId} selectedCompany={selectedCompany} thingTags={thingTags}></Tags>

                        <Grid container>
                            <Grid item xs={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Typography>Size</Typography>
                                <FormControl sx={{ m: 0, minWidth: 100 }} size="small">
                                    <InputLabel id="demo-select-small"></InputLabel>
                                    <Select
                                        name="selSize"
                                        labelId="sel-size"
                                        id="selSize"
                                        value={size}
                                        label=""
                                        onChange={(e) => handleValue(e)}
                                        // display="none"
                                        MenuProps={{
                                            MenuListProps: {
                                                style: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={0}>None</MenuItem>
                                        <MenuItem value={10}>5/8 x ¾”</MenuItem>
                                        <MenuItem value={20}>¾” x ¾”</MenuItem>
                                        <MenuItem value={30}>1”</MenuItem>
                                        <MenuItem value={40}>1 ½”</MenuItem>
                                        <MenuItem value={50}>2"</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Typography>Radio</Typography>
                                <CustomSwitch checked={radio} name="txtRadio" onChange={(e) => handleValue(e)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            <Box sx={{ display: "flex", mt: 1 }}>
                <AlertBar thingId={selectedThingId} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", m: 0, gap: 2 }}>
                {/* Show meter's last read */}
                <LastMeterRead selectedThingId={selectedThingId} selectedProduct={selectedProduct} />

                {/* Show meter's state */}
                <StateCard selectedThingId={selectedThingId} />

                {/* Show assigned Milesight display */}
                <DisplayCard selectedThingId={selectedThingId} />
            </Box>

            <SiteDialogue
                open={openSite}
                setOpen={setOpenSite}
                selectedThingId={selectedThingId}
                setReloadParent={() => {
                    setReload(moment());
                }}
            />

            <CheckOutDialog
                open={openCheckOut}
                setOpen={setOpenCheckOut}
                selectedThingId={selectedThingId}
                data={checkIn}
                attUnits={attUnits}
                setReload={setReload}
            />
        </Box>
    );
}

function getStatus(thingDetail) {
    if (thingDetail != undefined) {
        switch (thingDetail.status) {
            case "ACTIVE":
                // return <span className={'badge ' + style.active} >{thingDetail.status}</span>
                return (
                    <Chip label={thingDetail.status} size={"small"} style={{ fontSize: "11px", fontWeight: 400, backgroundColor: "#9cc96b", color: "#fff" }} />
                );
            case "INACTIVE":
            case "NOT_CONNECTED":
                // return <span className={'badge ' + style.inactive} >{thingDetail.status}</span>
                return (
                    <Chip
                        label={thingDetail.status}
                        size={"small"}
                        style={{ fontSize: "11px", fontWeight: 400, backgroundColor: "#E7E955", color: "#404044" }}
                    />
                );
            case "ERROR":
                // return <span className={'badge ' + style.error} >{thingDetail.status}</span>
                return (
                    <Chip label={thingDetail.status} size={"small"} style={{ fontSize: "11px", fontWeight: 400, backgroundColor: "#e44959", color: "#fff" }} />
                );
            default:
                break;
        }
    }
}
