const CompostConstants = {
    cluster: [
        {
            name: "cluster 1",
            data: [
                createData("Sensor 1", 100, 50, "27°C", "02/13/2024 12:38:22", ""),
                createData("Sensor 2 ", 100, 100, "28.1°C", "02/14/2024 09:38:12", ""),
                createData("Sensor 3 ", 100, 150, "27.8°C", "02/15/2024 10:38:32", ""),
                createData("Sensor 1 ", 100, 200, "28.2°C", "02/16/2024 11:34:22", ""),
            ],
        },
        {
            name: "cluster 2",
            data: [
                createData("Sensor 5", 100 + 100, 50, "27°C", "02/13/2024 12:38:22", ""),
                createData("Sensor 6 ", 100 + 100, 100, "28.1°C", "02/14/2024 09:38:12", ""),
                createData("Sensor 7 ", 100 + 100, 150, "27.8°C", "02/15/2024 10:38:32", ""),
                createData("Sensor 8 ", 100 + 100, 200, "28.2°C", "02/16/2024 11:34:22", ""),
            ],
        },
        {
            name: "cluster 3",
            data: [
                createData("Sensor 9", 100 + 200, 50, "27°C", "02/13/2024 12:38:22", ""),
                createData("Sensor 10", 100 + 200, 100, "28.1°C", "02/14/2024 09:38:12", ""),
                createData("Sensor 11", 100 + 200, 150, "27.8°C", "02/15/2024 10:38:32", ""),
                createData("Sensor 12 ", 100 + 200, 200, "28.2°C", "02/16/2024 11:34:22", ""),
            ],
        },
        {
            name: "cluster 4",
            data: [
                createData(
                    "Microclimate 1",
                    100,
                    350,
                    "27°C",
                    "02/13/2024 12:38:22",
                    "microclimate",
                    "37db7283-42b5-48c6-92a8-98f958f76fa3",
                    "37e91bf7-f7c6-45b9-8182-85016236ed0d"
                ),
                createData(
                    "KOU20 1",
                    400,
                    550,
                    "28.1°C",
                    "02/14/2024 09:38:12",
                    "kou20",
                    "38043d55-f0b9-4a5b-b097-046803c70a5f",
                    "7e8725c9-162c-4cf2-8a08-bb3a0970746d"
                ),
            ],
        },
    ],
};

function createData(name, x, y, temperature, date, type, thingId, attributeId) {
    return { name, position: createPosition(x, y), temperature, date, type, thingId, attributeId };
}

function createPosition(x, y) {
    return { x, y };
}

export default CompostConstants;
