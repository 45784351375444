import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import style from "./style.module.css";
import HalfCircleUI from "../../../components/ui/HalfCircleUI";
import CircleUI from "../../../components/ui/CircleUI";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import AttributeWidget from "Components/widgets/AttributeWidget";

export default function RoomCard({ entity }) {
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const [moyenne, setMoyenne] = useState(false);

    useEffect(() => {
        // do nothing
    }, [moyenne]);

    const handleCalendar = () => {
        setMoyenne(!moyenne);
    };

    const handleButton = (id) => {
        // console.log(id);

        switch (id) {
            case "co2":
                history.push(`${process.env.PUBLIC_URL}/emera/v1/chart_co2`);
                break;
            case "temperature":
                history.push(`${process.env.PUBLIC_URL}/emera/v1/chart_temp`);
                break;
            case "humidity":
                history.push(`${process.env.PUBLIC_URL}/emera/v1/chart_humidity`);
                break;
            default:
                break;
        }
    };

    const handleFloor = (floor) => {
        history.push(`${process.env.PUBLIC_URL}/emera/v1/floor/${floor}`);
    };

    return (
        <Box sx={{ margin: 3, width: "300px" }}>
            <div className={style.corner}>
                <Grid container>
                    {/* Room name */}
                    <Grid item xs={7}>
                        <span className={style.box} onClick={() => handleFloor(entity.floor)}>
                            {entity.floor}-{entity.name}
                        </span>
                    </Grid>

                    {/* Battery icon and Calendar icon */}
                    <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton
                            aria-label="btn-battery"
                            sx={{
                                "&.MuiButtonBase-root:hover": {
                                    cursor: "default",
                                },
                            }}
                            size="large"
                        >
                            <i className="fa fa-battery-full" aria-hidden="true"></i>
                        </IconButton>
                        {!moyenne && (
                            <IconButton aria-label="btn-calandar" onClick={handleCalendar} size="large">
                                <i className="fa fa-calendar" aria-hidden="true"></i>
                            </IconButton>
                        )}
                        {moyenne && (
                            <IconButton aria-label="btn-cock" onClick={handleCalendar} size="large">
                                <i className="fa fa-clock-o" aria-hidden="true"></i>
                            </IconButton>
                        )}
                    </Grid>

                    {/* Daily average */}
                    {moyenne && (
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", height: 30 }}>
                            <Typography variant="h6" component="h6">
                                {t("emera.room_daily_average")}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {!moyenne && <AttributeWidget variant="co2" entity={entity.co2} callback={() => handleButton("co2")} />}
                        {moyenne && <AttributeWidget variant="co2" entity={entity.co2Ave} callback={() => handleButton("co2")} />}
                    </Grid>

                    {/* Temperature */}
                    <Grid item xs={6}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Button variant="text" onClick={() => handleButton("temperature")} sx={{ textTransform: "none" }}>
                                {t("emera.room_temperature")}
                            </Button>
                        </Box>
                        {!moyenne && <HalfCircleUI entity={entity.temperature} />}
                        {moyenne && <HalfCircleUI entity={entity.temperatureAve} />}
                    </Grid>

                    {/* humidity */}
                    <Grid item xs={6}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Button variant="text" onClick={() => handleButton("humidity")} sx={{ textTransform: "none" }}>
                                {t("emera.room_humidity")}
                            </Button>
                        </Box>

                        {!moyenne && <CircleUI entity={entity.humidity} />}
                        {moyenne && <CircleUI entity={entity.humidityAve} />}
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
}
