import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

export default function ConfirmDialog({ maint, open, setOpen, callback }) {
    const { t, i18n } = useTranslation();

    useEffect(() => {}, [maint]);

    const handleClose = () => {
        // console.log("handleClose:");
        // console.log(maintId);
        setOpen(false);
    };

    const handleYes = (maint) => {
        // console.log("handleYes::::" + maint);
        callback(maint);
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth maxWidth="xs">
                <DialogTitle id="alert-dialog-title">{t("superviot.confirmation")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("superviot.del_confirm")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t("btn.no")}</Button>
                    <Button onClick={() => handleYes(maint)} autoFocus>
                        {t("btn.yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
