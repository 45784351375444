import { useState, useEffect, useContext } from "react";
import SettingContext from "Providers/SettingContext";
import ThingService from "./ThingService";
import _ from "underscore";
import Coordinator from "./Coordinator";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import UserService from "Services/userService";
import { frFR } from "@mui/x-data-grid/locales";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function ThingList({ keyword, callback, selectedClusterId }) {
    const setting = useContext(SettingContext);

    const [pageModel, setPageModel] = useState({ page: 0, pageSize: 20 });
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const [displayThing, setDisplayThing] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedThingId, setSelectedThingId] = useState();
    const [sortQuery, setSortQuery] = useState("");

    const { t, i18n } = useTranslation();

    useEffect(() => {
        setColumns(Coordinator.getFixeColumns());

        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u)

            ThingService.requestThing(u.companyId, pageModel.page, pageModel.pageSize, setRows, setRowCount, sortQuery).then((m) => {
                // console.log(m);
                // console.log(m.map(e => e.id))
                setDisplayThing(m.map((e) => e.id));

                ThingService.requestAssignedThing(u.companyId, selectedClusterId).then((r) => {
                    setSelectionModel(r);
                });
            });
        });
    }, [keyword, keyword, pageModel.page, pageModel.pageSize, setting, sortQuery]);

    const handleSortModelChange = (param) => {
        let sortModel = param;

        let qParams = [];
        _.each(sortModel, function (sm) {
            let result = "";

            switch (sm.field) {
                case "thing":
                    result += "displayName";
                    break;
                case "timestamp":
                    result += "lastMessageDate";
                    break;
                case "model":
                    result += "custom_model.name";
                    break;
                case "product":
                    result += "product.name";
                    break;
                case "status":
                    result += "status";
                    break;
                default:
                    break;
            }

            switch (sm.sort) {
                case "asc":
                    result += "&dir=ASC";
                    break;
                case "desc":
                    result += "&dir=DESC";
                    break;
                default:
                    break;
            }

            qParams.push(result);
        });

        let sortQuery = "";
        if (qParams.length > 0) {
            sortQuery = "&sort=" + qParams.join(",");
        }

        setSortQuery(sortQuery);
    };

    const handleSelectionModel = (selectionModel) => {
        setSelectionModel(selectionModel);
        callback(selectionModel, displayThing);
        // console.log(selectionModel);
    };

    const handleCellClick = (params) => {
        setSelectedThingId(params.row.id);
    };

    return (
        <Box>
            <DataGridPro
                columns={columns}
                rows={rows}
                disableColumnFilter
                pageModel={pageModel}
                pagination={true}
                rowCount={rowCount}
                paginationMode="server"
                sortingMode="server"
                pageSizeOptions={[20, 50, 100]}
                onPaginationModelChange={setPageModel}
                onSortModelChange={handleSortModelChange}
                checkboxSelection
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                density="compact"
                onCellClick={handleCellClick}
                disableSelectionOnClick
                style={{ width: "100%", height: "500px" }}
                sx={{
                    "& .MuiTablePagination-selectLabel": {
                        marginTop: 2,
                    },
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: 2,
                    },
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            company: false,
                            manufacturer: false,
                            connectivityType: false,
                            tag: false,
                            site: false,
                        },
                    },
                }}
                localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
            />
        </Box>
    );
}
