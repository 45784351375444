import { useEffect } from "react";
import _ from "underscore";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

// For the Leaflet
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import Box from "@mui/material/Box";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function Map({ name, position, gps }) {
    const { t, i18n } = useTranslation();

    // const [position, setPosition] = useState([45.49573, -73.60635]);
    // const [gps, setGps] = useState();

    function clickHandler(element) {
        // console.log(element);
    }

    useEffect(() => {
        // console.log(thingDetail);
        // DetailService.requestLastGps(thingDetail.id, setPosition, setGps);
    }, []);

    return (
        <Box sx={{ pl: 2, height: 500 }}>
            <Paper elevation={3} sx={{ width: "100%", height: "100%" }}>
                {gps && (
                    <MapContainer center={position} style={{ weight: "100%", height: "100%" }} zoom={13} scrollWheelZoom={true}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                        />
                        <Marker
                            position={gps}
                            eventHandlers={{
                                click: (e) => clickHandler(e),
                                mouseover: (e) => e.target.openPopup(),
                                mouseout: (e) => e.target.closePopup(),
                            }}
                        >
                            <Popup>{name}</Popup>
                        </Marker>
                    </MapContainer>
                )}
                {gps == undefined && (
                    <Typography variant="body1" gutterBottom sx={{ p: 2 }}>
                        {t("de.no_gps")}
                    </Typography>
                )}
            </Paper>
        </Box>
    );
}
