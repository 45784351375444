import { useState, useEffect, useRef, useContext } from 'react'
import makeCancellablePromise from 'make-cancellable-promise'
import { useParams } from 'react-router-dom'

import AppContext from 'Components/AppContext'

import style from './style.module.css';

import DashboardView from 'Components/applications/DashboardView'

const Dashboard = (props) => {

	const { id } = useParams();
	const [widgets, setWidgets] = useState()
	const [dashboard, setDashboard] = useState()

	const ctx = useContext(AppContext)

	// //same as componentDidMount
	useEffect(() => {
		const dashboardRequest = makeCancellablePromise(loadDashboard(id))
		const widgetRequest = makeCancellablePromise(loadDashboardWidgets(id))

		dashboardRequest.promise.then((json) => setDashboard(json))
		widgetRequest.promise.then((json) => setWidgets(json))

		return () => {
			if (dashboardRequest.cancel) dashboardRequest.cancel()
			if (widgetRequest.cancel) widgetRequest.cancel()
		}
	}, [])

	useEffect(() => {
		dashboard && ctx.updateTitle(dashboard.title)

		return () => {
			ctx.updateTitle(undefined)
		}
	}, [dashboard])

	return (
		<div className={style.home}>
			{widgets && <DashboardView widgets={widgets} />}
		</div>
	)
};

//api call to retrieve the dashboard information
const loadDashboard = async (id) => {
	return fetch(`/api/dashboards/${id}`)
		.then((r) => r.json())
		.catch(e => console.log("error during dashboard loading"))
}

//api call to retrieve the dashboard widgets
const loadDashboardWidgets = async (id) => {
	return fetch(`/api/dashboards/${id}/widgets`)
		.then((r) => r.json())
		.catch(e => console.log("error during widgets loading"))
}

export default Dashboard;
