import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import UtilService from "Services/utilService";
import DetailService from "Routes/smartAgriculture/details/DetailService";
import DashboardService from "Routes/smartAgriculture/table/DashboardService";
import _ from "underscore";
import moment from "moment";

export default function CompareMMChart({
    thLow,
    thHigh,
    thRate,
    handlePoint,
    type,
    chartData,
    attrCompare,
    chLow,
    chHigh,
    chRate,
    fixedName,
    attributeName,
    unit,
    periodIdx,
    componentRef,
}) {
    const [options, setOptions] = useState({});
    const [anotherChartData, setAnotherChartData] = useState([]);

    let requestedAttributeId = "";

    // Configuration for ReactHighcharts
    const configChart = {
        title: {
            text: "",
        },
        xAxis: {
            type: "datetime",
            labels: {
                overflow: "justify",
                formatter: function () {
                    return Highcharts.formatMD("%b/%e", this.value);
                },
            },
        },
        yAxis: [
            {
                title: {
                    text: attributeName,
                },
                min: 0,
                plotLines: [
                    {
                        color: "#FF0000",
                        // width: (chLow ? 2 : 0),
                        width: chLow ? 0 : 0,
                        value: thLow,
                    },
                    {
                        color: "#FF0000",
                        // width: (chHigh ? 2 : 0),
                        width: chHigh ? 0 : 0,
                        value: thHigh,
                    },
                ],
            },
            {
                title: {
                    text: DashboardService.getLabel(attrCompare.attributeName),
                },
                min: 0,
                opposite: true,
                plotLines: [
                    {
                        color: "#FF0000",
                        // width: (chLow ? 2 : 0),
                        width: chLow ? 0 : 0,
                        value: thLow,
                    },
                    {
                        color: "#FF0000",
                        // width: (chHigh ? 2 : 0),
                        width: chHigh ? 0 : 0,
                        value: thHigh,
                    },
                ],
            },
        ],
        tooltip: {
            formatter: function () {
                let formattedDate = "";
                if (periodIdx == 0) {
                    formattedDate = UtilService.formatMD(this.x) + " - " + UtilService.toFromToHouly(this.x);
                } else if (periodIdx == 1) {
                    formattedDate = UtilService.formatMD(this.x);
                } else if (periodIdx == 2) {
                    formattedDate = UtilService.formatM(this.x);
                } else if (periodIdx == 3) {
                    formattedDate = UtilService.formatMDHms(this.x);
                }

                let elements = [];
                _.forEach(this.points, (e) => {
                    elements.push(`<b>${e.series.userOptions.name} : </b>${e.point.y} ${e.series.userOptions.unit}`);
                });
                let strEle = elements.join("</br>");

                return formattedDate + "</br>" + strEle;
            },
            shared: true,
            crosshairs: true,
        },
        plotOptions: {
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3,
                    },
                },
                marker: {
                    enabled: true,
                },
            },
            series: {
                animation: {
                    duration: 2000,
                },
            },
        },
        chart: {
            type: type,
            height: 600,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
            zoomType: "x",
            showInNavigator: true,
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: true,
        },
        rangeSelector: {
            enabled: true,
        },
        series: [
            {
                yAxis: 0,
                name: `${attributeName}`,
                unit: `${unit == undefined ? "" : unit}`,
                data: chartData,
                cursor: "pointer",
                events: {
                    click: handlePoint,
                },
                showInLegend: false,
                marker: {
                    radius: 4,
                    enable: true,
                },
            },
            {
                yAxis: 1,
                name: `${DashboardService.getLabel(attrCompare.attributeName)}`,
                data: anotherChartData,
                unit: `${attrCompare.unit == undefined ? "" : attrCompare.unit}`,
                cursor: "pointer",
                events: {
                    click: handlePoint,
                },
                showInLegend: false,
                marker: {
                    radius: 4,
                    enable: true,
                },
            },
        ],
        navigator: {
            enabled: true,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download",
                },
            },
        },

        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };

    useEffect(() => {
        // console.log("useEffect in CompareMMChart");
        setOptions(configChart);

        if (attrCompare != undefined && requestedAttributeId != attrCompare.attributeId) {
            const thingName = attrCompare.thingName;
            const thingId = attrCompare.thingId;
            const attributeId = attrCompare.attributeId;
            if (periodIdx == 0) {
                DetailService.requestHistoryAverage(thingName, thingId, attributeId, attributeName, handleChartData, "hour");
            } else if (periodIdx == 1) {
                DetailService.requestHistoryAverage(thingName, thingId, attributeId, attributeName, handleChartData, "day");
            } else if (periodIdx == 2) {
                DetailService.requestHistoryAverage(thingName, thingId, attributeId, attributeName, handleChartData, "month");
            } else if (periodIdx == 3) {
                DetailService.requestHistoryAll(thingName, thingId, attributeId, attributeName, handleChartData, "none");
            }

            requestedAttributeId = attrCompare.attributeId;
        }
    }, [type, thLow, thHigh, handlePoint, attrCompare, anotherChartData]);

    const handleChartData = (data) => {
        if (anotherChartData != undefined && data != undefined && _.isEqual(anotherChartData, data)) {
            return;
        }
        setAnotherChartData(data);
    };

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return <HighchartsReact ref={componentRef} highcharts={Highcharts} constructorType={"stockChart"} options={options} />;
}
