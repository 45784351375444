import ApiService from "Services/apiService";
import moment from "moment";

const TenantService = {
    requestUsers: async (keyword, page, pageSize, setRows, setRowCount, user) => {
        let orFilter = [
            {
                property: ["cloud_user.first_name", "cloud_user.last_name", "cloud_user.email"],
                operator: "ilike",
                value: keyword,
            },
        ];

        // let filter = `cloud_user.role:eq_USER,cloud_user.groups:ilike_TENANTS`;
        let filter = `cloud_user.role:eq_USER,cloud_user.domain_name:eq_iflowinc`;

        if (user && user.authorities === "MANAGER") {
            // A MANAGER (like ESTHERLAN) can see only her company’s tenant
            filter += `,company.id:eq_${user.companyId}`;
        }

        let requestOptions = {
            url: `/api/cloud_user?pageSize=${pageSize}&page=${page + 1}&orFilter=${encodeURI(JSON.stringify(orFilter))}&filter=${filter}`,
        };

        // console.log("TenantService.requestUsers.....");
        // console.log(requestOptions);

        ApiService.getData(requestOptions).then((r) => {
            // console.log("//------");
            // console.log(r);
            setRows(r.data);
            setRowCount(r.totalSize);
        });
    },

    requestAssignedTenant: async (thingId, setSelectedRow) => {
        let requestOptions = {
            url: `/api/cloud_user/${thingId}/assigned_tenant`,
        };

        ApiService.getData(requestOptions).then((r) => {
            // console.log("//------");
            // console.log(r);
            setSelectedRow(r.id);
        });
    },

    requestAssignedTenantInfo: async (thingId) => {
        let requestOptions = {
            url: `/api/cloud_user/${thingId}/assigned_tenant`,
        };

        return ApiService.getData(requestOptions).then((r) => {
            return r;
        });
    },

    assignTenantToThing: async (thingId, cloudUserId) => {
        let requestOptions = {
            url: "/api/cloud_user/assign_tenant",
            data: {
                thingId: thingId,
                cloudUserId: cloudUserId,
            },
        };

        return ApiService.putData(requestOptions).then((res) => {
            // console.log(res);
        });
    },

    updateTenantName: async (thingId, firstName, lastName) => {
        let requestOptions = {
            url: `/v2/things/${thingId}/custom_fields`,
            data: [
                {
                    name: "watermeter_firstname",
                    value: firstName,
                },
                {
                    name: "watermeter_lastname",
                    value: lastName,
                },
            ],
        };
        // console.log(requestOptions);

        return ApiService.putData(requestOptions).then((res) => {
            // console.log(res);
        });
    },

    checkIn: async (thingId, cloudUserId, checkInDate) => {
        let date = moment(checkInDate).set({ hour: 0, minute: 0, second: 0 });

        let requestOptions = {
            url: "/v2/check/checkin",
            data: {
                cloudUserId: cloudUserId,
                thingId: thingId,
                checkInDate: date.unix() * 1000,
            },
        };

        // Check this out
        // console.log(requestOptions);

        return ApiService.postData(requestOptions).then((res) => {
            // console.log(res);
        });
    },

    checkOut: async (checkId, checkOutDate, checkOutVolume) => {
        let date = moment(checkOutDate).set({ hour: 0, minute: 0, second: 0 });

        let requestOptions = {
            url: "/v2/check/checkout",
            data: {
                checkId: checkId,
                checkOutDate: date.unix(),
                checkOutVolume: checkOutVolume,
            },
        };

        // Check this out
        // console.log(requestOptions);

        return ApiService.postData(requestOptions).then((res) => {
            // console.log(res);
            return res;
        });
    },

    requestLastCheckIn: async (thingId) => {
        let requestOptions = {
            url: `/v2/check/${thingId}/lastCheckIn`,
        };

        // console.log(requestOptions)

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r)
            if (!r.message) {
                return r;
            }
        });
    },

    requestLastCheckOut: async (thingId) => {
        let requestOptions = {
            url: `/v2/check/${thingId}/lastCheckOut`,
        };

        // console.log(requestOptions)

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r)
            if (!r.message) {
                return r;
            }
        });
    },

    requestVolume: async (thingId, baseDate) => {
        let requestOptions = {
            url: `/v2/check/${thingId}/${baseDate * 1000}/volume`,
        };

        // console.log(requestOptions);

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);
            return r;
        });
    },

    findCloudUser: async (cloudUserId) => {
        let requestOptions = {
            url: `/api/cloud_user/${cloudUserId}`,
        };

        return ApiService.getData(requestOptions).then((r) => {
            // console.log("//------");
            // console.log(r);
            return r;
        });
    },
};

export default TenantService;
