import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import GrowthService from './GrowthService';
import moment from 'moment';
import Alert from '@mui/material/Alert';

export default function GrowthDialog(props) {
    const { selectedAssetId, selectedCropId, selectedStep, onClose, ...other } = props;

    // const [open, setOpen] = useState(props.open);
    const [growthDate, setGrowthDate] = useState(new Date());
    const [isAllCluster, setIsAllCluster] = useState(false);
    const [errMsg, setErrMsg] = useState(props.errMsg);
    const [lock, setLock] = useState(false);

    const handleNo = () => {
        setErrMsg(undefined);
        onClose();
    };

    const handleYes = () => {
        // onClose();
        if (lock) {
            return;
        }

        setLock(true);

        GrowthService.postAsset(selectedAssetId, selectedCropId, moment(growthDate).unix(), selectedStep, (msg) => setErrMsg(msg), onClose)
            .then(() => { setLock(false) })
            .catch(() => { setLock(false) });
    };

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        // padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {
        // do nothing
    }, []);

    return (
        <div>
            <Dialog {...other}>
                <DialogTitle>Plant Growth</DialogTitle>
                <DialogContent style={{ paddingTop: '20px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Next stage start date"
                                    value={growthDate}
                                    onChange={(newValue) => {
                                        setGrowthDate(newValue);
                                    }}
                                    showTodayButton
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            Are you sure you would like to start the new stage ?
                            {errMsg &&
                                <Stack sx={{ width: '100%' }} spacing={2}>
                                    <Alert severity="error">{errMsg}</Alert>
                                </Stack>
                            }
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNo}>No</Button>
                    <Button onClick={handleYes}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
