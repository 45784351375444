import style from "./style.module.css";
import { useState, useEffect, useContext } from "react";
import makeCancellablePromise from "make-cancellable-promise";

import ApiService from "Services/apiService";

import DashboardList from "Components/applications/DashboardList";
import ApplicationList from "Components/applications/ApplicationList";

import ApplicationData from "Components/applications/ApplicationData";
import AppContext from "Components/AppContext";
import SettingContext from "../../providers/SettingContext";

export default function Home(props) {
    const [isLoading, setLoading] = useState(true);
    const [dashboards, setDashboards] = useState([]);

    // Initialize the title when returning from the application that changes the title.
    const ctx = useContext(AppContext);

    const setting = useContext(SettingContext);

    // //same as componentDidMount
    useEffect(() => {
        ctx.updateTitle(null);

        // Initialize setting
        setting.setGlobalSetting({
            appId: undefined,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        let request;

        if (dashboards.length === 0) {
            request = makeCancellablePromise(ApiService.getDashboards());

            request.promise
                .then(({ data }) => {
                    setDashboards(data);
                    setLoading(false);
                })
                .catch((e) => console.log("error during dashboard loading"));
        }

        return () => {
            //console.log('destroy')
            if (request.cancel) request.cancel();
        };
    }, []);

    return (
        <div className={style.home}>
            {/* <h2>Dashboards</h2> */}
            {dashboards ? (
                <div>
                    <br />
                    <DashboardList dashboards={dashboards} isLoading={isLoading}></DashboardList>
                </div>
            ) : (
                ""
            )}
            {/* <h2>Application</h2> */}
            <br />
            <ApplicationList applications={ApplicationData}></ApplicationList>
        </div>
    );
}
