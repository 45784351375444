import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { useEffect, useState, useRef } from 'react';
import _, { constant } from 'underscore';
import UtilService from 'Services/utilService';
import { set } from 'store';

export default function MasterDetailChart({ thLow, thHigh, thRate, handlePoint, type, chartData,
    attributeName, unit, chLow, chHigh, chRate, fixedName, periodIdx }) {

    const [detailOptions, setDetailOptions] = useState({});
    // const [masterOptions, setMasterOptions] = useState({});
    const [filterBegin, setFilterBegin] = useState(0);
    const [filterEnd, setFilterEnd] = useState(9999999999999);

    const chart1 = useRef(null);

    let maxEntity = _.max(chartData, function (o) {
        return o[1];
    })

    let maxValue = 0;
    if (!_.isUndefined(maxEntity) && !_.isEmpty(maxEntity)) {
        maxValue = maxEntity[1];
        if (maxEntity[1] < thHigh && chHigh) {
            maxValue = thHigh;
        };
    };

    const detailChart = {
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
        },
        yAxis: [{
            title: {
                text: `${attributeName}`
            },
            min: 0,
            max: maxValue,
            plotLines: [{
                dashStyle: 'Dash',
                color: '#F29925',
                width: (chLow ? 2 : 0),
                value: thLow
            },
            {
                dashStyle: 'Dash',
                color: '#F29925',
                width: (chHigh ? 2 : 0),
                value: thHigh
            }]
        },],
        tooltip: {
            formatter: function () {

                let formattedDate = '';
                if (periodIdx == 0) {
                    formattedDate = UtilService.formatMD(this.x) + ' - ' + UtilService.toFromToHouly(this.x);
                } else if (periodIdx == 1) {
                    formattedDate = UtilService.formatMD(this.x);
                } else if (periodIdx == 2) {
                    formattedDate = UtilService.formatM(this.x);
                } else if (periodIdx == 3) {
                    formattedDate = UtilService.formatMDHms(this.x);
                }


                return fixedName + "<br/>" + formattedDate +
                    '</br>' + '<b>' + (periodIdx == 3 ? 'Value' : 'Average Value') + ' : ' + this.y + '</b>';

                // return fixedName + "<br/>" + formattedDate +
                // 	'</br>' + '<b>' + (periodIdx == 3 ? 'Value' : 'Average Value') + ' : ' + this.y + ' ' + this.series.userOptions.unit + '</b>';
            }
        },
        plotOptions: {
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3
                    }
                },
                marker: {
                    enabled: true
                }
            },
            series: {
                animation: {
                    duration: 2000
                },
                dataGrouping: {
                    groupPixelWidth: 5
                }
            }
        },
        chart: {
            type: type,
            height: 500,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1
            },
            zoomType: 'x',
            showInNavigator: true
        },
        credits: {
            enabled: false
        },
        rangeSelector: {
            enabled: true
        },
        series: [{
            name: attributeName,
            unit: `${unit}`,
            data: chartData.filter(point => point[0] > filterBegin && point[0] < filterEnd),
            events: {
                click: handlePoint
            }
        }],
        navigator: {
            enabled: true
        },
        legend: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download"
                }
            }
        },
        time: {
            useUTC: false
        }
    };


    useEffect(() => {
        // console.log("useEffect in MasterDetailChart");
        // console.log(attributeName);
        setDetailOptions(detailChart);
        // setMasterOptions(masterChart);

    }, [type, thLow, thHigh, handlePoint, filterBegin, filterEnd, chartData]);

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [], h = (h / 3) - 2;
        path = path.concat(this.circle(w - h, y, h, h),
            this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return <div>
        <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={detailOptions} />
        {/* <HighchartsReact highcharts={Highcharts} options={masterOptions} /> */}
    </div>;
}