import { useEffect, useState, useContext } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import { useHistory } from "react-router-dom";
import ClusterContainer from "./ClusterContainer";

export default function TemperatureMonitoring(props) {
    let history = useHistory();
    const [settingCelsius, setSettingCelsius] = useState(props.settingCelsius);

    const ctx = useContext(AppContext);
    // ctx.updateTitle("Temperature monitoring")
    ctx.updateTitle("TempMon");

    const setting = useContext(SettingContext);

    useEffect(() => {
        let currentSetting = setting.globalSetting;
        setting.setGlobalSetting({
            appId: AppId.TEMPERATURE_MONITORING,
            temperatureMonitoring: {
                celsius: currentSetting.temperatureMonitoring.celsius,
                language: currentSetting.temperatureMonitoring.language,
            },
        });
        setSettingCelsius(props.settingCelsius);

        return () => {
            ctx.updateTitle(null);
        };
    }, []);
    return (
        <div className="container" style={{ marginTop: "55px", paddingLeft: "0px", paddingRight: "0px" }}>
            <ClusterContainer />
        </div>
    );
}
