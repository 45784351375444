import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UserService from "Services/userService";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
}));

export default function SiteBox({ callback }) {
    const classes = useStyles();

    const [value, setValue] = useState(0);

    useEffect(() => {
        // console.log("uef in SiteBox");

        UserService.getAuthenticatedUser().then((user) => {
            // console.log(user);
            if (user.authorities.indexOf("SUPER_ADMIN") != -1 || user.authorities.indexOf("APPLICATION_SMART_AGRICULTURE_PLUS") != -1) {
            }
        });
    }, []);

    const handleChange = (event) => {
        // setValue(event.target.value);
        callback(event.target.value);
        setValue(event.target.value);
    };

    return (
        <FormControl className={classes.formControl} style={{ width: "calc(100% - 20px)" }}>
            <Select
                id="site"
                value={value}
                onChange={handleChange}
                sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                }}
            >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Siege</MenuItem>
                <MenuItem value={2}>Agence</MenuItem>
            </Select>
        </FormControl>
    );
}
