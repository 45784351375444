import UtilService from "Services/utilService";
import ApiService from "Services/apiService";
import _ from "underscore";

const GatewayService = {
    requestGateways: async (page, pageSize, setRows, sort, appId, isAdmin, company, setRowCount) => {
        let requestOptions = {
            url: `/v2/gateways`,
            params: {
                page: page,
                pageSize: pageSize,
                sort: sort.map((s) => `${s.field}:${s.sort}`).join(","),
                company: company,
            },
        };

        // if (!isAdmin) {
        //     requestOptions.url += `&filter=company:eq_${company}`;
        // }

        requestOptions.url = encodeURI(requestOptions.url);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requestGateways...Response");
                // console.log(response);

                let data = [];

                response.content.forEach((ele) => {
                    // console.log(ele.gatewayModel);

                    let obj = {
                        id: ele.id,
                        name: ele.name,
                        connectionStatus: ele.connectionStatus == "Connected" ? "ACTIVE" : "INACTIVE",
                        lastUpdated: UtilService.shortFormateDate(ele.lastUpdated * 1000),
                        type: ele.type,
                        gatewayEui: ele.gatewayEui,
                        rfRegion: ele.rfRegion,
                        "company.name": ele.company.name,
                        manufacturer: ele.gatewayModel?.gatewayManufacturer.name,
                        model: ele.gatewayModel?.name,
                    };

                    data.push(obj);
                });

                setRowCount(response.totalElements);
                setRows(data);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestGateway: async (gatewayId) => {
        const result = await ApiService.getData({ url: `/v2/gateways/${gatewayId}` });
        return result;
    },

    updateGatewayStatus: async (id) => {
        let requestOptions = {
            url: `/api/gateways/${id}`,
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log("updateGatewayStatus...Response");
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateGatewayName: async (id, name) => {
        let requestOptions = {
            url: `/api/gateways/${id}/${encodeURI(name)}`,
        };

        // console.log(requestOptions);
        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log("updateGatewayName...Response");
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    createGateway: async (devEui, company, rfRegion, description, modelId, serialNumber) => {
        let requestOptions = {
            url: `/api/gateways/create`,
            data: {
                devEui,
                company,
                rfRegion,
                description, 
            },
        };

        if (modelId) {
            requestOptions.data["modelId"] = modelId;
        }

        if (serialNumber) {
            requestOptions.data["sn"] = serialNumber;
        }

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.log("createGateway...Response");
                console.log(response);

                if(response.code === "failure") {
                    throw new Error(response.message);
                }
            });
    },

    updateModel: async (selectedGwId, modelId) => {
        let requestOptions = {
            url: `/v2/gateways`,
            data: {
                gatewayId: selectedGwId,
                modelId,
            },
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log("createGateway...Response");
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    findManufacturers: async () => {
        let requestOptions = {
            url: `/v2/gateway_mfr`,
        };

        const result = await ApiService.getData(requestOptions);
        return result;
    },

    findModels: async (mtrId) => {
        let requestOptions = {
            url: `/v2/gateway_mfr/${mtrId}`,
        };

        const result = await ApiService.getData(requestOptions);
        return result;
    },
};

export default GatewayService;
