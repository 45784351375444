import moment from "moment";

export function chartOption(isCelsius, t, min, ave, max) {
    return {
        title: {
            text: "",
        },
        yAxis: [
            {
                title: {
                    text: "",
                },
                offset: 20,
                labels: {
                    formatter: function () {
                        return this.value + (isCelsius ? " °C" : " °F");
                    },
                    x: 10,
                    style: {
                        color: "#000",
                        position: "absolute",
                    },
                    align: "right",
                    padding: 5,
                },
                opposite: true,
            },
        ],
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ' + (isCelsius ? " °C" : " °F") + "<br/>",
            dateTimeLabelFormats: {
                hour: "%e. %b %H:%M",
            },
        },
        plotOptions: {
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3,
                    },
                },
                marker: {
                    enabled: false,
                },
            },
        },
        chart: {
            height: 300,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
            events: {
                load() {
                    this.showLoading();
                    setTimeout(this.hideLoading.bind(this), 2000);
                },
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: true,
        },
        xAxis: {
            type: "datetime",
            labels: {
                overflow: "justify",
            },
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false,
        },
        series: [
            // {
            //     name: t("history.min"),
            //     type: "spline",
            //     color: "#60BEC9",
            //     data: min,
            //     tooltip: {
            //         valueDecimals: 2,
            //     },
            //     showInNavigator: false,
            // },
            // {
            //     name: t("history.ave"),
            //     type: "spline",
            //     color: "#000000",
            //     data: ave,
            //     tooltip: {
            //         valueDecimals: 2,
            //     },
            //     showInNavigator: false,
            // },
            {
                // name: t("history.max"),
                name: t("history.temperature"),
                type: "spline",
                color: "#F05622",
                data: max,
                tooltip: {
                    valueDecimals: 2,
                },
                showInNavigator: false,
            },
        ],
        navigator: {
            enabled: false,
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
        exporting: {
            enabled: true,
            buttons: {
              contextButton: {
                // symbol: "download",
                menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS']
              }
            }
        }
    };
}
