import ApiService from 'Services/apiService';

const SensorService = {

    putTitle: async (thing, name) => {
        let requestOptions = {
            url: "/api/things/" + thing.id,
            data: {
                id: thing.id,
                name: thing.name,
                fixedName: name,
                status: thing.status,
                lastActivityDate: thing.lastActivityDate,
                lastMessageDate: thing.lastMessageDate
            }
        };

        ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            }).catch((e) => console.log(e));
    },

    putTemp: async (clusterId, thing, index, low, high) => {

        var newCustomField = {
            "thingId": thing.id,
            "name": (index == 0 ? 'TH_LOW_TEMP' : 'TH_HIGH_TEMP'),
            "value": (index == 0 ? low.celsius : high.celsius),
            "attributeId": "12932408-194f-4b8e-bbf1-a4bfc9713f6a" // atim's temerature
        };

        let requestOptions = {
            url: "/api/cluster/" + clusterId + "/custom_field",
            data: newCustomField
        };

        ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            }).catch((e) => console.error(e));
    },

    putHumidity: async (clusterId, thing, index, low, high) => {

        var newCustomField = {
            "thingId": thing.id,
            "name": (index == 0 ? 'TH_LOW_HUMIDITY' : 'TH_HIGH_HUMIDITY'),
            "value": (index == 0 ? low.celsius : high.celsius),
            "attributeId": "f5820d6e-a637-44be-9abb-3f4b3b195c82" // atim's humidity
        };

        let requestOptions = {
            url: "/api/cluster/" + clusterId + "/custom_field",
            data: newCustomField
        };

        ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            }).catch((e) => console.log(e));
    }
}

export default SensorService;