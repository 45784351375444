import { useState, useContext } from 'react';
import SettingContext from 'Providers/SettingContext';
import style from './style.module.css';

export default function Scales(props) {

	const [isCelsius, setIsCelsius] = useState(props.isCelsius);
	const setting = useContext(SettingContext);

	function handleCelsius(e) {
		setIsCelsius(e.target.checked);

		setting.setGlobalSetting({
			appId: setting.globalSetting.appId,
			temperatureMonitoring: {
				celsius: e.target.checked,
				language: setting.globalSetting.temperatureMonitoring.language
			}
		});
	}

	return (
		<div className={style.wrapper}>
			<span className={style.scale_left}>°F</span>
			<label className={style.switch}>
				{isCelsius ?
					<input type="checkbox" defaultChecked onClick={(e) => handleCelsius(e)} /> :
					<input type="checkbox" onClick={(e) => handleCelsius(e)} />
				}
				<span className={style.slider}></span>
			</label>
			<span className={style.scale_right}>°C</span>
		</div>
	);
};