import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import ClustersCENGN from './ClustersCENGN';
import ClustersPTH from './ClustersPTH';
import Alerts from './alerts/Alerts';
import _ from 'underscore';
import store from 'store';
import { useTranslation } from 'react-i18next';
import Constants from '../Constants';
import UserService from 'Services/userService';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
}));


export default function Dashboard(props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    let storedConnectivity = store.get(Constants.KEY_CONNECTIVITY) == undefined ? 'LORA' : store.get(Constants.KEY_CONNECTIVITY);
    const [connectivity, setConnectivity] = useState(storedConnectivity);
    const { t, i18n } = useTranslation();
    const [company, setCompany] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        store.set(Constants.KEY_TAP_INDEX, newValue);
    };

    const handleOption = (event, value) => {
        setConnectivity(value);
        store.set(Constants.KEY_CONNECTIVITY, value);

        let idx = store.get(Constants.KEY_TAP_INDEX);
        if (value !== 'LORA' && idx == 1) {
            store.set(Constants.KEY_TAP_INDEX, 0);
        }
    }

    useEffect(() => {
        let storedIdx = store.get(Constants.KEY_TAP_INDEX);
        if (!_.isUndefined(storedIdx)) {
            setValue(storedIdx);
        };

        UserService.getAuthenticatedUser().then((user) => setCompany(user.company));
    });

    return (
        // <div className="container-fluid" style={{ marginTop: '56px', paddingLeft: "0px", paddingRight: "0px" }}>
        //     <div className={classes.root}>
        <Box sx={{ flexGrow: 1, marginTop: '56px' }}>
            {/* <CircleRangeUI entity={{ value: 10, unit: '%', date: '10:30 AM' }}></CircleRangeUI> */}

            <Box sx={{ border: '1px solid #3F51B5', height: 70, display: 'flex', alignItems: 'center' }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        {/* <AppBar position="static"> */}
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab value={0} label={t('smart_ag.das.head')} {...a11yProps(0)} />
                            {connectivity == 'LORA' &&
                                <Tab value={1} label={t('smart_ag.das.grow')} {...a11yProps(1)} />
                            }
                            <Tab value={2} label={t('smart_ag.das.bottom')} {...a11yProps(2)} />
                        </Tabs>
                        {/* </AppBar> */}
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl component="fieldset" style={{ width: '100%', alignItems: 'flex-end' }}>
                            <RadioGroup row aria-label="gender" name="row-radio-buttons-group" defaultValue={'LORA'}
                                onChange={handleOption} value={connectivity}>
                                <FormControlLabel value={'LORA'} control={<Radio />} label={t('smart_ag.lorawan')} />
                                <FormControlLabel value={'CELLULAR'} control={<Radio />} label={t('smart_ag.cellular')} />
                                <FormControlLabel value={'WIFI'} control={<Radio />} label={t('smart_ag.wifi')} />
                                <FormControlLabel value={'ETHERNET'} control={<Radio />} label={t('smart_ag.ethernet')} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            <>
                {company == 'CENGN' && <ClustersCENGN tabIndex={value} connectivity={connectivity} />}
                {company == 'PTH' && <ClustersPTH tabIndex={value} connectivity={connectivity} />}

                <Alerts connectivity={connectivity}></Alerts>
            </>
        </Box>

        //     </div>
        // </div>
    )
};