import React from "react";
import { useEffect, useState, useCallback, useContext, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import AppContext from "Components/AppContext";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import Alerts from "./Alerts";
import { useTranslation } from "react-i18next";
import ToggleButton from "@mui/material/ToggleButton";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import ThresholdDialogV2 from "../../smartAgriculture/details/ThresholdDialogV2";
import EmeraConstants from "../EmeraConstants";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import moment from "moment";
import UtilService from "Services/utilService";

export default function Details(props) {
    const { t, i18n } = useTranslation();

    let history = useHistory();

    const [options, setOptions] = useState({});
    const [name, setName] = useState(props.name);
    const [openSidePanel, setOpenSidePanel] = useState(false);

    const [thLow, setThLow] = useState(5);
    const [thHigh, setThHigh] = useState(30);
    const [thRate, setThRate] = useState(10);

    const [chLow, setChLow] = useState(false);
    const [chHigh, setChHigh] = useState(false);
    const [chRate, setChRate] = useState(false);

    const ctx = useContext(AppContext);
    ctx.updateTitle("Emera");

    const setting = useContext(SettingContext);

    const chart = useRef();

    const [isOpenModal, setIsOpenModal] = useState(false);

    const [selected, setSelected] = useState(false);
    const [type, setType] = useState("spline");

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.EMERA,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        setOptions(configChart);
    }, [openSidePanel, isOpenModal]);

    // Configuration for ReactHighcharts
    const configChart = {
        title: {
            text: "",
        },
        xAxis: {
            type: "datetime",
            labels: {
                overflow: "justify",
                formatter: function () {
                    return UtilService.formatMD(this.value);
                },
            },
        },
        yAxis: [
            {
                title: {
                    text: "Temperature",
                },
                min: 0,
                plotLines: [
                    {
                        color: "#FF0000",
                        width: chLow ? 2 : 0,
                        value: thLow,
                    },
                    {
                        color: "#FF0000",
                        width: chHigh ? 2 : 0,
                        value: thHigh,
                    },
                ],
            },
        ],
        tooltip: {
            type: "datetime",
            labels: {
                overflow: "justify",
                formatter: function () {
                    // console.log(this);
                    return UtilService.formatHour(this.value);
                },
            },
            // formatter: function () {
            // 	console.log(this)
            // 	return this.series.userOptions.name + "<br/>" +
            // 		UtilService.shortestFormateDateMDY(this.x) +
            // 		'</br>' + '<b>' + this.y + '°C</b>';
            // }
        },
        plotOptions: {
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3,
                    },
                },
                marker: {
                    enabled: false,
                },
            },
        },
        chart: {
            type: "spline",
            height: 600,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: true,
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false,
        },
        series: [
            {
                name: "C1-Temperature2020",
                data: [
                    [Date.UTC(1971, 0, 4), 11.6],
                    [Date.UTC(1971, 0, 17), 12.55],
                    [Date.UTC(1971, 0, 24), 12.62],
                    [Date.UTC(1971, 1, 4), 12.5],
                    [Date.UTC(1971, 1, 14), 12.42],
                    [Date.UTC(1971, 2, 6), 12.74],
                    [Date.UTC(1971, 2, 14), 14.62],
                    [Date.UTC(1971, 2, 24), 22.6],
                    [Date.UTC(1971, 3, 1), 22.81],
                    [Date.UTC(1971, 3, 11), 22.63],
                    [Date.UTC(1971, 3, 27), 22.77],
                    [Date.UTC(1971, 4, 4), 22.68],
                    [Date.UTC(1971, 4, 9), 22.56],
                    [Date.UTC(1971, 4, 14), 23.39],
                    [Date.UTC(1971, 4, 19), 23.3],
                    [Date.UTC(1971, 5, 4), 23],
                    [Date.UTC(1971, 5, 9), 13.85],
                    [Date.UTC(1971, 5, 14), 13.49],
                    [Date.UTC(1971, 5, 19), 13.27],
                    [Date.UTC(1971, 5, 24), 10.99],
                    [Date.UTC(1971, 5, 29), 10.67],
                    [Date.UTC(1971, 6, 3), 10.18],
                    [Date.UTC(1971, 6, 4), 15],
                ],
                events: {
                    click: (event) => {
                        handlePoint(event);
                    },
                },
            },
            {
                name: "C1-Temperature2021",
                data: [
                    [Date.UTC(1971, 0, 5), 30.85],
                    [Date.UTC(1971, 0, 11), 27.89],
                    [Date.UTC(1971, 0, 17), 21.04],
                    [Date.UTC(1971, 0, 20), 22.02],
                    [Date.UTC(1971, 0, 25), 23.03],
                    [Date.UTC(1971, 0, 30), 21.39],
                    [Date.UTC(1971, 1, 5), 20.77],
                    [Date.UTC(1971, 1, 26), 22.12],
                    [Date.UTC(1971, 3, 19), 18.1],
                    [Date.UTC(1971, 4, 9), 17.7],
                    [Date.UTC(1971, 4, 29), 10.85],
                    [Date.UTC(1971, 5, 7), 10],
                    [Date.UTC(1971, 5, 14), 14.49],
                    [Date.UTC(1971, 5, 19), 15.27],
                    [Date.UTC(1971, 5, 24), 16.99],
                    [Date.UTC(1971, 5, 29), 17.67],
                    [Date.UTC(1971, 6, 3), 19.18],
                    [Date.UTC(1971, 6, 4), 16],
                ],
                cursor: "pointer",
                events: {
                    click: (event) => {
                        handlePoint(event);
                    },
                },
            },
        ],
        // navigator: {
        // 	enabled: false
        // },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download",
                },
            },
        },

        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };

    const handleType = (type) => {
        let newOption = configChart;
        newOption.chart.type = type;

        setSelected(!selected);
        setOptions(configChart);
    };

    function handlePoint(event) {
        if (event == undefined || event.point == undefined) return;

        setOpenSidePanel(true);
    }

    const handleHide = () => {
        setOpenSidePanel(false);
    };

    const handleThreshold = () => {
        // console.log("handleThreshold...");
        setIsOpenModal(true);
    };

    const changeValues = useCallback((thLow, thHigh, thRate) => {
        setThLow(thLow);
        setThHigh(thHigh);
        setThRate(thRate);
    }, []);

    const changeCheckBoxes = useCallback((chLow, chHigh, chRate) => {
        setChLow(chLow);
        setChHigh(chHigh);
        setChRate(chRate);

        if (chLow) {
            configChart.yAxis[0].plotLines[0].width = 2;
        } else {
            configChart.yAxis[0].plotLines[0].width = 0;
        }

        if (chHigh) {
            configChart.yAxis[0].plotLines[1].width = 2;
        } else {
            configChart.yAxis[0].plotLines[1].width = 0;
        }
    }, []);

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ marginTop: 10, marginLeft: 5 }}>
                        Salle 1 - {t("emera.alerts_temperature")}
                    </Grid>
                    <Grid item xs={12} sx={{ marginLeft: 5 }}>
                        <ToggleButton value="check" selected={!selected} onChange={() => handleType("spline")} size="small">
                            <ShowChartIcon />
                        </ToggleButton>
                        <ToggleButton value="check" selected={selected} onChange={() => handleType("column")} size="small">
                            <EqualizerIcon />
                        </ToggleButton>
                        <Button variant="contained" color="primary" style={{ textTransform: "none", margin: "5px" }} onClick={() => handleThreshold()}>
                            {t("btn.threshold")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <div className="row">
                <div className={openSidePanel ? "col-9" : "col"}>
                    <HighchartsReact ref={chart} highcharts={Highcharts} constructorType={"stockChart"} options={options} />
                </div>
                {openSidePanel && (
                    <div className="col-3">
                        <Button variant="contained" color="primary" style={{ textTransform: "none", margin: "5px" }} onClick={() => handleHide()}>
                            Hide
                        </Button>
                        <br></br>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Temp</th>
                                    <th scope="col">Time of Day</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">25</th>
                                    <td>4:00:00</td>
                                </tr>
                                <tr>
                                    <th scope="row">24</th>
                                    <td>4:10:00</td>
                                </tr>
                                <tr>
                                    <th scope="row">26.4</th>
                                    <td>4:20:00</td>
                                </tr>
                                <tr>
                                    <th scope="row">25</th>
                                    <td>4:30:00</td>
                                </tr>
                                <tr>
                                    <th scope="row">25.5</th>
                                    <td>4:40:00</td>
                                </tr>
                                <tr>
                                    <th scope="row">25</th>
                                    <td>4:50:00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            <Alerts />
            {/* <ThresholdDialog onClose={() => setIsOpenModal(false)} thLow={thLow} thHigh={thHigh} thRate={thRate}
				changeValues={changeValues} changeCheckBoxes={changeCheckBoxes} open={isOpenModal} title="Alarms Thresholds" /> */}

            <ThresholdDialogV2
                onClose={() => setIsOpenModal(false)}
                open={isOpenModal}
                thingId={EmeraConstants.thingId}
                attributeId={EmeraConstants.tempAttributeId}
                title="Alert's Thresholds"
                attributeName={name}
            />
        </React.Fragment>
    );
}
