import React, { useState, useEffect } from "react";
import { Stage, Layer, Image } from "react-konva";
import Box from "@mui/material/Box";
import useImage from "use-image";
import ImgFront from "Assets/icons/wifi-circle-icon.png";
import ImgSiege from "Assets/images/DataCenter_room.png";
import ImgAgence from "Assets/images/floor_agence.jpg";
import KonvaImage from "./KonvaImage";
import { v4 as uuidv4 } from "uuid";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { AppId } from "Components/applications/ApplicationData";
import ImageService from "Services/imageService";

export default function KonvaCanvas({ name, data }) {
    const [imgFront] = useImage(ImgFront);
    const [imgSiege] = useImage(ImgSiege);
    const [imgAgence] = useImage(ImgAgence);

    const [rooms, setRooms] = useState([]);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const [code, setCode] = useState();
    const [backgroundImage, setBackgroundImage] = useState();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        // console.log("useEffect in KonvaCanvas");
        // console.log(name);

        if (data) {
            const groupedData = data.reduce((acc, obj) => {
                const name = obj.name;
                if (!acc[name]) {
                    acc[name] = [];
                }
                acc[name].push(obj);
                return acc;
            }, {});

            let temp = [];
            for (let key in groupedData) {
                temp.push(groupedData[key]);
            }

            setRooms(temp);
        }

        let newWidth, newHeight, newImageCode;
        if (name === "Siege") {
            newWidth = 815;
            newHeight = 518;
            newImageCode = AppId.EMERA_V2 + "_siege_bg_image";
        } else {
            newWidth = 870;
            newHeight = 505;
            newImageCode = AppId.EMERA_V2 + "_agence_bg_image";
        }

        if (!backgroundImage && newImageCode !== code) {
            setWidth(newWidth);
            setHeight(newHeight);
            setCode(newImageCode);

            ImageService.requestBackgroundImage(newImageCode, setBackgroundImage).then((r) => {
                if (r) {
                    const imgElement = document.createElement("img");
                    imgElement.src = "data:image/png;base64," + r;
                    setBackgroundImage(imgElement);
                }
                setLoaded(true);
            });
        }
    }, [name, data, backgroundImage, loaded, code]);

    const handleImage = (e) => {
        ImageService.uploadBackgroundImage(code, e.target.files[0], setBackgroundImage).then((response) => {
            setCode(undefined);
            setBackgroundImage(undefined);
        });
    };

    return (
        <Box sx={{ flexGrow: 1, pt: 10, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "right", mr: 4 }}>
                    <label htmlFor="contained-button-file">
                        <input style={{ display: "none" }} accept="image/*" id="contained-button-file" type="file" onChange={handleImage} />
                        <Button variant="contained" component="span">
                            Upload
                        </Button>
                    </label>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Stage width={width} height={height}>
                        <Layer>
                            {!backgroundImage && loaded && name == "Siege" && <Image image={imgSiege} />}
                            {!backgroundImage && loaded && name == "Agence" && <Image image={imgAgence} />}
                            {backgroundImage && loaded && <Image image={backgroundImage} />}
                        </Layer>
                        <Layer>
                            {loaded &&
                                rooms &&
                                rooms.map((r) => {
                                    return <KonvaImage data={r} image={imgFront} key={uuidv4()} />;
                                })}
                        </Layer>
                    </Stage>
                </Grid>
            </Grid>
        </Box>
    );
}
