import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import moment from "moment";
require("highcharts/modules/exporting")(Highcharts);

export default function History(props) {
    const { t, i18n } = useTranslation();

    // Data for chart
    const [y20, setY20] = useState([]);
    const [y21, setY21] = useState([]);

    useEffect(() => {
        let y20 = [
            ["May", 211.6],
            ["Jun", 123.6],
            ["Jul", 423.6],
            ["Aug", 523.6],
            ["Sep", 523.6],
            ["Oct", 623.6],
            ["Nov", 223.6],
            ["Dec", 123.6],
        ];
        setY20(y20);

        let y21 = [
            ["Jan", 520.6],
            ["Feb", 627.6],
            ["Mar", 227.6],
            ["Apr", 325.6],
        ];
        setY21(y21);
    }, []);

    // Configuration for ReactHighcharts
    const configChart = {
        title: {
            // text: "<div style='height: 20px'>&nbsp;</div>",
            text: `<div style="font-family: 'Days One', sans-serif; font-size: 24px; font-weight: normal; color: #9B9B9B">${t("w_mgt.history")}</div>`,
            floating: false,
            useHTML: true,
        },
        yAxis: [
            {
                title: {
                    text: "",
                },
                offset: 20,
                labels: {
                    formatter: function () {
                        return this.value + " m3";
                    },
                    x: 10,
                    style: {
                        color: "#000",
                        position: "absolute",
                    },
                    align: "right",
                    padding: 5,
                },
                opposite: true,
            },
        ],
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            dateTimeLabelFormats: {
                hour: "%e. %b %H:%M",
            },
        },
        chart: {
            // height: 428,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
            styledMode: false,
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: true,
        },
        xAxis: {
            type: "category",
            labels: {
                overflow: "justify",
            },
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false,
        },
        series: [
            {
                name: "2021",
                type: "column",
                color: "#000000",
                data: y20,
                tooltip: {
                    valueDecimals: 2,
                },
                showInNavigator: false,
            },
            {
                name: "2022",
                type: "column",
                color: "#F05622",
                data: y21,
                tooltip: {
                    valueDecimals: 2,
                },
                showInNavigator: false,
            },
        ],
        navigator: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download",
                },
            },
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        // <div className="container" className={style.wrapper}>
        //     <div className="row ml-1 mr-0 pr-2" style={{ width: '90%', position: 'absolute', zIndex: '2' }}>
        //         <div className="col-6 m-0 p-0">
        //             <span style={{ fontFamily: 'Days One', fontSize: '24px', fontWeight: 'normal', color: "#9B9B9B" }}>{t('w_mgt.history')}</span>
        //         </div>
        //     </div>
        //     <div className="row" style={{ position: 'relative', zIndex: '1', width: '100%', marginLeft: '0px' }}>
        //         <div style={{ width: '100%', height: '100%' }}>
        //             <HighchartsReact highcharts={Highcharts} options={configChart} ></HighchartsReact>
        //         </div>
        //     </div>
        // </div>

        <Box>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <HighchartsReact highcharts={Highcharts} options={configChart}></HighchartsReact>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}
