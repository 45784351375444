import makeCancellablePromise from 'make-cancellable-promise'

import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import UtilService from 'Services/utilService'

import wStyle from './style.module.css'

import { useEffect, useState } from 'react'

var columns = [];

function createData(creationDate, level, message, fixedName, comment, lastUpdate, state, username) {
    return { creationDate: UtilService.formateDate(creationDate), level, message, fixedName, comment, lastUpdate: UtilService.formateDate(lastUpdate), state, username };
}

// for gridWidget
var rows = [];
// for statsWidget
var totalSize = 0;

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

const initializeColumn = (opt = {}) => {

    if (columns.length > 0) {
        return;
    }

    columns.push({ id: 'creationDate', label: 'Creation Date', minWidth: 80 });

    columns.push({ id: 'level', label: 'Level', minWidth: 80 });

    columns.push({ id: 'message', label: 'Message', minWidth: 80 });

    if (opt.thingAlert !== undefined && opt.thingAlert === "on")
        columns.push({ id: 'fixedName', label: 'Thing', minWidth: 80 });

    if (opt.commentAlert !== undefined && opt.commentAlert === "on")
        columns.push({ id: 'comment', label: 'Comment', minWidth: 80 });

    if (opt.lastUpdateAlert !== undefined && opt.lastUpdateAlert === "on")
        columns.push({ id: 'lastUpdate', label: 'Last update', minWidth: 80 });

    if (opt.stateAlert !== undefined && opt.stateAlert === "on")
        columns.push({ id: 'state', label: 'State', minWidth: 80 });

    if (opt.usernameAlert !== undefined && opt.usernameAlert === "on")
        columns.push({ id: 'username', label: 'User name', minWidth: 80 });
}

const AlertsWidget = (props) => {

    const [value, setValue] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const conf = props.config;
    const opt = props.config.options.checkboxs || {};
    const display = props.config.options.display; // gridWidget or statsWidget

    const [isGrid, setIsGrid] = useState(false);

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    /**
     * The api call is called only when the widget has been rendered.
     * It can be changed in the future.
     */
    useEffect(() => {
        if (display === 'gridWidget') {
            initializeColumn(opt);
            setIsGrid(true);
        }

        
        const request = makeCancellablePromise(props.getValues(configurationExtractor))

        request.promise.then((values) => {
            setValue(extractData(values).map(element => {
                return createData(
                    element.creationDate,
                    element.level,
                    element.message,
                    element.thing.fixedName,
                    element.comment,
                    element.lastUpdate,
                    element.state,
                    element.username)
            })) // set data for the table 
        })

        return () => {
            if (request.cancel) request.cancel()
        }
    }, [])

    return (
        <div className={"d-flex flex-column " + wStyle.w_container}>
            <div className="">
                <div className={"col" + wStyle.w_title}>
                    {conf.title}
                </div>
            </div>
            {isGrid ?
                <div className="d-flex flex-fill" style={{ overflow: "hidden" }}>
                    <div className="d-flex flex-column" style={{ height: '100%', width: '100%' }}>
                        <TableContainer className="flex-grow-1">
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {getColumns(columns)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getRows(value, rowsPerPage, page)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="flex-shrink-0"
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={value.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
                :
                <div className={"row " + wStyle.w_end} style={{ top: '0px', position: 'absolute', width: '100%', height: '100%' }} >
                    <div className="d-flex align-items-center" style={{ width: '100%' }}>
                        <div className="box" style={{ width: '100%' }}>{totalSize}</div>
                    </div>
                </div>
            }
        </div >
    );
}

/**
 * Get grid columns
 * @param {Array} columns
 */
const getColumns = (columns) => {
    return columns.map((column) => (
        <TableCell
            key={column.id}
            align={column.align}
            style={{ minWidth: column.minWidth }}
        >
            {column.label}
        </TableCell>
    ))
}

/**
 * Return the row jsx components
 * @param {Object} rows
 * @param {Integer} rowsPerPage
 * @param {Integer} page
 */
const getRows = (rows = [], rowsPerPage, page) => {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
        return (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {columns.map((column) => {
                    const value = row[column.id];
                    return (
                        <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    })
}

/**
 * Get the actual unit
 * @param {ApiValue} value
 */
const getUnit = (value) => {
    // Unit is not required for this widget
    return null;
}

/**
 * This method transform the api response into a new object usable by the widget render
 * @param {ApiValues} values
 */
const extractData = (values) => {
    // return values[0].data[0]
    return values[0].data;
}

/**
 * Every widget needs a configuration exctractor. Because every widget has its own configuration, we need to implement specific configuration extractor in order to transform widgetConfiguration to actual ApiService configuration
 * @param {WidgetConfigurationObject} configuration
 */
const configurationExtractor = (configuration) => {
    return [{
        options: { pageSize: 100 },
        filters: configuration.filters,
        url: '/api/alerts'
    }]
}

export default AlertsWidget
export { configurationExtractor }