import { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "Components/AppContext";
import History from "./history/History";
import CurrentMonthly from "./status/CurrentMonthly";
import Anomalies from "./anomalies/Anomalies";
import Note from "./note/Note";
import Map from "./map/Map";
import DailyConsumption from "./daily/DailyConsumption";
import Info from "./info/Info";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Stack } from "@mui/material";
import Paper from "@mui/material/Paper";

export default function Detail(props) {
    let history = useHistory();

    const ctx = useContext(AppContext);

    ctx.updateTitle("Water Management");

    useEffect(() => { }, []);

    return (
        <Box sx={{ flexGrow: 1, m: 2, pt: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Stack>
                        <Paper elevation={3}>
                            <Info />
                            <Map />
                        </Paper>
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <CurrentMonthly />
                </Grid>
                <Grid item xs={6}>
                    <Note />
                </Grid>
                <Grid item xs={6}>
                    <History />
                </Grid>
                <Grid item xs={6}>
                    <DailyConsumption />
                </Grid>
                <Grid item xs={12}>
                    <Anomalies />
                </Grid>
            </Grid>
        </Box>
    );
}
