import React, { useEffect, useState, useContext, useRef } from 'react';
import AppContext from 'Components/AppContext'
import SettingContext from 'Providers/SettingContext';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import { AppId } from 'Components/applications/ApplicationData';
import { useTranslation } from 'react-i18next';
import SiteBox from '../SiteBox';
import MaxByRoomChart from './MaxByRoomChart';
import HeatChart from './HeatChart';
import SalleA from './SalleA';
import Salle1 from './Salle1'
import Salle2 from './Salle2'
import Salle3 from './Salle3'
import Salle4 from './Salle4'

highchartsMore(Highcharts);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
}));

var averagesA = [
    ['Siege-Salle 2', 1430],
    ['Siege-Salle 1', 1400],
    ['Siege-Salle A', 1200],
];

var averagesB = [
    ['Agence-Salle 3', 1230],
    ['Agence-Salle 4', 1100],
];

export default function Analysis(props) {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    ctx.updateTitle("Emera");
    const { t, i18n } = useTranslation();

    // const componentRef = useRef();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        // console.log("UseEffect in Analysis")
        // console.log(value);

        setting.setGlobalSetting({
            appId: AppId.EMERA,
            temperatureMonitoring: {
                celsius: true,
                language: "en"
            }
        });

        return () => { ctx.updateTitle(null) }
    }, [value, chartData]);


    const handleChange = event => {
        setValue(event.target.value);
    };

    const getCurrentDate = () => {
        const today = new Date();
        return today.getFullYear() + "-" + (today.getMonth() + 1).toString(10).padStart(2, "0") + "-" + today.getDate().toString(10).padStart(2, "0");
    }

    const handleRoom = (name) => {
        // console.log("handleRoom=" + name)

        if (name.indexOf("Salle A") != -1) {
            setChartData(SalleA.data);
        } else if (name.indexOf("Salle 1") != -1) {
            // console.log("Here !!")
            setChartData(Salle1.data);
        } else if (name.indexOf("Salle 2") != -1) {
            setChartData(Salle2.data);
        } else if (name.indexOf("Salle 3") != -1) {
            setChartData(Salle3.data);
        } else if (name.indexOf("Salle 4") != -1) {
            setChartData(Salle4.data);
        }
    }

    return (
        // <div className="container-fluid" style={{ marginTop: '56px', paddingLeft: "0px", paddingRight: "0px" }}>
        <Box sx={{ pt: 10, ml: 2, mr: 2 }}>
            <div className={classes.root}>
                {/* <div className={style.flexWrapper}>  */}
                <div>
                    <Grid container spacing={1} alignItems="center">
                        {/* <Grid item style={{ paddingLeft: "10px" }}>
                            Date
                        </Grid>
                        <Grid item style={{ flexGrow: "1" }}>
                            <FormControl className={classes.formControl} style={{ margin: "10px", width: "calc(100% - 20px)" }}>
                                <TextField
                                    id="date"
                                    type="date"
                                    defaultValue={getCurrentDate()}
                                    className={classes.textField} />
                            </FormControl>
                        </Grid> */}

                        <Grid item xs={12}>
                            <SiteBox callback={setValue} />
                        </Grid >
                        <Grid item xs={12}>
                            {value == 0 &&
                                <Box sx={{ display: 'flex' }}>
                                    <MaxByRoomChart siteName="Siege" averages={averagesA} callback={handleRoom} />
                                    <MaxByRoomChart siteName="Agence" averages={averagesB} callback={handleRoom} />
                                </Box>
                            }
                            {value == 1 &&
                                <MaxByRoomChart siteName="Siege" averages={averagesA} callback={handleRoom} />
                            }
                            {value == 2 &&
                                <MaxByRoomChart siteName="Agence" averages={averagesB} callback={handleRoom} />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {chartData.length > 0 &&
                                <HeatChart chartData={chartData} />
                            }
                        </Grid>
                    </Grid>
                    {/* <ChartCo2 />
                    <ChartTemp />
                    <ChartHumidity /> */}
                </div>
                {/* </div>  */}
            </div>
        </Box>
        // </div> 
    )
};

