import ApiService from 'Services/apiService';
import UtilService from 'Services/utilService';
import _ from 'underscore';

const ThresholdServiceV2 = {

    requestThreshold: async (thingId, attributeId, setRows, setRowCount, sortQuery) => {

        let filter = `filter=thing.id:eq_${thingId},attribute.id:eq_${attributeId}`;
        // let sort = '&sort=alert_threshold.update_date&dir=DESC';
        let sort = (sortQuery === '' || sortQuery == undefined ? "&alert_threshold.update_date&dir=DESC" : sortQuery);

        let requestOptions = {
            url: `/api/alerts/threshold?${filter}${sort}`
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requestThreshold...Response...");
                // console.log(response);

                let data = [];
                let totalSize = response.totalSize;

                response.data.forEach(ele => {
                    // console.log(ele);

                    let obj = {
                        id: ele.id,
                        thingId: ele.thingId,
                        attributeId: ele.attributeId,
                        startTime: (ele.startTime == 0 ? '—' : UtilService.formateHMA(ele.startTime)),
                        endTime: (ele.endTime == 0 ? '—' : UtilService.formateHMA(ele.endTime)),
                        isAllTime: ele.allTime,
                        lowValue: ele.lowValue,
                        highValue: ele.highValue,
                        updateDate: ele.updateDate
                    }

                    data.push(obj);
                })

                setRows(data);
                setRowCount(totalSize);
            })
            .catch((e) => {
                console.error(e);
            })
    },

    insertThreshold: async (thingId, attributeId, isAllTime, timeStart, timeEnd, txtLow, txtHigh, callbackSave, callbackError) => {

        let requestOptions = {
            url: `/api/alerts/threshold`,
            data: {
                thingId: thingId,
                attributeId: attributeId,
                startTime: timeStart,
                endTime: timeEnd,
                isAllTime: isAllTime,
                lowValue: txtLow,
                highValue: txtHigh
            }
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.log(response);
                if (response.error) {
                    callbackError(response.error);
                    return;
                }
                callbackSave();
            })
            .catch((e) => {
                console.error(e);
            })
    },

    deleteThreshold: async (selectedIds, callbackRemove) => {
        let requestOptions = {
            url: `/api/alerts/threshold`,
            data: {
                ids: selectedIds
            }
        };

        return ApiService.deleteData(requestOptions)
            .then((response) => {
                callbackRemove();
            })
            .catch((e) => {
                console.error(e);
            })
    }
}

export default ThresholdServiceV2;