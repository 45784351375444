import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function StartEndDate({ initStart, initEnd, callback }) {
    const { t, i18n } = useTranslation();
    const [start, setStart] = useState(initStart);
    const [end, setEnd] = useState(initEnd);

    useEffect(() => {
        // console.log("uef in StartEndDate");
        // console.log(start);
    }, [start, end]);

    const handleStart = (newValue) => {
        setStart(newValue);
        callback(newValue, end);
    };

    const handleEnd = (newValue) => {
        setEnd(newValue);
        callback(start, newValue);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Stack spacing={2} direction={"row"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label={t('btn.start')}
                        format="DD/MM/YYYY"
                        value={start}
                        onChange={handleStart}
                        slotProps={{ textField: { variant: "outlined", size: "small" } }}
                        sx={{ width: 150 }}
                        disableFuture
                        maxDate={end}
                    />
                    <DesktopDatePicker
                        label={t('btn.end')}
                        format="DD/MM/YYYY"
                        value={end}
                        onChange={handleEnd}
                        slotProps={{ textField: { variant: "outlined", size: "small" } }}
                        sx={{ width: 150 }}
                        disableFuture
                        minDate={start}
                    />
                </LocalizationProvider>
            </Stack>
        </Box>
    );
}
