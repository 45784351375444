import { useEffect, useContext } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import _ from 'underscore';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SettingContext from 'Providers/SettingContext';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
}));

export default function ProductDetail(props) {

    const setting = useContext(SettingContext);
    const { product } = props;
    const appId = props.appId;

    useEffect(() => {
        // do nothing
    }, []);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {_.isEmpty(product) &&
                <Paper elevation={0}
                    sx={{
                        height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'
                    }}>
                    No Product selected
                </Paper>
            }
            {!_.isEmpty(product) &&
                <Paper elevation={0} sx={{ height: '100%' }}>
                    <Accordion defaultExpanded={true}>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={3} >
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        Product ID:
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        {product.productId}
                                    </Paper>
                                </Grid>
                                <Grid item xs={3} >
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        Commercial name:
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        {product.commercialName}
                                    </Paper>
                                </Grid>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        Product reference
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        {product.reference}
                                    </Paper>
                                </Grid>
                                <Grid item xs={3} >
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        Tags
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        {product.tag}
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={0}>
                                        <Item>Product description</Item>
                                        <Item>
                                            <TextField
                                                id="standard-multiline-static"
                                                // label="Multiline"
                                                multiline
                                                rows={4}
                                                defaultValue="Default Value"
                                                variant="outlined"
                                                value={product.description}
                                                fullWidth
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 }
                                                }}
                                            />
                                        </Item>
                                    </Stack>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid >
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Features</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        Technology:
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        {product.connectivityTypes}
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        Model:
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        {product.model}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Vendor</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        Name:
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        {product.manufacturer}
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        Category:
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        {product.manufacturerCategory}
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        Web:
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: 'left' }}>
                                        <a href={product.infoLink} target="_blank">{product.infoLink}</a>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            }
        </div>
    );
}
