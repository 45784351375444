import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useTranslation } from "react-i18next";
import AlertService from "./AlertService";
import style from "./style.module.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ClearConfirmDialog from "./ClearConfirmDialog";
import UserService from "Services/userService";
import moment from "moment";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import IconButton from "@mui/material/IconButton";

const AlertBar = ({ thingId }) => {
    const { t, i18n } = useTranslation();
    const [count, setCount] = useState(0);
    const [alerts, setAlerts] = useState([]);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [reload, setReload] = useState();

    // Accordion
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        AlertService.requestAlert(thingId, setAlerts, setCount);

        UserService.getAuthenticatedUser().then((u) => {
            setEmail(u.email);
        });
    }, [count]);

    function clickHandler(e, id) {
        e.target.className = e.target.className.replace("fa-circle-o", "fa-circle");
        AlertService.updateAlertState(id, userConfirmed, alerts, setAlerts, e);
    }

    const userConfirmed = () => {
        AlertService.requestAlert(thingId, setAlerts, setCount);
    };

    const handlePanel = () => {
        if (expanded !== "panel1") {
            setExpanded("panel1");
        } else {
            setExpanded(false);
        }
    };

    const handleClearAll = () => {
        // console.log("handleClearAll");
        AlertService.resolveAll(thingId, email).then(() => {
            setAlerts([]);
            setCount(0);
            setOpen(false);
            setReload(moment());
        });
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            {count > 0 && (
                <Accordion square expanded={expanded === "panel1"}>
                    <AccordionSummary
                        className="m-0 p-0"
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        style={{
                            backgroundColor: "#F05622",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px",
                            borderBottomLeftRadius: expanded ? "0px" : "5px",
                            borderBottomRightRadius: expanded ? "0px" : "5px",
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container sx={{ color: "#fff" }}>
                                <Grid item xs={5} onClick={handlePanel}>
                                    <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        <span className={style.title}>{count + " " + t("alert.name")}</span>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    {/* <IconButton aria-label="setting" size="small">
                                        <DisplaySettingsIcon sx={{ color: "white" }} />
                                    </IconButton> */}
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        sx={{ color: "#fff", textTransform: "none", borderColor: "#fff", ml: 2 }}
                                        onClick={() => setOpen(true)}
                                    >
                                        Clear All
                                    </Button>
                                </Grid>
                                <Grid item xs={1} onClick={handlePanel} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                                    {expanded ? (
                                        <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                    ) : (
                                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionSummary>
                    {alerts.map((row, idx) => {
                        return (
                            <AccordionDetails
                                // className={style.collapse}
                                key={row.id}
                                sx={{
                                    color: "#fff",
                                    backgroundColor: "#F05623",
                                    // borderTopLeftRadius: "5px",
                                    // borderTopRightRadius: "5px",
                                    borderBottomLeftRadius: idx == 2 ? "5px" : "0px",
                                    borderBottomRightRadius: idx == 2 ? "5px" : "0px",
                                    alignItems: "center",
                                    pt: "8px",
                                    pb: "8px",
                                }}
                            >
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Box sx={{ fontSize: "13px", display: "flex", alignItems: "center" }}>{row.message}</Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box
                                            // className={style.card_date_wrapper}
                                            onClick={(e) => {
                                                clickHandler(e, row.id);
                                            }}
                                            sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}
                                        >
                                            <span className={style.card_date}>{row.date}</span>
                                            <i className={"fa fa-circle-o " + style.card_icon} aria-hidden="true"></i>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        );
                    })}
                </Accordion>
            )}
            <ClearConfirmDialog open={open} setOpen={setOpen} callback={handleClearAll} />
        </Box>
    );
};

const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        // "&$expanded": {
        //     margin: "auto",
        // },
        // marginLeft: "7px",
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "0px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        // padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default AlertBar;
