import React, { useEffect, useContext, useState } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "../../providers/SettingContext";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Highcharts from "highcharts/highstock";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { AppId } from "Components/applications/ApplicationData";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import Button from "@mui/material/Button";
import ThresholdDialogV2 from "../smartAgriculture/details/ThresholdDialogV2";
import EmeraConstants from "./EmeraConstants";
import moment from "moment";
import UtilService from "Services/utilService";
highchartsMore(Highcharts);

var ranges = [
        [1625101200000, 14.3, 27.7],
        [1625104800000, 14.5, 27.8],
        [1625108400000, 15.5, 29.6],
        [1625112000000, 16.7, 30.7],
        [1625115600000, 16.5, 25.0],
        [1625119200000, 17.8, 25.7],
        [1625122800000, 13.5, 24.8],
        [1625126400000, 10.5, 21.4],
        [1625130000000, 9.2, 23.8],
        [1625133600000, 11.6, 21.8],
        [1625137200000, 10.7, 23.7],
        [1625140800000, 11.0, 23.3],
        [1625144400000, 11.6, 23.7],
        [1625148000000, 11.8, 20.7],
        [1625151600000, 12.6, 22.4],
        [1625155200000, 13.6, 19.6],
        [1625158800000, 11.4, 22.6],
        [1625162400000, 13.2, 25.0],
        [1625166000000, 14.2, 21.6],
        [1625169600000, 13.1, 17.1],
        [1625173200000, 12.2, 15.5],
        [1625176800000, 12.0, 20.8],
        [1625180400000, 12.0, 17.1],
    ],
    averages = [
        [1625101200000, 21.5],
        [1625104800000, 22.1],
        [1625108400000, 23],
        [1625112000000, 23.8],
        [1625115600000, 21.4],
        [1625119200000, 21.3],
        [1625122800000, 18.3],
        [1625126400000, 15.4],
        [1625130000000, 16.4],
        [1625133600000, 17.7],
        [1625137200000, 17.5],
        [1625140800000, 17.6],
        [1625144400000, 17.7],
        [1625148000000, 16.8],
        [1625151600000, 17.7],
        [1625155200000, 16.3],
        [1625158800000, 17.8],
        [1625162400000, 18.1],
        [1625166000000, 17.2],
        [1625169600000, 14.4],
        [1625173200000, 13.7],
        [1625176800000, 15.7],
        [1625180400000, 14.6],
    ];

function options(title, unit, type) {
    return {
        chart: {
            type: type,
        },
        title: {
            text: title,
        },

        xAxis: {
            type: "datetime",
            labels: {
                overflow: "justify",
                formatter: function () {
                    return UtilService.formatHour(this.value);
                },
            },
        },

        yAxis: {
            title: {
                text: null,
            },
        },

        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: unit,
        },

        series: [
            {
                name: title,
                data: averages,
                zIndex: 1,
                marker: {
                    fillColor: "white",
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[0],
                },
                showInLegend: false,
            },
            {
                name: "Range",
                data: ranges,
                type: "arearange",
                lineWidth: 0,
                linkedTo: ":previous",
                color: Highcharts.getOptions().colors[0],
                fillOpacity: 0.3,
                zIndex: 0,
                marker: {
                    enabled: false,
                },
            },
        ],
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function ChartTemp(props) {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    ctx.updateTitle("Emera");

    const [selected, setSelected] = useState(false);
    const [type, setType] = useState("spline");
    const [periodIdx, setPeriodIdx] = useState(3);
    const [cursor, setCursor] = useState("default");
    const [isOpenThresholdModal, setIsOpenThresholdModal] = useState(false);

    const [name, setName] = useState("Co2");
    const [thingId, setThingId] = useState(EmeraConstants.thingId);
    const [attributeId, setAttributeId] = useState(EmeraConstants.tempAttributeId);

    const { t, i18n } = useTranslation();

    const handleChartType = () => {
        setSelected(!selected);

        if (selected) {
            setType("spline");
        } else {
            setType("column");
        }
    };

    const handlePeriod = (periodIdx) => {
        setPeriodIdx(periodIdx);
        // setCursor('wait');
    };

    const handleThreshold = () => {
        setIsOpenThresholdModal(true);
    };

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.EMERA,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        return () => {
            ctx.updateTitle(null);
        };
    }, []);

    return (
        <Box sx={{ flexGrow: 1, marginTop: 15 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginLeft: 5 }}>
                    <ToggleButton value="check" selected={!selected} onChange={() => handleChartType()} size="small">
                        <ShowChartIcon />
                    </ToggleButton>
                    <ToggleButton value="check" selected={selected} onChange={() => handleChartType()} size="small">
                        <EqualizerIcon />
                    </ToggleButton>
                    <Button variant="contained" color="primary" style={{ textTransform: "none", margin: "5px" }} onClick={() => handleThreshold()}>
                        {t("btn.threshold")}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} options={options(t("emera.analysis_temperature"), "°C", type)} />
                </Grid>
            </Grid>

            <ThresholdDialogV2
                onClose={() => setIsOpenThresholdModal(false)}
                open={isOpenThresholdModal}
                thingId={thingId}
                attributeId={attributeId}
                title="Alert's Thresholds"
                attributeName={name}
            />
        </Box>
    );
}
