import { useState, useEffect } from "react";
import _ from "underscore";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { LicenseInfo } from "@mui/x-license-pro";
import { v4 as uuidv4 } from "uuid";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");


export default function ElectricDetail({ entity }) {
    const [serial, setSerial] = useState("");
    const [pulse, setPlulse] = useState("");

    useEffect(() => {
        // console.log("useEffect in ElectricDetail");
        // console.log(entity);
    }, []);

    return (
        <TableContainer component={Paper} elevation={3} sx={{ overflow: "hidden", height: "100%" }}>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow key={uuidv4()}>
                        <TableCell align="left">
                            <TextField
                                id="txtSerial"
                                variant="outlined"
                                // defaultValue={"Salle 1 et Salle 2"}
                                value={serial}
                                onChange={(e) => setSerial(e.target.value)}
                                fullWidth
                                size="small"
                                label="Serial number"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow key={uuidv4()}>
                        <TableCell align="left">
                            <TextField
                                id="txtPulse"
                                variant="outlined"
                                // defaultValue={"Salle 1 et Salle 2"}
                                value={pulse}
                                onChange={(e) => setPlulse(e.target.value)}
                                fullWidth
                                size="small"
                                label="Pluse per KwH coeff"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow key={uuidv4()}>
                        <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                    <TableRow key={uuidv4()}>
                        <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
