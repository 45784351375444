import { useState, useEffect } from "react";
import _ from "underscore";
import { DataGridPro } from "@mui/x-data-grid-pro";
import style from "./style.module.css";
import CropService from "../../crop/CropService";
import { useTranslation } from "react-i18next";
import { frFR } from "@mui/x-data-grid/locales";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function CropHistory(props) {
    const { t, i18n } = useTranslation();
    const [rows, setRows] = useState([]);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 });

    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
        CropService.requestCrop(paginationModel.page, paginationModel.pageSize, setRows, setRowCount);
    }, [props.reloadState, paginationModel.page, paginationModel.pageSize]);

    const handleStop = (id) => {
        CropService.updateCrop(id, reload);
    };

    const reload = () => {
        CropService.requestCrop(paginationModel.page, paginationModel.pageSize, setRows, setRowCount);
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            editable: false,
            sortable: false,
            hide: true,
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            editable: false,
            sortable: false,
        },
        {
            field: "startDate",
            headerName: "Start Date",
            flex: 1,
            editable: false,
            sortable: false,
        },
        {
            field: "endDate",
            headerName: "End Date",
            flex: 1,
            editable: false,
            sortable: false,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0.6,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return <span className={"badge " + (params.value == "ACTIVE" ? style.active : style.inactive)}>{params.value}</span>;
            },
        },
        // {
        //   field: "",
        //   headerName: "Stop",
        //   sortable: false,
        //   flex: 0.6,
        //   disableClickEventBubbling: true,
        //   renderCell: (cellValues) => {
        //     if (cellValues.row.status == 'ACTIVE') {
        //       return (
        //         <IconButton aria-label="stop" color="warning" onClick={() => handleStop(cellValues.row.id)}>
        //           <CancelIcon />
        //         </IconButton>
        //       )
        //     } else {
        //       return (
        //         <IconButton aria-label="stop" disabled color="warning"  >
        //           <CancelIcon />
        //         </IconButton>
        //       )
        //     }
        //   }
        // },
    ];

    return (
        <div style={{ height: "300px" }}>
            <DataGridPro
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                rowCount={rowCount}
                pagination={true}
                paginationMode="server"
                sortingMode="server"
                pageSizeOptions={[15, 50, 100]}
                onPaginationModelChange={setPaginationModel}
                checkboxSelection={false}
                density="compact"
                disableSelectionOnClick={true}
                sx={{
                    "& .MuiTablePagination-selectLabel": {
                        marginTop: 2,
                    },
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: 2,
                    },
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
            />
        </div>
    );
}
