import React, { useState, useEffect, useRef } from "react";
import { Text, Image, Rect, Group } from "react-konva";

export default function CompostKonvaImage({ data, image, callback }) {
    const [state, setState] = useState({
        isDragging: false,
        x: 0,
        y: 0,
    });

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipX, setTooltipX] = useState(0);
    const [tooltipY, setTooltipY] = useState(0);
    const [tooltipText, setTooltipText] = useState();
    const [tooltipHeight, setTooltipHeight] = useState(0);
    const tooltipRef = useRef(null);

    useEffect(() => {
        // console.log("useEffect in CompostKonvaImage");
        // console.log(data);

        if (data) {
            let position = data.position;
            if (position) {
                setState({
                    isDragging: false,
                    x: position.x,
                    y: position.y,
                });

                setTooltipX(position.x + 30);
                setTooltipY(position.y);
            }

            let arrText = [];
            if (data) {
                arrText.push(data.name);
                arrText.push("temperature:" + data.temperature);
            }

            setTooltipHeight(arrText.length * 16 + 5);
            setTooltipText(arrText.join("\n"));
        }
    }, []);

    const handleClick = (e) => {
        // console.log(e);
        // console.log(data)
        callback(data.name);
    };

    return (
        <>
            <Image
                image={image}
                x={state.x}
                y={state.y}
                width={30}
                height={30}
                draggable
                onDragStart={() => {
                    setState({
                        isDragging: true,
                    });
                }}
                onDragEnd={(e) => {
                    setState({
                        isDragging: false,
                        x: e.target.x(),
                        y: e.target.y(),
                    });

                    setTooltipX(e.target.x() + 30);
                    setTooltipY(e.target.y());
                }}
                onMouseEnter={(e) => {
                    // console.log(e);
                    // console.log(tooltipRef); 
                    tooltipRef.current.zIndex(27);
                    setTooltipVisible(true);
                }}
                onMouseOut={(e) => {
                    // console.log(e);
                    setTooltipVisible(false);
                }}
                onClick={(e) => handleClick(e)}
            />

            <Tooltip tooltipRef={tooltipRef} text={tooltipText} x={tooltipX} y={tooltipY} visible={tooltipVisible} tooltipHeight={tooltipHeight} />
        </>
    );
}

const Tooltip = ({ tooltipRef, text, x, y, visible, tooltipHeight }) => {
    return (
        <Group ref={tooltipRef} visible={visible} x={x} y={y}>
            <Rect fill="black" opacity={0.75} cornerRadius={5} width={150} height={tooltipHeight} />
            <Text text={text} fill="white" padding={5} fontSize={14} />
        </Group>
    );
};
