import { React, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Item from "../../../../components/ui/Item";
import moment from "moment";
require("highcharts/modules/exporting")(Highcharts);

// const useStyles = makeStyles((theme) => ({
// 	formControl: {
// 		margin: theme.spacing(0),
// 		minWidth: 80,
// 		maxWidth: 300,
// 	},
// 	chips: {
// 		display: 'flex',
// 		flexWrap: 'wrap',
// 	},
// 	chip: {
// 		margin: 2,
// 	},
// 	noLabel: {
// 		marginTop: theme.spacing(3),
// 	},
// }));

export default function ConsumptionWarnings(props) {
    let history = useHistory();
    const { t, i18n } = useTranslation();

    // const classes = useStyles();
    const [year, setYear] = useState("2021");
    const [month, setMonth] = useState("4");

    // Data for chart
    const [y19, setY19] = useState([]);
    const [y20, setY20] = useState([]);
    const [y21, setY21] = useState([]);

    useEffect(() => {
        let y19 = [
            ["Jan", 120.6],
            ["Feb", 227.6],
            ["Mar", 27.6],
            ["Apr", 325.6],
            ["May", 411.6],
            ["Jun", 223.6],
            ["Jul", 123.6],
            ["Aug", 423.6],
            ["Sep", 523.6],
            ["Oct", 223.6],
            ["Nov", 323.6],
            ["Dec", 223.6],
        ];
        setY19(y19);

        let y20 = [
            ["Jan", 20.6],
            ["Feb", 127.6],
            ["Mar", 327.6],
            ["Apr", 425.6],
            ["May", 211.6],
            ["Jun", 123.6],
            ["Jul", 423.6],
            ["Aug", 523.6],
            ["Sep", 523.6],
            ["Oct", 623.6],
            ["Nov", 223.6],
            ["Dec", 123.6],
        ];
        setY20(y20);

        let y21 = [
            ["Jan", 520.6],
            ["Feb", 627.6],
            ["Mar", 227.6],
            ["Apr", 325.6],
        ];
        setY21(y21);
    }, []);

    // Configuration for ReactHighcharts
    const configChart = {
        title: {
            text: `<div style="font-family: 'Days One', sans-serif; font-size: 24px; font-weight: normal; color: #9B9B9B">${t(
                "w_mgt.consumption_warnings"
            )}</div>`,
            aligh: "center",
            floating: false,
            useHTML: true,
        },
        yAxis: [
            {
                title: {
                    text: "",
                },
                offset: 20,
                labels: {
                    formatter: function () {
                        return this.value + " %";
                    },
                    x: 10,
                    style: {
                        color: "#000",
                        position: "absolute",
                    },
                    align: "right",
                    padding: 5,
                },
                opposite: false,
            },
        ],
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            dateTimeLabelFormats: {
                hour: "%e. %b %H:%M",
            },
        },
        chart: {
            type: "bar",
            // height: 300,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
            styledMode: false,
            events: {
                click: function (e) {
                    history.push(`${process.env.PUBLIC_URL}/water_management_detail`);
                },
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            categories: [
                "Customer5",
                "Customer10",
                "Customer7",
                "Customer12",
                "Customer9",
                "Customer6",
                "Customer11",
                "Customer4",
                "Customer13",
                "Customer2",
                "Customer15",
                "Customer8",
                "Customer1",
                "Customer14",
                "Customer3",
            ],
            title: {
                text: null,
            },
            labels: {
                overflow: "justify",
            },
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false,
        },
        series: [
            {
                name: "Percentage",
                data: [135, 134, 120, 66, 65, 65, 47, 45, 44, 35, 33, 33, 25, 22, 15],
            },
        ],
        navigator: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download",
                },
            },
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    const handleChangeYear = (event) => {
        setMonth(event.target.value);
    };

    const handleChangeMonth = (event) => {
        setMonth(event.target.value);
    };

    return (
        // <div className="container" >
        // 	<div className="row ml-1 mr-0 p-0" style={{ width: '90%', position: 'absolute', zIndex: '2' }}>
        // 		<div className="col m-0 p-0">
        // 			<span style={{ fontFamily: 'Days One', fontSize: '24px', fontWeight: 'normal', color: "#9B9B9B" }}>{t('w_mgt.consumption_warnings')}</span>
        // 		</div>
        // 		<div className="m-0" style={{ width: '170px', paddingTop: '10px', textAlign: 'right' }}>
        // 			<FormControl style={{ minWidth: 80, maxWidth: 300 }}>
        // 				<Select
        // 					labelId="l-id-year"
        // 					id="id-year"
        // 					value={year}
        // 					onChange={handleChangeYear}
        // 					autoWidth={true}
        // 					style={{ width: '80px' }}
        // 				>
        // 					<MenuItem value={2020}>2020</MenuItem>
        // 					<MenuItem value={2021}>2021</MenuItem>
        // 				</Select>
        // 			</FormControl>
        // 			<FormControl style={{ marginRight: '0px', minWidth: 80, maxWidth: 30 }}>
        // 				<Select
        // 					labelId="demo-simple-select-label"
        // 					id="demo-simple-select"
        // 					value={month}
        // 					onChange={handleChangeMonth}
        // 					autoWidth={true}
        // 					style={{ width: '80px' }}
        // 				>
        // 					<MenuItem value={1}>January</MenuItem>
        // 					<MenuItem value={2}>February</MenuItem>
        // 					<MenuItem value={3}>March</MenuItem>
        // 					<MenuItem value={4}>April</MenuItem>
        // 					<MenuItem value={5}>May</MenuItem>
        // 					<MenuItem value={6}>June</MenuItem>
        // 					<MenuItem value={7}>July</MenuItem>
        // 					<MenuItem value={8}>August</MenuItem>
        // 					<MenuItem value={9}>September</MenuItem>
        // 					<MenuItem value={10}>October</MenuItem>
        // 					<MenuItem value={11}>November</MenuItem>
        // 					<MenuItem value={12}>December</MenuItem>
        // 				</Select>
        // 			</FormControl>
        // 		</div>
        // 	</div>
        // 	<div className="row pt-2" style={{ position: 'relative', zIndex: '1', width: '100%', marginLeft: '0px' }}>
        // 		<div style={{ width: '100%', height: '100%' }}>
        // 			<HighchartsReact highcharts={Highcharts} options={configChart} ></HighchartsReact>
        // 		</div>
        // 	</div>
        // </div>

        <Grid container spacing={2}>
            {/* <Grid item xs={12}>
                <Item>
                    <span style={{ fontFamily: 'Days One', fontSize: '24px', fontWeight: 'normal', color: "#9B9B9B" }}>{t('w_mgt.consumption_warnings')}</span>
                </Item>
            </Grid> */}
            <Grid item xs={12}>
                <Item>
                    <HighchartsReact highcharts={Highcharts} options={configChart}></HighchartsReact>
                </Item>
            </Grid>
        </Grid>
    );
}
