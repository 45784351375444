import { useState, useEffect } from 'react'
import Button from '@mui/material/Button';

export default function ToggleButton(props) {

    const [on, setOn] = useState(props.on);
    const handleToggle = props.callback;

    useEffect(() => {
        //handleToggle(on);
    }, [on])

    const handleClick = () => {
        setOn(() => {
            handleToggle(!on);
            return !on;
        });
    }

    return (
        // <div style={{ width: '125px', color: 'rgb(63, 81, 181)' }} onClick={() => handleClick()}>
        <div onClick={() => handleClick()}>
            {/* {on && <i className="fa fa-bell" aria-hidden="true" style={{ paddingRight: '5px' }}></i>}
            {!on && <i className="fa fa-bell-o" aria-hidden="true" style={{ paddingRight: '5px' }}></i>} */}

            <Button variant="text" size='small'>
                {on && <i className="fa fa-bell" aria-hidden="true" style={{ paddingRight: '5px' }}></i>}
                {!on && <i className="fa fa-bell-o" aria-hidden="true" style={{ paddingRight: '5px' }}></i>}
                {props.on ? 'On' : 'Off'}{(props.all ? '(ALL)' : '')}
            </Button>

            {/* <span style={{ fontSize: '0.8125rem' }}>{props.on ? 'On' : 'Off'}{(props.all ? '(ALL)' : '')}</span> */}
        </div>
    )
};

