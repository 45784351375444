import React, { useEffect, useState, useContext } from "react";
import HistoryValues from "../history/HistoryValues";
import UtilService from "Services/utilService";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ThingService from "../popup/ThingService";
import style from "./style.module.css";
import SensorService from "./SensorService";
import SettingContext from "Providers/SettingContext";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AlertBar from "../bar/AlertBar";
import moment from "moment";

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
}));

highchartsMore(Highcharts);
solidGauge(Highcharts);

const SensorCard = (props) => {
    const { t, i18n } = useTranslation();

    const [thing, setThing] = useState(props.thing);
    const [name, setName] = useState(thing.displayName);

    const [date, setDate] = useState(thing.lastMessageDate ? UtilService.shortestFormateDate(thing.lastMessageDate * 1000) : "");

    // Thresholds
    const [low, setLow] = useState(props.low);
    const [high, setHigh] = useState(props.high);

    // Includes Celsius, Fahrenheit, Percentage of chart and color
    const [temps, setTemps] = useState({});

    // Background color of chart for rendering
    const [background, setBackground] = useState("");

    const setting = useContext(SettingContext);
    const [isCelsius, setIsCelsius] = useState(false);

    const [humidity, setHumidity] = useState();

    useEffect(() => {
        setIsCelsius(setting.globalSetting.sensDc.celsius);
        calculateAnother();

        if (thing.lastMeasurements.measurements.humidity) {
            setHumidity(thing.lastMeasurements.measurements.humidity.toFixed(1));
        } else {
            setHumidity("");
        }
    }, [temps, setting]);

    const calculateAnother = () => {
        if (thing.lastMeasurements == undefined || thing.lastMeasurements.measurements == undefined) {
            return;
        }

        const value = thing.lastMeasurements.measurements.temperature;

        setTemps((temps) => makeAll(temps, true, value)); // in database, the values are celsius
        calculatePercentage(low.celsius, value, high.celsius);
    };

    const makeAll = (obj, isCelsius, value) => {
        if (obj == undefined) return;

        if (isCelsius) {
            obj["celsius"] = parseInt(value).toFixed(1);
            obj["fahrenheit"] = UtilService.toFahrenheit(value, 0);
            obj["value"] = value;
            return obj;
        } else {
            obj["celsius"] = UtilService.toCelsius(value, 0);
            obj["fahrenheit"] = parseInt(value.toFixed(1));
            obj["value"] = UtilService.toCelsius(value, 0);
            return obj;
        }
    };

    const calculatePercentage = (low, value, high) => {
        let total = high - low;
        let percentage = (((value - low) / total) * 100).toFixed(0);

        // Just in case
        if (percentage > 100) {
            percentage = 100;
        } else if (percentage < 0) {
            percentage = 0;
        }

        setTemps((temp) => {
            temp["percentage"] = parseInt(percentage);
            if (value <= high) {
                temp["color"] = "#38B3B9";
            } else {
                temp["color"] = "#F05622";
            }

            return temp;
        });

        // For chart re-rendering
        if (background === "#D0D0CE") {
            setBackground("#D0D0CF");
        } else {
            setBackground("#D0D0CE");
        }
    };

    const handleTitle = (e) => {
        setName(e.target.value);
        SensorService.putTitle(thing, e.target.value);
    };

    const changeTemp = (e, index) => {
        var spanTag = e.target.parentElement.children[5];

        var changedValue = e.target.value;

        var lowValue = isCelsius ? low.celsius : low.fahrenheit;
        var tempValue = isCelsius ? temps.celsius : temps.fahrenheit;
        var highValue = isCelsius ? high.celsius : high.fahrenheit;

        if (index == 0) {
            // low
            setLow((low) => makeAll(low, isCelsius, parseInt(changedValue)));
            calculatePercentage(lowValue, tempValue, highValue);

            if (parseInt(highValue) < changedValue) {
                spanTag.innerHTML = "Must be less than high value";
            } else {
                const newLow = makeAll(low, isCelsius, changedValue);

                if (thing.id == "2c4d25c5-4d09-408b-b36b-893df24b77d5") {
                    // ATIM 5
                    SensorService.putHumidity(props.clusterId, thing, index, newLow, high);
                } else {
                    SensorService.putTemp(props.clusterId, thing, index, newLow, high);
                }

                spanTag.innerHTML = "";
            }
        } else if (index == 1) {
            // high

            setHigh((high) => makeAll(high, isCelsius, parseInt(changedValue)));
            calculatePercentage(lowValue, tempValue, highValue);

            if (parseInt(lowValue) > changedValue) {
                spanTag.innerHTML = "Must be higher than low value";
            } else if (thing.id == "2c4d25c5-4d09-408b-b36b-893df24b77d5" && parseInt(changedValue) > 100) {
                spanTag.innerHTML = "Must be lower than 100";
            } else {
                let newHigh = makeAll(high, isCelsius, parseInt(changedValue));

                if (thing.id == "2c4d25c5-4d09-408b-b36b-893df24b77d5") {
                    // ATIM 5
                    SensorService.putHumidity(props.clusterId, thing, index, low, newHigh);
                } else {
                    SensorService.putTemp(props.clusterId, thing, index, low, newHigh);
                }

                spanTag.innerHTML = "";
            }
        }
    };

    const handleRemove = () => {
        ThingService.removeThingInCloud(props.clusterId, [thing.id]).then(() => props.callback());
    };

    const options = {
        chart: {
            type: "solidgauge",
            height: 110,
        },

        title: null,

        pane: {
            center: ["50%", "85%"],
            size: "140%",
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
                innerRadius: "94%",
                outerRadius: "100%",
                shape: "arc",
            },
        },

        exporting: {
            enabled: false,
        },

        tooltip: {
            enabled: false,
        },

        yAxis: {
            min: 0,
            max: 100,
            stops: [
                [0, "#38B3B9"],
                [0.99, "#38B3B9"],
                [1, "#F05622"],
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            title: {
                y: -70,
            },
            labels: {
                y: 16,
                enabled: false,
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true,
                },
            },
        },
        series: [
            {
                name: "RPM",
                data: [
                    {
                        y: temps.percentage == undefined ? 0 : temps.percentage,
                        radius: 100,
                        innerRadius: 95,
                        outerRadius: 99,
                    },
                ],
                dataLabels: {
                    format:
                        '<div style="text-align:center">' +
                        '<span style="font-size:25px">{y:.1f}</span><br/>' +
                        '<span style="font-size:12px;opacity:0.4">' +
                        "* 1000 / min" +
                        "</span>" +
                        "</div>",
                    enabled: false,
                },
                tooltip: {
                    valueSuffix: " revolutions/min",
                },
            },
        ],
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };

    return (
        <>
            {/* Sensor Card #1 */}
            <Paper elevation={4} style={{ marginTop: "20px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AlertBar thing={thing}></AlertBar>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <Item>
                            <i className={"fa fa-exclamation-circle pt-2 " + style.title_icon} aria-hidden="true"></i>
                            <IconButton aria-label="delete" size="large"></IconButton>
                            <TextField id="standard-basic" value={name} variant="standard" onChange={(e) => handleTitle(e)} size="medium" />
                        </Item>
                    </Grid>
                    <Grid item xs={2} display={"flex"} justifyContent={"flex-end"}>
                        <Item>
                            <IconButton aria-label="remove" onClick={() => handleRemove()} size="large">
                                <CloseIcon></CloseIcon>
                            </IconButton>
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        {/* Low value */}
                        {low && (
                            <div className={"col m-0 p-0 " + style.value}>
                                <div className={"m-0 " + style.temp_wrapper}>
                                    <input
                                        type="number"
                                        value={isCelsius ? low.celsius : low.fahrenheit}
                                        className={style.temp}
                                        onChange={(e) => changeTemp(e, 0)}
                                    />
                                    <span>{props.thing.id == "2c4d25c5-4d09-408b-b36b-893df24b77d5" ? "%" : "°"}</span>
                                    <br></br>
                                    <span className={style.temp_low_name}>{t("card.low")}</span>
                                    <br></br>
                                    <span className={style.err_msg}></span>
                                </div>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        {/* Half donut Chart */}
                        <div className={"col m-0 p-0 " + style.chart_wrapper}>
                            <div className={style.chart}>
                                <HighchartsReact highcharts={Highcharts} options={options} />
                            </div>
                            <div className={style.scale}>
                                {props.thing.id == "2c4d25c5-4d09-408b-b36b-893df24b77d5" && humidity}
                                {props.thing.id != "2c4d25c5-4d09-408b-b36b-893df24b77d5" &&
                                    (isCelsius ? (isNaN(temps.celsius) ? "" : temps.celsius) : isNaN(temps.fahrenheit) ? "" : temps.fahrenheit)}
                            </div>
                            <div className={style.unit}>
                                {props.thing.id == "2c4d25c5-4d09-408b-b36b-893df24b77d5" && "%"}
                                {props.thing.id != "2c4d25c5-4d09-408b-b36b-893df24b77d5" && "°"}
                            </div>
                            <div className={style.date}>{date}</div>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        {/* High value*/}
                        {high && (
                            <div className={"col m-0 p-0 " + style.value}>
                                <div className={"m-0 " + style.temp_wrapper}>
                                    <input
                                        type="number"
                                        value={isCelsius ? high.celsius : high.fahrenheit}
                                        className={style.temp}
                                        onChange={(e) => changeTemp(e, 1)}
                                    />
                                    <span>{props.thing.id == "2c4d25c5-4d09-408b-b36b-893df24b77d5" ? "%" : "°"}</span>
                                    <br></br>
                                    <span className={style.temp_high_name}>{t("card.high")}</span>
                                    <br></br>
                                    <span className={style.err_msg}></span>
                                </div>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <HistoryValues thingId={thing.id}></HistoryValues>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default SensorCard;
