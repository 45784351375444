import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SuperviotConstants from "../SuperviotConstants";
import TooltipSlider from "./TooltipSlider";
import "rc-slider/assets/index.css";
import UserConfirm from "./UserConfirm";

export default function SliderNitrate({ name, marks, defaultValue, min = 0, max, step = 1, attributeId, callback }) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState();
    const [tempValue, setTempValue] = useState();
    const [changedValue, setChangedValue] = useState();

    const trackColors = [
        { backgroundColor: SuperviotConstants.BLUE },
        { backgroundColor: SuperviotConstants.GREEN },
        { backgroundColor: SuperviotConstants.YELLOW },
        { backgroundColor: SuperviotConstants.ORANGE },
        { backgroundColor: SuperviotConstants.RED },
    ];

    useEffect(() => {
        if (!value) {
            setValue(defaultValue);
            setTempValue(defaultValue);
        }
    }, [defaultValue, min, max, step, value, tempValue]);

    useEffect(() => {
        let k = Object.keys(marks);

        const arraysAreEqual = (arr1, arr2) => {
            if (arr1.length !== arr2.length) return false;
            let sortedArr1 = [...arr1].sort();
            let sortedArr2 = [...arr2].sort();
            return sortedArr1.every((val, index) => val === sortedArr2[index]);
        };

        if (value && k && !arraysAreEqual(k, value)) {
            setValue(marks);
        }
    }, [marks, value]);

    const handleChange = (tempValue) => {
        setTempValue(tempValue);
    };

    const handleChangeComplete = (tempValue) => {
        const changed = tempValue.find((v) => marks[v] === undefined);
        setChangedValue(changed);
        setOpen(true);
    };

    const handleCallback = (isYes) => {
        if (isYes) {
            setValue(tempValue); // Confirm the value
            callback(attributeId, trackColors, tempValue, false);
        } else {
            setTempValue(value); // Revert to the last confirmed value
        }
    };

    return (
        <Box sx={{ mb: 5 }}>
            <Typography id="track-false-range-slider" gutterBottom>
                {name}
            </Typography>
            <TooltipSlider
                range
                count={5}
                marks={marks}
                // defaultValue={defaultValue}
                pushable
                trackStyle={trackColors}
                handleStyle={[
                    { backgroundColor: SuperviotConstants.DEFAULT },
                    { backgroundColor: SuperviotConstants.DEFAULT },
                    { backgroundColor: SuperviotConstants.DEFAULT },
                    { backgroundColor: SuperviotConstants.DEFAULT },
                    { backgroundColor: SuperviotConstants.DEFAULT },
                    { backgroundColor: SuperviotConstants.DEFAULT },
                ]}
                railStyle={{ backgroundColor: "black" }}
                onChange={handleChange}
                onChangeComplete={handleChangeComplete}
                min={min}
                max={max}
                step={step}
                value={tempValue} // Bind to tempValue to allow mark movement
            />

            <UserConfirm open={open} setOpen={setOpen} changedValue={changedValue} callback={handleCallback} />
        </Box>
    );
}
