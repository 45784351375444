import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ThingsDialog from './popup/ThingsDialog';
import SensDcService from './SensDcService';
import SensorCard from './sensor/SensorCard';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    // heading: {
    //     fontSize: theme.typography.pxToRem(15),
    //     fontWeight: theme.typography.fontWeightRegular,
    // },
}));

export default function ClusterContainer(props) {

    const classes = useStyles();
    let history = useHistory();

    const [openDialog, setOpenDialog] = useState(false);
    const [cluster, setCluster] = useState([]);
    const [selectedClusterId, setSelectedClusterId] = useState();
    const [selectedClusterName, setSelectedClusterName] = useState();

    useEffect(() => {
        SensDcService.requestCluster(setCluster);
    }, []);

    const handleDialog = (cluster_id, cluster_name) => {
        setSelectedClusterId(cluster_id);
        setSelectedClusterName(cluster_name);
        setOpenDialog(true);
    }

    const reloadPage = () => {
        SensDcService.requestCluster(setCluster);
    }

    return (
        <>
            {cluster.map(c => {
                return (
                    <Accordion defaultExpanded={true} key={`accordion-${c.cluster.id}}`} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id={`panel1a-header-${c.cluster.id}}`}
                        >
                            <Typography className={classes.heading}>{c.cluster.name}</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {c.things.map(t => {
                                        return (
                                            <SensorCard clusterId={c.cluster.id} thing={t} low={t.threshold.low} high={t.threshold.high}
                                                callback={reloadPage} key={`sensor-${t.id}`}
                                            >
                                            </SensorCard>
                                        )
                                    })}
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display={'flex'} justifyContent='center'>
                                        <Fab color="primary" aria-label="add" onClick={() => handleDialog(c.cluster.id, c.cluster.name)} size={'small'}>
                                            <AddIcon />
                                        </Fab>
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )
            })}

            <ThingsDialog openDialog={openDialog} setOpenDialog={setOpenDialog} selectedClusterId={selectedClusterId}
                selectedClusterName={selectedClusterName} callback={reloadPage}>
            </ThingsDialog>
        </>
    )
};