import React, { useEffect, useState, useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import UtilService from "../../../services/utilService";
import withStyles from "@mui/styles/withStyles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useTranslation } from "react-i18next";
import SettingContext from "Providers/SettingContext";
import style from "./style.module.css";
import HistoryService from "./HistoryService";
import moment from "moment";

const HistoryValues = (props) => {
    const { t, i18n } = useTranslation();
    const THING_ID = props.thingId;
    const ATTRIBUTE_ID_TEMP = "12932408-194f-4b8e-bbf1-a4bfc9713f6a"; // atim's temperature
    const ATTRIBUTE_ID_HUMIDITY = "f5820d6e-a637-44be-9abb-3f4b3b195c82"; // atim's humidity

    // Data for chart
    const [ave, setAve] = useState([]);
    const [min, setMin] = useState([]);
    const [max, setMax] = useState([]);

    const [expanded, setExpanded] = useState(false);
    const setting = useContext(SettingContext);
    const [isCelsius, setIsCelsius] = useState(false);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);

        if (newExpanded) {
            if (THING_ID == "2c4d25c5-4d09-408b-b36b-893df24b77d5") {
                HistoryService.requestHistoryValues(THING_ID, ATTRIBUTE_ID_HUMIDITY, processResponse);
            } else {
                HistoryService.requestHistoryValues(THING_ID, ATTRIBUTE_ID_TEMP, processResponse);
            }
        }
    };

    useEffect(() => {
        setIsCelsius(setting.globalSetting.sensDc.celsius);
    }, [setting]);

    function processResponse(response) {
        let arrMin = [];
        let arrMax = [];
        let arrAve = [];

        response.forEach((element) => {
            if (element.unit === "%") {
                // humidity
                arrMin.push([element.end, element.min]);
                arrMax.push([element.end, element.max]);
                arrAve.push([element.end, element.value]);
            } else {
                if (isCelsius) {
                    if (element.unit === "°C" || element.unit === "") {
                        arrMin.push([element.end, element.min]);
                        arrMax.push([element.end, element.max]);
                        arrAve.push([element.end, element.value]);
                    } else {
                        arrMin.push([element.end, parseFloat(UtilService.toCelsius(element.min))]);
                        arrMax.push([element.end, parseFloat(UtilService.toCelsius(element.max))]);
                        arrAve.push([element.end, parseFloat(UtilService.toCelsius(element.value))]);
                    }
                } else {
                    if (element.unit === "°F") {
                        arrMin.push([element.end, element.min]);
                        arrMax.push([element.end, element.max]);
                        arrAve.push([element.end, element.value]);
                    } else {
                        arrMin.push([element.end, parseFloat(UtilService.toFahrenheit(element.min))]);
                        arrMax.push([element.end, parseFloat(UtilService.toFahrenheit(element.max))]);
                        arrAve.push([element.end, parseFloat(UtilService.toFahrenheit(element.value))]);
                    }
                }
            }
        });

        // set data for the chart
        setMin(arrMin);
        setMax(arrMax);
        setAve(arrAve);
    }

    // Configuration for ReactHighcharts
    const configChart = {
        title: {
            text: "",
        },
        yAxis: [
            {
                title: {
                    text: "",
                },
                offset: 20,
                labels: {
                    formatter: function () {
                        if (THING_ID == "2c4d25c5-4d09-408b-b36b-893df24b77d5") {
                            return this.value + " %";
                        } else {
                            return this.value + (isCelsius ? " °C" : " °F");
                        }
                    },
                    x: 10,
                    style: {
                        color: "#000",
                        position: "absolute",
                    },
                    align: "right",
                    padding: 5,
                },
                opposite: true,
            },
        ],
        tooltip: {
            pointFormat:
                '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ' +
                (THING_ID == "2c4d25c5-4d09-408b-b36b-893df24b77d5" ? " %" : isCelsius ? " °C" : " °F") +
                "<br/>",
            dateTimeLabelFormats: {
                hour: "%e. %b %H:%M",
            },
        },
        plotOptions: {
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3,
                    },
                },
                marker: {
                    enabled: false,
                },
            },
        },
        chart: {
            height: 300,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: true,
        },
        xAxis: {
            type: "datetime",
            labels: {
                overflow: "justify",
            },
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false,
        },
        series: [
            {
                name: t("history.min"),
                type: "spline",
                color: "#60BEC9",
                data: min,
                tooltip: {
                    valueDecimals: 2,
                },
                showInNavigator: false,
            },
            {
                name: t("history.ave"),
                type: "spline",
                color: "#000000",
                data: ave,
                tooltip: {
                    valueDecimals: 2,
                },
                showInNavigator: false,
            },
            {
                name: t("history.max"),
                type: "spline",
                color: "#F05622",
                data: max,
                tooltip: {
                    valueDecimals: 2,
                },
                showInNavigator: false,
            },
        ],
        navigator: {
            enabled: false,
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };

    return (
        <>
            <Accordion square expanded={expanded === "panel1"} onChange={handleChange("panel1")} className={style.accordion}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className={style.div_title}>
                        <span className={style.title}>{t("history.title")}</span>
                    </div>
                    <div className={style.arrow}>
                        {expanded ? <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={style.collapse}>
                        <HighchartsReact highcharts={Highcharts} options={configChart}></HighchartsReact>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

// Styles for Accordion
const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        // padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default HistoryValues;
