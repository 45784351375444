import React, { useState, useEffect } from "react";
import { Stage, Layer, Image, Rect, Group, Text } from "react-konva";
import Box from "@mui/material/Box";
import useImage from "use-image";
import ImgLocation from "Assets/icons/location-pointer.png";
import ImgMicroclimate from "Assets/icons/microclimate.png";
import ImgKou20 from "Assets/icons/kou20.png";
import ImgCompost from "Assets/images/CompostBackgroung.png";
import CompostKonvaImage from "./CompostKonvaImage";
import { v4 as uuidv4 } from "uuid";
import Grid from "@mui/material/Grid";
import { AppId } from "Components/applications/ApplicationData";
import ImageService from "Services/imageService";

export default function CompostMap({ cluster, callback }) {
    const [imgLocation] = useImage(ImgLocation);
    const [imgMicroclimate] = useImage(ImgMicroclimate);
    const [imgKou20] = useImage(ImgKou20);
    const [imgCompost] = useImage(ImgCompost);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [code, setCode] = useState();

    const [backgroundImage, setBackgroundImage] = useState();

    useEffect(() => {
        // console.log("uef in CompostMap, cluster...");
        // console.log(cluster);

        let newWidth, newHeight, newImageCode;
        newWidth = 686;
        newHeight = 709;
        newImageCode = AppId.COMPOST + "_bg_image";
        setBackgroundImage(imgCompost);

        if (!backgroundImage && newImageCode !== code) {
            setWidth(newWidth);
            setHeight(newHeight);
            setCode(newImageCode);

            ImageService.requestBackgroundImage(newImageCode, setBackgroundImage).then((r) => {
                if (r) {
                    const imgElement = document.createElement("img");
                    imgElement.src = "data:image/png;base64," + r;
                    setBackgroundImage(imgElement);
                }
            });
        }
    }, [cluster, backgroundImage, code, imgCompost]);

    const handleImage = (e) => {
        ImageService.uploadBackgroundImage(code, e.target.files[0], setBackgroundImage).then((response) => {
            setCode(undefined);
            setBackgroundImage(undefined);
        });
    };

    return (
        <Box sx={{ flexGrow: 1, pt: 10, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2}>
                {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "right", mr: 4 }}>
                    <label htmlFor="contained-button-file">
                        <input style={{ display: "none" }} accept="image/*" id="contained-button-file" type="file" onChange={handleImage} />
                        <Button variant="contained" component="span">
                            Upload
                        </Button>
                    </label>
                </Grid> */}
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Stage width={width} height={height}>
                        <Layer>{backgroundImage && <Image image={backgroundImage} />}</Layer>
                        <Layer>
                            {cluster &&
                                cluster.map((sensor) => {
                                    return sensor.data.map((r) => {
                                        return (
                                            <CompostKonvaImage
                                                data={r}
                                                image={r.type == "microclimate" ? imgMicroclimate : r.type == "kou20" ? imgKou20 : imgLocation}
                                                key={uuidv4()}
                                                callback={(name) => callback(name)}
                                            />
                                        );
                                    });
                                })}
                        </Layer>
                    </Stage>
                </Grid>
            </Grid>
        </Box>
    );
}
