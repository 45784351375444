import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import UserService from "Services/userService";
import UtilService from "Services/utilService";
import _ from "underscore";

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
        marginTop: "-4px",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        disableScrollLock={true}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    // root: {
    // 	'&:focus': {
    // 		backgroundColor: theme.palette.primary.main,
    // 		'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    // 			color: theme.palette.common.white,
    // 		},
    // 	},
    // },
}))(MenuItem);

export default function MainMenu(props) {
    const { t, i18n } = useTranslation();

    let history = useHistory();
    const setting = useContext(SettingContext);
    const appId = setting.globalSetting.appId;

    const [anchorEl, setAnchorEl] = useState(null);

    const [isAdmin, setIsAdmin] = useState(false);
    const [isManager, setIsManager] = useState(false);
    const [isGrandChildrenCompany, setIsGrandChildrenCompany] = useState(false);
    const [company, setCompany] = useState("");

    useEffect(() => {
        // console.log("useEffect in MainMenu");
        // console.log(appId);

        UserService.getAuthenticatedUser().then((user) => {
            if (user != undefined && !UtilService.isEmpty(user.authorities)) {
                let roles = user.authorities.split(",");
                // console.log(roles);

                _.each(roles, function (role) {
                    if (role.toUpperCase() == "ADMIN" || role.toUpperCase() == "SUPER_ADMIN" || role.toUpperCase() == "CLIENT_ADMIN") {
                        setIsAdmin(true);
                    }

                    if (role.toUpperCase() == "TENANTS") {
                        setIsAdmin(false);
                    } else if (role.toUpperCase() == "MANAGER") {
                        setIsManager(true);
                    }
                });

                setCompany(user.company);
                setIsGrandChildrenCompany(user.isGrandChildrenCompany);
            }
        });
    }, [setting.globalSetting.appId]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        // console.log("handleClose");
        setAnchorEl(null);
    };

    const handleMenu = (location) => {
        // console.log(location);
        history.push(location);
        setAnchorEl(null);
    };

    const getAppMenu = (appId) => {
        switch (appId) {
            case AppId.WATER_MANAGEMENT:
                return (
                    <div>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/water_management`)}>
                            <ListItemText primary="Overview" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/${appId}/main`)}>
                            <ListItemText primary="Device Explorer" />
                        </StyledMenuItem>
                    </div>
                );

            case AppId.SMART_AGRICULTURE:
                return (
                    <div>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/smart_agriculture`)}>
                            <ListItemText primary="Farm" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/smart_agriculture/table`)}>
                            <ListItemText primary="Sensors dashboard" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/${appId}/main`)}>
                            <ListItemText primary="Device Explorer" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/smart_agriculture/plant`)}>
                            <ListItemText primary="Plant Growth" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/smart_agriculture/crop`)}>
                            <ListItemText primary="Crop Analysis" />
                        </StyledMenuItem>
                    </div>
                );

            case AppId.EMERA:
                return (
                    <div>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/emera/v1`)}>
                            <ListItemText primary={t("emera.menu_dashboard")} />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/emera/v1/analysis`)}>
                            <ListItemText primary={t("emera.menu_analysis")} />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/emera/v1/alerts`)}>
                            <ListItemText primary={t("emera.menu_alerts")} />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/emera/v1/reports`)}>
                            <ListItemText primary={t("emera.menu_reports")} />
                        </StyledMenuItem>
                    </div>
                );

            case AppId.EMERA_V2:
                return (
                    <div>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/emera/v2`)}>
                            <ListItemText primary={t("emera_v2.menu_dashboard")} />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/emera/v2/analysis`)}>
                            <ListItemText primary={t("emera_v2.menu_analysis")} />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/emera/v2/alerts`)}>
                            <ListItemText primary={t("emera_v2.menu_alerts")} />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/emera/v2/reports`)}>
                            <ListItemText primary={t("emera_v2.menu_reports")} />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/${appId}/main`)}>
                            <ListItemText primary={t("emera_v2.menu_device_explorer")} />
                        </StyledMenuItem>
                    </div>
                );

            case AppId.AGRICULTURE_WATER:
                return (
                    <div>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/agriculture_water`)}>
                            <ListItemText primary="Dashboard" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/agriculture_water/irrigation`)}>
                            <ListItemText primary="Irrigation" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/${appId}/main`)}>
                            <ListItemText primary="Device Explorer" />
                        </StyledMenuItem>
                    </div>
                );

            case AppId.SENS_DC:
                return (
                    <div>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/sens_dc`)}>
                            <ListItemText primary="Rack sensors" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/${appId}/main`)}>
                            <ListItemText primary="Device Explorer" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/sens_dc/alerts`)}>
                            <ListItemText primary="Alerts" />
                        </StyledMenuItem>
                    </div>
                );
            case AppId.DEVICE_EXPLORER:
                return (
                    <div>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/device_explorer/main`)}>
                            <ListItemText primary={t("de.device")} />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/measurement`)}>
                            <ListItemText primary={t("de.measurement")} />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/gateways`)}>
                            <ListItemText primary={t("de.gateways")} />
                        </StyledMenuItem>
                        {isAdmin && !isGrandChildrenCompany && (
                            <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/companies`)}>
                                <ListItemText primary={t("com.organization")} />
                            </StyledMenuItem>
                        )}
                        {isAdmin && (
                            <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/users`)}>
                                <ListItemText primary={t("com.users")} />
                            </StyledMenuItem>
                        )}
                    </div>
                );
            case AppId.SUPERVIOT:
                return (
                    <div>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/superviot`)}>
                            <ListItemText primary={t("superviot.carte")} />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/${appId}/main`)}>
                            <ListItemText primary="Device Explorer" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/superviot/logs`)}>
                            <ListItemText primary={t("superviot.logs")} />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/superviot/alarms`)}>
                            <ListItemText primary={t("superviot.alarms")} />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/superviot/setting`)}>
                            <ListItemText primary={t("superviot.setting")} />
                        </StyledMenuItem>
                    </div>
                );
            case AppId.COMPOST:
                return (
                    <div>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/compost/field_map`)}>
                            <ListItemText primary="Compost" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/${appId}/main`)}>
                            <ListItemText primary="Device Explorer" />
                        </StyledMenuItem>
                    </div>
                );
            case AppId.TEMPERATURE_MONITORING:
                return (
                    <div>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/temperature_monitoring`)}>
                            <ListItemText primary="Dashboard" />
                        </StyledMenuItem>
                    </div>
                );
            case AppId.WATER_METERING:
                return (
                    <div>
                        <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/water_metering/water_metering`)}>
                            <ListItemText primary={t("wm.meters")} />
                        </StyledMenuItem>
                        {isAdmin && (
                            <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/device_explorer/gateways`)}>
                                <ListItemText primary={t("de.gateways")} />
                            </StyledMenuItem>
                        )}
                        {isAdmin && (
                            <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/water_metering/users/org`)}>
                                <ListItemText primary={t("com.organization")} />
                            </StyledMenuItem>
                        )}
                        {isAdmin && (
                            <StyledMenuItem onClick={() => handleMenu(`${process.env.PUBLIC_URL}/water_metering/assign/things`)}>
                                <ListItemText primary={t("com.users")} />
                            </StyledMenuItem>
                        )}
                    </div>
                );
            default:
                break;
        }
    };

    return (
        appId != undefined &&
        (appId == AppId.WATER_MANAGEMENT ||
            appId == AppId.SMART_AGRICULTURE ||
            appId == AppId.EMERA ||
            appId == AppId.AGRICULTURE_WATER ||
            appId == AppId.SENS_DC ||
            appId == AppId.EMERA_V2 ||
            appId == AppId.DEVICE_EXPLORER ||
            appId == AppId.SUPERVIOT ||
            appId == AppId.COMPOST ||
            appId == AppId.TEMPERATURE_MONITORING ||
            appId == AppId.WATER_METERING) && (
            <>
                <IconButton aria-label="delete" onClick={handleClick} size="large">
                    <i className="fa fa-bars" aria-hidden="true" style={{ color: "white" }}></i>
                </IconButton>

                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    // keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {getAppMenu(appId)}
                </StyledMenu>
            </>
        )
    );
}
