import _ from "underscore";
import ApiService from "Services/apiService";
import UtilService from "Services/utilService";
import Coordinator from "./Coordinator";
import { AppId } from "Components/applications/ApplicationData";
import UserService from "Services/userService";
import fileDOwnload from "js-file-download";
import { EXCLUDED_ATTRIBUTES_NAMES } from "../thingDetail/DetailService";
import CloudUserService from "./CloudUserService";

const MeasurementService = {
    abortController: new AbortController(),

    requestData: async (createData, page, pageSize, setRows, allColumns, setColumns, keyword, sortQuery, on, appId, setRowCount) => {
        if (MeasurementService.abortController) {
            MeasurementService.abortController.abort(); // Cancel the previous request
        }

        MeasurementService.abortController = new AbortController(); // Create a new controller for the current request
        const { signal } = MeasurementService.abortController;

        let orFilter = "";

        if (keyword != undefined) {
            let idsQuery = [];
            idsQuery.push(`{"property":["thing.name", "thing.fixedname", "site.name", "thing.tags"],"operator":"ilike","value":"${keyword}"}`);

            if (appId == AppId.SMART_AGRICULTURE) {
                idsQuery.push(`{"property":["company"],"operator":"eq","value":"CENGN"}`);
            }

            let concated = idsQuery.join(",");
            orFilter += encodeURI(`&orFilter=[${concated}]`);
        }

        let filter = "";

        const user = await UserService.getAuthenticatedUser();
        if (user && user.company != "PTH") {
            filter = `&filter=company.name:eq_${user.company}`;
        }

        sortQuery = sortQuery === "" ? "&sort=displayName&dir=ASC" : sortQuery;

        let requestOptions = {
            url: "/api/things?detailed=true&page=" + (page + 1) + "&pageSize=" + pageSize + filter + orFilter + sortQuery,
            signal,
        };

        let enableColumns = [];

        // console.log("requestOptions....in MeasurementService");
        let tempRow = [];

        const result = await ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requestData in MeasurementService");
                // console.log(response);

                let totalSize = response.totalSize;
                let excludeAttributes = ["_Battery", "_battery", "mode", "event_count", "minutes_since_last_event", "relative_humidity"];

                let values = response.data.map((ele) => {
                    let modelName = "";
                    let manufacturerName = "";
                    let productName = "";
                    let connectivityType = "";
                    let tags = "";
                    let site = "";

                    if (ele.product != undefined) {
                        modelName = ele.customModel != undefined ? ele.customModel.name : "";
                        manufacturerName = ele.product.manufacturer.name;
                        productName = ele.product.name;
                        connectivityType = ele.product.connectivityTypes ? ele.product.connectivityTypes[0] : "";

                        let arrTags = [];
                        _.each(ele.tags, function (e) {
                            arrTags.push(e.tag);
                        });
                        tags = arrTags.join(", ");
                    }

                    let attributes = [];

                    if (ele.lastMeasurements) {
                        let measurement = ele.lastMeasurements.measurements;

                        if (measurement != undefined) {
                            _.each(measurement, function (value, key) {
                                // If any attribute doesn't show up, put the attribute into Attributes in Coordinator.js
                                // console.log(key);

                                if (!excludeAttributes.includes(key)) {
                                    var temp = {};
                                    var id = Coordinator.getId(key);

                                    temp[id] = value;
                                    attributes.push(temp);

                                    let foundColumn = false;
                                    _.each(enableColumns, function (col) {
                                        if (col == id) {
                                            foundColumn = true;
                                        }
                                    });

                                    if (!foundColumn) {
                                        enableColumns.push(id);
                                    }
                                }
                            });
                        }
                    }

                    if (ele.site != undefined) {
                        site = ele.site.name;
                    }

                    let tempVolume = "";
                    if (ele.lastMeasurements && ele.lastMeasurements.measurements) {
                        tempVolume = ele.lastMeasurements.measurements.volume;
                    }

                    let tempVolumeUnit = "";
                    if (ele.lastMeasurements && ele.lastMeasurements.units && ele.lastMeasurements.units.hasOwnProperty("volume")) {
                        tempVolumeUnit = ele.lastMeasurements.units.volume;
                    }

                    let alertCount = ele.alert ? ele.alert.alertCount : 0;
                    let alertLastMessage = "";
                    let alertLastDate = "";

                    if (ele.alert && ele.alert.lastAlert) {
                        alertLastMessage = ele.alert.lastAlert.message;
                        alertLastDate = UtilService.formateDate(ele.alert.lastAlert.creationDate * 1000);
                    }

                    let formattedSize = "";
                    switch (ele.attrSizeValue) {
                        case "0":
                            formattedSize = "";
                            break;
                        case "10":
                            formattedSize = "5/8 x ¾”";
                            break;
                        case "20":
                            formattedSize = "¾” x ¾”";
                            break;
                        case "30":
                            formattedSize = "1”";
                            break;
                        case "40":
                            formattedSize = "1 ½”";
                            break;
                        case "50":
                            formattedSize = "2";
                            break;
                        default:
                            formattedSize = "";
                            break;
                    }

                    // tenent column uses it
                    let owner = ele.attrFirstnameValue || "";
                    owner += ele.attrLastnameValue ? ` ${ele.attrLastnameValue}` : "";

                    // console.log(ele);

                    return createData(
                        ele.id,
                        ele.company,
                        ele.displayName,
                        ele.lastMessageDate ? UtilService.formateDate(ele.lastMessageDate * 1000) : "",
                        modelName,
                        manufacturerName,
                        productName,
                        connectivityType,
                        ele.status,
                        tags,
                        site,
                        attributes,
                        tempVolume,
                        tempVolumeUnit,
                        alertCount,
                        alertLastMessage,
                        alertLastDate,
                        formattedSize,
                        ele.attrRadioValue,
                        ele.attrUnitValue ? ele.attrUnitValue : "",
                        ele.serialNumber,
                        owner,
                        ele
                    );
                });

                tempRow = values;
                // setRows(values);
                setRowCount(totalSize);

                // Save the column order initially and retrieve it for display.
                let copiedColumns = [...allColumns];
                let savedColumns = Coordinator.getColumnOrder();

                if (_.isEmpty(savedColumns) || savedColumns === "null") {
                    Coordinator.saveInitialOrder(copiedColumns);
                } else {
                    // End of pushing the columns
                    // Reorder the columns base on savedColumns. if not style dosn't effect
                    copiedColumns = savedColumns.map((s) => copiedColumns.find((c) => c.field === s.field)).filter((c) => c != undefined);

                    // Saved columns don't have the unit column because It added after saving the column feature...
                    if (copiedColumns) {
                        if (copiedColumns.find((c) => c.field === "unit") == undefined) {
                            copiedColumns.push({
                                field: "unit",
                                headerName: "Unit",
                                width: 110,
                                sortable: false,
                            });
                        }
                        if (copiedColumns.find((c) => c.field === "alertLastMessage") == undefined) {
                            copiedColumns.push({
                                field: "alertLastMessage",
                                headerName: "Alert",
                                width: 110,
                                sortable: false,
                            });
                        }
                        if (copiedColumns.find((c) => c.field === "alertLastDate") == undefined) {
                            copiedColumns.push({
                                field: "alertLastDate",
                                headerName: "Timestamp",
                                width: 200,
                                sortable: false,
                            });
                        }
                    }
                }

                setColumns(copiedColumns);
            })
            .then(() => {
                setRows(tempRow);
                return tempRow;
            })
            .catch((e) => console.log(e));

        return result;
    },

    requestModel: async (thingId, setModel) => {
        let requestOptions = {
            url: `/api/things/${thingId}/model`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requestModel...Response");
                // console.log(response);

                let attributes = [];
                attributes.push({ id: "all", label: "All" });

                if (response.attributes) {
                    response.attributes.forEach((attr) => {
                        if (!EXCLUDED_ATTRIBUTES_NAMES.includes(attr.name)) {
                            attributes.push({
                                id: attr.id,
                                key: attr.id,
                                label: attr.name,
                            });
                        }
                    });
                }
                // console.log(attributes);

                setModel(attributes);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requesCustomtModel: async (thingId, setModel) => {
        let requestOptions = {
            url: `/api/things/${thingId}/custom_model/attributes`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requesCustomtModel...Response");
                // console.log(response);

                if (!response.error) {
                    setModel((model) => {
                        response.forEach((e) =>
                            model.push({
                                id: e.id,
                                key: e.id,
                                label: e.name,
                            })
                        );
                        return model;
                    });
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestReport: async (page, pageSize, keyword, sortQuery, reportType) => {
        let orFilter = "";

        if (keyword != undefined) {
            let idsQuery = [];
            idsQuery.push(`{"property":["thing.name", "thing.fixedname", "site.name", "thing.tags"],"operator":"ilike","value":"${keyword}"}`);

            // if (appId == AppId.SMART_AGRICULTURE) {
            //     idsQuery.push(`{"property":["company"],"operator":"eq","value":"CENGN"}`);
            // }

            let concated = idsQuery.join(",");
            orFilter += encodeURI(`&orFilter=[${concated}]`);
        }

        let filter = "";

        const user = await UserService.getAuthenticatedUser();
        if (user && user.company != "PTH") {
            filter = `&filter=company.name:eq_${user.company}`;
        }

        sortQuery = sortQuery === "" ? "&sort=displayName&dir=ASC" : sortQuery;

        let requestOptions = {
            url:
                `/api/report?detailed=true&page=` +
                (page + 1) +
                "&pageSize=" +
                pageSize +
                filter +
                orFilter +
                sortQuery +
                "&reportType=" +
                reportType +
                "&appName=H2O",
        };

        // console.log(requestOptions);

        ApiService.downloadFile(requestOptions)
            .then((response) => {
                fileDOwnload(response, `SensorList.${reportType}`);
            })
            .catch((e) => console.log(e));
    },

    requestReportMinol: async (page, pageSize, keyword, sortQuery, reportType) => {
        let orFilter = "";

        if (keyword != undefined) {
            let idsQuery = [];
            idsQuery.push(`{"property":["thing.name", "thing.fixedname", "site.name", "thing.tags"],"operator":"ilike","value":"${keyword}"}`);

            // if (appId == AppId.SMART_AGRICULTURE) {
            //     idsQuery.push(`{"property":["company"],"operator":"eq","value":"CENGN"}`);
            // }

            let concated = idsQuery.join(",");
            orFilter += encodeURI(`&orFilter=[${concated}]`);
        }

        let filter = "";

        const user = await UserService.getAuthenticatedUser();
        if (user && user.company != "PTH") {
            filter = `&filter=company.name:eq_${user.company}`;
        }

        sortQuery = sortQuery === "" ? "&sort=displayName&dir=ASC" : sortQuery;

        let requestOptions = {
            url:
                `/api/report/minol?detailed=true&page=` +
                (page + 1) +
                "&pageSize=" +
                pageSize +
                filter +
                orFilter +
                sortQuery +
                "&reportType=" +
                reportType +
                "&appName=H2O",
        };

        // console.log(requestOptions);

        ApiService.downloadFile(requestOptions)
            .then((response) => {
                fileDOwnload(response, `SensorList.txt`);
            })
            .catch((e) => console.log(e));
    },
};

export default MeasurementService;
