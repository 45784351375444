import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FavoriteCard from "./FavoriteCard";
import FavoriteService from "./FavoriteService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SensorDetailDialog from "../measurements/thingDetail/SensorDetailDialog";
import StarRateIcon from "@mui/icons-material/StarRate";
import Item from "../../../components/ui/Item";
import moment from "moment";

export default function FavoriteWrapper({ reload, user, lastMeasure, setReload }) {
    const [open, setOpen] = useState(false);
    const [selectedThingId, setSelectedThingId] = useState("");

    useEffect(() => {
        // do nothing
        // console.log(lastMeasure);
    }, [reload]);

    const handleOpen = (e, thingId) => {
        e.stopPropagation();
        setSelectedThingId(thingId);
        setOpen(true);
    };

    const handleStar = (e, thingId) => {
        e.stopPropagation();
        FavoriteService.removeFavorite(user.cognitoUserName, thingId).then(() => {
            setReload(moment().unix());
        });
    };

    return (
        <>
            {lastMeasure && (
                <>
                    <Item elevation={0}>
                        <Box sx={{ m: 1 }}>
                            {lastMeasure.map((l) => {
                                // console.log("///-----")
                                // console.log(l.dataPoints)

                                return (
                                    <Accordion key={l.thingId} defaultExpanded={true}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <div onClick={(e) => handleStar(e, l.thingId)} style={{ cursor: "pointer" }}>
                                                <StarRateIcon />
                                            </div>
                                            <div onClick={(e) => handleOpen(e, l.thingId)}>
                                                <Typography>{l.displayName}</Typography>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box sx={{ mb: 2, flexGrow: 1, display: "flex", flexWrap: "wrap", gap: 2 }}>
                                                {l.dataPoints.map((d) => (
                                                    <FavoriteCard
                                                        measure={d}
                                                        key={d.id}
                                                        user={user}
                                                        setReload={() => {
                                                            setReload(moment().unix());
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </Box>
                    </Item>
                    <SensorDetailDialog open={open} setOpen={setOpen} thing={lastMeasure} user={user} selectedThingId={selectedThingId} />
                </>
            )}
        </>
    );
}
