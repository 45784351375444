import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import UtilService from "Services/utilService";
import Grid from "@mui/material/Grid";
import { MdSignalCellularAlt1Bar, MdSignalCellularAlt2Bar, MdSignalCellularAlt } from "react-icons/md";
import { green, yellow, red } from "@mui/material/colors";

export default function GatewayStatus({ entity }) {
    const [name, setName] = useState("");
    const [sf, setSf] = useState("");
    const [time, setTime] = useState("");
    const [rssi, setRssi] = useState("");
    const [snr, setSnr] = useState("");

    const [rssiSignalIndicator, setRssiSignalIndicator] = useState("Good");
    const [snrSignalIndicator, setSnrSignalIndicator] = useState("Good");

    useEffect(() => {
        // console.log("uef in GatewayStatus");
        // console.log(entity);

        if (entity) {
            setName(entity.gatewayName);
            setSf(entity.sf);
            setTime(UtilService.formateMDYHMS2(entity.creationTimestamp));
            setRssi(entity.rssi);
            setSnr(entity.snr);

            setSignalIndicator(entity.sf, entity.rssi, setRssiSignalIndicator, entity.snr, setSnrSignalIndicator);
        }
    }, [entity]);

    return (
        <Paper elevation={3} sx={{ width: "200px", m: 2, height: 150 }}>
            <Grid container spacing={1} sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" component="div">
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="subtitle2" component="div">
                        {rssi} dbm
                    </Typography>
                    {getSignalIndicatorIcon(rssiSignalIndicator)}
                    <Typography variant="subtitle2" component="div">
                        RSSI
                    </Typography>
                </Grid>
                <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" component="div">
                        SF{sf}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="subtitle2" component="div">
                        {snr} dB
                    </Typography>
                    {getSignalIndicatorIcon(snrSignalIndicator)}
                    <Typography variant="subtitle2" component="div">
                        SNR
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <Typography variant="body1" component="div">
                        {time}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

function setSignalIndicator(sf, rssi, setRssiSignalIndicator, snr, setSnrSignalIndicator) {
    switch (sf) {
        case 7:
            if (rssi < -117) {
                setRssiSignalIndicator("Bad");
            } else if (rssi < -107) {
                setRssiSignalIndicator("Average");
            } else {
                setRssiSignalIndicator("Good");
            }
            if (snr < 0) {
                setSnrSignalIndicator("Bad");
            } else if (snr < 10) {
                setSnrSignalIndicator("Average");
            } else {
                setSnrSignalIndicator("Good");
            }
            break;
        case 8:
            if (rssi < -120) {
                setRssiSignalIndicator("Bad");
            } else if (rssi < -110) {
                setRssiSignalIndicator("Average");
            } else {
                setRssiSignalIndicator("Good");
            }
            if (snr < -5) {
                setSnrSignalIndicator("Bad");
            } else if (snr < 5) {
                setSnrSignalIndicator("Average");
            } else {
                setSnrSignalIndicator("Good");
            }
            break;
        case 9:
            if (rssi < -123) {
                setRssiSignalIndicator("Bad");
            } else if (rssi < -113) {
                setRssiSignalIndicator("Average");
            } else {
                setRssiSignalIndicator("Good");
            }
            if (snr < -8) {
                setSnrSignalIndicator("Bad");
            } else if (snr < 2) {
                setSnrSignalIndicator("Average");
            } else {
                setSnrSignalIndicator("Good");
            }
            break;
        case 10:
            if (rssi < -127) {
                setRssiSignalIndicator("Bad");
            } else if (rssi < -127) {
                setRssiSignalIndicator("Average");
            } else {
                setRssiSignalIndicator("Good");
            }
            if (snr < -10) {
                setSnrSignalIndicator("Bad");
            } else if (snr < 0) {
                setSnrSignalIndicator("Average");
            } else {
                setSnrSignalIndicator("Good");
            }
            break;
        case 11:
            if (rssi < -129) {
                setRssiSignalIndicator("Bad");
            } else if (rssi < -119) {
                setRssiSignalIndicator("Average");
            } else {
                setRssiSignalIndicator("Good");
            }
            if (snr < -12) {
                setSnrSignalIndicator("Bad");
            } else if (snr < -2) {
                setSnrSignalIndicator("Average");
            } else {
                setSnrSignalIndicator("Good");
            }
            break;
        default: // 12
            if (rssi < -130) {
                setRssiSignalIndicator("Bad");
            } else if (rssi < -120) {
                setRssiSignalIndicator("Average");
            } else {
                setRssiSignalIndicator("Good");
            }
            if (snr < -15) {
                setSnrSignalIndicator("Bad");
            } else if (snr < -5) {
                setSnrSignalIndicator("Average");
            } else {
                setSnrSignalIndicator("Good");
            }
            break;
    }
}

function getSignalIndicatorIcon(signalIndicator) {
    switch (signalIndicator) {
        case "Bad":
            return <MdSignalCellularAlt1Bar size="1.5em" color={red[500]} />;
        case "Average":
            return <MdSignalCellularAlt2Bar size="1.5em" color={yellow[800]} />;
        case "Good":
            return <MdSignalCellularAlt size="1.5em" color={green[500]} />;
        default:
            return <MdSignalCellularAlt1Bar size="1.5em" color={red[500]} />;
    }
}
