import makeCancellablePromise from 'make-cancellable-promise'

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import _ from 'underscore';
import UtilService from 'Services/utilService'

import wStyle from './style.module.css'

import { useEffect, useState } from 'react'

const headCells = [];

var globalPage = 0;
var globalRowPerPage = 10;
var globalOrder = 'desc';
var globalOrderBy = 'timestamp';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id && headCell.id != 'tag'}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={headCell.id === 'tag'}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

function createData(thing, attribute, measure, type, unit, timestamp, model, product, tags = []) {
    return {
        thing, attribute, measure: `${measure} ${unit}`, type, unit, timestamp: UtilService.formateDate(timestamp / 1000),
        model, product, tag: tags.map(tag => tag.tag).join(',')
    };
}

const initializeColumn = (opt) => {
    if (headCells.length > 0) {
        return;
    }

    headCells.push({ id: 'thing', numeric: false, disablePadding: false, label: 'Thing' });

    headCells.push({ id: 'attribute', numeric: false, disablePadding: false, label: 'Attribute' });

    headCells.push({ id: 'measure', numeric: false, disablePadding: false, label: 'Measure' });

    if (opt.typeMeasure === "on")
        headCells.push({ id: 'type', numeric: false, disablePadding: false, label: 'type' });

    if (opt.dateMeasure === "on")
        headCells.push({ id: 'timestamp', numeric: false, disablePadding: false, label: 'Timestamp' });

    if (opt.modelMeasure === "on")
        headCells.push({ id: 'model', numeric: false, disablePadding: false, label: 'Model' });

    if (opt.productMeasure === "on")
        headCells.push({ id: 'product', numeric: false, disablePadding: false, label: 'Product' });

    if (opt.tagsMeasure === "on")
        headCells.push({ id: 'tag', numeric: false, disablePadding: false, label: 'Tag' });
}

const GridWidget = (props) => {

    const conf = props.config;
    const opt = props.config.options.checkboxs || {};

    const classes = useStyles();
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('thing');

    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    initializeColumn(opt);

    const handleRequestSort = (event, property) => {
        if (property === 'tag') // The tag column is not sorted.
            return;

        const isAsc = orderBy === property && order === 'asc';

        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        // initialize sort options
        setPage(0);
        globalPage = 0;
        globalOrder = order;
        globalOrderBy = property;

        configureTable();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);

        globalPage = newPage;
        configureTable();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);

        globalPage = 0;
        globalRowPerPage = event.target.value;
        configureTable();
    };

    /**
     * The api call is called only when the widget has been rendered.
     * It can be changed in the future.
     */
    useEffect(() => {
        configureTable();
    }, [])

    const configureTable = () => {
        props.getValues(configurationExtractor)
            .then((values) => {

                let receivedData = extractData(values).map(element => {
                    return createData(
                        element.thing.name,
                        element.attribute.name,
                        element.value,
                        element.attribute.type.name,
                        element.attribute.unit,
                        element.timestamp,
                        element.model.name,
                        element.product.name,
                        element.thing.tags)
                });

                var arrTemp = [];

                for (var k = 0; k < receivedData.length; k++) {
                    arrTemp.push(receivedData[k]);
                }

                if (globalPage > 0) {
                    // copy the receivedData to the last page for paging
                    for (var i = 0; i < globalPage; i++) {
                        if (i == globalPage - 1) { // last page
                            for (var k = 0; k < receivedData.length; k++) {
                                arrTemp.push(receivedData[k]);
                            }
                        } else { // middle pages
                            for (var k = 0; k < receivedData.length; k++) {
                                arrTemp.push([]); // adding empty array for paging
                            }
                        }
                    }
                }

                setRows(arrTemp); // set data for the table
            })
    };

    return (
        <div className={"d-flex flex-column " + wStyle.w_container}>
            <div className="">
                <div className={"col" + wStyle.w_title}>
                    {conf.title}
                </div>
            </div>
            <div className="d-flex flex-fill flex-column" style={{ overflow: 'hidden' }}>
                <div className="d-flex flex-column" style={{ height: '100%', width: '100%' }}>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                            style={{ tableLayout: 'fixed' }}
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {getRows(rows, rowsPerPage, page, order, orderBy, classes)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        className="flex-shrink-0"
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={-1}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </div>
    );
}

/**
 * Return the row jsx components
 * @param {Object} rows 
 * @param {Integer} rowsPerPage 
 * @param {Integer} page 
 * @param {Integer} order 
 * @param {Integer} orderBy 
 */
const getRows = (rows = [], rowsPerPage, page, order, orderBy, classes) => {
    return stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {

            var rowsEle = headCells.map((cell) => {
                switch (cell.id) {
                    case 'thing':
                        return <TableCell align="left">{row.thing}</TableCell>;
                    case 'attribute':
                        return <TableCell align="left">{row.attribute}</TableCell>;
                    case 'measure':
                        return <TableCell align="left">{row.measure}</TableCell>;
                    case 'type':
                        return <TableCell align="left">{row.type}</TableCell>;
                    case 'timestamp':
                        return <TableCell align="left">{row.timestamp}</TableCell>;
                    case 'model':
                        return <TableCell align="left">{row.model}</TableCell>;
                    case 'product':
                        return <TableCell align="left">{row.product}</TableCell>;
                    case 'tag':
                        return <TableCell align="left">{row.tag}</TableCell>;
                }
            });

            return (
                <TableRow>{rowsEle}</TableRow>
            );
        })
}

/**
 * This method transform the api response into a new object usable by the widget render
 * @param {ApiValues} values
 */
const extractData = (values) => {
    return values[0].data
}

/**
 * Every widget needs a configuration extractor. Because every widget has its own configuration, we need to implement specific configuration extractor in order to transform widgetConfiguration to actual ApiService configuration
 * @param {WidgetConfigurationObject} configuration
 */
const configurationExtractor = (configuration) => {
    switch (globalOrderBy) {
        case 'thing':
            globalOrderBy = 'thing.name';
            break;
        case 'attribute':
            globalOrderBy = 'attribute.name';
            break;
        case 'measure':
            globalOrderBy = 'value';
            break;
        case 'type':
            globalOrderBy = 'attribute.name';
            break;
        case 'timestamp':
            globalOrderBy = 'timestamp';
            break;
        case 'model':
            globalOrderBy = 'thing_model.name';
            break;
        case 'product':
            globalOrderBy = 'product.name';
            break;
    }

    let sortParam = globalOrder == 'asc' ? '+' : '-';
    sortParam += globalOrderBy;

    return [{
        options: { page: globalPage, pageSize: globalRowPerPage, detailed: "true", sort: sortParam },
        filters: configuration.filters,
        url: '/api/measures'
    }];
}

export default GridWidget
export { configurationExtractor }
