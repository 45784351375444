import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import _ from "underscore";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ShareService from "./ShareService";
import Box from "@mui/material/Box";

export default function SetupComplete(props) {
    let history = useHistory();
    let errors = {
        name: false,
        message: "hi",
    };
    const appId = props.appId;

    const forwardKey = props.forwardKey;
    const [checkShare, setCheckShare] = useState(true);
    const [disableShare, setDisableShare] = useState(false);

    useEffect(() => {
        ShareService.checkFlowTarget(forwardKey, setCheckShare, setDisableShare);
    }, [checkShare]);

    const handleHome = () => {
        history.push(`${process.env.PUBLIC_URL}/device_explorer/${appId}/main`);
    };

    const handleSharing = () => {
        ShareService.enableFlowFlowTargetByCompany(forwardKey, true).then((enable) => {
            handleHome();
            setCheckShare(enable);
        });
    };

    const handleStopSharing = () => {
        ShareService.enableFlowFlowTargetByCompany(forwardKey, false).then((enable) => setCheckShare(enable));
    };

    return (
        <>
            <Paper elevation={6} spacing={2} style={{ height: "300px", width: '100%' }}>
                <Grid container direction="column" align="center" justifyContent="center" style={{ height: "100%" }}>
                    <Grid item style={{ height: "100%", alignItems: "center" }}>
                        <Box sx={{ width: "100%", height: "120px" }} />
                        {!checkShare && (
                            <Button variant="contained" style={{ backgroundColor: "#9CC96B" }} onClick={() => handleSharing()}>
                                Start sharing
                            </Button>
                        )}
                        {checkShare && (
                            <Button variant="contained" style={{ backgroundColor: "#9CC96B" }} onClick={() => handleStopSharing()}>
                                Stop sharing
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
