import { useState, useEffect } from "react";
import GatewayStatus from "./GatewayStatus";
import Box from "@mui/material/Box";
import DetailService from "./DetailService";

export default function GatewayStatusPagination({ lastGatewayStatus, selectedThingId }) {
    const [gatewayStatus, setGatewayStatus] = useState([]);

    useEffect(() => {
        DetailService.requestGatewayStatus(selectedThingId).then((response) => {
            // console.log(response);
            setGatewayStatus(response);
        });
    }, [lastGatewayStatus, selectedThingId]);

    return (
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2, overflowY: "scroll", height: 290 }}>
            {gatewayStatus.map((g) => {
                return <GatewayStatus key={g.id} entity={g} />;
            })}
        </Box>
    );
}
