import React from "react";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { blueGrey } from "@mui/material/colors";

export default function SearchBox({ callback }) {
    const handleChange = (e) => {
        // console.log("-------------");
        // console.log(e.target.value);
        callback(e.target.value);
    };

    return (
        <Box sx={{ position: "relative", borderRadius: 1, backgroundColor: blueGrey[100] }}>
            <Box sx={{ pl: 1, pr: 1, position: "absolute", display: "flex", alignItems: "center", height: "34px" }}>
                <SearchIcon />
            </Box>
            <InputBase sx={{ color: "inherit", paddingLeft: 4 }} placeholder="Search…" inputProps={{ "aria-label": "search" }} onChange={handleChange} />
        </Box>
    );
}
