import style from './style.module.css';
import store from 'store';

export const KEY_PREFIX = "sa.alert.";
export const KEY_FILTER = "sa.alert.filter";

export let columns = [
    {
        field: 'creationDate',
        headerName: 'Creation Date',
        flex: 0.7,
        editable: false,
        sortable: true,
        filterable: false,
        // hide: false
        hide: (store.get(KEY_PREFIX + 'creationDate') == undefined ? false : !store.get(KEY_PREFIX + 'creationDate'))
    },
    {
        field: 'level',
        headerName: 'Level',
        flex: 0.4,
        editable: false,
        sortable: true,
        filterable: false,
        renderCell: (params) => {
            let labelStyle = style.default;
            switch (params.value) {
                case "WARNING":
                    labelStyle = style.warning;
                    break;
                case "CRITICAL":
                    labelStyle = style.critical;
                    break;
                default:
                    break;
            }
            return (
                <span className={"badge " + style.label + " " + labelStyle}>{params.value}</span>
            );
        },
        // hide: false
        hide: (store.get(KEY_PREFIX + 'level') == undefined ? false : !store.get(KEY_PREFIX + 'level'))
    },
    {
        field: 'message',
        headerName: 'Message',
        flex: 1.5,
        editable: false,
        sortable: true,
        filterable: false,
        // hide : false
        hide: (store.get(KEY_PREFIX + 'message') == undefined ? false : !store.get(KEY_PREFIX + 'message'))
    },
    {
        field: 'fixedName',
        headerName: 'Thing',
        flex: 0.7,
        editable: false,
        sortable: true,
        filterable: false,
        // hide : false
        hide: (store.get(KEY_PREFIX + 'fixedName') == undefined ? false : !store.get(KEY_PREFIX + 'fixedName'))
    },
    {
        field: 'comment',
        headerName: 'Comment',
        flex: 1,
        editable: false,
        sortable: true,
        filterable: false,
        // hide: true
        hide: (store.get(KEY_PREFIX + 'comment') == undefined ? false : !store.get(KEY_PREFIX + 'comment'))
    },
    {
        field: 'lastUpdate',
        headerName: 'Last update',
        flex: 1,
        editable: false,
        sortable: true,
        filterable: false,
        // hide: true
        hide: (store.get(KEY_PREFIX + 'lastUpdate') == undefined ? false : !store.get(KEY_PREFIX + 'lastUpdate'))
    },
    {
        field: 'state',
        headerName: 'State',
        flex: 0.4,
        editable: false,
        sortable: false,
        renderCell: (params) => {
            let stateStyle = style.default;
            if (params.value != undefined) {
                switch (params.value.toUpperCase()) {
                    case "OPENED":
                        stateStyle = style.critical;
                        break;
                    case "RESOLVED":
                        stateStyle = style.success;
                        break;
                    case "IN_PROGRESS":
                        stateStyle = style.in_progress;
                        break;
                    case "IGNORED":
                        stateStyle = style.ignored;
                        break;
                    default:
                        break;
                }
            }
            return (
                <span className={"badge " + style.label + " " + stateStyle}>{params.value == undefined ? '' : params.value}</span>
            );
        },
        // hide: false
        hide: (store.get(KEY_PREFIX + 'state') == undefined ? false : !store.get(KEY_PREFIX + 'state'))
    },
    {
        field: 'username',
        headerName: 'User name',
        flex: 1,
        editable: false,
        sortable: true,
        filterable: false,
        // hide: true,
        hide: (store.get(KEY_PREFIX + 'username') == undefined ? false : !store.get(KEY_PREFIX + 'username'))
    },
];
