import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Fab from "@mui/material/Fab";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import Badge from "@mui/material/Badge";
import AppContext from "Components/AppContext";
import MapInteractionCustom from "Components/MapInteractionCustom";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import style from "./style.module.css";
import agricultureMap from "Assets/images/agriculture_map.png";
import agricultureMap_edf from "Assets/images/agriculture_map_edf.png";
import DashboardService from "./table/DashboardService";
import UserService from "Services/userService";
import Button from "@mui/material/Button";
import ImageService from "Services/imageService";

export default function SmartAgriculture(props) {
    const history = useHistory();
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    ctx.updateTitle("Smart Agriculture");
    const [alertCount, setAlertCount] = useState([]);
    const [company, setCompany] = useState();
    const [backgroundImage, setBackgroundImage] = useState();
    const [showUploadButton, setShowUploadButton] = useState(false);

    const code = AppId.SMART_AGRICULTURE + "_bg_image";

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.SMART_AGRICULTURE,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        UserService.getAuthenticatedUser()
            .then((user) => {
                setCompany(user.company);

                if (user.authorities.indexOf("SUPER_ADMIN") != -1 || user.authorities.indexOf("APPLICATION_SMART_AGRICULTURE_PLUS") != -1) {
                    setShowUploadButton(true);
                }

                return user.company;
            })
            .then((company) => {
                ImageService.requestBackgroundImage(code, setBackgroundImage).then((imageExist) => {
                    if (!imageExist) {
                        if (company) {
                            if (company == "PTH" || company == "CENGN") {
                                setBackgroundImage(agricultureMap);
                            } else if (company == "EDF") {
                                setBackgroundImage(agricultureMap_edf);
                            } else {
                                setBackgroundImage(agricultureMap);
                            }
                        }
                    }
                    DashboardService.requestAlertCountByCluster(setAlertCount);
                });
            });

        return () => {
            ctx.updateTitle(null);
        };
    }, [backgroundImage]);

    const handleImage = (e) => {
        ImageService.uploadBackgroundImage(code, e.target.files[0]).then((response) => {
            setBackgroundImage(`data:image/jpeg;base64,${response.bytes}`);
        });
    };

    return (
        <div className="container-fluid" style={{ padding: "56px 0 0 0", height: "100vh" }}>
            <MapInteractionCustom showControls={true}>
                <div className={style.mapContainer}>
                    {backgroundImage && <img src={backgroundImage} style={{ width: "100%", height: "100%", objectFit: "contain" }} />}

                    <div className={style.clusterContainer}>
                        {alertCount.map((ele) => {
                            return (
                                <Fab key={ele.id} color="primary" onClick={() => history.push(`${process.env.PUBLIC_URL}/smart_agriculture/table`)}>
                                    {ele.count > 0 ? (
                                        <Badge overlap="rectangular" badgeContent={ele.count} max={999} color="error">
                                            <GroupWorkIcon />
                                        </Badge>
                                    ) : (
                                        <GroupWorkIcon />
                                    )}
                                </Fab>
                            );
                        })}
                    </div>

                    {showUploadButton && (
                        <label htmlFor="contained-button-file" className={style.uploadContainer}>
                            <input style={{ display: "none" }} accept="image/*" id="contained-button-file" type="file" onChange={handleImage} />
                            <Button variant="contained" component="span">
                                Upload
                            </Button>
                        </label>
                    )}
                </div>
            </MapInteractionCustom>
        </div>
    );
}
