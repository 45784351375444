import style from "./style.module.css";

export const columns = [
    {
        field: "id",
        headerName: "ID",
        flex: 1,
    },
    {
        field: "thing.fixedName",
        headerName: "Serial Number",
        flex: 1,
        editable: false,
        sortable: true,
    },
    {
        field: "size",
        headerName: "Size",
        flex: 0.5,
        editable: false,
        sortable: false,
    },
    {
        field: "radio",
        headerName: "Radio",
        flex: 0.5,
        editable: false,
        sortable: false,
    },
    {
        field: "thing.status",
        headerName: "Status",
        sortable: true,
        flex: 0.5,
        renderCell: (params) => {
            let classStyle = style.error;

            switch (params.value) {
                case "ACTIVE":
                    classStyle = style.active;
                    break;
                case "INACTIVE":
                case "PROVISIONED":
                    classStyle = style.inactive;
                    break;
                default:
                    break;
            }

            return <span className={"badge " + classStyle}>{params.value}</span>;
        },
    },
];
