import UtilService from "./utilService";
import _ from "underscore";
import ApiService from "Services/apiService";
import i18n from "i18next";

class Deferred {
    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}

const ImportService = {
    csvFile: null,

    onClose: function (view) {
        view.reset();
    },

    onError: function (display, deferred, error) {
        if (display == true) {
            // console.log(error);
        } else {
            deferred.reject(error);
            throw error;
        }
        return;
    },

    verifyCSV: function (csvFile) {
        this.csvFile = csvFile;

        let deferred = new Deferred();

        this.readData()
            .then(this.verifyCompanyInformation.bind(this), this.onError.bind(this, false, deferred))
            .then(this.retrieveData.bind(this), this.onError.bind(this, false, deferred))
            .then(this.afterLoaded.bind(this), this.onError.bind(this, false, deferred))
            .then(this.verifySiteInformation.bind(this), this.onError.bind(this, false, deferred))
            .then(this.displayDataAndConfirm.bind(this), this.onError.bind(this, false, deferred))
            // .then(this.getView().unmask.bind(this.getView()), this.onError.bind(this, true))
            .then(() => {
                deferred.resolve("Verified the CSV file.");
            })
            .catch((msg) => {
                deferred.reject(msg);
            });

        return deferred;
    },

    readData: function () {
        // console.log("readData...");
        var result = { sites: {}, numberOfObject: -1, products: {}, headers: {}, deferred: new Deferred(), companies: {} };
        var reader = new FileReader();

        if (UtilService.isEmpty(this.csvFile)) {
            reader.onload = this.onReadLineByLine.bind(this, result);
            reader.onerror = this.onReadError.bind(reader, result);
            reader.readAsText(this.csvFile);
        } else {
            result.deferred.reject();
        }

        return result.deferred.promise;
    },

    onReadError: function (result) {
        result.deferred.reject(this.error);
    },

    onReadLineByLine: function (result, event) {
        if (UtilService.isEmpty(event.target.result)) {
            return;
        }

        _.each(
            event.target.result.split("\n"),
            function (line, rowNum) {
                if (result.numberOfObject < 0) {
                    this.readHeaders(result, line, rowNum);
                } else {
                    if (line.trim() != "") {
                        this.readObject(result, line, rowNum);
                    }
                }

                if (result.deferred.completed) {
                    return false;
                }
            },
            this
        );

        result.deferred.resolve(result);
    },

    readObject: function (result, line, rowNum) {
        var data = line.split(";");
        var productIndex = result.headers["/product/id"];
        var productId = data[productIndex];
        // var siteName = !Ext.isEmpty(data[result.headers['/site/name']]) ? data[result.headers['/site/name']] : 'default';
        var siteName = UtilService.isEmpty(data[result.headers["/site/name"]]) ? data[result.headers["/site/name"]] : "default";
        // var companyName = this.getViewModel().get('companyComboValue');
        var companyName = "PTH";
        var productResult = result.products[productId] || {};

        if (data.length - 1 !== result.headerCount) {
            result.deferred.reject(["i18n.thing_import_incorrect_column_number", rowNum]);
            return;
        }

        if (data.length > 1) {
            result.numberOfObject++;

            siteName = siteName == undefined ? "" : siteName.trim();
            productId = productId == undefined ? "" : productId.trim();
            companyName = companyName.trim();

            productResult[siteName] = productResult[siteName] || { count: 0 };
            productResult[siteName].count++;

            result.sites[siteName] = true;
            result.products[productId] = productResult;
            result.companies[companyName] = true;
        }
    },

    readHeaders: function (result, line, rowNum) {
        var index = 0;

        if (UtilService.isEmpty(line)) return;

        line.split(";").forEach(function (headerName) {
            result.headers[headerName.trim()] = index;
            if (!UtilService.isEmpty(headerName.trim())) {
                index++;
            }
        });

        result.numberOfObject++;
        result.headerCount = index;

        return index;
    },

    displayDataAndConfirm: function (data) {
        // console.log("displayDataAndConfirm...");
        // this.getViewModel().set('dataFromFile', data);
    },

    retrieveData: function (data) {
        // console.log("retrieveData...");
        var deferred = new Deferred();
        data.siteArray = Object.keys(data.sites).map(function (site) {
            return site;
        });

        deferred.resolve(data);

        return Promise.all([data, this.retrieveProducts(data), this.retrieveSites(data)]);
    },

    retrieveSites: function (data) {
        var siteKeys = Object.keys(data.sites);

        if (siteKeys.length > 0) {
            let requestOptions = {
                url: "/api/sites",
                params: ApiService.getFilterParameters([
                    {
                        field: "site.name",
                        operator: "in",
                        value: siteKeys
                            .map(function (site) {
                                return site;
                            })
                            .join(","),
                    },
                    {
                        field: "site.company",
                        operator: "eq",
                        value: '"' + data.company + '"',
                    },
                ]),
            };

            var deferred = new Deferred();

            ApiService.getData(requestOptions)
                .then((response) => {
                    deferred.resolve({ responseText: response.data });
                })
                .catch((e) => {
                    console.error(e);
                    deferred.reject(e);
                });
        } else {
            var deferred = new Deferred();
            return deferred.resolve();
        }
    },

    retrieveProducts: function (data) {
        var productKeys = Object.keys(data.products);

        if (productKeys.length > 0 && productKeys[0] !== "") {
            let keys = productKeys
                .map(function (product) {
                    return product;
                })
                .join(",");

            let requestOptions = {
                url: "/api/products",
                params: ApiService.getFilterParameters([{ field: "product.id", operator: "in", value: keys }]),
            };

            var deferred = new Deferred();

            ApiService.getData(requestOptions)
                .then((response) => {
                    deferred.resolve({ responseText: response.data });
                })
                .catch((e) => {
                    deferred.reject();
                    console.error(e);
                });
        } else {
            var deferred = new Deferred();
            return deferred.resolve();
        }
    },

    afterLoaded: function (argumentsArray) {
        var productsRequest = argumentsArray[1];
        var siteRequest = argumentsArray[2];
        var data = argumentsArray[0];
        var productsJson = (!UtilService.isEmpty(productsRequest) ? JSON.parse(productsRequest.responseText) : { data: [] }).data;
        var sitesJson = (!UtilService.isEmpty(siteRequest) ? JSON.parse(siteRequest.responseText) : { data }).data;

        data.productArray = [];
        data.missingSiteArray = [];

        if (productsJson.length === Object.keys(data.products).length) {
            // Ext.Array.reduce(productsJson, this.buildProducts.bind(this, data), data.productArray);
        } else {
            //Starter.Util.errorToast(i18n.thing_import_product_no_match);
        }

        if (sitesJson.length !== Object.keys(data.sites).length) {
            data.missingSiteMap = Object.assign({}, data.sites);
            _.each(sitesJson, this.buildMissingSitesMap.bind(this, data));
            this.buildMissingSiteArray(data);
        }

        return data;
    },

    buildMissingSiteArray: function (data) {
        var keys;

        if (!UtilService.isEmpty(data.missingSiteMap) && (keys = Object.keys(data.missingSiteMap))) {
            data.missingSiteArray = keys;
        }
    },

    buildMissingSitesMap: function (data, site) {
        if (data.missingSiteMap.hasOwnProperty(site.name)) {
            delete data.missingSiteMap[site.name];
        }
    },

    buildProducts: function (data, accumulator, product) {
        var productsBySite = Object.keys(data.products[product.id]).map(function (productSite) {
            return Object.assign({ site: productSite }, product, data.products[product.id][productSite]);
        });

        accumulator.push.apply(accumulator, productsBySite);
    },

    verifyFails: function (data) {
        // this.getViewModel().set('dataFromFile', Ext.clone(data));
        // this.getViewModel().notify();
    },

    verifyCompanyInformation: function (data) {
        // console.log("verifyCompanyInformation...");
        var deferred = new Deferred();
        var companies = Object.keys(data.companies);

        if (data.companies.hasOwnProperty("error")) {
            // Starter.Util.errorToast(i18n.thing_import_missing_company);
            deferred.reject("error: missing company");
        } else if (companies.length > 1) {
            // Starter.Util.errorToast(i18n.thing_import_multiple_companies);
            deferred.reject("error: multiple companies");
        } else {
            data.company = companies[0];
            deferred.resolve(data);
        }

        return data;
    },

    verifySiteInformation: function (data) {
        // console.log("verifySiteInformation...");
        if (!UtilService.isEmpty(data.missingSiteArray)) {
            data.siteCreationWarning = "One or more sites will be automatically created" + " : " + data.missingSiteArray.join(",");
        }
        return data;
    },

    importCSVConfirmed: function () {
        var form = this.getView().getForm();
        var data = this.getViewModel().get("dataFromFile");

        if (form.isValid()) {
            this.getView().mask(i18n.loading);
            this.createSiteIfNeeded(data)
                .then(this.uploadCsv.bind(this), this.onError.bind(this))
                .then(this.getView().fireEvent.bind(this.getView(), "thingsimported"), this.onError.bind(this))
                .then(this.getView().unmask.bind(this.getView()), this.onError.bind(this));
            // .then(Starter.Util.successToast.bind(null, i18n.successful), this.onError.bind(this))
        }
    },

    createSiteIfNeeded: function (data) {
        // var deferred = new Ext.Deferred();
        // if (Ext.isEmpty(data.missingSiteArray)) {
        //     deferred.resolve();
        // } else {
        //     this.createSite(data)
        //         .then(deferred.resolve.bind(deferred), deferred.reject.bind(deferred))
        // }
        // return deferred;
    },

    createSite: function (data, deferred) {
        // return Ext.Ajax.request({
        //     method: 'POST',
        //     url: Starter.RestApi.url('sites'),
        //     params: { company: data.company },
        //     jsonData: data.missingSiteArray.map(function (siteName) { return { name: siteName, company: data.company } })
        // });
    },

    uploadCSV: function (technology, csvFile, company, networkOn) {
        // var fileField = this.lookup('fileInputField');
        var deferred = new Deferred();
        // var company = this.getViewModel().get('companyComboValue');
        // var queryParams = !Ext.isEmpty(company) ? { company: company } : null;

        let queryString = `?networkOn=${networkOn}`;
        queryString += `${UtilService.isEmpty(company) ? "" : "&company=" + company}`;
        queryString += `&technology=${technology}`;

        let requestOptions = {
            // url: "/api/things" + queryString,
            url: "/v2/things/import" + queryString,
            file: csvFile,
        };

        ApiService.uploadFile(requestOptions)
            .then((response) => {
                // console.log("importService...response, those are not thing_ids, what are those?");
                deferred.resolve(JSON.parse(response));
            })
            .catch((response) => {
                console.error(response);
                deferred.reject(JSON.parse(response));
            });

        return deferred;
    },

    includesLorawan: function (csvFile) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                const contents = e.target.result;
                resolve(ImportService.parseCSV(contents));
            };

            reader.onerror = (event) => {
                reject(event.target.error);
            };

            reader.readAsText(csvFile);
        });
    },

    parseCSV: (csvContent) => {
        const lines = csvContent.split("\n");

        // const headers = lines[0].split(';');
        // console.log(headers);

        let result = false;

        const data = lines.slice(1).map((line) => {
            const values = line.split(";");
            // console.log(values);

            const foundValue = values.find((v) => v.indexOf("OtaaV1_0") !== -1);
            if (foundValue != undefined) {
                // console.log('true.....')
                result = true;
            }
        });

        return result;
    },
};

export default ImportService;
