import _ from "underscore";

export function createData(
    id,
    company,
    thing,
    timestamp,
    model,
    manufacturer,
    product,
    connectivityType,
    status,
    tags,
    site,
    attributes,
    volume,
    volumeUnit,
    alertCount,
    alertLastMessage,
    alertLastDate,
    size,
    radio,
    unit,
    serialNumber,
    owner,
    ele
) {
    var obj = {
        id,
        company,
        thing,
        timestamp,
        model,
        manufacturer,
        product,
        connectivityType,
        status,
        tags,
        site,
        volume,
        volumeUnit,
        alertCount,
        alertLastMessage,
        alertLastDate,
        size,
        radio,
        unit,
        serialNumber,        
        owner,
        ele,
    };

    _.each(attributes, function (attr) {
        for (const [key, value] of Object.entries(attr)) {
            obj[key] = value;
        }
    });

    return obj;
}
