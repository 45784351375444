import { useEffect, useState } from "react";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Item from '../../../../components/ui/Item'

export default function CurrentMonthly(props) {
    const { t, i18n } = useTranslation();

    const [active, setActive] = useState(0);
    const [inactive, setInactive] = useState(0);

    const [imgUrl, setImgUrl] = useState();

    useEffect(() => {
        setActive(10);
        setInactive(0);
    }, []);

    return (
        <Box sx={{ height: '100%' }} >
            <Paper elevation={3} sx={{ height: '100%' }}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Item>
                            <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B", width: "450px" }}>
                                {t("w_mgt.monthly_consumption")}
                            </span>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 4, mb: 2 }}>
                        <Item>
                            <div className={style.w_value}>
                                30 <font className={style.w_unit}>m3</font>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item>
                            <span>2/5/2021 10:00AM</span>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 6 }}>
                        <Item>
                            <span className={"badge " + style.active}>ACTIVE</span>
                        </Item>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}
