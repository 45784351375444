import { useState, useEffect, useRef, useContext, createRef } from "react";
import { useHistory } from "react-router-dom";

import QrScanner from "qr-scanner";

import style from "./style.module.css";

import imgFront from "Assets/images/camera_front.png";
import imgBack from "Assets/images/camera_back.png";
import imgQrcode from "Assets/images/qrcode.png";

import ApiService from "Services/apiService";
import AppContext from "Components/AppContext";

import TabMenu from "../tab/index";

import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";

// import QrScannerWorkerPath from '!!file-loader!../../../../node_modules/qr-scanner/qr-scanner-worker.min.js';
// import QrScannerWorkerPath from '../../../../node_modules/qr-scanner/qr-scanner-worker.min.js';
// QrScanner.WORKER_PATH = QrScannerWorkerPath;

// eslint-disable-next-line import/no-webpack-loader-syntax
import qrScannerWorkerSource from "!!raw-loader!../../../../node_modules/qr-scanner/qr-scanner-worker.min.js";
QrScanner.WORKER_PATH = URL.createObjectURL(new Blob([qrScannerWorkerSource]));

export default function QrCodeScan(props) {
    let history = useHistory();

    const setting = useContext(SettingContext);

    const [front, seFront] = useState(true);
    const [state, setState] = useState({ mode: "user" });

    const [imgCamera, setImageCamera] = useState(imgFront);

    const video = createRef();
    let scanner = createRef();

    let hasScanned = false;

    let appCtx = useContext(AppContext);
    appCtx.updateTitle("Device Scanner");

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.DEVICE_SCAN,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        scanner = new QrScanner(video.current, (result) => handleResult(result));

        scanner.start().then(() => {
            // DO NOTHING
        });
    }, []);

    const toggleImage = () => {
        setState((state) => ({ front: !state.front }));

        if (front) {
            setState((state) => ({ mode: "user" }));
            setImageCamera(imgBack);
            seFront(false);
        } else {
            setState((state) => ({ mode: "environment" }));
            setImageCamera(imgFront);
            seFront(true);
        }
    };

    const handleStart = () => {
        scanner.start();
    };

    const handleStop = () => {
        scanner.stop();
    };

    const handleResult = (result) => {
        scanner.stop();

        // Prevent duplicate event processing
        if (!hasScanned) {
            hasScanned = true;
            requestThings(history, result);
            scanner.stop();
        }
    };

    return (
        <div className="container" style={{ marginTop: "55px", paddingLeft: "0px", paddingRight: "0px", textAlign: "center" }}>
            <div className="row">
                <TabMenu value={0}></TabMenu>
            </div>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col-3" style={{ textAlign: "right" }}>
                            <img src={imgQrcode} width="50" height="50"></img>
                        </div>
                        <div className="col-9" style={{ fontSize: "20px", textAlign: "left", marginTop: "10px" }}>
                            <div>Scan Device QR code</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ml-2 mr-2" style={{ textAlign: "center", display: "inline-block", border: "1px solid grey" }}>
                <div className="col" style={{ weight: "250px", height: "225px", padding: "0px", position: "relative" }}>
                    <div style={{ width: "100%", height: "100%" }}>
                        <video ref={video} width="100%" height="100%"></video>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img src={imgCamera} onClick={toggleImage} />
                </div>
            </div>
        </div>
    );
}

function requestThings(history, deveui) {
    let requestOptions = {
        url: "/api/app/devicescan?filter=thing.name:eq_" + deveui + "&detailed=true",
    };

    ApiService.getData(requestOptions)
        .then((response) => {
            switch (response.CODE) {
                case "FOUND":
                case "ACTIVE":
                    history.push(
                        `${process.env.PUBLIC_URL}/device_scan/found?pid=` +
                        response.PRODUCT_ID +
                        "&deveui=" +
                        deveui +
                        "&code=" +
                        response.CODE +
                        "&message=" +
                        response.MESSAGE
                    );
                    break;
                case "NOT_FOUND":
                    history.push(`${process.env.PUBLIC_URL}/device_scan/notfound?message=` + response.MESSAGE);
                    break;
                default:
                    break;
            }
        })
        .catch((e) => console.log(e));
}
