import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import UserList from "./UserList";
import AssignedThings from "./AssignedThings";
import ThingList from "./ThingList";
import Button from "@mui/material/Button";
import AssignService from "./AssignService";
import moment from "moment";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Item from "../../../components/ui/Item";
import CreateUserDialog from "./CreateUserDialog";
import UserService from "Services/userService";
import Alert from "@mui/material/Alert";
import { Box } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

export default function AssignMain() {
    const [cloudUserId, setCloudUserId] = useState();
    const [assignedThings, setAssignedThings] = useState([]);

    const [selectedThings, setSelectedThings] = useState([]);
    const [reload, setReload] = useState();

    const [open, setOpen] = useState(false);

    const [hideCompanyColumn, setHideCompanyColumn] = useState(false);

    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        UserService.getAuthenticatedUser().then((u) => {
            if (u.company == "CALSUB" || u.company == "HARMONY" || u.company == "ABRO_MANAGEMENT") {
                setHideCompanyColumn(true);
            }
        });
    }, []);

    const handleCloudUserId = (cloudUserId) => {
        setCloudUserId(cloudUserId);
    };

    const handleAdd = () => {
        // console.log("cloudUserId=" + cloudUserId);
        if (!cloudUserId) {
            setErrorMessage("You must select a user to add a water counter.");
            setIsError(true);
            return;
        }
        setErrorMessage("");
        setIsError(false);

        if (selectedThings.length == 0) {
            // setIsError(true);
            setErrorMessage("You must select a thing to add a water counter");
            setIsError(true);
            return;
        }
        setErrorMessage("");
        setIsError(false);

        // console.log(selectedThings); //selected selectedThings.
        // console.log(cloudUserId); //selected cloudUserId

        // AssignService.validateThings(cloudUserId, selectedThings)
        //     .then(r => {
        //         AssignService.assignThings(cloudUserId, selectedThings).then(() => setReload(moment().unix()));
        //     })
        //     .catch(msg => {
        //         setErrorMessage(msg);
        //         setIsError(true);
        //     })

        if (cloudUserId && selectedThings.length > 0) {
            AssignService.assignThings(cloudUserId, selectedThings).then(() => setReload(moment().unix()));
        }
    };

    const handleRemove = () => {
        if (cloudUserId && assignedThings) {
            AssignService.removeThings(cloudUserId, assignedThings).then(() => setReload(moment().unix()));
        }
    };

    const handleCreateUser = () => {
        // console.log("handleCreateuser...");
        setOpen(true);
        setReload(moment().unix());
    };

    const handleCallback = () => {
        setReload(moment().unix());
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} sx={{ height: 70 }}>
                    &nbsp;
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Item sx={{ display: "flex" }}>
                        <Button
                            variant="contained"
                            onClick={handleCreateUser}
                            style={{ width: "100px", backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }}
                        >
                            Add
                        </Button>
                    </Item>
                    <Item>
                        <AppBar position="static">
                            <Toolbar variant="desnse" sx={{ backgroundColor: "#2E82D6", color: "#FFF" }}>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                    Users
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </Item>
                    <Item elevation={3} sx={{ height: "calc(100vh - 200px)" }}>
                        <UserList reload={reload} callback={handleCloudUserId} hideCompanyColumn={hideCompanyColumn} />
                    </Item>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Item sx={{ display: "flex" }}>
                        <Button
                            variant="contained"
                            onClick={handleRemove}
                            style={{ width: "100px", backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }}
                        >
                            Remove
                        </Button>
                    </Item>
                    <Item>
                        <AppBar position="static">
                            <Toolbar variant="desnse" sx={{ backgroundColor: "#2E82D6", color: "#FFF" }}>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                    User's water meters
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </Item>
                    <Item elevation={3} sx={{ height: "calc(100vh - 200px)" }}>
                        <AssignedThings reload={reload} cloudUserId={cloudUserId} callback={setAssignedThings} />
                    </Item>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Item sx={{ display: "flex" }}>
                        <Box>
                            <Button
                                variant="contained"
                                onClick={handleAdd}
                                style={{ width: "100px", backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }}
                            >
                                Add
                            </Button>
                        </Box>
                    </Item>
                    <Item>
                        <AppBar position="static">
                            <Toolbar variant="desnse" sx={{ backgroundColor: "#2E82D6", color: "#FFF" }}>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                    All water meters
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </Item>
                    <Item elevation={3} sx={{ height: "calc(100vh - 280px)" }}>
                        <ThingList callback={setSelectedThings} cloudUserId={cloudUserId} reload={reload} />
                    </Item>
                </Grid>
            </Grid>
            <CreateUserDialog open={open} setOpen={setOpen} callback={handleCallback} />

            <Snackbar open={isError} autoHideDuration={6000} onClose={setIsError}>
                <Alert severity="warning" variant="filled">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}
