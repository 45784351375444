import React, { useEffect } from 'react';
import SensorsIcon from '@mui/icons-material/Sensors';
import IconButton from '@mui/material/IconButton';
import { blueGrey } from '@mui/material/colors';
import Tooltip, { } from '@mui/material/Tooltip';
import TooltipText from './TooltipText';

export default function CustomSensorIcon({ top, left, data }) {

    useEffect(() => {
        // do nothing
    }, []);

    return <>
        <Tooltip title={<TooltipText data={data} />} arrow>
            <IconButton
                aria-label="al-bottom"
                sx={{
                    backgroundColor: blueGrey[200],
                    "&:hover": { backgroundColor: blueGrey[100] },
                    top: top, left: left
                }}
                size="large">
                <SensorsIcon />
            </IconButton>
        </Tooltip>
    </>;
};
