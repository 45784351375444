import _ from "underscore";
import ApiService from "Services/apiService";
import UserService from "Services/userService";
import fileDOwnload from "js-file-download";

const ThingService = {
    // requestModel: async (thingId, setModel) => {
    //     let requestOptions = {
    //         url: `/api/things/${thingId}/model`,
    //     };

    //     return ApiService.getData(requestOptions)
    //         .then((response) => {
    //             // console.log("requestModel...Response");
    //             // console.log(response);

    //             let attributes = [];
    //             attributes.push({ id: "all", label: "All" });
    //             response.attributes.forEach((attr) => {
    //                 attributes.push({
    //                     id: attr.id,
    //                     label: attr.name,
    //                 });
    //             });
    //             setModel(attributes);
    //         })
    //         .catch((e) => {
    //             console.error(e);
    //         });
    // },

    // requestReport: async (createData, page, pageSize, setRows, allColumns, setColumns, keyword, sortQuery, on, reportType) => {
    //     let orFilter = "";

    //     if (keyword != undefined) {
    //         let idsQuery = [];
    //         idsQuery.push(`{"property":["thing.name", "thing.fixedname", "site.name", "thing.tags"],"operator":"ilike","value":"${keyword}"}`);

    //         // if (appId == AppId.SMART_AGRICULTURE) {
    //         //     idsQuery.push(`{"property":["company"],"operator":"eq","value":"CENGN"}`);
    //         // }

    //         let concated = idsQuery.join(",");
    //         orFilter += encodeURI(`&orFilter=[${concated}]`);
    //     }

    //     let filter = "";

    //     const user = await UserService.getAuthenticatedUser();
    //     if (user && user.company != "PTH") {
    //         filter = `&filter=company.name:eq_${user.company}`;
    //     }

    //     sortQuery = sortQuery === "" ? "&sort=displayName&dir=ASC" : sortQuery;

    //     let requestOptions = {
    //         url: "/api/report?detailed=true&page=" + (page + 1) + "&pageSize=" + pageSize + filter + orFilter + sortQuery + "&reportType=" + reportType,
    //     };

    //     // console.log(requestOptions);

    //     ApiService.downloadFile(requestOptions)
    //         .then((response) => {
    //             fileDOwnload(response, `SensorList.${reportType}`);
    //         })
    //         .catch((e) => console.log(e));
    // },

    // addThingInCluster: async (clusterId, thingIds, displayThing, callback, handleClose) => {
    //     let requestOptions = {
    //         url: `/api/cluster/${clusterId}`,
    //         data: {
    //             thingId: thingIds,
    //             displayThing: displayThing,
    //         },
    //     };

    //     return ApiService.putData(requestOptions)
    //         .then(() => {
    //             // console.log(response);
    //             callback();
    //             handleClose();
    //         })
    //         .catch((e) => {
    //             console.error(e);
    //         });
    // },

    // removeThingInCloud: async (clusterId, thingIds, callback) => {
    //     let requestOptions = {
    //         url: `/api/cluster/${clusterId}`,
    //         data: { thingId: thingIds },
    //     };

    //     return ApiService.deleteData(requestOptions)
    //         .then(() => {
    //             // console.dir(response);
    //             callback();
    //         })
    //         .catch((e) => console.log(e));
    // },

    requestThing: async (companyId, page, pageSize, setRows, setRowCount, sortQuery) => {
        let request = {
            url: "/v2/company_thing/company",
            params: {
                page: page,
                pageSize: pageSize,
                sort: "",
                companyId: companyId,
                productId: "2e979207-625d-40b7-b886-fa792af13713", // Milesight Display
            },
        };

        // console.log(request);
        const result = await ApiService.getData(request);

        const mapped = result.content.map((c) => {
            let map = c;
            if (c.product) {
                map["productName"] = c.product.name;
                if (c.product.manufacturer) {
                    map["manufacturer"] = c.product.manufacturer.name;
                }
                map["connectivityType"] = c.product.connectivityTypes.join(",");
            }

            return map;
        });

        setRows(mapped);
        setRowCount(result.totalElements);

        return mapped;
    },

    associateMeterDisplay: async (meterThingId, selectedDisplay, displayThingList) => {
        let requestOptions = {
            url: `/v2/things/associateMeterDisplay`,
            data: {
                meterThingId: meterThingId,
                selectedDisplay: selectedDisplay,
                displayThingList: displayThingList,
            },
        };
        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .catch((e) => {
                console.error(e);
            });
    },

    requestAssignedThing: async (companyId, clusterId) => {
        let request = {
            url: "/v2/cluster/assigned_thing",
            params: {
                companyId: companyId,
                clusterId: clusterId,
            },
        };

        // console.log(request);
        const result = await ApiService.getData(request);
        // console.log(result);
        return result;
    },
};

export default ThingService;
