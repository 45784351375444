import { useEffect, useState } from "react";
import RoomCard from "./card/RoomCard";
import Box from "@mui/material/Box";
import RoomElectricityCard from "./card/RoomElectricityCard";
import RoomContainerService from "./RoomContainerService";

export default function RoomContainer({ selectedSiteId, moyenne, setMoyenne, parentReload }) {
    const [assetAttribute, setAssetAttribute] = useState([]);

    useEffect(() => {
        // console.log("uef in RoomContainer");
        // console.log("selectedSite: " + selectedSite);

        RoomContainerService.requestAsset().then((r) => {
            // console.log(r);
            setAssetAttribute(r);
        });
    }, [selectedSiteId, parentReload]);

    return (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {assetAttribute
                .filter((a) => selectedSiteId === 0 || (a.site && selectedSiteId === a.site.id))
                .map((row) => {
                    if (row.assetAttribute.length == 1) {
                        return <RoomElectricityCard asset={row} key={row.assetId} />;
                    } else {
                        return <RoomCard asset={row} key={row.assetId} entity={row} moyenne={moyenne} setMoyenne={setMoyenne} />;
                    }
                })}
        </Box>
    );
}
