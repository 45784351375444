import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import UtilService from "Services/utilService";

export default function GatewayStatus({ value, date, name, eui }) {
    return (
        <Paper elevation={2} variant={"elevation"} sx={{ p: 1 }}>
            <Typography variant="subtitle1" gutterBottom>
                {`${value} dBM`}
            </Typography>
            <span style={{ fontSize: "12px" }}>{UtilService.shortestFormateDateHMMDY(date)}</span>
            <br></br>
            <span style={{ fontSize: "12px" }}>{eui}</span>
        </Paper>
    );
}
