import tinygradient from 'tinygradient';

const ColorService = {

    /**
     * Returns a single rgb(x,x,x) string from a defined position in the gradient (from 0 to 1).
     */
    getGradientRgba: (value, unit) => {
        var gradient = tinygradient([
            '#38B3B9',
            '#38B3B9',
            '#F05622'
        ]);

        var colorAt55Percent = gradient.rgbAt(ColorService.getPosition(value, unit));

        return 'rgba(' +
            Math.floor(colorAt55Percent._r) + "," +
            Math.floor(colorAt55Percent._g) + "," + Math
                .floor(colorAt55Percent._b) + ",0.6)";
    },

    getPosition(value, unit) {
        let min = 0;
        let max = 100;

        switch (unit) {
            case 'lx':  // lux for light
                min = 1 / 100000;
                max = 130000;
                break;
            case '°C':
                min = 10;
                max = 40;
                break;
        }

        if (max < value) {
            max = value;
        }
        if (min > value) {
            min = value;
        }

        let total = max - min;
        let percent = (value - min) / total;
        if (percent > 1) {
            percent = 1;
        }
        percent = percent.toFixed(3);

        return percent;
    }

}

export default ColorService