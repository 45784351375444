import { useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import ApiService from "Services/apiService";
import _ from "underscore";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/ui/BootstrapDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

export default function Confirm({ callback, selectionModel, open, setOpen }) {
    const { t, i18n } = useTranslation();
    const [disableButtons, setDisableButtons] = useState(false);
    const [delOption, setDelOption] = useState("INVISIBLE");

    useEffect(() => {
        setDisableButtons(false);
    }, []);

    const closeModal = (e) => {
        // console.log("closeMode....")
        setOpen(false);
        callback();
    };

    const handleDelete = () => {
        if (selectionModel.length > 0) {
            setDisableButtons(true);

            let allWaitRequest = deleteThings(selectionModel, delOption);

            Promise.all(allWaitRequest)
                .then((result) => {
                    // console.log("result...")
                    // console.log(result);
                    closeModal();
                })
                .catch((result) => {
                    _.each(result, (r) => {
                        console.error(r);
                    });
                });
        }
    };

    const handleClose = () => {
        setOpen(false);
        setDelOption("INVISIBLE");
    };

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {t("de.delete_title")}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>{selectionModel.length == 0 && t("de.delete_select")}</Typography>
                    <Typography gutterBottom>{selectionModel.length > 0 && t("de.delete_confirm", { count: selectionModel.length })}</Typography>

                    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={delOption}
                                name="radio-buttons-group"
                                onChange={(e) => setDelOption(e.target.value)}
                            >
                                <FormControlLabel value={"INVISIBLE"} control={<Radio />} label={t("de.delete_opt_1")} />
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <FormControlLabel value={"DEL_ALL"} control={<Radio />} label={t("de.delete_opt_2")} />
                                    <Typography sx={{ color: "red", mt: 1, ml: 4 }}>{t("de.delete_opt_3")}</Typography>
                                </Box>
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                        {selectionModel.length > 0 && (
                            <Button variant="contained" color="primary" style={{ textTransform: "unset" }} onClick={handleDelete} disabled={disableButtons}>
                                {t("btn.yes")}
                            </Button>
                        )}
                        <Button variant="contained" color="primary" style={{ textTransform: "unset" }} onClick={closeModal} disabled={disableButtons}>
                            {t("btn.no")}
                        </Button>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

function deleteThings(thingIds, delOption) {
    // Determine if force should be applied based on delOption
    const force = delOption === "DEL_ALL";

    // Map through thingIds and create an array of promises
    return thingIds.map((thingId) => {
        const requestOptions = {
            url: `/api/things/${thingId}?force=${force}`,
        };

        return ApiService.deleteMultipleData(requestOptions);
    });
}
