import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Item from "../../../components/ui/Item";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Timezones from "./Timezones";
import validator from "validator";
import _ from "underscore";
import AssignService from "./AssignService";
import Button from "@mui/material/Button";
import UserService from "Services/userService";

export default function CreateUserDialog({ open, setOpen, callback }) {
    const { t, i18n } = useTranslation();

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("COMPANY_HARMONY");
    const [role, setRole] = useState("user");
    const [locale, setLocale] = useState("en");
    const [zoneInfo, setZoneInfo] = useState("America/Montreal");
    const [subCompanies, setSubCompanies] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleClose = () => {
        setEmail("");
        setFirstName("");
        setLastName("");
        setCompany("COMPANY_HARMONY");
        setRole("user");
        setLocale("en");
        setZoneInfo("America/Montreal");

        setOpen(false);
        setLoading(false);
        setError(null);
    };

    const handleSubmit = () => {
        // console.log("handleSubmit...")

        if (_.isEmpty(email)) {
            setError("Please enter an email");
            return;
        }
        if (!validator.isEmail(email)) {
            setError("Please enter valid email address");
            return;
        }

        // if (_.isEmpty(firstName)) {
        //     setError("Please enter a first name");
        //     return;
        // }

        if (_.isEmpty(lastName)) {
            setError("Please enter a last name");
            return;
        }

        setLoading(true);
        setError(null);

        AssignService.createUser(email, firstName, lastName, company, role, locale, zoneInfo).then((r) => {
            // console.log("//--------")
            // console.log(r)
            // console.log(r.type)

            callback();
            setLoading(false); 

            if (r && r.code && r.code == "success") {
                setEmail("");
                setFirstName("");
                setLastName("");
                setCompany("COMPANY_HARMONY");
                setRole("user");
                setLocale("en");
                setZoneInfo("America/Montreal");
                setOpen(false);
                setError("");

                setOpen(false);
            } else if (r && r.type && r.type == "failure") {
                setError(r.message);
            }
        });
    };

    useEffect(() => {
        // console.log("useEffect...in CreateuserDialog");
        UserService.getAuthenticatedUser().then((user) => {
            if (user && user.authorities == "MANAGER") {
                setCompany("COMPANY_" + user.company);
            }
        });

        AssignService.requestSubCompaines(setSubCompanies);
    }, []);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="sm" fullWidth>
                <Stack direction={"row"}>
                    <Item elevation={0}>
                        <DialogTitle id="alert-dialog-title">{t('wm.add_user')}</DialogTitle>
                    </Item>
                    <Item sx={{ display: "flex", flexDirection: "row-reverse", flexGrow: 1 }} elevation={0}>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 0,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Item>
                </Stack>

                <Box sx={{ pt: 2, pl: 10, pr: 10 }}>
                    <Stack spacing={2}>
                        <TextField
                            variant="standard"
                            label={t("login.email")}
                            type="email"
                            fullWidth
                            required
                            disabled={loading}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            variant="standard"
                            label={t("login.first-name")}
                            type="text"
                            fullWidth
                            disabled={loading}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <TextField
                            variant="standard"
                            label={t("login.last-name")}
                            type="text"
                            fullWidth
                            required
                            disabled={loading}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />

                        <FormControl variant="filled" fullWidth required>
                            <InputLabel id="sel-company-label">{""}</InputLabel>
                            <Select
                                labelId="sel-company-label"
                                id="sel-company"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                disabled={loading}
                                label="Company"
                            >
                                <MenuItem value={"COMPANY_FLOWINC"} style={{ display: "block" }}>
                                    FLOWINC
                                </MenuItem>
                                {subCompanies.map((s) => {
                                    return (
                                        <MenuItem value={"COMPANY_" + s.name} style={{ display: "block" }}>
                                            {s.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl variant="filled" fullWidth required>
                            <InputLabel id="sel-role-label" sx={{ ml: 0 }}>
                                {" "}
                            </InputLabel>
                            <Select
                                labelId="sel-role-label"
                                id="sel-role"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                disabled={loading}
                                label="Role"
                            >
                                <MenuItem value={"user"} style={{ display: "block" }}>
                                    User
                                </MenuItem>
                                <MenuItem value={"manager"} style={{ display: "block" }}>
                                    Manager
                                </MenuItem>
                                <MenuItem value={"admin"} style={{ display: "block" }}>
                                    Admin
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl variant="filled" fullWidth required>
                            <InputLabel id="sel-locale-label" sx={{ ml: 0, alignContent: "left" }}>
                                {""}
                            </InputLabel>
                            <Select
                                labelId="sel-locale-label"
                                id="sel-locale"
                                value={locale}
                                onChange={(e) => setLocale(e.target.value)}
                                disabled={loading}
                                label="Locale"
                            >
                                <MenuItem value={"en"} style={{ display: "block" }}>
                                    English
                                </MenuItem>
                                <MenuItem value={"fr"} style={{ display: "block" }}>
                                    French
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <Timezones disabled={loading} sx={{ width: 300 }} callback={(e) => setZoneInfo(e)} />

                        <Alert severity="error" sx={{ opacity: error ? 1 : 0 }}>
                            {error}
                        </Alert>
                    </Stack>
                </Box>

                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }} onClick={handleSubmit}>
                        {t("login.create")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
