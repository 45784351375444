import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import _ from "underscore";
import { useTranslation } from "react-i18next";

export default function ThresholdDialog(props) {
    const { t, i18n } = useTranslation();

    const { thLow, thHigh, thRate, chLow, chHigh, chRate, changeValues, onClose, title, changeCheckBoxes, ...other } = props;

    const [thLowState, setThLowState] = useState(thLow);
    const [thHighState, setThHighState] = useState(thHigh);
    const [thRateState, setThRateState] = useState(thRate);

    const [chLowState, setChLowState] = useState(chLow);
    const [chHighState, setChHighState] = useState(chHigh);
    const [chRateState, setChRateState] = useState(chRate);

    const handleCancel = () => {
        setThLowState(thLow);
        setThHighState(thHigh);
        setThRateState(thRate);

        onClose();
    };

    const handleOk = () => {
        changeValues(thLowState, thHighState, thRateState);
        changeCheckBoxes(chLowState, chHighState, chRateState);

        onClose();
    };

    const handleChange = (event, source) => {
        switch (source) {
            case "low":
                setChLowState(event.target.checked);
                break;
            case "high":
                setChHighState(event.target.checked);
                break;
            case "rate":
                setChRateState(event.target.checked);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setThLowState(() => thLow);
        setThHighState(() => thHigh);
        setThRateState(() => thRate);
        setChLowState(() => chLow);
        setChHighState(() => chHigh);
        setChRateState(() => chRate);
    }, [thLow, thHigh, thRate, chLow, chHigh, chRate]);

    const handleLowValue = (e) => {
        let highValue = parseInt(thHighState);
        let value = parseInt(e.target.value);

        if (_.isNaN(value)) {
            setThLowState(undefined);
        } else if (highValue > value) {
            setThLowState(e.target.value);
        }
    };

    const handleHighValue = (e) => {
        let lowValue = parseInt(thLowState);
        let value = parseInt(e.target.value);

        if (_.isNaN(value)) {
            setThHighState(undefined);
        } else if (lowValue < value) {
            setThHighState(e.target.value);
        }
    };

    return (
        <Dialog maxWidth="xs" {...other}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <div class="container">
                    <div class="row">
                        <div class="col-1 d-flex justify-content-center">
                            <Checkbox
                                checked={chLowState}
                                onChange={(e) => handleChange(e, "low")}
                                color="primary"
                                inputProps={{ "aria-label": "primary checkbox" }}
                            />
                        </div>
                        <div class="col w-100">
                            <TextField
                                margin="dense"
                                id="low"
                                label="Low"
                                type="number"
                                fullWidth
                                value={thLowState}
                                inputProps={{
                                    step: "0.1",
                                }}
                                onChange={(e) => handleLowValue(e)}
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-1 d-flex justify-content-center">
                            <Checkbox
                                checked={chHighState}
                                onChange={(e) => handleChange(e, "high")}
                                color="primary"
                                inputProps={{ "aria-label": "primary checkbox" }}
                            />
                        </div>
                        <div class="col w-100">
                            <TextField
                                margin="dense"
                                id="high"
                                label="High"
                                type="number"
                                fullWidth
                                value={thHighState}
                                inputProps={{
                                    step: "0.1",
                                }}
                                onChange={(e) => handleHighValue(e)}
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-1 d-flex justify-content-center">
                            <Checkbox
                                checked={chRateState}
                                onChange={(e) => handleChange(e, "rate")}
                                color="primary"
                                inputProps={{ "aria-label": "primary checkbox" }}
                            />
                        </div>
                        <div class="col w-100">
                            <TextField
                                margin="dense"
                                id="rate"
                                label="Rate"
                                type="number"
                                fullWidth
                                value={thRateState}
                                inputProps={{
                                    step: "0.1",
                                }}
                                onChange={(e) => setThRateState(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    {t("btn.cancel")}
                </Button>
                <Button onClick={handleOk} color="primary">
                    {t("btn.ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
