import ApiService from "Services/apiService";

const StateService = {
    requestCountValueInMeasures: async (setStates) => {
        let requestOptions = {
            url: "/api/measures/countThingsByState",
            params: {
                companyId: "84863331-d1f4-4b92-a2bd-858ac436d152", //flowinc
            },
        };

        return ApiService.getData(requestOptions).then((res) => {
            let mapped = res.filter((r) => r.value !== "Low temperature");

            const valuesToCheck = ["Backflow", "Dry", "Burst", "Leakage", "Tamper", "Low Battery"];

            valuesToCheck.forEach((value) => {
                if (!mapped.find((r) => r.value === value)) {
                    mapped.push({ value, count: 0 });
                }
            });

            setStates(mapped);
        });
    },
};

export default StateService;
