import ApiService from "Services/apiService";

const CloudUserService = {
    abortController: new AbortController(),

    requestTenant: async (thingId) => {
        let requestOptions = {
            url: `/v2/things/${thingId}/custom_fields`,
        };
        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        const mapped = result.reduce((acc, e) => {
            if (e.name === "watermeter_firstname") {
                acc["firstname"] = e.value;
            } else if (e.name === "watermeter_lastname") {
                acc["lastname"] = e.value;
            } else if (e.name === "watermeter_email") {
                acc["email"] = e.value;
            }
            return acc;
        }, {});

        // console.log(mapped);

        return mapped;
    },

    // name should have 'tenant or email
    updateTenantInfo: async (thingId, name, value) => {
        let requestOptions = {
            url: `/v2/things/${thingId}/custom_fields`,
            data: [{ name: `watermeter_${name}`, value: value }],
        };

        return await ApiService.putData(requestOptions).then((r) => {
            // console.log(r);
        });
    },
};

export default CloudUserService;
