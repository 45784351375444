import { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LightUI from 'Components/ui/LightUI';
import BasicUI from 'Components/ui/BasicUI';
import CircleUI from 'Components/ui/CircleUI';
import DashboardService from './DashboardService';
import { loadingUI } from './Loading';
import { useTranslation } from 'react-i18next';
import SettingContext from 'Providers/SettingContext';
import { AppId } from 'Components/applications/ApplicationData';
import AppContext from 'Components/AppContext'
import store from 'store';
import Constants from '../Constants';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});


export default function ClustersCENGN(props) {
    const ctx = useContext(AppContext);
    ctx.updateTitle("Smart Agriculture");

    const setting = useContext(SettingContext);

    let history = useHistory();
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [rows, setRows] = useState([]);
    const [flexibleWidth, setFlexibleWidth] = useState('12%');
    const [colspanLight, setColspanLight] = useState(2);

    useEffect(() => {
        if (props.connectivity != undefined) {
            DashboardService.requestAllClustersThings(handleRows, props.connectivity);
        }

        setting.setGlobalSetting({
            appId: AppId.SMART_AGRICULTURE,
            temperatureMonitoring: {
                celsius: true,
                language: "en"
            }
        });

        let width = (props.connectivity == 'LORA' ? '12%' : '15%');
        setFlexibleWidth(width);

        let storedConnectivity = store.get(Constants.KEY_CONNECTIVITY) == undefined ? 'LORA' : store.get(Constants.KEY_CONNECTIVITY);
        if (storedConnectivity == 'LORA') {
            setColspanLight(2);
        } else {
            setColspanLight(1);
        }

    }, [props.connectivity]);

    const handleDetail = (obj, clusterName) => {
        // console.log("handleDetail......");

        if (obj != undefined && obj.unit != undefined) {
            let unit = 'no';
            if (obj.unit == '%') {
                unit = 'percent';
            } else if (obj.unit == "") {
                unit = 'no';
            }

            history.push(`${process.env.PUBLIC_URL}/smart_agriculture/detail/${obj.thingId}/${obj.thingName}/${obj.attributeId}/${obj.attributeName}/${clusterName}/${unit}/${obj.fixedName}/${props.tabIndex}`);
        };
    }

    const handleRows = (rows) => {
        setRows(rows);
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Cluster</TableCell>
                            {/* Head */}
                            {props.tabIndex == 0 &&
                                <>
                                    <TableCell align="center" colspan={colspanLight}>{t('smart_ag.light')}</TableCell>
                                    {props.connectivity == 'LORA' &&
                                        <TableCell align="center">{t('smart_ag.par')}</TableCell>
                                    }
                                    <TableCell align="center">{t('smart_ag.airtemp')}</TableCell>
                                    <TableCell align="center">{t('smart_ag.humidity')}</TableCell>
                                    <TableCell align="center">{t('smart_ag.o2')}</TableCell>
                                    <TableCell align="center">{t('smart_ag.co2')}</TableCell>
                                    <TableCell align="center">{t('smart_ag.barometric')}</TableCell>
                                </>
                            }

                            {/* Grow */}
                            {props.tabIndex == 1 &&
                                <>
                                    <TableCell align="center" colSpan={2}>{t('smart_ag.temperature')}</TableCell>
                                    <TableCell align="center">{t('smart_ag.moisture')}</TableCell>
                                    <TableCell align="center">{t('smart_ag.ec')}</TableCell>
                                    <TableCell align="center">{t('smart_ag.humidity')}</TableCell>
                                    <TableCell align="center">{t('smart_ag.ph')}</TableCell>
                                </>
                            }

                            {/* Bottom */}
                            {props.tabIndex == 2 && props.connectivity == 'LORA' &&
                                <>
                                    <TableCell align="center">{t('smart_ag.light')}</TableCell>
                                    <TableCell align="center">{t('smart_ag.airtemp')}</TableCell>
                                    <TableCell align="center">{t('smart_ag.humidity')}</TableCell>
                                </>
                            }
                            {props.tabIndex == 2 && props.connectivity != 'LORA' &&
                                <>
                                    <TableCell align="center">{t('smart_ag.moisture')}</TableCell>
                                    <TableCell align="center" colSpan={2}>{t('smart_ag.ph')}</TableCell>
                                </>
                            }
                        </TableRow>
                    </TableHead>

                    <TableBody>

                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row" align="center">{row.name.replace("Cluster ", "")}</TableCell>
                                {props.tabIndex == 0 &&
                                    <>
                                        {/* First Column */}
                                        {props.connectivity == 'LORA' &&
                                            <>
                                                <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}
                                                    onClick={() => handleDetail(row.light_001, row.name)}
                                                >
                                                    <div className="d-flex justify-content-around">
                                                        <LightUI entity={row.light_001} />
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}
                                                    onClick={() => handleDetail(row.lux_003, row.name)}
                                                >
                                                    <div className="d-flex justify-content-around">
                                                        <LightUI entity={row.lux_003} />
                                                    </div>
                                                </TableCell>
                                            </>
                                        }
                                        {props.connectivity != 'LORA' &&
                                            <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}
                                                onClick={() => handleDetail(row.light_001, row.name)}>
                                                <div className="d-flex justify-content-around">
                                                    <LightUI entity={row.light_001} />
                                                </div>
                                            </TableCell>
                                        }
                                        {/* End of First Column */}
                                        {props.connectivity == 'LORA' &&
                                            <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}
                                                onClick={() => handleDetail(row.photosynthetically_active_radiation_002, row.name)}>
                                                <div className="d-flex justify-content-around">
                                                    <LightUI entity={row.photosynthetically_active_radiation_002} />
                                                </div>
                                            </TableCell>
                                        }
                                        <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.temperature_003, row.name)}>
                                            <BasicUI entity={row.temperature_003}></BasicUI>
                                        </TableCell>
                                        <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.humidity_003, row.name)}>
                                            <CircleUI entity={row.humidity_003}></CircleUI>
                                        </TableCell>
                                        <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.o2perc_003, row.name)}>
                                            <CircleUI entity={row.o2perc_003}></CircleUI>
                                        </TableCell>
                                        <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.co2_003, row.name)}>
                                            <BasicUI entity={row.co2_003}></BasicUI>
                                        </TableCell>
                                        <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.pressure_003, row.name)}>
                                            <BasicUI entity={row.pressure_003}></BasicUI>
                                        </TableCell>
                                    </>
                                }
                                {props.tabIndex == 1 &&
                                    <>
                                        <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.soil_temp_004, row.name)}>
                                            <div className="d-flex justify-content-around">
                                                <BasicUI entity={row.soil_temp_004}></BasicUI>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.temperature_005, row.name)}>
                                            <div className="d-flex justify-content-around">
                                                <BasicUI entity={row.temperature_005}></BasicUI>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.soil_moisture_raw_004, row.name)}>
                                            <BasicUI entity={row.soil_moisture_raw_004}></BasicUI>
                                        </TableCell>
                                        <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.ec_005, row.name)}>
                                            <BasicUI entity={row.ec_005}></BasicUI>
                                        </TableCell>
                                        <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.humidity_005, row.name)}>
                                            <CircleUI entity={row.humidity_005}></CircleUI>
                                        </TableCell>
                                        <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.phvalue_006, row.name)}>
                                            <BasicUI entity={row.phvalue_006}></BasicUI>
                                        </TableCell>
                                    </>
                                }
                                {props.tabIndex == 2 && props.connectivity == 'LORA' &&
                                    <>
                                        <TableCell align="center" style={{ width: '33%', cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.ambient_light_004, row.name)}
                                        >
                                            <div className="d-flex justify-content-around">
                                                <LightUI entity={row.ambient_light_004} />
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" style={{ width: '33%', cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.ambient_temp_004, row.name)}>
                                            <div className="d-flex justify-content-around">
                                                <BasicUI entity={row.ambient_temp_004}></BasicUI>
                                            </div>
                                        </TableCell>
                                        {/* <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.temperature_005, row.name)}>
                                            <div className="d-flex justify-content-around">
                                                <BasicUI entity={row.temperature_005}></BasicUI>
                                            </div>
                                        </TableCell> */}
                                        <TableCell align="center" style={{ width: '33%', cursor: 'pointer' }}
                                            onClick={() => handleDetail(row.ambient_humidity_004, row.name)}>
                                            <CircleUI entity={row.ambient_humidity_004}></CircleUI>
                                        </TableCell>
                                    </>
                                }
                                {props.tabIndex == 2 && props.connectivity != 'LORA' &&
                                    <>
                                        <TableCell align="center" style={{ width: '45%', cursor: 'pointer' }}
                                        >
                                            <div className="d-flex justify-content-around">
                                                <LightUI entity={undefined} />
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" style={{ width: '45%', cursor: 'pointer' }}
                                        >
                                            <div className="d-flex justify-content-around">
                                                <BasicUI entity={undefined} />
                                            </div>
                                        </TableCell>
                                    </>
                                }
                            </TableRow>
                        ))}

                        {
                            rows.length == 0 && loadingUI(props.tabIndex, props.connectivity)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
};