import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import CompostMap from "./CompostMap";
import Grid from "@mui/material/Grid";
import Item from "Components/ui/Item";
import CompostConstants from "./CompostConstants";
import AppContext from "Components/AppContext";
import { useHistory } from "react-router-dom";

export default function CompostMapMain() {
    const history = useHistory();
    const [cluster, setCluster] = useState();
    const [selectedName, setSelectedName] = useState("");

    const ctx = useContext(AppContext);
    // const setting = useContext(SettingContext);
    ctx.updateTitle("Compost");

    useEffect(() => {
        // console.log("uef in CompostMapMain");
        setCluster(CompostConstants.cluster);
    }, [selectedName]);

    const handleClcik = (name) => {
        // console.log(name);
        setSelectedName(name);

        // console.log(CompostConstants.cluster);
        const selectedSensor = CompostConstants.cluster
            .map((c) => c.data)
            .flat()
            .find((r) => r.name == name);
        // console.log(selectedSensor);

        if (selectedSensor.thingId && selectedSensor.attributeId) {
            history.push(`/apps/compost/chart/${selectedSensor.thingId}/${selectedSensor.attributeId}`);
        }
    };

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Item>
                        <CompostMap cluster={cluster} callback={(name) => handleClcik(name)} />
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}
