import React, { useState, useEffect } from "react";
import { Stage, Layer, Image } from "react-konva";
import Box from "@mui/material/Box";
import IslandKonvaImage from "./IslandKonvaImage";
import { v4 as uuidv4 } from "uuid";
import Grid from "@mui/material/Grid";
import ImageService from "Services/imageService";
import Button from "@mui/material/Button";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function IslandMap({ positions, callback }) {
    const { t, i18n } = useTranslation();

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [reload, setReload] = useState();

    const code = "SUPERVIOT_BG_IMAGE";
    const [backgroundImage, setBackgroundImage] = useState();

    useEffect(() => {
        // console.log("useEffect in IslandMap");
        if (!backgroundImage) {
            ImageService.requestBackgroundImage(code).then((r) => {
                // console.log(r);
                if (r) {
                    const bgImg = new window.Image();
                    bgImg.src = "data:image/png;base64," + r;
                    setBackgroundImage(bgImg);

                    bgImg.onload = () => {
                        setWidth(bgImg.naturalWidth);
                        setHeight(bgImg.naturalHeight);
                    };
                }
            });
        }
    }, [positions, backgroundImage, code]);

    const handleImage = (e) => {
        ImageService.uploadBackgroundImage(code, e.target.files[0]).then((r) => {
            setBackgroundImage(null);
            setReload(moment());
        });
    };

    const handleCallback = (name) => {
        callback(name);
    };

    return (
        <Box sx={{ flexGrow: 1, pt: 10, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2} sx={{ alignItems: "center", wrap: "nowrap" }}>
                <Grid item xs={12} sx={{ height: 100, flexGrow: 1, display: "flex", justifyContent: "flex-end", mr: 4 }}>
                    <label htmlFor="contained-button-file">
                        <input style={{ display: "none" }} accept="image/*" id="contained-button-file" type="file" onChange={handleImage} />
                        <Button
                            variant="contained"
                            component="span"
                            sx={{
                                backgroundColor: "#5694B5",
                                color: "#FFFFFF",
                                "&:hover": {
                                    backgroundColor: "#3A7CA5",
                                },
                                textTransform: "none",
                            }}
                        >
                            {t("superviot.upload")}
                        </Button>
                    </label>
                </Grid>
                <Grid item xs={12} sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "flex-start", height: "100%" }}>
                    <Stage width={width} height={height}>
                        <Layer>{backgroundImage && <Image image={backgroundImage} />}</Layer>
                        <Layer>
                            {positions &&
                                positions.map((p) => {
                                    return <IslandKonvaImage positions={p} key={uuidv4()} callback={(name) => handleCallback(name)} />;
                                })}
                        </Layer>
                    </Stage>
                </Grid>
            </Grid>
        </Box>
    );
}
