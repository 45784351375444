import React from "react";
import { Box } from "@mui/material";
import ChartElectricity from "./ChartElectricity";
import { useParams } from "react-router-dom";
import GraphMain from "./GraphMain";
import moment from "moment";

export default function ChartElectricityMain(params) {
    const { thingId } = useParams();

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <ChartElectricity thingId={thingId} />
            <GraphMain selectedThingId={"c106b4ca-a00c-4f3c-8c68-ead64770ae3b"} user={{ zoneinfo: moment.tz.guess() }} />
        </Box>
    );
}
