import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import AssignService from "./AssignService";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const columns = [
    {
        field: "id",
        headerName: "ID",
        width: 90,
    },
    {
        field: "thing.fixedName",
        headerName: "Serial Number",
        width: 170,
        editable: true,
    },
    // {
    //     field: "thing.name",
    //     headerName: "Thing",
    //     width: 150,
    //     editable: true,
    // },
];

export default function AssignedThings({ reload, cloudUserId, callback }) {
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });
    const [rowCount, setRowCount] = useState(0);
    const [rows, setRows] = useState([]);
    const [sort, setSort] = useState([{ field: "thing.name", sort: "asc" }]);

    useEffect(() => {
        if (cloudUserId) {
            AssignService.requestCloudUserThing(cloudUserId, paginationModel.page, paginationModel.pageSize, setRows, setRowCount, sort);
        }
    }, [paginationModel.page, paginationModel.pageSize, cloudUserId, reload, sort]);

    const handleSelectionModel = (param) => {
        // console.log("handleSelectionModel")
        // console.log(param)
        callback(param);
    };

    const handleSortModelChange = (sortModel) => {
        setSort(sortModel);
    };

    return (
        <Box sx={{ flexGrow: 1, height: "100%" }}>
            <DataGridPro
                rows={rows}
                rowCount={rowCount}
                columns={columns}
                disableColumnFilter
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pagination={true}
                paginationMode="server"
                sortingMode="server"
                pageSizeOptions={[20, 50, 100, 200, 500]}
                onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                density="compact"
                checkboxSelection
                // disableSelectionOnClick
                // style={{ height: '100%' }}
                // headerHeight={0}
                hideFooterSelectedRowCount
                onSortModelChange={handleSortModelChange}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            thing_name: false,
                        },
                    },
                }}
            />
        </Box>
    );
}
