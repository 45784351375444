import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { CustomButton } from "../../../../../components/ui/CustomButton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

export default function SiteEntity({ entity, callbackDetail, callbackSelect }) {
    // const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        // console.log("uef in SiteEntity");
        // console.log(entity);
    }, []);

    const handleSelect = () => {
        callbackSelect(entity);
    };

    const handleCell = () => {
        callbackDetail(entity);
    };

    // const handleMouseEnter = () => {
    //     setIsHovered(true);
    // };

    // const handleMouseLeave = () => {
    //     setIsHovered(false);
    // };

    return (
        <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Paper elevation={3} sx={{ p: 2 }}>
                {/* <Grid container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} sx={{ cursor: "pointer" }}> */}
                <Grid container sx={{ cursor: "pointer" }}>
                    <Grid item xs={9} onClick={() => handleCell()}>
                        <Stack>
                            <Typography variant="h6" align="left">
                                {entity.name}
                            </Typography>
                            <Typography variant="body1" align="left">
                                {entity.address}
                            </Typography>
                        </Stack>
                    </Grid>
                    {/* {isHovered && ( */}
                    <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box>
                            <CustomButton name="Select" callback={() => handleSelect()} />
                        </Box>
                    </Grid>
                    {/* )} */}
                </Grid>
            </Paper>
        </Box>
    );
}
