import Box from "@mui/material/Box";
import LogMain from "./LogMain";

export default function LogWrapper() {
    return (
        <Box
            sx={{
                display: "flex",
                flexGrow: 1,
                pt: 10,
                pl: 2,
                pr: 2,
                boxSizing: "border-box",
                overflow: "hidden", // Prevent scroll bars 
            }}
        >
            <LogMain />
        </Box>
    );
}
