import ApiService from "Services/apiService";
import UtilService from "Services/utilService";
import _ from "underscore";

const DEFAULT_THRESHOLD_HIGH = 0;
const DEFAULT_THRESHOLD_LOW = 0;
const DEFAULT_THRESHOLD_RATE = 0;

const ThresholdService = {
    requestThreshold: async (thingId, attributeId, callBackRequestThreshold) => {
        let requestOptions = {
            url: `/api/things/${thingId}/${attributeId}/custom_fields`,
        };

        // console.log("requestThreshold...");
        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requestThreshold...Response...");
                // console.log(response);

                let entity = {
                    thingId: thingId,
                    attributeId: attributeId,
                    thLow: {
                        custom_field_id: "",
                        attributeName: "",
                        value: DEFAULT_THRESHOLD_LOW,
                    },
                    thHigh: {
                        custom_field_id: "",
                        attributeName: "",
                        value: DEFAULT_THRESHOLD_HIGH,
                    },
                    thRate: {
                        custom_field_id: "",
                        attributeName: "",
                        value: DEFAULT_THRESHOLD_RATE,
                    },
                };

                response.data.forEach((ele) => {
                    if (ele.name.startsWith("TH_LOW")) {
                        entity.thLow.custom_field_id = ele.id;
                        entity.thLow.attributeName = `TH_LOW;${ele.label}`;
                        entity.thLow.value = parseFloat(ele.value);
                    } else if (ele.name.startsWith("TH_HIGH")) {
                        entity.thHigh.custom_field_id = ele.id;
                        entity.thHigh.attributeName = `TH_HIGH;${ele.label}`;
                        entity.thHigh.value = parseFloat(ele.value);
                    } else if (ele.name.startsWith("TH_RATE")) {
                        entity.thRate.custom_field_id = ele.id;
                        entity.thRate.attributeName = `TH_RATE;${ele.label}`;
                        entity.thRate.value = parseFloat(ele.value);
                    }
                });

                // console.log("requestThreshold----response");
                // console.log(entity);

                callBackRequestThreshold(entity);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    handleThreshold: async (entity) => {
        // console.log("handleThreshold...//////");
        // console.log(entity);

        const { thingId, attributeId, attributeName } = entity;

        if (UtilService.isEmpty(entity.thLow.custom_field_id)) {
            ThresholdService.createThreshold(thingId, attributeId, `TH_LOW;${attributeName}`, entity.thLow.value, attributeName);
        } else {
            ThresholdService.updateThreshold(thingId, entity.thLow.custom_field_id, `TH_LOW;${attributeName}`, entity.thLow.value, attributeName, attributeId);
        }

        if (UtilService.isEmpty(entity.thHigh.custom_field_id)) {
            ThresholdService.createThreshold(thingId, attributeId, `TH_HIGH;${attributeName}`, entity.thHigh.value, attributeName);
        } else {
            ThresholdService.updateThreshold(
                thingId,
                entity.thHigh.custom_field_id,
                `TH_HIGH;${attributeName}`,
                entity.thHigh.value,
                attributeName,
                attributeId
            );
        }

        if (UtilService.isEmpty(entity.thRate.custom_field_id)) {
            ThresholdService.createThreshold(thingId, attributeId, `TH_RATE;${attributeName}`, entity.thRate.value, attributeName);
        } else {
            ThresholdService.updateThreshold(
                thingId,
                entity.thRate.custom_field_id,
                `TH_RATE;${attributeName}`,
                entity.thRate.value,
                attributeName,
                attributeId
            );
        }
    },

    createThreshold: async (thingId, attributeId, name, value, label) => {
        let requestOptions = {
            url: `/api/things/${thingId}/${attributeId}/custom_fields`,
            data: {
                name: name,
                label: label,
                value: value,
                type: "TEXT",
            },
        };

        // console.log("createThreshold...");
        // console.log(requestOptions);

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.log("createThreshold...Response");
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateThreshold: async (thingId, custom_field_id, name, value, label, attributeId) => {
        let requestOptions = {
            url: `/api/things/${thingId}/custom_fields`,
            data: {
                id: custom_field_id,
                name: name,
                value: value,
                label: label,
                type: "TEXT",
                attributeId: attributeId,
            },
        };

        // console.log("updateThreshold...");
        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log("updateThreshold...Response");
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    deleteThreshold: async (thingId, custom_field_id, name, value, label, attributeId) => {
        let requestOptions = {
            url: `/api/things/${thingId}/custom_fields/${custom_field_id}`,
            // data: {
            //     id: custom_field_id,
            //     name: name,
            //     value: value,
            //     label: label,
            //     type: "TEXT",
            //     attributeId: attributeId
            // }
        };

        // console.log("updateThreshold...");
        // console.log(requestOptions);

        return ApiService.deleteData(requestOptions)
            .then((response) => {
                // console.log("updateThreshold...Response");
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default ThresholdService;
