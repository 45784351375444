import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Item from "../../../../components/ui/Item";
import Typography from "@mui/material/Typography";
import DetailService from "./DetailService";
import StateIcon from "./StateIcon";
import Paper from "@mui/material/Paper";

export default function StateCard({ selectedThingId }) {
    const [value, setValue] = useState();

    useEffect(() => {
        DetailService.requestLastState(selectedThingId, setValue);
    }, [selectedThingId]);

    return (
        <>
            {value && (
                <Paper elevation={5} sx={{ marginTop: "15px", width: "150px" }}>
                    <Box sx={{ mr: 0, height: "100%" }}>
                        <Stack direction={"column"}>
                            <Item sx={{ textAlign: "left" }}>State</Item>
                            <Item sx={{ pt: 3 }}>
                                <Typography variant="h6" color={value === "OK" ? "#14B25C" : "inherit"}>
                                    {value === "OK" ? "Normal" : value}
                                </Typography>
                            </Item>
                            <Item>
                                <Stack direction={"row"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <StateIcon state={value} />
                                </Stack>
                            </Item>
                        </Stack>
                    </Box>
                </Paper>
            )}
        </>
    );
}
