import { useState, useContext, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import { debounce } from "lodash";
import { Box, Paper, Checkbox, TextField, FormControlLabel, Typography } from "@mui/material";
import RobotService from "./RobotService";
import UserService from "Services/userService";
import moment from "moment";

export default function RobotMain() {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    const { t, i18n } = useTranslation();

    const [enableThreshold, setEnableThreshold] = useState(false);
    const [threshold, setThreshold] = useState(0);
    const [enableBeyondPlan, setEnableBeyondPlan] = useState(false);
    const [user, setUser] = useState();
    const [reload, setReload] = useState();

    useEffect(() => {
        ctx.updateTitle("Robot");
        setting.setGlobalSetting((prevSetting) => ({
            ...prevSetting,
            appId: AppId.ROBOT,
        }));

        UserService.getAuthenticatedUser().then((user) => {
            // console.log(user);
            setUser(user);

            RobotService.requestSetting(user.companyId).then((r) => {
                // console.log(r);

                if (r.value) {
                    const json = JSON.parse(r.value);

                    setEnableThreshold(json.enableThreshold);
                    setThreshold(json.threshold);
                    setEnableBeyondPlan(json.enableBeyondPlan);
                }
            });
        });
    }, []);

    const saveToDatabase = (updatedData) => {
        debounceSave(user.companyId, updatedData);
    };

    const debounceSave = useCallback(
        debounce((companyId, updatedData) => {
            // Perform save operation here
            // console.log("in debouse....");
            // console.log(companyId, updatedData);

            RobotService.saveSettinig(companyId, updatedData).then(() => setReload(moment()));
        }, 500),
        []
    );

    const handleThresholdChange = (e) => {
        const value = e.target.value !== "" ? Number(e.target.value) : 0;
        setThreshold(value);
        saveToDatabase({ enableThreshold, threshold: value, enableBeyondPlan });
    };

    const handleEnableThresholdChange = (e) => {
        const value = e.target.checked;
        setEnableThreshold(value);
        saveToDatabase({ enableThreshold: value, threshold, enableBeyondPlan });
    };

    const handleEnableBeyondPlanChange = (e) => {
        const value = e.target.checked;
        setEnableBeyondPlan(value);
        saveToDatabase({ enableThreshold, threshold, enableBeyondPlan: value });
    };

    return (
        <Box sx={{ mt: 10, p: 2, maxWidth: "sm" }}>
            <Paper elevation={2} sx={{ p: 2 }}>
                <FormControlLabel control={<Checkbox checked={!!enableThreshold} onChange={handleEnableThresholdChange} />} label={t("robot.rule1")} />

                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2, justifyContent: "center" }}>
                    <Typography>{t("robot.seuils")}</Typography>
                    <TextField variant="outlined" type="number" sx={{ mt: 2 }} value={threshold} onChange={handleThresholdChange} />
                </Box>
            </Paper>

            <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
                <FormControlLabel control={<Checkbox checked={!!enableBeyondPlan} onChange={handleEnableBeyondPlanChange} />} label={t("robot.rule2")} />
            </Paper>
        </Box>
    );
}
