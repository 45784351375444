import React, { useState, useEffect, useContext } from "react";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import UserService from "Services/userService";
import TimoutService from "./TimoutService";
import Alert from "@mui/material/Alert";

export default function TimeoutSetup() {
    const setting = useContext(SettingContext);
    const appId = setting.globalSetting.appId;
    const [isClientAdmin, setClientAdmin] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [period, setPeriod] = useState(10800);
    const [company, setCompany] = useState(null);
    const [errPeriod, setErrPeriod] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            setCompany(user.company);

            if (user && user.authorities && user.authorities.indexOf("CLIENT_ADMIN") !== -1) {
                setClientAdmin(true);
            }

            TimoutService.findDefaultTimeout(user.company).then((r) => {
                if (r && r.id) {
                    setPeriod(r.defaultActiveTimeoutPeriod);
                }
            });
        });
    }, [isClientAdmin]);

    const handlePeriod = (e) => {
        const newPeriod = parseInt(e.target.value);
        setPeriod(newPeriod);

        // Check if the new period is greater than or equal to 600
        if (newPeriod >= 600) {
            setErrPeriod(false);

            TimoutService.updateTimeout(company, newPeriod);
        } else {
            setErrPeriod(true);
        }
    };

    return (
        <>
            {appId === AppId.DEVICE_EXPLORER && isClientAdmin && (
                <IconButton aria-label="settings" onClick={handleClick} size="large">
                    <i className="fa fa-cog" aria-hidden="true" style={{ color: "white" }}></i>
                </IconButton>
            )}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Box sx={{ flexGrow: 1, p: 2, width: 450 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Setup</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1">Things</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography>Inactivity period (in seconds) ​</Typography>
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <TextField
                                            hiddenLabel
                                            id="txtPeriod"
                                            type="number"
                                            value={period}
                                            onChange={handlePeriod}
                                            variant="filled"
                                            size="small"
                                            fullWidth
                                            error={errPeriod}
                                        />
                                    </Grid>
                                    {errPeriod && (
                                        <Grid item xs={12}>
                                            <Alert severity="error">The period must be greater than or equal to 600</Alert>
                                        </Grid>
                                    )}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </>
    );
}
