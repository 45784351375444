import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CustomStyle from "Components/CustomStyle";
import IconButton from "@mui/material/IconButton";
import DetailService from "./DetailService";
import Box from "@mui/material/Box";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SiteDialogue from "Components/site/SiteDialogue";
import moment from "moment";
import Grid from "@mui/material/Grid";

export default function TabPlace({ selectedThingId }) {
    let defaltError = {
        size: false,
        radio: false,
        firstname: false,
        lastname: false,
        location: false,
        unit: false,
        email: false,
        message: "",
    };
    const [errors, setError] = useState(defaltError);
    const [location, setLocation] = useState("");
    const [openSite, setOpenSite] = useState(false);
    const [openTenant, setOpenTenant] = useState(false);
    const [thing, setThing] = useState();
    const [reload, setReload] = useState();
    const [user, setUser] = useState();

    // const [age, setAge] = useState('');

    const [isSaving, setIsSaving] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState();

    useEffect(() => {
        // console.log("uef in TabPlace");

        DetailService.requestThing(selectedThingId, setThing).then((t) => {
            // console.log("uef in MeterSummary")
            // console.log(t)
            if (t.site) {
                setLocation(t.site.name);
            } else {
                setLocation("");
            }
            setSelectedProduct(t.product);
        });
    }, [isSaving, reload, selectedThingId]);

    const handleValue = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "txtLocation":
                setLocation(value);
                break;
            default:
                break;
        }
    };

    return (
        <Box>
            <Paper elevation={3} sx={{ p: 1 }}>
                <Grid container spacing={0} sx={{ alignItems: "center" }}>
                    <Grid item xs={3} sx={{ pl: 2 }}>
                        <Typography>Location</Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: "flex" }}>
                        <TextField
                            required
                            name="txtLocation"
                            fullWidth
                            margin="dense"
                            size="small"
                            value={location}
                            onChange={(e) => handleValue(e)}
                            error={errors.location ? true : false}
                            sx={CustomStyle.RemoveLabel}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <IconButton aria-label="delete" size="large" variant="contained" onClick={() => setOpenSite(true)}>
                            <MoreHorizIcon fontSize="inherit" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>

            <SiteDialogue
                open={openSite}
                setOpen={setOpenSite}
                selectedThingId={selectedThingId}
                setReloadParent={() => {
                    setReload(moment());
                }}
            />
        </Box>
    );
}
