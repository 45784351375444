import React, { useState, useEffect } from "react";
import Confirm from "./Confirm";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";

export default function DeleteButton({ selectionModel, callback }) {
    const { t, i18n } = useTranslation();
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

    useEffect(() => {
        //do nothing
    }, [openDeleteConfirm]);

    return (
        <React.Fragment>
            <div className={style.btnContainer} style={{ width: "90px", color: "rgb(63, 81, 181)" }} onClick={() => setOpenDeleteConfirm(true)}>
                <i className="fa fa-trash-o" aria-hidden="true" style={{ paddingRight: "5px" }} />
                <span style={{ fontSize: "0.8125rem" }}>{t("btn.delete_upper")}</span>
            </div>
            <Confirm callback={callback} open={openDeleteConfirm} setOpen={setOpenDeleteConfirm} selectionModel={selectionModel} />
        </React.Fragment>
    );
}
