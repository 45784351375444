import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SuperviotConstants from "../SuperviotConstants";
import SettingMain from "./SettingMain";

export default function SviSettingMain() {
    const [value, setValue] = useState("0");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        // console.log("uef in SviSettingMain");
    }, []);

    return (
        <Box sx={{ flexGrow: 1, pt: 10, ml: 2, mr: 2 }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {SuperviotConstants.positions.map((ele, idx) => {
                            return <Tab label={ele.name} value={idx.toString()} key={idx} />;
                        })}
                    </TabList>
                </Box>

                {SuperviotConstants.positions.map((ele, idx) => {
                    return (
                        <TabPanel value={idx.toString()} key={idx}>
                            <Stack gap={5}>
                                <SettingMain siteName={ele.name} thingId={ele.thingId} />
                            </Stack>
                        </TabPanel>
                    );
                })}
            </TabContext>
        </Box>
    );
}
