import ApiService from "Services/apiService";

const ImageService = {
    requestBackgroundImage: async (code, setBackgroundImage) => {
        let requestOptions = {
            url: `/api/images/${code}`,
        };

        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                if (response.message && response.message == "no data") {
                    return false;
                }

                setBackgroundImage(`data:image/jpeg;base64,${response.bytes}`);
                return response.bytes;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    uploadBackgroundImage: async (code, imagefile) => {
        let requestOptions = {
            url: `/api/images/${code}`,
            file: imagefile,
        };

        return ApiService.uploadFile(requestOptions)
            .then((response) => {
                // Here are examples how to use the response:
                // 1. setBackgroundImage(`data:image/jpeg;base64,${response.bytes}`);
                // 2. const imgElement = document.createElement("img");
                //    imgElement.src = "data:image/png;base64," + r;
                //    setBackgroundImage(imgElement);
                return response;
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default ImageService;
