const RoomConstants = {
    rowsA: [
        createData(
            "Siege",
            "Salle A",
            entity(98, "%", "Aug 15, 09:01 PM"),
            entity(100, "ppm", "Aug 15, 09:01 PM"),
            entity(87, "ppm", "Jan 2", "Moyenne"),
            entity(29, "°C", "Aug 15, 09:01 PM"),
            entity(25, "°C", "Jan 2", "Moyenne"),
            entity(5, "%", "Aug 15, 09:01 PM"),
            entity(6, "%", "Jan 2", "Moyenne")
        ),

        createData(
            "Siege",
            "Salle 1",
            entity(90, "%", "Aug 15, 09:01 PM"),
            entity(1000, "ppm", "Aug 15, 09:01 PM"),
            entity(900, "ppm", "Jan 2", "Moyenne"),
            entity(31, "°C", "Aug 15, 09:01 PM"),
            entity(26, "°C", "Jan 2", "Moyenne"),
            entity(40, "%", "Aug 15, 09:01 PM"),
            entity(30, "%", "Jan 2", "Moyenne")
        ),

        createData(
            "Siege",
            "Salle 2",
            entity(90, "%", "Aug 15, 09:01 PM"),
            entity(1500, "ppm", "Aug 15, 09:01 PM"),
            entity(1400, "ppm", "Jan 2", "Moyenne"),
            entity(32, "°C", "Aug 15, 09:01 PM"),
            entity(27, "°C", "Jan 2", "Moyenne"),
            entity(55, "%", "Aug 15, 09:01 PM"),
            entity(40, "%", "Jan 2", "Moyenne")
        ),
    ],

    rowsB: [
        createData(
            "Agence",
            "Salle 3",
            entity(100, "%", "Aug 15, 09:01 PM"),
            entity(1550, "ppm", "Aug 15, 09:01 PM"),
            entity(2100, "ppm", "Jan 2", "Moyenne"),
            entity(33, "°C", "Aug 15, 09:01 PM"),
            entity(27, "°C", "Jan 2", "Moyenne"),
            entity(60, "%", "Aug 15, 09:01 PM"),
            entity(45, "%", "Jan 2", "Moyenne")
        ),

        createData(
            "Agence",
            "Salle 4",
            entity(97, "%", "Aug 15, 09:01 PM"),
            entity(500, "ppm", "Aug 15, 09:01 PM"),
            entity(700, "ppm", "Jan 2", "Moyenne"),
            entity(34, "°C", "Aug 15, 09:01 PM"),
            entity(28, "°C", "Jan 2", "Moyenne"),
            entity(54, "%", "Aug 15, 09:01 PM"),
            entity(44, "%", "Jan 2", "Moyenne")
        ),
    ],
};

function createData(floor, name, pwr, co2, co2Ave, temperature, temperatureAve, humidity, humidityAve) {
    return { floor, name, pwr, co2, co2Ave, temperature, temperatureAve, humidity, humidityAve };
}

function entity(value, unit, date, moyenne) {
    return { value, unit, date, moyenne };
}

export default RoomConstants;
