import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

function options(title, subtitle, unit, averages, callback) {
    return {
        chart: {
            type: "column",
        },
        title: {
            // text: title,
            text: null,
        },
        subtitle: {
            // text: subtitle,
            text: null,
        },
        xAxis: {
            type: "category",
            labels: {
                // rotation: -45,
                style: {
                    fontSize: "13px",
                    fontFamily: "Verdana, sans-serif",
                },
            },
            categories: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
            ],
        },
        yAxis: {
            title: {
                text: null,
            },
            min: 0,
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: unit,
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: title,
                data: averages,
                // zIndex: 1,
                // marker: {
                //     fillColor: 'white',
                //     lineWidth: 2,
                //     lineColor: Highcharts.getOptions().colors[0]
                // },
                // showInLegend: false
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: "#FFFFFF",
                    align: "right",
                    // format: '{point.y:.1f}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: "13px",
                        fontFamily: "Arial",
                    },
                },
                events: {
                    click: function (e) {
                        // console.log(e.point.name);
                        callback(e.point.name); //  ex) Slege-Salle A
                    },
                },
            },
        ],
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };
}

export default function MaxByRoomChart({ siteName, averages, callback }) {
    useEffect(() => {
        // console.log("uef in MaxByRoomChart");
        // console.log(siteName);
        // console.log(averages);
    }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ width: 600 }}>
                <HighchartsReact highcharts={Highcharts} options={options(siteName, "Maximum CO²", "ppm", averages, callback)} />
            </Box>
        </Box>
    );
}
