import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Item from "../../../../components/ui/Item";
import Typography from "@mui/material/Typography";
import DetailService from "./DetailService";
import Paper from "@mui/material/Paper";
import { CustomButton } from "Components/ui/CustomButton";
import moment from "moment";
import ThingsDialog from "./popup/ThingsDialog";

export default function DisplayCard({ selectedThingId }) {
    const [value, setValue] = useState();
    const [reload, setReload] = useState();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        DetailService.requestDisplayThing(selectedThingId, setValue);
    }, [selectedThingId, reload]);

    const handleRemove = (meterDisplayId) => {
        DetailService.disassociateDisplayThing(meterDisplayId).then(() => setReload(moment()));
    };

    const handleSelect = () => {
        setOpen(true);
    };

    return (
        <>
            {value && value.length === 0 && (
                <Paper elevation={5} sx={{ marginTop: "15px", width: "200px" }}>
                    <Box
                        sx={{
                            mr: 0,
                            height: "100%",
                            flexDirection: "column",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Stack direction={"column"}>
                            <Item sx={{ textAlign: "left" }}>Display</Item>
                            <Item sx={{ pt: 4, pb: 4 }}>
                                <Typography variant="body">No display</Typography>
                            </Item>
                        </Stack>

                        <Box sx={{ pb: 2, display: "flex", justifyContent: "center" }}>
                            <CustomButton name="Select" callback={handleSelect} />
                        </Box>
                    </Box>
                </Paper>
            )}
            {value &&
                value.map((meterDisplay) => {
                    const displayThing = meterDisplay.displayThing;
                    return (
                        <Paper elevation={5} sx={{ marginTop: "15px", width: "200px" }}>
                            <Box
                                sx={{
                                    mr: 0,
                                    height: "100%",
                                    flexDirection: "column",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Stack direction={"column"}>
                                    <Item sx={{ textAlign: "left" }}>Display</Item>
                                    <Item sx={{ pt: 3, display: "flex", flexDirection: "column" }}>
                                        <Typography variant="subtitle1">{displayThing.fixedName}</Typography>
                                        <Typography variant="body">{displayThing.name}</Typography>
                                    </Item>
                                </Stack>
                                <Box sx={{ pb: 2, display: "flex", justifyContent: "center" }}>
                                    <CustomButton name="Remove" callback={() => handleRemove(meterDisplay.id)} />
                                </Box>
                            </Box>
                        </Paper>
                    );
                })}

            <ThingsDialog open={open} setOpen={setOpen} meterThingId={selectedThingId} callback={() => setReload(moment())} />
        </>
    );
}
