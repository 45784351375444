import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

export default function CustomStyledButton({ icon, name, callback, component = "button" }) {
    const commonStyles = {
        backgroundColor: "#5694B5",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#3A7CA5",
        },
        textTransform: "none",
        padding: "0 16px", // Standard button padding
        height: "40px", // Adjust as needed
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px", // Same as Button's default
    };

    return icon ? (
        <IconButton
            component={component}
            sx={{
                ...commonStyles,
                minWidth: "64px", // Minimum width to resemble a button
                padding: "0 16px", // IconButton typically has zero padding, override it
            }}
            onClick={callback}
        >
            {icon}
            {name && <span>{name}</span>} {/* Optional name if needed */}
        </IconButton>
    ) : (
        <Button variant="contained" component={component} sx={commonStyles} onClick={callback}>
            {name}
        </Button>
    );
}
