import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import style from "./style.module.css";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import ShareIcon from "@mui/icons-material/Share";
import SensorDetailDialog from "../thingDetail/SensorDetailDialog";
import RoomElectricityCardService from "./RoomElectricityCardService";
import AttributeWidget from "../../../components/widgets/AttributeWidget";
import Item from "../../../components/ui/Item";

export default function RoomElectricityCard({ asset }) {
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState();

    const [entity, setEntity] = useState({});
    const [site, setSite] = useState(null);

    useEffect(() => {
        // console.log("useEffect in RoomElectricityCard");
        // console.log(asset);

        const attributeId = asset.assetAttribute[0].attribute.id;
        const thingId = asset.assetAttribute[0].thing.id;

        // console.log(attributeId);
        // console.log(thingId);

        RoomElectricityCardService.requestLastMeasures(thingId, [attributeId]).then((r) => {
            // console.log(r);

            r.forEach((ele) => {
                setEntity({
                    thingId: ele.thingId,
                    fixedName: ele.fixedName,
                    value: ele.value,
                    unit: ele.attribute.unit,
                    date: ele.timestamp,
                });
            });

            setSite(asset.site ? asset.site : null);
        });
    }, [asset]);

    const handleButton = (thingId) => {
        // console.log(id);
        // console.log(entity);
        history.push(`${process.env.PUBLIC_URL}/emera/v2/chart_electricity/${entity.thingId}`);
    };

    const handleFloor = () => {
        history.push(`${process.env.PUBLIC_URL}/emera/v2/floor/Siege`);
    };

    return (
        <Box sx={{ margin: 3, width: "300px", minHeight: 270 }}>
            <Paper elevation={8} sx={{ borderRadius: "15px", p: 2, height: "100%" }}>
                <Grid container>
                    {/* Room name */}
                    <Grid item xs={7}>
                        <span className={style.box} onClick={() => handleFloor()}>
                            {site && site.name}
                        </span>
                    </Grid>

                    {/* Battery icon and Calendar icon */}
                    <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end", height: "30px" }}>
                        <IconButton
                            aria-label="btn-battery"
                            sx={{
                                "&.MuiButtonBase-root:hover": {
                                    cursor: "default",
                                },
                            }}
                            size="small"
                        >
                            <i className="fa fa-battery-full" aria-hidden="true"></i>
                        </IconButton>

                        {entity.isMaster && (
                            <IconButton aria-label="btn-cock" size="small" style={{ filter: "grayscale(50%)" }}>
                                <ShareIcon size="small" />
                            </IconButton>
                        )}
                    </Grid>

                    {/* Daily average */}
                    {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", height: 30 }}>
                        <Typography variant="h6" component="h6">
                            {t("emera_v2.room_daily_average")}
                        </Typography>
                    </Grid> */}

                    <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
                        <AttributeWidget
                            variant="electricity"
                            entity={entity}
                            title={entity ? entity.fixedName : ""}
                            thingId={entity ? entity.thingId : undefined}
                            callback={(thingId) => handleButton(thingId)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Item sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end", width: "100%" }}>
                            <IconButton aria-label="delete" size="large" onClick={() => setOpen(true)}>
                                <i className="fa fa-cog" aria-hidden="true"></i>
                            </IconButton>
                        </Item>
                    </Grid>
                </Grid>
            </Paper>
            <SensorDetailDialog open={open} setOpen={setOpen} thingId={entity.thingId} entity={entity} setReload={setReload} />
        </Box>
    );
}
