import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import GatewayStatus from "./GatewayStatus";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Box from "@mui/material/Box";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
}));

export default function GatewayStatusPagination({ lastGatewayStatus }) {
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        // console.log(lastGatewayStatus);
        setRows((r) => lastGatewayStatus.slice(0, 2));
        setTotalPage(Math.ceil(lastGatewayStatus.length / 2));
    }, [lastGatewayStatus]);

    const handleBefore = () => {
        if (page > 1) {
            setPage(() => {
                let newPage = page - 1;
                let offset = (newPage - 1) * 2;
                setRows(lastGatewayStatus.slice(offset, offset + 2));
                return newPage;
            });
        }
    };

    const handleNext = () => {
        if (page < totalPage) {
            setPage(() => {
                let newPage = page + 1;
                let offset = (newPage - 1) * 2;
                setRows(lastGatewayStatus.slice(offset, offset + 2));
                return newPage;
            });
        }
    };

    return (
        <Stack direction={"row"} spacing={0}>
            <Item style={{ display: "flex", alignItems: "center" }} elevation={0}>
                <IconButton aria-label="delete" size="small" onClick={handleBefore}>
                    {page != 1 && <NavigateBeforeIcon fontSize="inherit" />}
                    {page == 1 && <Box sx={{ width: "20px" }}>&nbsp;</Box>}
                </IconButton>
            </Item>
            {rows &&
                rows.map((r) => {
                    return (
                        <Item key={r.id} style={{ width: "50%" }}>
                            <GatewayStatus value={r.rssi} date={r.creationTimestamp} name={r.gatewayName} eui={r.gatewayEui} />
                        </Item>
                    );
                })}
            {rows && rows.length == 1 && <Box sx={{ width: "190px" }}>&nbsp;</Box>}
            <Item style={{ display: "flex", alignItems: "center" }} elevation={0}>
                <IconButton aria-label="delete" size="small" onClick={handleNext}>
                    {totalPage != 0 && page != totalPage && <NavigateNextIcon fontSize="inherit" />}
                    {page == totalPage && <Box sx={{ width: "20px" }}>&nbsp;</Box>}
                </IconButton>
            </Item>
        </Stack>
    );
}
