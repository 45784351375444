import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CustomButton } from "../../../../../components/ui/CustomButton";

export default function ClearConfirmDialog({ open, setOpen, callback }) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="sm" fullWidth>
                <DialogTitle id="alert-dialog-title">{"Would you like to clear all alerts ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please be aware that if you clear all alerts, this action cannot be undone
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CustomButton name="Cancle" callback={handleClose} />
                    <CustomButton name="Clear All" callback={callback} />
                </DialogActions>
            </Dialog>
        </div>
    );
}
