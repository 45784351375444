import ApiService from "Services/apiService";

const RoomElectricityCardService = {
    requestLastMeasures: async (thingId, attributeIds) => {
        let requestOptions = {
            url: `/stats/last/things/${thingId}`,
        };

        return ApiService.getData(requestOptions).then((response) => {
            return response.data.filter((r) => attributeIds.includes(r.attribute.id));
        });
    },
};

export default RoomElectricityCardService;
