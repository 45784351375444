import { forwardRef } from 'react'
import Paper from '@mui/material/Paper';

import style from './style.module.css'

/**
 * This is the base widget which help us to encapsulate the widget inside the grid-item
 * Children are rendered inside it.
 */
const Base = forwardRef((props, ref) => (
    <div className={style.widget}
        ref={ref}
        gs-x={props['gs-x']}
        gs-y={props['gs-y']}
        gs-w={props['gs-w']}
        gs-h={props['gs-h']}
        gs-id={props['gs-id']}
    >
        <Paper className={`${style.widgetContent} grid-stack-item-content`}>
            {props.children}
        </Paper>
    </div>
))

export default Base