import { useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../../components/ui/BootstrapDialog";
import Grid from "@mui/material/Grid";
import TenantService from "./TenantService";
import { columns } from "./columns";
import { CustomButton } from "../../../../../components/ui/CustomButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import makeStyles from "@mui/styles/makeStyles";
import CheckInDialog from "./CheckInDialog";
import { Button } from "@mui/material";

import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    title: {
        display: "none",
    },
    search: {
        position: "relative",
        marginLeft: 0,
        width: "100%",
        color: "#424242",
    },
    searchIcon: {
        // padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "grey",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        width: "100%",
    },
    sectionDesktop: {
        display: "none",
    },
    sectionMobile: {
        display: "flex",
    },
}));

export default function TenantDialog({ open, setOpen, selectedThingId, setReloadParent, user }) {
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const classes = useStyles();

    const [openCheckIn, setOpenCheckIn] = useState(false);

    useEffect(() => {
        TenantService.requestUsers(keyword, paginationModel.page, paginationModel.pageSize, setRows, setRowCount, user).then(() => {
            TenantService.requestAssignedTenant(selectedThingId, setSelectedRow);
        });
    }, [paginationModel.page, paginationModel.pageSize, keyword, selectedThingId, user]);

    const handleClose = () => {
        setOpen(false);
        setSelectedRow(null);
        setKeyword("");
    };

    const handleSortModelChange = () => {
        // console.log("handleSortModelChange ...");
    };

    const handleRowClick = (params) => {
        // console.log("handleRowClick ...");
        // console.log(params);
        setSelectedRow(params.id);
    };

    const handleSelectionModel = (model) => {
        if (model.length === 0) {
            setSelectedRow("");
        } else {
            setSelectedRow(model[1]);
        }
    };

    const handleSave = () => {
        // console.log(selectedThingId); // thing.id
        // console.log(selectedRow); //cloud_user.id

        if (!selectedRow) {
            return;
        }

        TenantService.assignTenantToThing(selectedThingId, selectedRow).then(() => {
            const tenant = rows.find((e) => e.id === selectedRow);

            TenantService.updateTenantName(
                selectedThingId,
                tenant && tenant.firstName ? tenant.firstName : "",
                tenant && tenant.lastName ? tenant.lastName : ""
            ).then(() => {
                setOpen(false);
                setReloadParent();
            });
        });
    };

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Select a tenant
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <div className={classes.search} style={{ width: "400px" }}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon sx={{ ml: 1, mb: 2 }} />
                                </div>
                                <InputBase
                                    placeholder="Search by email, name"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ "aria-label": "search" }}
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                    sx={{ ml: 4, mb: 2 }}
                                    fullWidth
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sx={{ height: 400 }}>
                            <DataGridPro
                                rows={rows}
                                rowCount={rowCount}
                                columns={columns}
                                pagination={true}
                                paginationMode="server"
                                sortingMode="server"
                                paginationModel={paginationModel}
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={[20, 50, 100]}
                                onSortModelChange={handleSortModelChange}
                                onRowClick={handleRowClick}
                                checkboxSelection
                                rowSelectionModel={[selectedRow]}
                                onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                                density="compact"
                                // filterModel={{
                                // 	items: [
                                // 		{ columnField: 'state', operatorValue: 'contains', value: filterState },
                                // 	],
                                // }}
                                filterMode="server"
                                disableMultipleColumnsFiltering={true}
                                // onFilterModelChange={handleFilterModelChange}
                                // onColumnVisibilityChange={handleVisibilityChange}
                                disableColumnReorder={true}
                                hideFooterSelectedRowCount
                                height="100%"
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false,
                                            username: false,
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <CustomButton autoFocus name="Check-in" callback={() => setOpenCheckIn(true)} />
                    <CustomButton autoFocus name="Save as Tenant" callback={handleSave} />
                </DialogActions>
            </BootstrapDialog>

            <CheckInDialog
                open={openCheckIn}
                setOpen={setOpenCheckIn}
                selectedThingId={selectedThingId}
                selectedCloudUserId={selectedRow}
                setReloadParent={setReloadParent}
            />
        </div>
    );
}
