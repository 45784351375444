import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useTranslation } from "react-i18next";
import AlertService from "./AlertService";
import style from "./style.module.css";

const AlertBar = (props) => {
    const { t, i18n } = useTranslation();
    const [count, setCount] = useState(0);
    const [alerts, setAlerts] = useState([]);

    // Accordion
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        AlertService.requestAlert(props.thing.id, setAlerts, setCount);
    }, [count]);

    function clickHandler(e, id) {
        e.target.className = e.target.className.replace("fa-circle-o", "fa-circle");
        AlertService.updateAlertState(id, userConfirmed, alerts, setAlerts, e);
    }

    const userConfirmed = () => {
        AlertService.requestAlert(props.thing.id, setAlerts, setCount);
    };

    return (
        <>
            {count > 0 && (
                <Accordion square expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                    <AccordionSummary className="m-0 p-0" aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: "#F05622" }}>
                        <div className={style.toggle}>
                            <div className={style.div_title}>
                                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                <span className={style.title}>{count + " " + t("alert.name")}</span>
                            </div>
                            <div className={style.arrow}>
                                {expanded ? <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>}
                            </div>
                        </div>
                    </AccordionSummary>
                    {alerts.map((row) => {
                        return (
                            <AccordionDetails className={style.collapse} key={row.id}>
                                <div style={{ width: "100%" }}>
                                    <div className={style.card_body}>{row.message}</div>
                                    <div
                                        className={style.card_date_wrapper}
                                        onClick={(e) => {
                                            clickHandler(e, row.id);
                                        }}
                                    >
                                        <span className={style.card_date}>{row.date}</span>
                                        <i className={"fa fa-circle-o " + style.card_icon} aria-hidden="true"></i>
                                    </div>
                                </div>
                            </AccordionDetails>
                        );
                    })}
                </Accordion>
            )}
        </>
    );
};

const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
        marginLeft: "7px",
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        // padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default AlertBar;
