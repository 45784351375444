const SensorDetailColumn = {
    getColumns: function (t) {
        const columns = [
            {
                field: "id",
                headerName: t("col.id"),
                flex: 1,
                editable: false,
                sortable: false,
            },
            {
                field: "timestamp",
                headerName: t("col.timestamp"),
                flex: 1,
                editable: false,
                sortable: true,
            },
            {
                field: "measurement",
                headerName: t("col.measurement"),
                flex: 1,
                editable: false,
                sortable: true,
            },
            {
                field: "value",
                headerName: t("col.value"),
                flex: 1,
                editable: false,
                sortable: false,
            },
        ];
        return columns;
    },
};

export default SensorDetailColumn;
