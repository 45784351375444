import ApiService from "Services/apiService";

const IslandService = {
    requestThing: async (thingId) => {
        // console.log("requestThing...");
        // console.log(thingId);

        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            if (r.lastMeasurements && r.lastMeasurements.measurements) {
                let measure = r.lastMeasurements.measurements;
                let units = r.lastMeasurements.units;
                let timestamp = r.lastMeasurementsTimestamps.measurements;

                const combinedData = Object.keys(measure).map((key) => ({
                    name: key,
                    measurement: measure[key],
                    unit: units[key] ? units[key] : "",
                    timestamp: timestamp[key],
                }));

                let worstColor = r.customFields
                    ?.filter((c) => c.name === "WORST_COLOR")
                    .map((c) => c.value)
                    .find((c) => c);

                if (worstColor) {
                    combinedData["worstColor"] = worstColor;
                }

                // combinedData will now be an array of objects combining measure, unit, and timestamp
                // console.log(combinedData);

                return { measures: combinedData, customFields: r.customFields };
            }
        });
    },

    saveIconPosition: async (thingId, x, y) => {
        let requestOptions = {
            url: `/v2/things/${thingId}/custom_fields`,
            data: [
                {
                    name: "SUPERVIOT_ICON_POSITION",
                    label: "SUPERVIOT_ICON_POSITION",
                    value: JSON.stringify({
                        x,
                        y,
                    }),
                    type: "TEXT",
                },
            ],
        };
        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default IslandService;
