import ApiService from 'Services/apiService';
import { v4 as uuidv4 } from 'uuid';
import _ from 'underscore';
import UserService from 'Services/userService';

const GrowthService = {

    requestAsset: async (selectedCropId, selectedAssetId, setSelectedAssetId, setAsset, setActiveStep, setChartData) => {

        const company = await UserService.getAuthenticatedUser().then(user => user.company);

        if (selectedAssetId == undefined) {
            selectedAssetId = await GrowthService.requestDefaultAsset();
            setSelectedAssetId(selectedAssetId);
        }

        let requestOptions = {
            url: `/api/asset?filter=asset.id:eq_${selectedAssetId},crop.id:eq_${selectedCropId},company.name:eq_${company}`
        };

        // console.log("in requestAsset")
        // console.log(requestOptions)

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("Response");
                // console.log(response);

                const data = response.data;

                let steps = [];
                let durations = [];
                let assetId = 0;
                let count = 0;
                let temp = [];

                data.forEach(ele => {
                    const step = {
                        idx: count,
                        assetId: ele.id,
                        growthId: ele.growthId,
                        step: ele.step,
                        duration: ele.duration,
                        growthDate: ele.growthDate
                    }
                    assetId = ele.id;

                    temp.push(step);
                    durations.push(ele.duration);
                });

                // add all steps
                for (let i = 0; i < 15; i++) {
                    steps.push({
                        idx: i,
                        assetId: assetId,
                        growthId: uuidv4(), // key for step component
                        step: i + 1,
                        duration: 0,
                        growthDate: undefined
                    })
                    // debugger
                }

                // console.log("Check !!!--------------")
                // console.log(steps)

                // debugger

                for (var i = 0; i < temp.length; i++) {
                    steps[temp[i].step].growthDate = temp[i].growthDate;
                    steps[temp[i].step].step = temp[i].step;
                    steps[temp[i].step].duration = temp[i].duration;


                    // debugger;

                    if (i == temp.length - 1) {
                        setActiveStep(temp[i].step);
                    }
                }

                // console.log("Check !!!--- steps")
                // console.log(steps)

                // debugger

                setAsset(steps);

                let chartData = [];
                if (durations.length == 1) {
                    chartData.push(durations[0]);
                } else {
                    for (var i = 0; i < durations.length; i++) {
                        if (durations[i] == 0) {
                            chartData.push(0);
                        } else {
                            for (var j = 0; j < durations[i]; j++) {
                                chartData.push(data[i].step + 1);
                            }
                        }
                    }
                }

                setChartData(chartData);

                return steps;

            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestDefaultAsset: async () => {

        let requestOptions = {
            url: `/api/asset/default`
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                return response.id; // asset_id
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestAllAsset: async (selectedCropGrowthIds, selectedAssetId, setChartData) => {

        if (selectedCropGrowthIds.length == 0) {
            setChartData([]);
            return;
        }

        let idsQuery = [];
        let orFilter = "";
        if (selectedCropGrowthIds != undefined && selectedCropGrowthIds.length > 0) {
            for (var i = 0; i < selectedCropGrowthIds.length; i++) {
                idsQuery.push(`{"property":["crop.id"],"operator":"eq","value":"${selectedCropGrowthIds[i]}"}`);
            }
            let concated = idsQuery.join(",");
            orFilter = encodeURI(`&orFilter=[${concated}]`);
        }

        let requestOptions = {
            url: `/api/asset?${orFilter}`
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                const data = response.data;

                let series = []; // assetId, growthId

                data.forEach(ele => {
                    if (selectedAssetId === undefined) {
                        return;
                    } else if (selectedAssetId != ele.id) {
                        return;
                    }

                    let currentEle = _.find(series, function (s) {
                        return s.assetId == ele.id && s.cropGrowthId == ele.cropGrowthId;
                    })
                    if (currentEle == undefined) {
                        series.push({
                            assetId: ele.id,
                            cropGrowthId: ele.cropGrowthId,
                            cropGrowthName: ele.cropGrowthName,
                            cropStatus: ele.cropStatus,
                            clusterName: ele.name,
                            name: ele.cropGrowthName + "(" + ele.shortName + ")", // for legend in the chart
                            data: [ele.duration]
                        })
                    } else {
                        currentEle.data.push(ele.duration);
                    }
                })

                series.forEach(ele => {
                    if (ele.data.length > 0) {
                        ele.data = GrowthService.appendData(ele.data);
                    }
                })

                setChartData(series);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    appendData: (durations) => {
        let chartData = [];
        if (durations.length == 1) {
            chartData.push(durations[0]);
        } else {
            // let previous = 0;
            let yAxis = 0;

            for (var i = 1; i < durations.length; i++) {
                for (var j = 0; j < durations[i]; j++) {
                    // chartData.push(previous + durations[i - 1]);
                    chartData.push(yAxis);
                }
                yAxis++;
                // previous = durations[i - 1];
            }
        }
        return chartData;
    },

    updateAsset: async (selectedAssetId, selectedCropId, growthDate, selectedStep, setMessage, onClose) => {
        // if (growthId != -1) {
        //     GrowthService.putAsset(selectedAssetId, selectedCropId, growthDate, selectedStep, setMessage, onClose);
        // } else {
        GrowthService.postAsset(selectedAssetId, selectedCropId, growthDate, selectedStep, setMessage, onClose);
        // }
    },

    putAsset: async (selectedAssetId, selectedCropId, growthDate, selectedStep, setMessage, onClose) => {
        let requestOptions = {
            url: `/api/asset/${growthId}`,
            data: {
                assetId: assetId,
                growthId: growthId,
                step: selectedStep,
                growthDate: growthDate,
                isAllCluster: isAllCluster,
                selectedCropId: selectedCropId,
                selectedAssetId: selectedAssetId
            }
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                if (response.error == undefined) {
                    onClose();
                } else {
                    setMessage(response.error);
                }

            })
            .catch((e) => {
                console.error(e);
            });
    },

    postAsset: async (selectedAssetId, selectedCropId, growthDate, selectedStep, setMessage, onClose) => {

        let requestOptions = {
            url: `/api/asset`,
            data: {
                assetId: selectedAssetId,
                // growthId: growthId,
                step: selectedStep,
                growthDate: growthDate,
                duration: 0,
                // isAllCluster: isAllCluster,
                selectedCropId: selectedCropId
            }
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                if (response.error == undefined) {
                    onClose();
                } else {
                    setMessage(response.error);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }
}

export default GrowthService;