import React, { useState, useEffect, useRef } from "react";
import { Text, Image as KonvaImage, Rect, Group } from "react-konva";
import IslandService from "./IslandService";
import ImgBlue from "Assets/icons/group-blue.png";
import ImgGreen from "Assets/icons/group-green.png";
import ImgOrange from "Assets/icons/group-orange.png";
import ImgRed from "Assets/icons/group-red.png";
import ImgYellow from "Assets/icons/group-yellow.png";
import useImage from "use-image";
import Konva from 'konva';

export default function IslandKonvaImage({ positions, callback }) {
    const [state, setState] = useState({
        isDragging: false,
        x: 0,
        y: 0,
    });
    const [visibleIcon, setVisibleIcon] = useState(false);

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipX, setTooltipX] = useState(0);
    const [tooltipY, setTooltipY] = useState(0);
    const [tooltipText, setTooltipText] = useState();
    const [worstColor, setWorstColor] = useState("BLUE"); // Default worstColor to 'BLUE'

    const [tooltipHeight, setTooltipHeight] = useState(0);
    const [tooltipWidth, setTooltipWidth] = useState(180);

    const tooltipRef = useRef();
    const imageRef = useRef();

    // Load all images
    const [imgBlue] = useImage(ImgBlue);
    const [imgGreen] = useImage(ImgGreen);
    const [imgOrange] = useImage(ImgOrange);
    const [imgRed] = useImage(ImgRed);
    const [imgYellow] = useImage(ImgYellow);

    // Map worstColor to corresponding image
    const imageMap = {
        BLUE: imgBlue,
        GREEN: imgGreen,
        ORANGE: imgOrange,
        RED: imgRed,
        YELLOW: imgYellow,
    };

    useEffect(() => {
        IslandService.requestThing(positions.thingId).then((ctx) => {
            if (ctx) {
                let arrText = [];
                let height = 0;

                const measures = ctx.measures;

                // console.log(measures);

                if (measures) {
                    measures.forEach((e) => {
                        const capitalizedName = e.name.charAt(0).toUpperCase() + e.name.slice(1);
                        arrText.push([`${capitalizedName}`, `${e.measurement} ${e.unit}`]);
                        height += 17;
                    });
                }

                setTooltipHeight(height);
                setTooltipText(arrText);
                // setWorstColor(measures.worstColor || "BLUE"); // Set the worstColor or default to 'BLUE'

                // Find saved positions
                if (ctx.customFields) {
                    const position = ctx.customFields.find((c) => c.name === "RIOT_ICON_POSITION")?.value;
                    if (position) {
                        try {
                            const p = JSON.parse(position);

                            if (p && p.x !== undefined && p.y !== undefined) {
                                // console.log(p.x);
                                // console.log(p.y);

                                setState((state) => ({
                                    ...state,
                                    x: p.x,
                                    y: p.y,
                                }));

                                setTooltipX(p.x + 60);
                                setTooltipY(p.y);
                            }
                        } catch (error) {
                            console.error("Failed to parse JSON:", error);
                        }
                    }
                }
                setVisibleIcon(true);
            }
        });
    }, []);

    useEffect(() => {
        // Update Konva image when worstColor changes
        const imageNode = imageRef.current;
        if (imageNode) {
            imageNode.image(imageMap[worstColor]);
            imageNode.getLayer().batchDraw(); // Redraw the layer to reflect the image change
        }
    }, [worstColor, imageMap]);

    useEffect(() => {
        if (tooltipRef.current) {
            tooltipRef.current.zIndex(2); // Set a high zIndex value to bring it to the top
        }
    }, [tooltipVisible]);

    const [tooltipOpacity, setTooltipOpacity] = useState(0);
    const [tooltipScale, setTooltipScale] = useState(0.5);

    useEffect(() => {
        if (tooltipVisible && tooltipRef.current) {
            tooltipRef.current.to({
                opacity: 1,
                scaleX: 1,
                scaleY: 1,
                duration: 0.3,
                easing: Konva.Easings.EaseOut,
                onFinish: () => {
                    setTooltipOpacity(1);
                    setTooltipScale(1);
                }
            });
        } else if (!tooltipVisible && tooltipRef.current) {
            tooltipRef.current.to({
                opacity: 0,
                scaleX: 0.5,
                scaleY: 0.5,
                duration: 0.2,
                onFinish: () => {
                    setTooltipOpacity(0);
                    setTooltipScale(0.5);
                }
            });
        }
    }, [tooltipVisible]);

    const [tooltipTimer, setTooltipTimer] = useState(null);

    const showTooltipWithDelay = () => {
        const timer = setTimeout(() => {
            setTooltipVisible(true);
        }, 400);
        setTooltipTimer(timer);
    };

    const hideTooltip = () => {
        if (tooltipTimer) {
            clearTimeout(tooltipTimer);
        }
        setTooltipVisible(false);
    };

    const handleClick = () => {
        callback(positions.name);
    };

    const handleIconPosition = (x, y) => {
        IslandService.saveIconPosition(positions.thingId, x, y);
    };

    const dragBoundFunc = (pos) => {
        const stage = imageRef.current.getStage();
        const stageWidth = stage.width();
        const stageHeight = stage.height();
        const imageWidth = imageRef.current.width();
        const imageHeight = imageRef.current.height();

        return {
            x: Math.min(Math.max(pos.x, 0), stageWidth - imageWidth),
            y: Math.min(Math.max(pos.y, 0), stageHeight - imageHeight),
        };
    };

    const [labelPosition, setLabelPosition] = useState({ x: 0, y: 0 });

    const handleDrag = (e) => {
        const newX = e.target.x();
        const newY = e.target.y();
        setState({
            isDragging: true,
            x: newX,
            y: newY,
        });
        setTooltipX(newX + 60);
        setTooltipY(newY);
    };

    const handleDragEnd = (e) => {
        const newX = e.target.x();
        const newY = e.target.y();
        setState({
            isDragging: false,
            x: newX,
            y: newY,
        });
        setTooltipX(newX + 60);
        setTooltipY(newY);
        handleIconPosition(newX, newY);
        showTooltipWithDelay();
    };

    return (
        <>
            <KonvaImage
                ref={imageRef}
                image={imageMap[worstColor]}
                x={state.x}
                y={state.y}
                width={50}
                height={50}
                draggable
                dragBoundFunc={dragBoundFunc}
                onDragStart={() => {
                    setState(prev => ({ ...prev, isDragging: true }));
                    hideTooltip();
                }}
                onDragEnd={handleDragEnd}
                onMouseEnter={showTooltipWithDelay}
                onMouseOut={hideTooltip}
                onClick={handleClick}
                visible={visibleIcon}
                onDragMove={handleDrag}
            />
            <Group x={state.x - 45} y={state.y + 60} visible={visibleIcon}>
                <Rect fill="black" opacity={0.75} cornerRadius={5} width={150} height={25} />
                <Text text={positions.name} fill="white" padding={5} fontSize={14} align="center" drawBorder={true} width={150} />
            </Group>

            {tooltipVisible && (
                <Group 
                    ref={tooltipRef} 
                    x={tooltipX} 
                    y={tooltipY} 
                    opacity={tooltipOpacity}
                    scaleX={tooltipScale}
                    scaleY={tooltipScale}
                    visible={tooltipVisible}
                >
                    <Rect fill="black" opacity={0.75} cornerRadius={5} width={tooltipWidth} height={tooltipHeight} />
                    {tooltipText &&
                        tooltipText.map((t, i) => (
                            <Group key={i}>
                                <Text text={t[0]} fill="white" padding={5} fontSize={14} align="left" y={i * 16} x={5} width={tooltipWidth} />
                                <Text text={t[1]} fill="white" padding={5} fontSize={14} align="right" y={i * 16} width={tooltipWidth} />
                            </Group>
                        ))}
                </Group>
            )}
        </>
    );
}
