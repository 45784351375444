import { useState, useEffect } from "react";
import { BarChart, Bar } from "recharts";
import { Box } from "@mui/material";
import GraphService from "../thingDetail/graph/GraphService";
import moment from "moment";

export default function WidgetTinyChart({ thing, user }) {
    const [data, setData] = useState();

    useEffect(() => { 
        let startDate = moment();

        if (thing) {
            GraphService.requestHourlyDeltaVolume(thing.id, "year", startDate, user, thing.product).then((result) => {
                if (result.length === 0) return;

                // console.log("-------");
                // console.log(thing.id);
                // console.log(result);

                let preset = [];

                let sixMonthAgo = moment().subtract(6, "months");
                for (let i = 0; i < 7; i++) {
                    preset.push({
                        date: sixMonthAgo.unix() * 1000,
                        value: 0,
                    });
                    sixMonthAgo.add(1, "month");
                }

                // console.log(preset);

                let mapped = preset.map((p) => {
                    const found = result.find((r) => {
                        // console.log(r);
                        // console.log(moment(p.date).format("YYYYMM") + " - " + moment(r[3]).format("YYYYMM"));

                        return moment(p.date).format("YYYYMM") === moment(r[3]).format("YYYYMM");
                    });

                    // console.log(pDate + ", " + rDate);
                    // console.log(found);

                    if (found) {
                        p.value = found[2];
                        return p;
                    }

                    return p;
                });
                // console.log(mapped);

                setData(mapped);
            });
        }
    }, [thing]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <BarChart width={45} height={40} data={data}>
                <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
        </Box>
    );
}
