import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import UtilService from 'Services/utilService'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
}));

export default function MonthlyConsumption({ monthlyConsumption }) {

    return (
        <Paper elevation={5} sx={{ marginTop: '15px', width: '250px' }}>
            {
                monthlyConsumption && monthlyConsumption.error &&
                <Grid container spacing={0}>
                    <Grid item xs={12} style={{ height: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom>
                            No monthly consumption
                        </Typography>
                    </Grid>
                </Grid>
            }
            {
                monthlyConsumption && !monthlyConsumption.error &&
                <Grid container spacing={0}>
                    <Grid item xs={12} style={{ display: 'flex', textAlign: 'left' }}>
                        <Item>
                            <Typography variant='subtitle1'>
                                Monthly consumption
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12} style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Item>
                            <Typography variant='h4'>
                                {monthlyConsumption.value} gal
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item>
                            <Typography variant='subtitle2'>
                                {UtilService.formatMonthYear(monthlyConsumption.timestamp * 1000)}
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
            }
        </Paper >
    )
}