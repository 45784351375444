import { useEffect, useState, useCallback, useContext } from 'react';
import { Link } from 'react';
import style from './style.module.css';
import fa from 'font-awesome/css/font-awesome.min.css';
import { Box } from '@mui/material';

// For the Leaflet
import { MapContainer, LayersControl, LayerGroup, TileLayer, Marker, Popup } from 'react-leaflet';
import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import "react-leaflet-markercluster/dist/styles.min.css";
import _ from 'underscore';

export default function Map(props) {

    const position = [45.49573, -73.60635]

    /**
     * The api call is called only when the widget has been rendered.
     * It can be changed in the future.
     */
    useEffect(() => {
    }, [])

    function clickHandler(element) {
    }

    function createMarkerIcon() {

        var backColor = '#2A2AD3';
        var icon = 'fa-tint';

        const htmlTag = "<span className='fa-stack fa-lg'>" +
            "<i className='fa fa-circle fa-2x fa-stack-2x' style='color:" + backColor + "'></i>" +
            "<i className='fa " + icon + " fa-1x fa-stack-1x' style='color:white'></i>" +
            "</span>";

        const customMarkerIcon = new Leaflet.DivIcon({
            className: 'custom-div-icon',
            html: htmlTag,
        });

        return customMarkerIcon;
    }

    return (
        <div className={style.wrapper} style={{ zIndex: '0', height: '200px' }}>
            <MapContainer center={position} style={{ weight: '100%', height: '100%' }} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                <Marker position={position}
                    icon={createMarkerIcon()}
                    eventHandlers={{
                        click: (e) => clickHandler(e),
                        mouseover: (e) => e.target.openPopup(),
                        mouseout: (e) => e.target.closePopup()
                    }}
                >
                    <Popup>
                        Water Sensor
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    );
}

