const SalleElectricity1 = {

    data: [
        [1682049600000, 133],
        [1682053200000, 196],
        [1682056800000, 154],
        [1682060400000, 186],
        [1682064000000, 176],
        [1682067600000, 153],
        [1682071200000, 156],
        [1682074800000, 133],
        [1682078400000, 125],
        [1682082000000, 188],
        [1682085600000, 131],
        [1682089200000, 140],
        [1682092800000, 156],
        [1682096400000, 108],
        [1682100000000, 131],
        [1682103600000, 127],
        [1682107200000, 126],
        [1682110800000, 144],
        [1682114400000, 169],
        [1682118000000, 194],
        [1682121600000, 176],
        [1682125200000, 164],
        [1682128800000, 160],
        [1682132400000, 166]
    ]
}

export default SalleElectricity1;