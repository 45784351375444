import ApiService from "Services/apiService";

const RoomContainerService = {
    requestAsset: async () => {
        let companyId = "c07e6258-0a04-4aca-9bdd-119b21dd5f9c";
        let appId = "EMERA_V2";

        let quryString = `companyId=${companyId}&appId=${appId}`;

        const param = {
            url: `/v2/asset?${quryString}`,
        };

        return ApiService.getData(param).then((response) => {
            // console.log("in requestAsset");
            // console.log(response);

            return response.map((r) => {
                // console.log("in map");
                const site = r.assetAttribute.find((a) => a.thing.site)?.thing.site;

                return {
                    assetId: r.id,
                    name: r.name,
                    assetAttribute: r.assetAttribute.map((a) => {
                        return { attribute: a.attribute, thing: a.thing };
                    }),
                    site: site,
                };
            });
        });
    },
};

export default RoomContainerService;
