import style from "./style.module.css";

export const ThingListColumns = {
    getColumn: (t) => {
        const columns = [
            {
                field: "id",
                headerName: t("col.id"),
                // flex: 1,
                width: 200,
            },
            {
                field: "thing_fixedName",
                headerName: t("col.name"),
                flex: 1,
                editable: false,
                sortable: false,
            },
            {
                field: "assigned_users",
                headerName: t("col.user"),
                flex: 1,
                editable: false,
                sortable: false,
            },
            {
                field: "assigned_company",
                headerName: t("col.organization"),
                flex: 1,
                editable: false,
                sortable: false,
            },
            {
                field: "vendor",
                headerName: t("col.vendor"),
                flex: 0.5,
                editable: false,
                sortable: false,
            },
            {
                field: "product_name",
                headerName: t("col.product"),
                flex: 1,
                editable: false,
                sortable: false,
            },
            {
                field: "status",
                headerName: t("col.status"),
                sortable: false,
                flex: 1,
                renderCell: (params) => {
                    let classStyle = style.error;
                    // console.log(params.value);

                    switch (params.value) {
                        case "ACTIVE":
                        case "CONNECTED":
                            classStyle = style.active;
                            break;
                        case "INACTIVE":
                        case "NOT_CONNECTED":
                        case "JOIN_RECEIVED":
                            classStyle = style.inactive;
                            break;
                        default:
                            classStyle = style.inactive;
                            break;
                    }

                    return <span className={"badge " + classStyle}>{params.value}</span>;
                },
            },
        ];

        return columns;
    },
};

export default ThingListColumns;
