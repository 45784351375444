import { useEffect, useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import style from "./style.module.css";
// import { AuthenticatedUser } from 'Providers/Context';
import IconButton from "@mui/material/IconButton";
import MainMenu from "./MainMenu";
import Setting from "./Setting";
import UserMenu from "./UserMenu";
import AppContext from "../AppContext";
import UserService from "Services/userService";
import NotificationSetting from "Routes/smartAgriculture/notification/NotificationSetting";
import { AppId } from "../../components/applications/ApplicationData";
import Help from "./Help";
import iflowinc_logo from "Assets/icons/iflowinc_logo.png";
import TimeoutSetup from "./TimeoutSetup";

// const baseTitle = "Sensor Apps";
const baseTitle = "H2O Mobile"; //
// const user = useContext(AuthenticatedUser)

function isOnHome(path) {
    // smart agriculture landing page is ignored voluntarily.
    return (
        path === "/apps/" ||
        path === "/apps" ||
        path === "/apps/smart_agriculture" ||
        path === "/apps/smart_agriculture/" ||
        path === "/apps/sens_dc" ||
        path === "/apps/water_metering/water_metering"
    );
}

function isSmartAgriculture(path) {
    return path.indexOf("smart_agriculture") !== -1;
}

function isSensDc(path) {
    return path.indexOf("sens_dc") !== -1;
}

function isDeviceExplorer(path) {
    return path.indexOf("device_explorer") !== -1;
}

function isTemperatureMornitoring(path) {
    return path.indexOf("temperature_monitoring") !== -1;
}

// (example)
// /apps/device_explorer/catalog/manufacturerList 	-> length 5 -> goto the app's home : /apps/device_explorer/device_explorer
// /apps/device_explorer/device_explorer				-> lenght 4 -> goto the list page  : /apps/
function isSubPages(history) {
    let paths = history.location.pathname.split("/");
    if (paths.length > 4) {
        return true;
    }
}

export default function Header(props) {
    let history = useHistory();
    let location = useLocation();
    const ctx = useContext(AppContext);
    const title = ctx.title;
    const dynamicTitle = title ? title : baseTitle;
    const [enableGoHome, setEnableGoHome] = useState(false);
    const [isPth, setIsPth] = useState(false);
    const [subCompany, setSubCompany] = useState();

    const handleClick = (event) => {
        history.push("/apps/");
    };

    useEffect(() => {
        UserService.getAuthenticatedUser()
            .then((user) => {
                if (user.company == "PTH") {
                    setIsPth(true);
                }
                if (user.company == "CALSUB") {
                    setSubCompany(user.company);
                }

                // users only have an application. so they can't go to home
                if (user.application && user.application.length > 1) {
                    setEnableGoHome(true);
                }
            })
            .catch((e) => console.error(e));
    }, [location]);

    return (
        <>
            {props.isLoggedIn && (
                <header className={style.header}>
                    <MainMenu></MainMenu>
                    {!isOnHome(history.location.pathname) && (
                        <IconButton
                            aria-label="delete"
                            onClick={() => {
                                if (enableGoHome) {
                                    history.goBack();
                                } else {
                                    if (isSubPages(history)) {
                                        history.goBack();
                                    } else {
                                        history.push("/apps/");
                                    }
                                }
                            }}
                            size="large"
                        >
                            <i className="fa fa-arrow-left" style={{ color: "white" }}></i>
                        </IconButton>
                    )}
                    {dynamicTitle && dynamicTitle == "H2O Mobile" && <img src={iflowinc_logo} style={{ width: 20 }} alt="Logo" />}
                    <h1 style={{ margin: "0px", paddingLeft: "10px" }}>{dynamicTitle}</h1>
                    {subCompany && <div>{subCompany}</div>}
                    <nav style={{ paddingRight: 20 }}>
                        {dynamicTitle !== baseTitle && enableGoHome && (
                            <IconButton aria-label="home" onClick={handleClick} size="large">
                                <i className="fa fa-th" style={{ color: "white" }}></i>
                            </IconButton>
                        )}
                        {isSmartAgriculture(history.location.pathname) && isPth && <NotificationSetting appId={AppId.SMART_AGRICULTURE}></NotificationSetting>}
                        {isSensDc(history.location.pathname) && <NotificationSetting appId={AppId.SENS_DC}></NotificationSetting>}
                        {isTemperatureMornitoring(history.location.pathname) && (
                            <NotificationSetting appId={AppId.TEMPERATURE_MONITORING}></NotificationSetting>
                        )}
                        {isDeviceExplorer(history.location.pathname) && <TimeoutSetup />}
                        <Help />
                        <Setting></Setting>
                        <UserMenu></UserMenu>
                    </nav>
                </header>
            )}
        </>
    );
}
