export const columns = [
    {
        field: "id",
        headerName: "ID",
        flex: 0.7,
        editable: false,
        sortable: true,
        filterable: false,
    },
    {
        field: "email",
        headerName: "Email",
        flex: 1,
        editable: false,
        sortable: true,
        filterable: false,
    },
    {
        field: "username",
        headerName: "Username",
        flex: 0.7,
        editable: false,
        sortable: true,
        filterable: false,
    },
    {
        field: "firstName",
        headerName: "First name",
        flex: 0.7,
        editable: false,
        sortable: true,
        filterable: false,
    },
    {
        field: "lastName",
        headerName: "Last name",
        flex: 0.7,
        editable: false,
        sortable: true,
        filterable: false,
    },
];
