import { useEffect, useState } from "react";
import _ from "underscore";
import Box from "@mui/material/Box";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import Grid from "@mui/material/Grid";
import { columns } from "./columns";
import { CustomButton } from "../../../components/ui/CustomButton";
import { useTranslation } from "react-i18next";
import ElectricityService from "./ElectricityService";
import { frFR } from "@mui/x-data-grid/locales";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function AssociateAttribute({ entity, setOpen }) {
    const { t, i18n } = useTranslation();
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 15 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [sort, setSort] = useState([{ field: "name", sort: "asc" }]);
    const [selectedRow, setSelectedRow] = useState([]);

    const [wid, setWid] = useState("WID_ELECTRICITY");

    useEffect(() => {
        // console.log("useEffect in AssociateAttribute");
        // console.log(entity);

        ElectricityService.requestThingAttribute(setRows, paginationModel.page, paginationModel.pageSize, setRowCount, sort);

        ElectricityService.requestMappedAttribute(wid, setSelectedRow);
    }, [entity]);

    const handleSortModelChange = () => {
        // console.log("handleSortModelChange ...");
    };

    const handleRowClick = () => {
        // console.log("handleRowClick ...");
    };

    const handleSelectionModel = (newSelection) => {
        // console.log(newSelection);
        if (!_.isEmpty(newSelection)) {
            setSelectedRow(newSelection[newSelection.length - 1] || null);
        } else {
            setSelectedRow([]);
        }
    };

    const handleAssociate = () => {
        if (!_.isEmpty(selectedRow)) {
            const data = selectedRow.split(",");
            const thingId = data[0];
            const attributeId = data[1];

            ElectricityService.associateAttribute(thingId, attributeId, wid).then(() => setOpen(false));
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Grid container spacing={0}>
                <Grid item xs={12} sx={{ height: 320 }}>
                    {/* <Item sx={{ border: '1px solid red' }}> */}
                    <DataGridPro
                        rows={rows}
                        rowCount={rowCount}
                        columns={columns}
                        pagination={true}
                        paginationMode="server"
                        sortingMode="server"
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[15, 50]}
                        onSortModelChange={handleSortModelChange}
                        onRowClick={handleRowClick}
                        checkboxSelection={true}
                        onRowSelectionModelChange={handleSelectionModel}
                        rowSelectionModel={selectedRow}
                        density="compact"
                        // filterModel={{
                        // 	items: [
                        // 		{ columnField: 'state', operatorValue: 'contains', value: filterState },
                        // 	],
                        // }}
                        filterMode="server"
                        disableMultipleColumnsFiltering={true}
                        // onFilterModelChange={handleFilterModelChange}
                        // onColumnVisibilityChange={handleVisibilityChange}
                        disableColumnReorder={true}
                        height="100%"
                        sx={{
                            "& .MuiTablePagination-selectLabel": {
                                marginTop: 2,
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginTop: 2,
                            },
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                    />
                    {/* </Item> */}
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <CustomButton name="Associate" callback={handleAssociate} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
