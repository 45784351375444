import React, { useState, Suspense, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import style from './style.module.css';
import { Auth } from 'aws-amplify';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import validator from 'validator';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
}));

function ForgotPassword() {
    let param = useParams();
    let history = useHistory();

    const { t, i18n } = useTranslation();

    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [info, setInfo] = useState();

    const submit = e => {
        e.preventDefault();

        setLoading(true);
        setError(null);

        Auth.signIn(param.email, password)
            .then(e => {
                // sucesss
                setLoading(false);
                history.push("/apps");
            })
            .catch(e => {
                setLoading(false);
                setError('Incorrect username or password');
                console.log(e);
            });
    }

    const handleReset = () => {
        if (validator.isEmpty(password)) {
            setError('login.password-cannottbe-empty');
            return;
        } else if (validator.isEmpty(code)) {
            setError(t('login.code-cannottbe-empty'));
            return;
        } else if (password != confirmPassword) {
            setError(t('login.passward-not-same'));
            return
        }

        setError("");
        Auth.forgotPasswordSubmit(decodeURIComponent(param.email), code, password, {})
            .then(u => {
                history.push("/apps");
            })
            .catch(e => {
                //console.dir(e)
                switch (e.name) {
                    case "UserNotFoundException":
                        setError(t('login.user-notfound'));
                        break;
                    case "LimitExceededException":
                        setError(t('login.limit-exceeded'));
                        break;
                    default:
                        setError(e.message);
                        break;
                }
            });
    }

    const handleResend = () => {
        setError("");
        Auth.forgotPassword(decodeURIComponent(param.email), {})
            .then(() => {
                setInfo(t('login.sent-again'));
            })
            .catch((e) => console.log(e));
    }

    return (
        <>
            <div className={style.background}>
                <Box sx={{ backgroundColor: 'white', border: '1px solid black', width: '500px' }}>
                    <Suspense fallback={<span />}>
                        <Header />
                    </Suspense>
                    <Box sx={{ p: 5, pb: 2 }}>
                        {/* <img src="/apps/full-logo.png" alt="Pilot Things" /> */}
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Stack justifyContent={"center"} justifyItems={"center"}>
                                <Item>
                                    <Typography variant="h5" gutterBottom component="div">
                                        iFLOW H2O Mobile
                                    </Typography>
                                </Item>
                                <Item>
                                    <img src="/apps/iflowinc_logo.png" alt="Pilot Things" width={95} height={93} />
                                </Item>
                            </Stack>
                        </div>

                        <h4>{t('login.use-code')}</h4>
                        <Stack
                            component="form"
                            spacing={2}
                            onSubmit={submit}
                        >
                            <TextField variant="standard" label={t('login.code')} type="number" fullWidth required disabled={loading} onChange={e => setCode(e.target.value)} />
                            <TextField variant="standard" label={t('login.password')} type="password" fullWidth required value={password} disabled={loading} onChange={e => setPassword(e.target.value)}
                                helperText={t('login.password-policy')} />
                            <TextField variant="standard" label={t('login.enter-password-again')} type="password" fullWidth required value={confirmPassword} disabled={loading} onChange={e => setConfirmPassword(e.target.value)} />

                            {/* opacity is a clever trick to hide the error message while still reserving space for it. */}
                            {error && <Alert severity="error" sx={{ opacity: error ? 1 : 0 }}>{error}</Alert>}
                            {/* <LoadingButton loading={loading} loadingPosition="end" variant="contained" type="submit" endIcon={<LockOpenIcon />} fullWidth>{t('login.login')}</LoadingButton> */}
                            {info && <Alert severity="info" sx={{ opacity: info ? 1 : 0 }}>{info}</Alert>}

                            <Box sx={{ height: '20px' }}></Box>
                            <Button variant="contained" onClick={handleReset}>{t('login.reinitializer')} </Button>
                            <Box sx={{ mt: 3 }} textAlign="center">
                                <Link onClick={handleResend} sx={{ cursor: 'pointer' }}>{t('login.resend-code')}</Link>
                            </Box>
                        </Stack>
                    </Box>

                    <div style={{ display: 'flex', justifyContent: 'right', marginRight: 10, marginBottom: 10 }}>
                        <img src="/apps/iflowinc_new_log.png" alt="iFlowinc" />
                    </div>
                </Box>
            </div>
        </>
    );
}

export default ForgotPassword;