import ApiService from "Services/apiService";
import validator from "validator";
import UtilService from "Services/utilService";
import { AppId } from "Components/applications/ApplicationData";
import fileDOwnload from "js-file-download";

const CompanyService = {
    requestAllCompany: async () => {
        let requestOptions = {
            url: `/v2/companies`,
            params: {
                page: 0,
                pageSize: 100,
                sort: "name:asc",
            },
        };

        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        const mapped = result.content.map((r) => {
            return {
                id: r.id,
                name: r.name,
                // name: r.name.replace("COMPANY_", ""),
            };
        });

        return mapped;
    },

    requestCompany: async (page, pageSize, sort, setRows, setRowCount) => {
        let requestOptions = {
            url: `/v2/companies`,
            params: {
                page: page,
                pageSize: pageSize,
                sort: sort.map((s) => s.field + ":" + s.sort).join(","),
            },
        };

        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        const mapped = result.content.map((r) => {
            return {
                id: r.id,
                name: r.name,
                startDate: r.lastSubscription
                    ? r.lastSubscription.startDate
                        ? UtilService.formatYearMonthDay(r.lastSubscription.startDate * 1000)
                        : undefined
                    : undefined,
                endDate: r.lastSubscription
                    ? r.lastSubscription.endDate
                        ? UtilService.formatYearMonthDay(r.lastSubscription.endDate * 1000)
                        : undefined
                    : undefined,
                autoRenewal: r.lastSubscription ? r.lastSubscription.autoRenewal : false,
                subscriptionId: r.lastSubscription ? r.lastSubscription.id : undefined,
            };
        });
        // console.log(mapped)
        setRows(mapped);
        setRowCount(result.totalElements);

        return mapped;
    },

    requestSubCompany: async (company, page, pageSize, sort, setRows, setRowCount) => {
        let requestOptions = {
            url: `/v2/companies/sub`,
            params: {
                page: page,
                pageSize: pageSize,
                sort: sort.map((s) => s.field + ":" + s.sort).join(","),
                companyName: company,
            },
        };
        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        const mapped = result.content.map((r) => {
            // console.log(r);

            return {
                id: r.id,
                name: r.name,
                startDate: r.lastSubscription
                    ? r.lastSubscription.startDate
                        ? UtilService.formatYearMonthDay(r.lastSubscription.startDate * 1000)
                        : undefined
                    : undefined,
                endDate: r.lastSubscription
                    ? r.lastSubscription.endDate
                        ? UtilService.formatYearMonthDay(r.lastSubscription.endDate * 1000)
                        : undefined
                    : undefined,
                autoRenewal: r.lastSubscription ? r.lastSubscription.autoRenewal : false,
                subscriptionId: r.lastSubscription ? r.lastSubscription.id : undefined,
            };
        });
        // console.log(mapped);
        setRows(mapped);
        setRowCount(result.totalElements);

        return mapped;
    },

    requestSubCompanyWithMine: async (company) => {
        let requestOptions = {
            url: `/v2/companies/sub_with_mine`,
            params: {
                companyName: company,
            },
        };

        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        const mapped = result.map((r) => {
            return {
                id: r.id,
                name: r.name,
                // name: r.name.replace("COMPANY_", ""),
            };
        });
        // console.log(mapped)

        return mapped;
    },

    createGroup: async (company, groupName, authorities) => {
        let requestOptions = {
            url: `/v2/companies`,
            data: {
                companyName: company,
                subCompanyName: groupName.replace(/ /g, '_'),
                authorities: authorities,
            },
        };

        // console.log(requestOptions);

        const result = await ApiService.postData(requestOptions).catch((e) => console.error(e));
        // console.log(result);
        return result;
    },

    requestThingByCompanyId: async (selectedCompanyId) => {
        if (validator.isEmpty(selectedCompanyId)) {
            return;
        }

        let requestOptions = {
            url: `/v2/companies/${selectedCompanyId}/things`,
        };

        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);
        // console.log(result);
        return result;
    },

    assignThings: async (selectedCompanyId, model, rowIds) => {
        let requestOptions = {
            url: `/v2/companies/assign`,
            data: {
                selectedCompanyId: selectedCompanyId,
                selectedThingIds: model,
                rowIds: rowIds,
            },
        };
        // console.log(requestOptions);

        const result = await ApiService.putData(requestOptions);
        // console.log(result);
        return result;
    },

    removeGroup: async (companyId) => {
        let requestOptions = {
            url: `/v2/companies/${companyId}`,
        };

        // console.log(requestOptions);

        const result = await ApiService.deleteData(requestOptions);
        // console.log(result);
        return result;
    },

    setAutoRenewal: async (subscriptionId, renewal) => {
        let requestOptions = {
            url: `/v2/companies/set_auto_renewal`,
            data: {
                id: subscriptionId,
                renewal: renewal,
            },
        };
        // console.log(requestOptions);

        const result = await ApiService.putData(requestOptions);
        // console.log(result);
        if (result.code && result.code === "failure") {
            throw result.message;
        }

        return result;
    },

    renew: async (subId, companyId) => {
        let requestOptions = {
            url: `/v2/companies/renew_subscription`,
            data: {},
        };

        // console.log(companyId);

        if (subId) {
            requestOptions.data["id"] = subId;
        } else if (companyId) {
            requestOptions.data["companyId"] = companyId;
            requestOptions.data["appId"] = AppId.WATER_METERING;
        }

        // console.log(requestOptions);

        const result = await ApiService.putData(requestOptions);
        // console.log(result);
        if (result.code && result.code === "failure") {
            throw result.message;
        }

        return result;
    },

    requestReport: async (reportType, company) => {
        let requestOptions = {
            url:
                "/v2/companies/report?" +
                "page=0" +
                "&pageSize=100" +
                "&sort=name:asc" +
                "&appName=H2O" +
                `&reportType=${reportType}` +
                `&companyName=${company}`,
        };
        // console.log(requestOptions);

        ApiService.downloadFile(requestOptions)
            .then((response) => {
                fileDOwnload(response, `Subscriptions.${reportType}`);
            })
            .catch((e) => console.log(e));
    },
};

export default CompanyService;
