import makeCancellablePromise from 'make-cancellable-promise'

import ApiService from 'Services/apiService'
import wStyle from './style.module.css'
import { useEffect, useState } from 'react'

/**
 * This widget has a simple ui to display the measurement.
 * The widget receive the json configuration from the dashboard view
 * 
 * A service will take this configuration and generate the proper api call to retrieve data
 * 
 * @param {string} title of the widget 
 */

const MeasurementWidget = (props) => {

    const [value, setValue] = useState()
    const [unit, setUnit] = useState()

    const { title, subtitle, displayDate } = props.config;
    const { background, color } = props.style;
    const hasImage = props.hasImage;

    var div_subtitle;
    if (subtitle !== undefined) {
        div_subtitle = <span className={wStyle.w_subtitle}> {subtitle} </span>;
    }

    const [dateValue, setDateValue] = useState();
    const [isOpen, setIsOpen] = useState(false);

    const option = { month: 'long', weekday: 'long', year: 'numeric',  day: 'numeric' };
    const dateTimeFormat = new Intl.DateTimeFormat('en-US' , { dateStyle: 'medium', timeStyle: 'short' }, option);

    /**
     * The api call is called only when the widget has been rendered.
     * It can be changed in the future.
     */
    useEffect(() => {
        const request = makeCancellablePromise(props.getValues(configurationExtractor))

        request.promise.then((values) => {

            if (displayDate) {
                var date = new Date(values[0].data[0].timestamp); 
                setDateValue(dateTimeFormat.format(date*1000));
            }

            setValue(extractData(values[0]))
            setUnit(getUnit(values[0]))
        })

        return ()=>{
            if(request.cancel) request.cancel()
        }
    }, [])

    return (
        <div className={wStyle.w_wrapper} style={{ backgroundColor: background, color: color }}>
            <div className="container-fluid" className={wStyle.w_container}>
                <div className="row" className={wStyle.w_start}>
                    <div className="col" className={wStyle.w_title}>
                        {title}
                    </div>
                </div>
                <div className="row" className={wStyle.w_middle}>
                    <div className="col" className={wStyle.w_value}>
                        {value} <font className={wStyle.w_unit}>{unit}</font>
                        {div_subtitle}
                    </div>
                </div>

                <div className="row" className={wStyle.w_end}>
                    <div className="col">
                        {dateValue}
                    </div>
                </div>
            </div>
        </div>
    )
}

/**
 * Get the actual unit
 * @param {ApiValue} value
 */
const getUnit = ({data}) => {
    if(data && data.length > 0 && data[0].attribute.unit){
        return data[0].attribute.unit
    }else if(data && data.length > 0){
        return data[0].attribute.type.defaultUnit
    }
}

/**
 * This method transform the api response into a new object usable by the widget render
 * @param {ApiValues} values
 */
const extractData = ({data}) => {
    return data && data.length > 0 ? data[0].value : null
}

/**
 * Every widget needs a configuration exctractor. Because every widget has its own configuration, we need to implement specific configuration extractor in order to transform widgetConfiguration to actual ApiService configuration
 * @param {WidgetConfigurationObject} configuration
 */
const configurationExtractor = (configuration) => {

    return [{
        options: configuration.contents.value.options,
        filters: null,
        url: configuration.contents.value.url
    }]
}

export { configurationExtractor }
export default MeasurementWidget