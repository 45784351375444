export function LogColumns(t) {
    return [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "entryDate",
            headerName: "Date",
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "thing.fixedname",
            headerName: "Sonde",
            flex: 0.5,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "message",
            headerName: "Message",
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "username",
            headerName: t("col.user"),
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
    ];
}
