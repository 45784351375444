import { useState, useEffect, useContext } from "react";
import SettingContext from "Providers/SettingContext";
import ThingService from "./ThingService";
import _ from "underscore";
import Coordinator from "./Coordinator";
import UtilService from "Services/utilService";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { frFR } from "@mui/x-data-grid/locales";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

function createData(id, company, thing, timestamp, model, manufacturer, product, connectivityType, status, tags, site, fridge) {
    let formattedTimestamp = "";
    if (timestamp != undefined) {
        formattedTimestamp = UtilService.formateDate(timestamp * 1000);
    }

    return { id, company, thing, timestamp: formattedTimestamp, model, manufacturer, product, connectivityType, status, tags, site, fridge };
}

export default function ThingList(props) {
    const { t, i18n } = useTranslation();
    const setting = useContext(SettingContext);

    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [keyword, setKeyword] = useState(props.keyword);

    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedThingId, setSelectedThingId] = useState();
    const [sortQuery, setSortQuery] = useState("");

    const appId = props.appId;

    useEffect(() => {
        setColumns(Coordinator.getFixeColumns());

        ThingService.requestData(createData, paginationModel.page, paginationModel.pageSize, setRows, setRowCount, props.keyword, "", appId);
    }, [props.keyword, keyword, paginationModel.page, paginationModel.pageSize, setting]);

    const handleSortModelChange = (param) => {
        let sortModel = param;

        let qParams = [];
        _.each(sortModel, function (sm) {
            let result = "";

            switch (sm.field) {
                case "thing":
                    result += "displayName";
                    break;
                case "timestamp":
                    result += "lastMessageDate";
                    break;
                case "model":
                    result += "custom_model.name";
                    break;
                case "product":
                    result += "product.name";
                    break;
                case "status":
                    result += "status";
                    break;
                default:
                    break;
            }

            switch (sm.sort) {
                case "asc":
                    result += "&dir=ASC";
                    break;
                case "desc":
                    result += "&dir=DESC";
                    break;
                default:
                    break;
            }

            qParams.push(result);
        });

        let sortQuery = "";
        if (qParams.length > 0) {
            sortQuery = "&sort=" + qParams.join(",");
        }

        setSortQuery(sortQuery);
        ThingService.requestData(createData, paginationModel.page, paginationModel.pageSize, setRows, setRowCount, keyword, sortQuery, appId);
    };

    const handleSelectionModel = (selectionModel) => {
        setSelectionModel(selectionModel);
        props.callback(selectionModel);
        // console.log(selectionModel);
    };

    const handleCellClick = (params) => {
        setSelectedThingId(params.row.id);
    };

    return (
        <Box>
            <DataGridPro
                columns={columns}
                rows={rows}
                disableColumnFilter
                paginationModel={paginationModel}
                rowCount={rowCount}
                pagination={true}
                paginationMode="server"
                sortingMode="server"
                pageSizeOptions={[10, 20]}
                onPaginationModelChange={setPaginationModel}
                onSortModelChange={handleSortModelChange}
                checkboxSelection
                onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                density="compact"
                onCellClick={handleCellClick}
                disableSelectionOnClick
                style={{ width: "100%", height: "500px" }}
                sx={{
                    "& .MuiTablePagination-selectLabel": {
                        marginTop: 2,
                    },
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: 2,
                    },
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
            />
        </Box>
    );
}
