import { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import moment from "moment-timezone";
import UtilService from "Services/utilService";
import dayjs from "dayjs";

export function ChartElectricityOptions(title, type, chartData, chatCunsumptionData, groupEnable, setGroupEnable) {
    useEffect(() => {
        // do nothing
    }, [groupEnable]);

    let unit = "";

    if (chartData && chartData.length > 1) {
        unit = chartData[0][2];
    } else if (chatCunsumptionData && chatCunsumptionData.length > 1) {
        unit = chatCunsumptionData[0][2];
    }

    return {
        chart: {
            type: type,
        },
        title: {
            text: null,
        },
        xAxis: {
            type: "datetime",
            labels: {
                overflow: "justify",
            },
            events: {
                afterSetExtremes: function (e) {
                    var minDate = dayjs(e.min);
                    var maxDate = dayjs(e.max);
                    var differenceInDays = maxDate.diff(minDate, "days");

                    if (differenceInDays < 2) {
                        setGroupEnable(false);
                    } else {
                        setGroupEnable(true);
                    }
                },
            },
        },
        yAxis: [
            {
                labels: {
                    format: "{value} A",
                    style: {
                        color: Highcharts.getOptions().colors[4],
                    },
                },
                title: {
                    text: "Current",
                    style: {
                        color: Highcharts.getOptions().colors[4],
                    },
                },
            },
            {
                // gridLineWidth: 0,
                labels: {
                    format: "{value} wh",
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
                title: {
                    text: "Consumption",
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
            },
        ],
        tooltip: {
            shared: true,
            split: false,
            formatter: function () {
                let formattedDate = UtilService.formatMDHms(this.x);

                if (this.points.length == 1) {
                    let cname = this.points[0].series.name;
                    return formattedDate + `</br> <b>${cname}: ` + UtilService.truncate2Digits(this.y) + " " + unit + "</b></b>";
                } else if (this.points.length == 2) {
                    let ampValue = this.points[0].y;
                    let consumptionValue = this.points[1].y;

                    return (
                        formattedDate +
                        "</br> <b>Current: " +
                        UtilService.truncate2Digits(ampValue) +
                        " " +
                        unit +
                        "</b></br>" +
                        "<b>Consumption: " +
                        UtilService.truncate2Digits(consumptionValue) +
                        " Wh" +
                        "<b>"
                    );
                }
            },
        },
        credits: {
            enabled: false,
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
        series: [
            {
                name: "Current",
                data: chartData,
                zIndex: 1,
                marker: {
                    fillColor: "white",
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[0],
                },
                showInLegend: false,
                dataGrouping: {
                    groupPixelWidth: 5,
                    enabled: groupEnable,
                },
                yAxis: 1,
            },
            {
                name: "Consumption",
                data: chatCunsumptionData,
                zIndex: 1,
                marker: {
                    fillColor: "white",
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[0],
                },
                showInLegend: false,
                dataGrouping: {
                    groupPixelWidth: 5,
                    enabled: groupEnable,
                },
            },
        ],
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download",
                },
            },
        },
        rangeSelector: {
            buttons: [
                {
                    type: "day",
                    count: 1,
                    text: "1d raw",
                    events: {
                        click: function () {
                            setGroupEnable(false);
                        },
                    },
                },
                {
                    type: "week",
                    count: 1,
                    text: "1w",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
                {
                    type: "month",
                    count: 1,
                    text: "1m",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
                {
                    type: "1y",
                    count: 1,
                    text: "12m",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
                {
                    type: "all",
                    text: "All",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
            ],
            selected: 5,
        },
    };
}
