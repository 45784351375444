
import ApiService from 'Services/apiService';

const HistoryService = {

    requestHistoryValues: async (THING_ID, ATTRIBUTE_ID, processResponse) => {

        let now = new Date()
        let rightNow = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);

        now.setMonth(now.getMonth() - 3); // 3 month ago
        let threeMonthAgo = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);

        let requestOptions = [{
            url: "/stats/measurements?filter=thing.id:eq_" + THING_ID + ",attribute.id:eq_" + ATTRIBUTE_ID +
                "&time=1&interval=day&start=" + threeMonthAgo + "&end=" + rightNow
        }];

        ApiService.getValues(requestOptions)
            .then((response) => {
                processResponse(response[0]);
            })
            .catch((e) => console.log(e));
    }
};

export default HistoryService;