import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

export default function GrowthChart({ chartData, unit }) {
    const [detailOptions, setDetailOptions] = useState({});
    const [masterOptions, setMasterOptions] = useState({});
    const [filterBegin, setFilterBegin] = useState(0);
    const [filterEnd, setFilterEnd] = useState(9999999999999);

    // Configuration for ReactHighcharts
    const detailChart = {
        title: {
            text: "",
        },
        xAxis: {
            title: {
                text: `Number of Days`,
            },
            min: 0,
        },
        yAxis: [
            {
                title: {
                    text: `Height Zones`,
                },
                min: 0,
            },
        ],
        tooltip: {
            formatter: function () {
                return "Height Zone:" + this.y;
            },
        },
        plotOptions: {
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3,
                    },
                },
                marker: {
                    enabled: false,
                },
            },
        },
        chart: {
            type: "line",
            height: 500,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
        },
        credits: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false,
        },
        series: [
            {
                // unit: `${unit}`,
                data: chartData,
                // data: chartData.filter(point => point[0] > filterBegin && point[0] < filterEnd)
            },
        ],
        navigator: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download",
                },
            },
        },
    };

    const masterChart = {
        title: {
            text: "",
        },
        xAxis: {
            showLastTickLabel: true,
            plotBands: [
                {
                    id: "mask-before",
                    from: chartData.length > 0 ? chartData[0][0] : 0,
                    to: filterBegin,
                    color: "rgba(0, 0, 0, 0.2)",
                },
                {
                    id: "mask-after",
                    from: filterEnd,
                    to: chartData.length > 0 ? chartData[chartData.length - 1][0] : 9999999999999,
                    color: "rgba(0, 0, 0, 0.2)",
                },
            ],
            labels: {
                overflow: "justify",
                // formatter: function () {
                // 	return UtilService.formatMD(this.value);
                // }
            },
        },
        yAxis: [
            {
                title: {
                    text: null,
                },
                labels: {
                    enabled: false,
                },
                showFirstLabel: false,
                min: 0,
            },
            {
                title: {
                    text: null,
                },
                labels: {
                    enabled: false,
                },
                showFirstLabel: false,
                min: 0,
                opposite: true,
            },
        ],
        // tooltip: {
        // 	formatter: function () {
        // 		return false;
        // 	}
        // },
        plotOptions: {
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3,
                    },
                },
                marker: {
                    enabled: false,
                },
            },
            series: {
                fillColor: {
                    linearGradient: [0, 0, 0, 70],
                    stops: [
                        [0, "rgba(102,170,243,200)"],
                        [1, "rgba(102,170,243,0)"],
                    ],
                },
                lineWidth: 1,
                marker: {
                    enabled: false,
                },
                shadow: false,
                states: {
                    hover: {
                        lineWidth: 1,
                    },
                },
                enableMouseTracking: false,
            },
        },
        chart: {
            type: "area",
            height: 100,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
            events: {
                selection: (event) => {
                    const { min, max } = event.xAxis[0];
                    setFilterBegin(min);
                    setFilterEnd(max);

                    return false;
                },
            },
            zoomType: "x",
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        series: [
            {
                data: chartData,
            },
        ],
        navigator: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };

    useEffect(() => {
        setDetailOptions(detailChart);
        setMasterOptions(masterChart);
    }, [filterBegin, filterEnd, chartData]);

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={detailOptions} />
            {/* <HighchartsReact highcharts={Highcharts} options={masterOptions} /> */}
        </div>
    );
}
