import React, { useEffect, useContext, useState } from "react";
import Box from "@mui/material/Box";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import SensDcService from "../SensDcService";
import Alerts from "../../smartAgriculture/table/alerts/Alerts";

const SensDcAlerts = (props) => {
    const appCtx = useContext(AppContext);
    const setting = useContext(SettingContext);
    // ctx.updateTitle("SensDC");

    const [things, setThings] = useState([]);

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.SENS_DC,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });
        appCtx.updateTitle(ApplicationData.find((a) => a.id == AppId.SENS_DC).title);

        SensDcService.requestCluster(setCluster);
    }, []);

    const setCluster = (clusters) => {
        let tempThings = [];
        clusters.forEach((c) => {
            c.things.forEach((t) => {
                tempThings.push({ thingId: t.id });
            });
        });

        setThings(tempThings);
    };

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2 }}>
            <Alerts things={things}></Alerts>
        </Box>
    );
};

export default SensDcAlerts;
