import ApiService from "Services/apiService";

const RobotService = {
    requestSetting: async (companyId) => {
        let options = {
            url: `/v2/robot/${companyId}/company`,
        };
        // console.log(options);

        const result = await ApiService.getData(options);
        // console.log(result);

        return result;
    },

    saveSettinig: async (companyId, updatedData) => {
        if (!companyId) return;

        let options = {
            url: "/v2/robot",
            data: {
                companyId: companyId,
                name: "SETTING",
                value: JSON.stringify(updatedData),
            },
        };
        // console.log(options);

        return ApiService.putData(options)
            .then((r) => {
                // console.log(r);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default RobotService;
