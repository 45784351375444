import React, { useState, useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import { Box, Grid, Alert } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { BootstrapDialog, BootstrapDialogTitle } from "Components/ui/BootstrapDialog";
import DialogContent from "@mui/material/DialogContent";
import { CustomButton } from "Components/ui/CustomButton";
import GatewayService from "./GatewayService";
import moment from "moment";
import UserService from "Services/userService";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import validator from "validator";

export default function CreateGatewayDialog({ open, setOpen, setReload }) {
    const { t } = useTranslation();

    const [devEui, setDevEui] = useState("");
    const [rfRegion, setRfRegion] = useState("");
    const [description, setDescription] = useState("");
    const [company, setCompany] = useState("");
    const [modelId, setModelId] = useState("");
    const [selectedMfr, setSelectedMfr] = useState("");
    const [serialNumber, setSerialNumber] = useState("");

    const [loading, setLoading] = useState(false);
    const [errDevEui, setErrDevEui] = useState(false);
    const [errRfRegion, setErrRfRegion] = useState(false);
    const [manufacturers, setManufacturers] = useState([]);
    const [models, setModels] = useState([]);
    const [errSerialNumber, setErrSerialNumber] = useState(false);

    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        UserService.getAuthenticatedUser().then((u) => {
            setCompany(u.company);
        });

        GatewayService.findManufacturers().then((data) => setManufacturers(data));

        console.log("uef", selectedMfr);
        if (selectedMfr && selectedMfr !== "") {
            GatewayService.findModels(selectedMfr).then((data) => setModels(data));
        }
    }, [selectedMfr]);

    const handleClose = () => {
        setDevEui("");
        setRfRegion("");
        setDescription("");
        setSelectedMfr("");
        setModelId("");
        setSelectedMfr("");
        setSerialNumber("");
        setOpen(false);
        setLoading(false);
        setErrDevEui(false);
        setErrSerialNumber(false);
        setErrMsg("");
    };

    const handleSubmit = () => {
        let hasError = false;

        setErrMsg("");
        if (validator.isEmpty(devEui)) {
            setErrDevEui(true);
            hasError = true;
        } else {
            const isValidDevEUI = (devEui) => /^[0-9A-Fa-f]{16}$/.test(devEui);
            if (!isValidDevEUI(devEui)) {
                setErrDevEui(true);
                hasError = true;
            } else {
                setErrDevEui(false);
            }
        }

        if (validator.isEmpty(rfRegion)) {
            setErrRfRegion(true);
            hasError = true;
        } else {
            setErrRfRegion(false);
        }

        if (selectedMfr && manufacturers.find((m) => m.id === selectedMfr)?.name === "Milesight") {
            if (validator.isEmpty(serialNumber) || !validator.isHexadecimal(serialNumber)) {
                setErrSerialNumber(true);
                hasError = true;
            } else {
                setErrSerialNumber(false);
            }
        }

        if (hasError) {
            return;
        }

        setLoading(true);
        GatewayService.createGateway(devEui, company, rfRegion, description, modelId, serialNumber)
            .then((r) => {
                setReload(moment().unix());
                setLoading(false);
                handleClose();
            })
            .catch((e) => {
                console.log(e.message);
                if (e.message.includes("rps_record_already_exists")) {
                    setErrMsg(t("de.gw_exist"));
                } else if (e.message.includes("device_data_not_found")) {
                    setErrMsg(t("de.gw_not_found"));
                } else if (e.message.includes("Unexpected end of JSON input")) {
                    // Server returns empty JSON when ConflictException is thrown or oher reasons
                    // Check server logs for more details
                    setErrMsg(t("de.gw_unexpected"));
                } else {
                    setErrMsg(e.message);
                }
                setLoading(false);
            });
    };

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {t("de.gw_new")}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={{ pt: 2, pl: 5, pr: 5, pb: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="lbl-manufacturer">{t("catalog.mfr")}</InputLabel>
                                    <Select
                                        labelId="sel-manufacturer"
                                        id="select-manufacturer"
                                        value={selectedMfr}
                                        label="Manufacturer"
                                        onChange={(e) => setSelectedMfr(e.target.value)}
                                        variant="standard"
                                        disabled={loading}
                                    >
                                        {manufacturers.map((m) => (
                                            <MenuItem key={m.id} value={m.id}>
                                                {m.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="lbl-model">{t("catalog.model")}</InputLabel>
                                    <Select
                                        labelId="sel-model"
                                        id="select-model"
                                        value={modelId}
                                        label="Model"
                                        onChange={(e) => setModelId(e.target.value)}
                                        variant="standard"
                                        disabled={loading}
                                    >
                                        {models &&
                                            models !== "" &&
                                            models.map((m) => (
                                                <MenuItem key={m.id} value={m.id}>
                                                    {m.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {selectedMfr && manufacturers.find((m) => m.id === selectedMfr)?.name === "Milesight" && (
                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        label="Serial Number"
                                        fullWidth
                                        required
                                        disabled={loading}
                                        value={serialNumber}
                                        onChange={(e) => setSerialNumber(e.target.value)}
                                        helperText={errSerialNumber ? t("de.enter_valid_hexa") : t("de.enter_sn")}
                                        error={errSerialNumber}
                                        inputProps={{ maxLength: 32 }}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={6}>
                                <TextField
                                    variant="standard"
                                    label="DEVEUI"
                                    fullWidth
                                    required
                                    disabled={loading}
                                    value={devEui}
                                    onChange={(e) => setDevEui(e.target.value)}
                                    helperText={t("de.gw_enter")}
                                    error={errDevEui}
                                    inputProps={{ maxLength: 16 }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="lbl-rf-region">RF Region</InputLabel>
                                    <Select
                                        labelId="sel-rf-region"
                                        id="demo-simple-select"
                                        value={rfRegion}
                                        label="RF Region"
                                        onChange={(e) => setRfRegion(e.target.value)}
                                        variant="standard"
                                        disabled={loading}
                                        error={errRfRegion}
                                    >
                                        <MenuItem value={"EU868"}>EU868</MenuItem>
                                        <MenuItem value={"US915"}>US915</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    label="Description"
                                    fullWidth
                                    disabled={loading}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    rows={2}
                                    multiline
                                />
                            </Grid>
                            {errMsg && (
                                <Grid item xs={12}>
                                    <Alert severity="error">{errMsg}</Alert>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <CustomButton name={t("btn.add")} callback={handleSubmit} />
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
