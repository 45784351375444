import React, { useState, useEffect } from "react";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import Item from "../../../../../components/ui/Item";
import Box from "@mui/material/Box";
import { CustomButton } from "../../../../../components/ui/CustomButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SiteService from "./SiteService";
import SiteEntity from "./SiteEntity";
import UserService from "Services/userService";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function SiteList({ selectedThingId, callbackDetail, reload, setReload, callbackNew, handleCompleteSelected }) {
    const [rows, setRows] = useState([]);
    const [thing, setThing] = useState();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        // console.log("useEffect in SiteList");
        // console.log("selectedThingId:" + selectedThingId);

        UserService.getAuthenticatedUser().then((u) => {
            SiteService.list(u.company, setRows);
        });
    }, [reload]);

    const handleNew = () => {
        callbackNew();
    };

    const handleSelect = (e) => {
        // console.log(e);
        SiteService.assign(selectedThingId, e.id).then(() => handleCompleteSelected());
    };

    const handleDetail = (e) => {
        callbackDetail(e);
    };

    return (
        <Box sx={{ height: "100%" }}>
            <Grid container spacing={0} sx={{ height: "100%" }}>
                <Grid item xs={12} sx={{ height: "70px", display: "flex" }}>
                    <Item>
                        <CustomButton startIcon={<AddCircleOutlineIcon />} name={"New"} callback={handleNew} />
                    </Item>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", height: "400px", flexDirection: "column", overflow: "auto" }}>
                    <Item>
                        {rows.map((r) => {
                            return <SiteEntity key={r.id} entity={r} callbackSelect={(e) => handleSelect(e)} callbackDetail={(e) => handleDetail(e)} />;
                        })}
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}
