import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import style from "./style.module.css";
import UtilService from "Services/utilService";
import ThingListDialog from "./associate/ThingListDialog";
import ThingAttributeService from "./associate/ThingAttributeService";
import Badge from "@mui/material/Badge";
import { useHistory } from "react-router-dom";

export default function GeneralCard({ wid, title, alert, fullwidth, callback, reload, setReload }) {
    const history = useHistory();
    const [open, setOpen] = useState(false);

    const [thing, setThing] = useState();
    const [attribute, setAttribute] = useState();
    const [lastMeasure, setLastMeasure] = useState();
    const [countAlerts, setCountAlerts] = useState(0);
    const [url, setUrl] = useState();

    const handleClick = (type) => {
        // console.log("handleClick::::" + type);
        if (type == "body") {
            // console.log(url);
            if (url) {
                // console.log(url + "/no");
                history.push(url + "/no");
            }
        } else if (type == "alert") {
            if (url) {
                // console.log(url + "/scroll");
                history.push(url + "/scroll");
            }
        } else {
            callback(type, thing, attribute);
        }
    };

    useEffect(() => {
        // console.log("uef in GeneralCard");
        // console.log(wid);

        // get thing and attribute using  wid
        if (wid) {
            ThingAttributeService.requestLastMeasure(wid, setThing, setAttribute, setLastMeasure, setCountAlerts).then((r) => {
                if (r && !r.message) {
                    const thingId = r.thing.id;
                    const thingName = r.thing.name;
                    const attributeId = r.attribute.id;
                    const attributeName = r.attribute.name;
                    const clusterName = "_";
                    const unit = r.attribute.unit;
                    const fixedName = r.thing.fixedName;
                    const tabIndex = 0;

                    let url = encodeURI(
                        `${process.env.PUBLIC_URL}/agriculture_water/detail/${thingId}/${thingName}/${attributeId}/${attributeName}/${clusterName}/${fixedName}/${tabIndex}`
                    );
                    setUrl(url);
                }
            });
        }
    }, [reload]);

    return (
        <Badge color="secondary" badgeContent={countAlerts} max={999}>
            <Box
                sx={{
                    position: "relative",
                    width: fullwidth ? 170 : 140,
                    height: 100 + (alert ? 10 : 0),
                    backgroundColor: "#0190B1",
                    "&:hover": {
                        backgroundColor: "primary.main",
                        opacity: [0.9, 0.8, 0.7],
                    },
                    color: "white",
                }}
                borderRadius="3px"
            >
                <div className={style.card} style={{ paddingTop: 2 }}>
                    <div className={style.card} style={{ position: "absolute", left: "2px", top: "2px" }}>
                        <IconButton size="small" style={{ color: "#FFF" }} onClick={() => handleClick("threshold")}>
                            <i className="fa fa-cog" aria-hidden="true"></i>
                        </IconButton>
                    </div>
                    <div className={style.card} style={{ display: "flex", flexGrow: 1, textAlign: "center", marginTop: "6px" }}>
                        <Typography variant="subtitle1" sx={{ wordWrap: "break-word", lineHeight: "1em", cursor: "pointer" }} onClick={() => setOpen(true)}>
                            {title}
                        </Typography>
                    </div>
                </div>

                <div className={style.card} style={{ marginTop: 5, marginBottom: 5 }} onClick={() => handleClick("body")}>
                    <div className={style.card} style={{ width: "100%" }}>
                        <Typography variant="h6">{lastMeasure && lastMeasure.value}</Typography>
                        <Typography variant="body">{lastMeasure && lastMeasure.attribute.unit}</Typography>
                    </div>
                </div>
                <div className={style.card}>
                    <div className={style.card} style={{ width: "100%" }}>
                        <Typography variant="subtitle1">{lastMeasure && UtilService.formateHMA(lastMeasure.timestamp * 1000)}</Typography>
                    </div>
                </div>
                <ThingListDialog open={open} setOpen={setOpen} wid={wid} setReload={setReload} />
            </Box>
        </Badge>
    );
}
