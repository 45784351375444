import { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import ApiService from "Services/apiService";
import _ from "underscore";
import SettingContext from "Providers/SettingContext";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/ui/BootstrapDialog";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";

export default function Confirm({ selectionModel, callback, open, setOpen }) {
    const { t, i18n } = useTranslation();
    const [disableButtons, setDisableButtons] = useState(false);
    const setting = useContext(SettingContext);
    const [isLoading, setLoading] = useState(false); // State to manage loading state
    const [delOption, setDelOption] = useState("INVISIBLE");

    useEffect(() => {
        // console.log("useEffect in Confirm")
        // console.log(selectionModel)
        setDisableButtons(false);
    }, [isLoading]);

    const closeModal = (e) => {
        setOpen(false);
        // callback();
    };

    const handleDelete = () => {
        let totalRequest = selectionModel.length;

        if (selectionModel.length > 0) {
            setDisableButtons(true);
            setLoading(true);

            // console.log("selectionModel...");
            // console.log(selectionModel);
            let allWaitRequest = deleteThings(selectionModel, delOption);

            Promise.all(allWaitRequest)
                .then((result) => {
                    _.each(result, (r) => {
                        if (r == "ok") {
                            totalRequest -= 1;
                        }
                    });

                    closeModal();
                    callback();

                    // If this request back to the server with no time delay, the server will return old data.
                    // The MeasurementView will request again when the setting change
                    setTimeout(() => {
                        if (totalRequest == 0) {
                            // delete all rows that we requested
                            setting.setGlobalSetting({
                                notifyStateChange: "deleted",
                            });
                        }
                        setLoading(false);
                    }, 1000);
                })
                .catch((error) => {
                    console.error("Error deleting things:", error);
                    setLoading(false); // Ensure loading state is reset even on error
                });
        }
    };

    return (
        <BootstrapDialog onClose={closeModal} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={closeModal}>
                {t('wm.remove_meter')}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                    <Typography gutterBottom>{selectionModel && selectionModel.length == 0 && t("de.delete_select")}</Typography>
                    <Typography gutterBottom>{selectionModel && selectionModel.length > 0 && t("de.delete_confirm", { count: selectionModel.length })}</Typography>

                    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={delOption}
                                name="radio-buttons-group"
                                onChange={(e) => setDelOption(e.target.value)}
                            >
                                <FormControlLabel value={"INVISIBLE"} control={<Radio />} label={t("de.delete_opt_1")} />
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <FormControlLabel value={"DEL_ALL"} control={<Radio />} label={t("de.delete_opt_2")} />
                                    <Typography sx={{ color: "red", mt: 1, ml: 4 }}>{t("de.delete_opt_3")}</Typography>
                                </Box>
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </DialogContent>
            <DialogActions>
                {selectionModel && selectionModel.length > 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            backgroundColor: "#5694B5",
                            color: "#FFFFFF",
                            textTransform: "none",
                            cursor: isLoading ? "wait" : "pointer",
                        }}
                        onClick={handleDelete}
                        disabled={disableButtons}
                    >
                        Yes
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "10px", backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }}
                    onClick={closeModal}
                    disabled={disableButtons}
                >
                    No
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

function deleteThings(thingIds, delOption) {
    // Determine if force should be applied based on delOption
    const force = delOption === "DEL_ALL";

    // Map through thingIds and create an array of promises
    return thingIds.map((thingId) => {
        const requestOptions = {
            url: `/api/things/${thingId}?force=${force}`,
        };

        return ApiService.deleteMultipleData(requestOptions);
    });
}

