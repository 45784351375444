import ApiService from 'Services/apiService';
import UtilService from 'Services/utilService';

const SensDcService = {

    requestCluster: async (setCluster) => {

        let requestOptions = {
            url: '/api/cluster/all/SENS_DC'
        };

        ApiService.getData(requestOptions)
            .then((response) => {

                let remap = response.map(c => {
                    c.things = c.things.map(t => {

                        let eleLow = '';
                        let eleHigh = '';

                        if (t.id == '2c4d25c5-4d09-408b-b36b-893df24b77d5') { // atim 5
                            eleLow = t.customFields.find(c => c.name == "TH_LOW;humidity");
                            eleHigh = t.customFields.find(c => c.name == "TH_HIGH;humidity");
                        } else {
                            eleLow = t.customFields.find(c => c.name == "TH_LOW;temperature");
                            eleHigh = t.customFields.find(c => c.name == "TH_HIGH;temperature");
                        }

                        t['threshold'] = {};
                        if (eleLow != undefined) {
                            t.threshold['low'] = {
                                celsius: parseFloat(eleLow.value),
                                name: 'ALERT LOW',
                                fahrenheit: UtilService.toFahrenheit(eleLow.value, 0),
                                value: parseFloat(eleLow.value)
                            };
                        } else {
                            t.threshold['low'] = {
                                celsius: '',
                                name: 'ALERT LOW',
                                fahrenheit: '',
                                value: ''
                            };
                        }

                        if (eleHigh != undefined) {
                            t.threshold['high'] = {
                                celsius: parseFloat(eleHigh.value),
                                name: 'ALERT HIGH',
                                fahrenheit: UtilService.toFahrenheit(eleHigh.value, 0),
                                value: parseFloat(eleHigh.value)
                            };
                        } else {
                            // default values for UI
                            t.threshold['high'] = {
                                celsius: '',
                                name: 'ALERT HIGH',
                                fahrenheit: '',
                                value: ''
                            };
                        }

                        return t
                    });

                    return c;
                });

                // console.log("remap...");
                // console.log(remap);
                setCluster(remap);
            }).catch((e) => console.error(e));
    }
}

export default SensDcService;