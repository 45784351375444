import _ from "underscore";

export const PRODUCT_HOTDROP = "921474ed-df84-4f93-8a53-ea6ba8a7349e";

export const ATTRIBUTE_ID_AMP = "0941b1c2-442a-4619-a14d-5a381b50b42f";

export const ATTRIBUTE_AMP_CONSUMPTION = "188ec171-f414-407a-98f6-d03c5ea47224";

const DetailService = {};

export default DetailService;
