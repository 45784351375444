import { useEffect, useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import GrowthService from './GrowthService';
import UtilService from 'Services/utilService'
import GrowthDialog from './GrowthDialog';
import GrowthChart from './GrowthChart';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CropService from '../crop/CropService';
import Button from '@mui/material/Button';
import CropComboBox from '../crop/CropComboBox';
import { styled } from '@mui/material/styles';
import AlertDialog from './AlertDialog';
import AppContext from 'Components/AppContext'
import SettingContext from 'Providers/SettingContext';
import { AppId } from 'Components/applications/ApplicationData';
import CropDialog from './NewCropDialog';
import Typography from '@mui/material/Typography';
import UserConfirm from './UserConfirm';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
}));


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    // backButton: {
    //     marginRight: theme.spacing(1),
    // },
    // instructions: {
    //     marginTop: theme.spacing(1),
    //     marginBottom: theme.spacing(1),
    // },
}));


export default function Growth(props) {


    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isAlertDialoge, setIsAlertDialoge] = useState(false);

    const [asset, setAsset] = useState([]);

    const [selectedStep, setSelectedStep] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [crop, setCrop] = useState();

    const [selectedCropId, setSelectedCropId] = useState();
    const [selectedAssetId, setSelectedAssetId] = useState();

    const [openDialog, setOpenDialog] = useState(false);
    const [isUserConfirmDialoge, setIsUserConfirmDialoge] = useState(false);

    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    ctx.updateTitle("Smart Agriculture");


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleStep = (idx, assetId, growthId) => {
        // console.log("activeStep=" + activeStep + ", idx=" + idx);

        if (selectedAssetId == undefined) {
            setIsAlertDialoge(true);
            return;
        }

        if (growthId != -1) {
            return;
        }

        setSelectedStep(idx);
        setIsOpenModal(true);
    }

    const receiveRows = (rows) => {
        setRows(rows);
    }

    const selectCluster = (assetId) => {
        setSelectedAssetId(assetId);

        if (!UtilService.isEmpty(crop) && !UtilService.isEmpty(assetId)) {
            // console.log("selectCluster...")
            GrowthService.requestAsset(crop.id, assetId, setAsset, setActiveStep, setChartData);
        }
    }

    const handleClose = () => {
        setIsOpenModal(false)
        if (!UtilService.isEmpty(crop) && !UtilService.isEmpty(selectedAssetId)) {
            // console.log("handleClose...")
            GrowthService.requestAsset(crop.id, selectedAssetId, setAsset, setActiveStep, setChartData);
        }
    }

    const handleStopCrop = () => {
        setIsUserConfirmDialoge(true);
    }

    const handleNewCrop = () => {
        setOpenDialog(true);
    }

    const closeDialog = () => {
        setOpenDialog(false);
        loadData();
    }

    const callbackUserConfirm = (yesNo) => {
        setIsUserConfirmDialoge(false);

        CropService.updateCrop(crop.id, loadData);
    }

    const loadData = () => {
        CropService.requestActiveFinishedCrop(setCrop, setSelectedCropId)
            .then((cropGrowthId) => {
                if (cropGrowthId) {
                    GrowthService.requestAsset(cropGrowthId, selectedAssetId, setSelectedAssetId, setAsset, setActiveStep, setChartData);
                }
            });
    }

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.SMART_AGRICULTURE,
            temperatureMonitoring: {
                celsius: true,
                language: "en"
            }
        });

        loadData();

    }, [isOpenModal]);

    return (
        <Box sx={{ width: '100%', mt: 15, ml: 4, mr: 4 }}>
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={2}>
                    {/* <Item> */}
                    <CropComboBox selectCluster={selectCluster}
                        disabled={crop != undefined && crop.status === 'FINISHED' ? true : false}
                        default={true} >
                    </CropComboBox>
                    {/* </Item> */}
                </Grid>
                <Grid item xs={8} textAlign="center">
                    {/* <Item> */}
                    <Typography variant="h4" component="h1">
                        {crop != undefined && crop.name}
                    </Typography>
                    {/* </Item> */}
                </Grid>
                <Grid item xs={2}>
                    {/* <Item style={{ textAlign: 'right' }}> */}
                    {crop != undefined && crop.stauts === 'ACTIVE' &&
                        <Button variant="contained" onClick={() => handleStopCrop()} style={{ marginRight: '10px' }}>Stop</Button>
                    }
                    <Button variant="contained" onClick={() => handleNewCrop()}>New Crop</Button>
                    {/* </Item> */}
                </Grid>

                <Grid item xs={12} sx={{ mt: 5 }}>
                    <Item>
                        <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                            {asset.map((label) => {
                                return (
                                    <Step key={label.growthId} disabled>
                                        {label.growthDate &&
                                            // <StepLabel  >
                                            <StepButton color="inherit" onClick={(e) => handleStep(label.idx, label.assetId, label.growthId)}
                                                disabled={crop != undefined && crop.status === 'FINISHED' ? true : false}>
                                                {UtilService.formatYearMonthDay(label.growthDate * 1000)}
                                                <br></br>
                                                {'Duration: ' + label.duration + 'd'}
                                            </StepButton>
                                            // </StepLabel>
                                        }
                                        {!label.growthDate &&
                                            // <StepLabel  >
                                            <StepButton color="inherit" onClick={(e) => handleStep(label.idx, label.assetId, -1)}
                                                disabled={crop != undefined && crop.status === 'FINISHED' ? true : false}>
                                                {'Start'}
                                            </StepButton>
                                            // </StepLabel>
                                        }
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <GrowthChart chartData={chartData} unit={'Unit'} />
                    </Item>
                </Grid>
            </Grid>


            <AlertDialog isAlertDialoge={isAlertDialoge} close={() => setIsAlertDialoge(false)}></AlertDialog>
            <GrowthDialog open={isOpenModal} onClose={() => handleClose()}
                selectedStep={selectedStep} selectedCropId={selectedCropId} selectedAssetId={selectedAssetId}>\
            </GrowthDialog>
            <CropDialog open={openDialog} close={closeDialog}></CropDialog>
            <UserConfirm open={isUserConfirmDialoge} close={callbackUserConfirm}></UserConfirm>
        </Box >
    );
}