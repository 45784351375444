import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Item from "../ui/Item";
import { useTranslation } from "react-i18next";
import _ from "underscore";
import SubscriptionService from "./SubscriptionService";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function CreateSubscriptionDialog({ open, setOpen, callback, company, selectedCompanyId, appId }) {
    const { t, i18n } = useTranslation();

    const [start, setStart] = useState(dayjs());
    const [end, setEnd] = useState(dayjs().add(1, "year"));

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [message, setmessage] = useState("");

    const handleClose = () => {
        // setName("");

        setOpen(false);
    };

    const handleSubmit = () => {
        // console.log("handleSubmit...");
        SubscriptionService.addSubscription(selectedCompanyId, appId, start.unix() * 1000, end.unix() * 1000)
            .then(() => {
                callback();
                handleClose();
            })
            .catch((e) => {
                setmessage(e);
                setOpenSnackBar(true);
            });
    };

    useEffect(() => {
        // console.log("useEffect...in CreateCompanyDialog");
        // console.log(company);
    }, [company]);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="xs" fullWidth>
                <Stack direction={"row"}>
                    <Item elevation={0}>
                        <DialogTitle id="alert-dialog-title">{t("tm.add_subscription")}</DialogTitle>
                    </Item>
                    <Item sx={{ display: "flex", flexDirection: "row-reverse", flexGrow: 1 }} elevation={0}>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 0,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Item>
                </Stack>

                <Box sx={{ pl: 2, pr: 2 }}>
                    <Grid container spacing={0} sx={{ p: 0, m: 0 }}>
                        <Grid item xs={12} sx={{ textAlign: "center", width: "100%", mb: 2 }}>
                            {t("tm.select_period")} {company}
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Item>{t("label.start")}</Item>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Item>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker label="" value={start} onChange={(newValue) => setStart(newValue)} maxDate={end} />
                                </LocalizationProvider>
                            </Item>
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Item>{t("label.end")}</Item>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Item>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker label="" value={end} onChange={(newValue) => setEnd(newValue)} minDate={start} />
                                </LocalizationProvider>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>

                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }} onClick={handleSubmit}>
                        {t("btn.add")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={() => setOpenSnackBar(false)}>
                <Alert onClose={() => setOpenSnackBar(false)} severity="warning" variant="filled" sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}
