import React from "react";
import { Box } from "@mui/material";
import ChartGeneral from "./ChartGeneral";
import { useParams } from "react-router-dom";

export default function ChartGeneralMain() {
    const { thingId, attributeId } = useParams();

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <ChartGeneral thingId={thingId} attributeId={attributeId} />
        </Box>
    );
}
