import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UserService from "Services/userService";
import SiteService from "Components/site/SiteService";

export default function SiteBox({ selectedSiteId, callback }) {
    const [value, setValue] = useState(0);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        // console.log("uef in SiteBox");

        UserService.getAuthenticatedUser().then((user) => {
            // console.log(user);
            SiteService.list(user.company, setRows).then((response) => {});
        });
    }, []);

    useEffect(() => {        
        setValue(selectedSiteId)
    }, [selectedSiteId]);

    const handleChange = (event) => {
        // setValue(event.target.value);
        callback(event.target.value);
        setValue(event.target.value);
    };

    return (
        <FormControl style={{ flexGrow: 1, width: "calc(100% - 20px)" }}>
            <Select
                id="site"
                label="Site"
                value={value}
                onChange={handleChange}
                sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                }}
                size="small"
            >
                <MenuItem key={0} value={0}>
                    All
                </MenuItem>
                {rows.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                        {row.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
