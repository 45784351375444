import { useEffect, useState, useCallback, useContext, createRef } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import AlertDialog from "../../../../components/ui/AlertDialog";
import _ from "underscore";
import UtilService from "../../../../services/utilService";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Item from "../../../../components/ui/Item";
import { Box, Stack } from "@mui/material";

const headCells = [];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox inputProps={{ "aria-label": "select all desserts" }} />
                </TableCell>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id && headCell.id != "tag"}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={headCell.id === "tag"}
                            size="small"
                            style={{ fontWeight: 600, color: "#9B9B9B" }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    // numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        // marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 550,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

function createData(id, creationDate, level, message, fixedName, comment, lastUpdate, state, username) {
    return {
        id,
        creationDate: UtilService.formateDate(creationDate * 1000),
        level,
        message,
        fixedName,
        comment,
        lastUpdate: UtilService.formateDate(lastUpdate * 1000),
        state,
        username,
    };
}

const initializeColumn = () => {
    if (headCells.length > 0) {
        return;
    }

    headCells.push({ id: "creationDate", numeric: false, disablePadding: false, label: "Creation Date" });

    headCells.push({ id: "level", numeric: false, disablePadding: false, label: "Level" });

    headCells.push({ id: "message", numeric: false, disablePadding: false, label: "Message" });

    // if (opt.thingAlert !== undefined && opt.thingAlert === "on")
    headCells.push({ id: "fixedName", numeric: false, disablePadding: false, label: "Thing" });

    // if (opt.commentAlert !== undefined && opt.commentAlert === "on")
    headCells.push({ id: "comment", numeric: false, disablePadding: false, label: "Comment" });

    // if (opt.lastUpdateAlert !== undefined && opt.lastUpdateAlert === "on")
    headCells.push({ id: "lastUpdate", numeric: false, disablePadding: false, label: "Last update" });

    // if (opt.stateAlert !== undefined && opt.stateAlert === "on")
    headCells.push({ id: "state", numeric: false, disablePadding: false, label: "State" });

    // if (opt.usernameAlert !== undefined && opt.usernameAlert === "on")
    headCells.push({ id: "username", numeric: false, disablePadding: false, label: "User name" });
};

export default function WaterCounter(props) {
    const { t, i18n } = useTranslation();

    const conf = props.config;

    const classes = useStyles();
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("thing");
    const [selected, setSelected] = useState([]);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rowCount, setRowCount] = useState(0);

    const [isOpenModal, setIsOpenModal] = useState(false);

    initializeColumn();

    const handleRequestSort = (event, property) => {
        if (property === "tag")
            // The tag column is not sorted.
            return;

        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);

        // initialize sort options
        setPage(0);

        configureTable();
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        configureTable();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);

        configureTable();
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handlerIgnore = (e) => {
        // console.log("handlerIgnore...");
        setIsOpenModal(true);
    };

    const handlerInprogress = (e) => {
        // console.log("handlerInprogress...");
        setIsOpenModal(true);
    };

    const handleValidate = (comment) => {
        // console.log(`handleValidate: ${comment}`);
        // todo: save comment
    };

    /**
     * The api call is called only when the widget has been rendered.
     * It can be changed in the future.
     */
    useEffect(() => {
        configureTable();
    }, []);

    const configureTable = () => {
        // props.getValues()
        // 	.then((values) => {

        let receivedData = [
            createData("ec65723c-2a57-4554-ba58-cd46a115ec89", 1619619473, "WARNING", "Leak", "Water Sensor", null, null, "OPENED", "Admin"),
            createData("70595479-2984-49f5-9a7e-1bf266186d61", 1619615874, "WARNING", "Revert water", "Water Sensor", null, null, "OPENED", "Admin"),
            createData("3d4d64db-177b-4c4a-b63d-97ab4d132336", 1619612278, "WARNING", "Leak", "Water Sensor", null, null, "OPENED", "Admin"),
            createData("74127249-9689-4ff2-82d3-2479717978d8", 1619608674, "WARNING", "Leak", "Water Sensor", null, null, "OPENED", "Admin"),
            createData("556559bf-2523-401a-bc56-9204d63347c9", 1619605074, "WARNING", "Leak", "Water Sensor", null, null, "OPENED", "Admin"),
            createData("8a3f45b6-4031-46b7-b4a2-0f8fb2a2b5f0", 1619601474, "WARNING", "Leak", "Water Sensor", null, null, "OPENED", "Admin"),
            createData("219e99ef-285c-4f0e-b827-65cffe9198da", 1619597874, "WARNING", "Leak", "Water Sensor", null, null, "OPENED", "Admin"),
            createData("960f47b0-3043-4695-a693-ec44d5c0c544", 1619594274, "WARNING", "Leak", "Water Sensor", null, null, "OPENED", "Admin"),
            createData("848c651f-b96b-492a-8ff6-742461867cc5", 1619590680, "WARNING", "Leak", "Water Sensor", null, null, "OPENED", "Admin"),
            createData("2cf46880-f4b8-4811-89c3-f550adb1dfd7", 1619587074, "WARNING", "Leak", "Water Sensor", null, null, "OPENED", "Admin"),
        ];

        setRowCount(receivedData.length);
        setRows(receivedData); // set data for the table
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Item sx={{ display: "flex", justifyContent: "flex-start", pl: 2 }}>
                        <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{t("w_mgt.anomaly_detection")}</span>
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Stack direction={"row"} spacing={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "unset", backgroundColor: "rgb(148,148,149)" }}
                                onClick={(e) => handlerIgnore(e)}
                            >
                                Ignore
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "unset", backgroundColor: "rgb(251,192,45)" }}
                                onClick={(e) => handlerInprogress(e)}
                            >
                                In progress
                            </Button>
                        </Stack>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table" style={{ tableLayout: "fixed" }}>
                            <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />
                            <TableBody>{getRows(rows, rowsPerPage, page, order, orderBy, classes, isSelected, handleClick)}</TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        className="flex-shrink-0"
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={-1}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        rowCount={rowCount}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            "& .MuiTablePagination-selectLabel": {
                                alignSelf: "flex-end",
                            },
                            "& .MuiTablePagination-displayedRows": {
                                alignSelf: "flex-end",
                            },
                        }}
                    />
                </Grid>

                <AlertDialog onClose={() => setIsOpenModal(false)} title="Add a comment to" label="Comment" changeValues={handleValidate} open={isOpenModal} />
            </Grid>
        </Box>
    );
}

/**
 * Return the row jsx components
 * @param {Object} rows
 * @param {Integer} rowsPerPage
 * @param {Integer} page
 * @param {Integer} order
 * @param {Integer} orderBy
 */
const getRows = (rows = [], rowsPerPage, page, order, orderBy, classes, isSelected, handleClick) => {
    return stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            let labelStyle = style.default;
            switch (row.level) {
                case "WARNING":
                    labelStyle = style.warning;
                    break;
                case "CRITICAL":
                    labelStyle = style.critical;
                    break;
                default:
                    break;
            }

            let stateStyle = style.default;
            switch (row.state.toUpperCase()) {
                case "OPENED":
                    stateStyle = style.critical;
                    break;
                case "RESOLVED":
                    stateStyle = style.success;
                    break;
                default:
                    break;
            }

            var rowsEle = headCells.map((cell) => {
                switch (cell.id) {
                    case "creationDate":
                        return <TableCell align="left">{row.creationDate}</TableCell>;
                    case "level":
                        return (
                            <TableCell align="left">
                                <span className={style.label + " " + labelStyle}>{row.level}</span>
                            </TableCell>
                        );
                    case "message":
                        return <TableCell align="left">{row.message}</TableCell>;
                    case "fixedName":
                        return <TableCell align="left">{row.fixedName}</TableCell>;
                    case "comment":
                        return <TableCell align="left">{row.comment}</TableCell>;
                    case "lastUpdate":
                        return <TableCell align="left">{row.lastUpdate}</TableCell>;
                    case "state":
                        return (
                            <TableCell align="left">
                                <span className={style.label + " " + stateStyle}>{row.state}</span>
                            </TableCell>
                        );
                    case "username":
                        return <TableCell align="left">{row.username}</TableCell>;
                    default:
                        break;
                }
            });

            rowsEle.unshift(
                <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                </TableCell>
            );

            return (
                <TableRow hover onClick={(event) => handleClick(event, row.id)}>
                    {rowsEle}
                </TableRow>
            );
        });
};
