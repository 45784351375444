import React, { useState, useEffect, useContext } from 'react';
import ImgSiege from 'Assets/images/floor_siege.jpg'
import ImgAgence from 'Assets/images/floor_agence.jpg'
import style from './style.module.css';
import { useParams } from "react-router-dom";
import AppContext from 'Components/AppContext'
import { useTranslation } from 'react-i18next';
import SettingContext from 'Providers/SettingContext';
import { AppId } from 'Components/applications/ApplicationData';
import CustomSensorIcon from './CustomSensorIcon';
import RoomConstants from '../RoomConstants';

export default function Floor() {

    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    ctx.updateTitle("Emera");

    const { t, i18n } = useTranslation();
    let { name } = useParams();

    const [data, setData] = useState();

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.EMERA,
            temperatureMonitoring: {
                celsius: true,
                language: "en"
            }
        });

        // console.log("name=" + name)

        if (name == 'Siege') {
            setData(RoomConstants.rowsA);
        } else if (name == 'Agence') {
            setData(RoomConstants.rowsB);
        }

    }, []);

    return (
        <div className="container-fluid" style={{ padding: '56px 0 0 0', height: '100vh' }}>
            <div style={{ marginTop: 100 }}></div>
            <div className={style.mapContainer} style={{ position: 'relative', zIndex: 1 }}>
                {name && name == 'Siege' &&
                    <img className={`${style.floor}`} src={ImgSiege} alt="Pilot Things" />
                }
                {name && name == 'Agence' &&
                    <img className={`${style.floor}`} src={ImgAgence} alt="Pilot Things" />
                }

                {name == 'Siege' &&
                    <div style={{ position: 'absolute', zIndex: 2 }}>
                        {/* Salle A */}
                        <CustomSensorIcon top={80} left={120} data={data ? data[0] : undefined} />

                        {/* Salle 1 */}
                        <CustomSensorIcon top={400} left={200} data={data ? data[1] : undefined} />

                        {/* Salle 2 */}
                        <CustomSensorIcon top={170} left={-330} data={data ? data[2] : undefined} />
                    </div>
                }

                {name == 'Agence' &&
                    <div style={{ position: 'absolute', zIndex: 2 }}>
                        {/* Salle 3 */}
                        <CustomSensorIcon top={80} left={340} data={data ? data[0] : undefined} />

                        {/* Salle 4 */}
                        <CustomSensorIcon top={380} left={280} data={data ? data[1] : undefined} />
                    </div>
                }
            </div>
        </div>
    )
};
