import { useEffect, useState } from "react";
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import Tooltip from "@mui/material/Tooltip";

highchartsMore(Highcharts);
solidGauge(Highcharts);

export default function HalfCircleUI(props) {
    const [value, setValue] = useState(parseFloat(props.entity.value));
    const [unit, setUnit] = useState(props.entity.unit);
    const [date, setDate] = useState(props.entity.date);
    const [fixedName, setFixedName] = useState("");

    // const fixedName = props.entity.fixedName;
    // const [temps, setTemps] = useState({});

    useEffect(() => {
        if (props.entity) {
            setFixedName(props.entity.fixedName ? props.entity.fixedName : "");
        }
    }, []);

    const options = {
        chart: {
            type: "solidgauge",
            margin: [0, 0, 0, 0],
            height: 80,
            width: 80,
        },

        title: null,

        pane: {
            center: ["50%", "50%"],
            size: "100%",
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
                innerRadius: "60%",
                outerRadius: "100%",
                shape: "arc",
            },
        },

        exporting: {
            enabled: false,
        },

        tooltip: {
            enabled: false,
        },

        yAxis: {
            stops: [
                [0, "#A8D071"],
                [1, "#A8D071"],
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            labels: {
                y: 16,
                enabled: false,
            },
            min: 0,
            max: 100,
        },

        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true,
                },
            },
        },

        credits: {
            enabled: false,
        },

        series: [
            {
                data: [
                    {
                        y: value,
                    },
                ],
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
            },
        ],
    };

    return (
        <div className="container m-0 p-0" style={{ textAlign: "center", width: "100%", height: "80px", position: "relative" }}>
            <Tooltip title={fixedName}>
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                    <div style={{ position: "absolute" }}>
                        <HighchartsReact highcharts={Highcharts} options={options} />
                    </div>
                    <div style={{ position: "absolute", zIndex: "1" }}>
                        <span style={{ fontSize: "15px" }}>
                            {value} {unit}
                        </span>
                        <br></br>
                        <span style={{ fontSize: "12px" }}>{date}</span>
                    </div>
                </div>
            </Tooltip>
        </div>
    );
}
