import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { a11yProps, CustomTabPanel } from "Components/ui/CustomTabPanel";
import ScheduleToday from "./ScheduleToday";
import ScheduleTomorrow from "./ScheduleTomorrow";
import GraphMain from './graph/GraphMain'

export default function IrrigationMain() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ mt: 7, width: "100%", p: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Today" {...a11yProps(0)} sx={{ textTransform: "none" }} />
                    <Tab label="Tomorrow" {...a11yProps(1)} sx={{ textTransform: "none" }} />
                    <Tab label="Historical" {...a11yProps(2)} sx={{ textTransform: "none" }} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <ScheduleToday />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <ScheduleTomorrow />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <GraphMain />
            </CustomTabPanel>
        </Box>
    );
}
