import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import WidgetComposite from "./WidgetComposite";
import TablePagination from "@mui/material/TablePagination";

export default function WidgetViewMain({ rows, rowCount, paginationModel, setPaginationModel, user, callback }) {
    const [page, setPage] = useState(paginationModel.page);
    const [rowsPerPage, setRowsPerPage] = useState(paginationModel.pageSize);

    useEffect(() => {
        // console.log(rows);
    }, [rows, rowCount, paginationModel, user]);

    const handleChangePage = (event, newPage) => {
        // console.log("handleChangePage::::" + newPage);
        setPage(newPage);
        setPaginationModel({ page: newPage, pageSize: rowsPerPage });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

        setPaginationModel({ page: 0, pageSize: parseInt(event.target.value, 10) });
    };

    return (
        <Box sx={{ flexGrow: 1, display: "flex", width: "100%", flexWrap: "wrap" }}>
            {rows &&
                rows.map((r) => {
                    return <WidgetComposite ele={r.ele} key={r.id} user={user} callback={callback} />;
                })}

            <Box sx={{ flexGrow: 1, width: "100%" }}>
                <TablePagination
                    component="div"
                    count={rowCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[20, 50, 100]}
                />
            </Box>
        </Box>
    );
}
