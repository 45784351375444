import { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import RoomCard from './card/RoomCard';
import Box from '@mui/material/Box';
import RoomConstants from './RoomConstants';

// const useStyles = makeStyles({
//     table: {
//         minWidth: 650,
//     },
// });


export default function RoomContainer({ selectedItem }) {

    // const classes = useStyles();
    // const [value, setValue] = useState({});
    // const { t, i18n } = useTranslation();

    useEffect(() => {
        //do nothing
    }, [selectedItem]);

    return (
        <div>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {/* select the All */}
                {selectedItem == 0 && RoomConstants.rowsA.map((row) => (
                    drawRoomCard(row)
                ))
                }
                {selectedItem == 0 && RoomConstants.rowsB.map((row) => (
                    drawRoomCard(row)
                ))}

                {/* select the Siege */}
                {selectedItem == 1 && RoomConstants.rowsA.map((row) => (
                    drawRoomCard(row)
                ))}

                {/* select the Agence */}
                {selectedItem == 2 && RoomConstants.rowsB.map((row) => (
                    drawRoomCard(row)
                ))}
            </Box>
        </div>
    )

    function drawRoomCard(row) {
        return <RoomCard entity={row} key={row.floor + row.name} />
    }
};
