import React, { useEffect, useState } from "react";
import moment from "moment";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";

export default function TimezonesBox({ disabled, callback }) {
    const { t, i18n } = useTranslation();
    const [zones, setZones] = useState(moment.tz.names());
    const [selectedZone, setSelectedZone] = useState("America/Montreal");

    useEffect(() => {
        // console.log(zones);
    }, []);

    const handleItem = (e) => {
        // console.log(e.target.value);
        callback(e.target.value);
        setSelectedZone(e.target.value);
    };

    return (
        <FormControl variant="filled">
            {/* <InputLabel id="sel-timezone-label" sx={{ ml: 0, alignContent: 'left' }}>{t('login.zoneInfo')}</InputLabel> */}
            <InputLabel id="sel-timezone-label" sx={{ ml: 0, alignContent: "left" }}>
                {t("com.timezone")}
            </InputLabel>
            <Select
                id="combo-box-demo"
                variant="filled"
                sx={{ maxHeight: "200px", overflow: "none", width: "100%" }}
                MenuProps={{ PaperProps: { style: { maxHeight: "300px" } } }}
                value={selectedZone}
                onChange={handleItem}
                disabled={disabled}
            >
                {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
                {zones &&
                    zones.map((z) => {
                        return (
                            <MenuItem value={z} style={{ display: "flex" }} key={z}>
                                {z}
                            </MenuItem>
                        );
                    })}
                {/* </div> */}
            </Select>
        </FormControl>
    );
}
