import { useState, useEffect } from 'react'

import { GridStack } from 'gridstack';
// problem with the preact cli, this lib is not compatible. Or we need to exclude it from the bundling process
// import 'gridstack/dist/h5/gridstack-dd-native';
import 'gridstack/dist/gridstack.min.css';

import Base from 'Components/widgets/base'
import MeasurementWidget from 'Components/widgets/measurement';
import GridWidget from 'Components/widgets/grid';
import AlertsWidget from 'Components/widgets/alerts';

import ApiService from 'Services/apiService'

const refs = {}

const widgetTypes = {
    "Starter.view.dashboard.widgets.number.Container": MeasurementWidget,
    "Starter.view.dashboard.widgets.measurement.Container": GridWidget,
    "Starter.view.dashboard.widgets.alerts.Container": AlertsWidget
}

const DashboardView = (props) => {
    const [widgetsCmp, setWidgetsCmp] = useState([])
    let gridstack;

    useEffect(() => {
        if (window === undefined) return

        gridstack = GridStack.init({
            float: true,
            cellHeight: "70px",
            minRow: 1,
            staticGrid: true
        })

        setWidgetsCmp(generateWidgets(gridstack, props.widgets))

        return () => { gridstack.destroy(false) }
    }, [props.widgets])


    return <section className='grid-stack'>
        {widgetsCmp.length > 0 && widgetsCmp}
    </section>
}

const getRefs = () => refs

const generateWidgets = (gridstack, widgets = []) => {
    const refs = getRefs()

    // console.log('generate widgets')
    // console.log(widgets)

    return widgets && widgets
        .filter(widget => widgetTypes.hasOwnProperty(widget.type))
        .map(widget => {
            const WidgetType = widgetTypes[widget.type]

            return <Base
                ref={(ref) => makeWidget(gridstack, ref)}
                gs-x={widget.x}
                gs-y={widget.y}
                gs-w={widget.width}
                gs-h={widget.height}>

                <WidgetType
                    config={widget.configuration}
                    style={widget.style}
                    hasImage={widget.hasImage}
                    getValues={(extractor) => (
                        ApiService.getValues(
                            extractor(widget.configuration)
                        )
                    )}
                />
            </Base>
        })
}

const makeWidget = (gridstack, domElem) => {
    domElem && gridstack.makeWidget(domElem)
}

export default DashboardView