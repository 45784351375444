import _ from "underscore";
import ApiService from "Services/apiService";
import UtilService from "Services/utilService";
import moment from "moment";

const DetailService = {
    // It is one of Axioma's attribute which is generated by PTH system. not sensor
    ATTRIBUTE_ID_VOLUME_HOURLY: "0ed0d11a-e838-4e35-a0ad-cf334df5f49d",

    requestThing: async (thingId, setThing) => {
        if (thingId == undefined) {
            return;
        }

        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);
                setThing(response);
                return response;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestMeasurements: async (page, pageSize, setRows, thingId, sortQuery, selectedAttributeId, setRowCount) => {
        if (thingId == undefined) {
            return;
        }

        setRows([]); // reset the rows

        // let filter = `thing.id:eq_${thingId},measure.timestamp:gt_${moment().subtract(1, 'months').unix()}`;
        let filter = `thing.id:eq_${thingId}`;
        if (selectedAttributeId != "all") {
            filter += `,attribute.id:eq_${selectedAttributeId}`;
        }

        let sort = "&sort=measure.timestamp&dir=DESC";
        if (sortQuery) {
            sort = sortQuery;
        }

        let requestOptions = {
            url: `/api/measures?filter=${filter}&page=${page + 1}&pageSize=${pageSize}&detailed=true&${sort}`,
        };

        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                let data = [];
                let totalSize = response.totalSize;

                response.data.forEach((ele) => {
                    let obj = {
                        id: ele.id,
                        measurement: ele.attribute.name,
                        value: ele.value + " " + ele.attribute.unit,
                        timestamp: UtilService.formatMDYYHMS(ele.timestamp * 1000),
                    };

                    data.push(obj);
                });

                setRowCount(totalSize);
                setRows(data);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateName: async (thingId, newName, thingDetail, thingNameCallback) => {
        thingDetail["fixedName"] = newName;

        let requestOptions = {
            url: `/api/things/${thingId}`,
            data: thingDetail,
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                thingNameCallback(response.fixedName);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestLastGatewayStatus: async (thingId, setLastGatewayStatus) => {
        if (thingId == undefined) {
            return;
        }

        let requestOptions = {
            url: `/api/gateways/last/${thingId}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                setLastGatewayStatus(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestLastGps: async (thingId, setPosition, setGps) => {
        let requestOptions = {
            url: `/api/things/${thingId}/last_measurements`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                const gps = response.filter((a) => a.attribute.name == "gps");
                // console.log(gps);
                if (!_.isEmpty(gps)) {
                    // console.log("//-------------------");
                    // console.log(gps[0].value.split(","));

                    setPosition(gps[0].value.split(","));
                    setGps(gps[0].value.split(","));
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestMonthlyConsumption: async (thingId, setMonthlyConsumption, setTabIndex) => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const start = moment().tz(timezone).startOf("month");
        const end = moment().tz(timezone).add(1, "month").startOf("day");

        const filter = `filter=thing.id:eq_${thingId},attribute.id:eq_${DetailService.ATTRIBUTE_ID_VOLUME_HOURLY}`;
        const period = `&start=${moment(start).valueOf()}&end=${moment(end).valueOf()}`;

        let requestOptions = {
            url: `/stats/measurements/all?${filter}&interval=hours${period}&time=1`,
        };

        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                if (response.error || response.length == 0) {
                    setTabIndex(1);
                    setMonthlyConsumption(undefined);
                } else {
                    let firstStart = null;
                    let totalSum = 0;

                    for (const entity of response) {
                        totalSum += entity.value;
                        if (firstStart === null) {
                            firstStart = entity.start;
                        }
                    }

                    // console.log("firstStart=" + firstStart + ", totalSum=" + totalSum);

                    setMonthlyConsumption({
                        value: totalSum,
                        timestamp: firstStart / 1000,
                    });
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateFavoriteAttribute: async (star, cognitoUsername, selectedAttributeId, allAttributes, selectedThingId) => {
        let attributes = [];
        if (selectedAttributeId == "all") {
            allAttributes
                .filter((a) => a.id != "all")
                .forEach((a) => {
                    attributes.push(a.id);
                });
        } else {
            attributes.push(selectedAttributeId);
        }

        let requestOptions = {
            url: `/api/favorite/${star ? "delete" : "add"}`,
            data: {
                username: cognitoUsername,
                attributes: attributes,
                thingId: selectedThingId,
            },
        };

        // console.log(requestOptions)

        if (star) {
            return ApiService.deleteData(requestOptions)
                .then((r) => console.log(r))
                .catch((e) => console.error(e));
        } else {
            return ApiService.putData(requestOptions)
                .then((r) => console.log(r))
                .catch((e) => console.error(e));
        }
    },

    requestFavoriteAttribute: async (cognitoUsername, selectedThingId, selectedAttributeId, setStar) => {
        if (selectedAttributeId) {
            let requestOptions = {
                url: `/api/favorite/${cognitoUsername}/${selectedThingId}/${selectedAttributeId}/exist`,
            };

            // console.log(requestOptions)
            return ApiService.getData(requestOptions).then((r) => {
                // console.log(r);
                setStar(r.isExist);
            });
        } else {
            let requestOptions = {
                url: `/api/favorite/${cognitoUsername}/${selectedThingId}`,
            };

            // console.log(requestOptions)
            return ApiService.getData(requestOptions).then((r) => r);
        }
    },

    requestGatewayStatus: async (thingId) => {
        let requestOptions = {
            url: `/api/gateways/last/${thingId}`,
            // url: `/api/gateways/last/2bd20308-5ebc-44b5-b596-c8398d85af1e`,
        };

        // console.log(requestOptions)
        return ApiService.getData(requestOptions).then((response) => {
            // console.log(response);
            return response;
        });
    },

    updateSerialNumber: async (thingId, serial) => {
        let requestOptions = {
            url: "/v2/things/serial_number",
            data: {
                thingId: thingId,
                serialNumber: serial,
            },
        };

        return ApiService.putData(requestOptions);
    },
};

export default DetailService;
