import UtilService from 'Services/utilService';
import ApiService from 'Services/apiService';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';

const CatalogService = {

    requestManufacturers: async (page, pageSize, setRows, keyword) => {

        let filter = '';
        if (keyword != undefined) {
            let idsQuery = [];
            idsQuery.push(`{"property":["name", "product.name"],"operator":"ilike","value":"${keyword}"}`);

            let concated = idsQuery.join(",");
            filter += encodeURI(`orFilter=[${concated}]`);
        }

        let requestOptions = {
            url: `/api/manufacturers?${filter}&page=${page + 1}&pageSize=${pageSize}&sort=name&dir=ASC`
        };

        setRows([]);

        return ApiService.getData(requestOptions)
            .then((response) => {

                let data = [];
                let totalSize = response.totalSize;

                response.data.forEach(ele => {
                    let obj = {
                        id: ele.id,
                        link: ele.link,
                        name: ele.name
                    }

                    data.push(obj);
                })

                // console.log(data);

                let emptyRows = totalSize - data.length;

                if (emptyRows > 0) {
                    for (var i = 0; i < emptyRows; i++) {
                        data.push({ id: uuidv4() }); // adding empty array for paging, id is mandatory  field in the datagrid
                    }
                }

                setRows(data);

            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestProducts: async (page, pageSize, setRows, selectedManufacturer, keyword) => {

        let filter = '';
        let manufacturer;
        if (!_.isEmpty(selectedManufacturer)) {
            manufacturer = selectedManufacturer.id;
        }

        let idsQuery = [];
        if (manufacturer != undefined) {
            idsQuery.push(`{"property":["product.manufacturer.id"],"operator":"eq","value":"${manufacturer}"}`);
        }

        if (keyword != undefined) {
            idsQuery.push(`{"property":["name","product.manufacturer.name","reference"],"operator":"ilike","value":"${keyword}"}`);
        }

        if (Array.isArray(idsQuery) && idsQuery.length > 0) {
            let concated = idsQuery.join(",");
            filter += encodeURI(`orFilter=[${concated}]`);
        }

        let requestOptions = {
            url: `/api/products?${filter}&page=${page + 1}&pageSize=${pageSize}&sort=name&dir=ASC&detailed=true`
        };

        setRows([]);

        return ApiService.getData(requestOptions)
            .then((response) => {

                let data = [];
                let totalSize = response.totalSize;

                response.data.forEach(ele => {
                    let eleTag = [];

                    // console.log(ele)

                    if (ele.tags && Array.isArray(ele.tags)) {
                        ele.tags.forEach(e => {
                            eleTag.push(e.tag);
                        })
                    }

                    let model = (ele.model ? ele.model.name : '');
                    let manufacturerCategory = (ele.manufacturerCategory ? ele.manufacturerCategory : '');
                    let connectivityTypes = (ele.connectivityTypes ? ele.connectivityTypes.join("_") : '');

                    let obj = {
                        id: ele.id, // to identify a row in table
                        productId: ele.id,
                        name: ele.manufacturer.name + ' - ' + ele.name,
                        connectivityTypes: CatalogService.convertConnectivity(connectivityTypes),
                        tag: eleTag.join(","),
                        commercialName: ele.name,
                        reference: ele.reference,
                        description: ele.description,
                        manufacturer: ele.manufacturer.name,
                        infoLink: ele.infoLink,
                        model: model,
                        manufacturerCategory: manufacturerCategory
                    }

                    // console.log(obj);
                    data.push(obj);
                })

                let emptyRows = totalSize - data.length;

                if (emptyRows > 0) {
                    for (var i = 0; i < emptyRows; i++) {
                        data.push({ id: uuidv4() }); // adding empty array for paging, id is mandatory  field in the datagrid
                    }
                }

                // console.log(data);

                setRows(data);

            })
            .catch((e) => {
                console.error(e);
            });
    },

    convertConnectivity: (name) => {
        switch (name) {
            case 'LORA':
                return 'LoRaWAN'
            default:
                return name;
        }
    }
}

export default CatalogService