import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

function options(title, subtitle, unit, chartData, callback, height) {
    return {
        chart: {
            type: "column",
            height: height,
        },
        title: {
            text: null,
        },
        subtitle: {
            text: null,
        },
        xAxis: {
            type: "datetime",
            labels: {
                rotation: -45,
                style: {
                    fontSize: "13px",
                    fontFamily: "Verdana, sans-serif",
                },
                formatter: function () {
                    return moment(this.value).format("HH:mm");
                },
            },
        },
        yAxis: {
            title: {
                text: null,
            },
            min: 0,
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: unit,
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: title,
                data: chartData,
                // zIndex: 1,
                // marker: {
                //     fillColor: 'white',
                //     lineWidth: 2,
                //     lineColor: Highcharts.getOptions().colors[0]
                // },
                // showInLegend: false
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: "#FFFFFF",
                    align: "right",
                    // format: '{point.y:.1f}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: "13px",
                        fontFamily: "Arial",
                    },
                },
                events: {
                    click: function (e) {
                        // console.log(e.point.name);
                        callback(e.point.name); //  ex) Slege-Salle A
                    },
                },
            },
        ],

        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };
}

export default function ElectricityChart({ siteName, subtitle, chartData, callback, height }) {
    useEffect(() => {
        // console.log("useEffect in ElectricityChart");
        // console.log(siteName);
        // console.log(subtitle);
        // console.log(chartData);
    }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
            {/* <HighchartsReact highcharts={Highcharts} options={options(siteName, subtitle, "kw", chartData, callback, height ? height : 450)} /> */}
            <HighchartsReact highcharts={Highcharts} options={options("Siege-Master", "counter 2", "kw", chartData, callback, height ? height : 450)} />
        </Box>
    );
}
