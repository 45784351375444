const DeviceExplorerConstant = {
    MENU_EMPTY: 'menu_empty',
    MENU_MQTT: 'menu_mqtt',
    MENU_HTTP: 'menu_http',
    MENU_IOT_CORE: 'menu_iot_core',
    MENU_ESRI: 'menu_esri',
    MENU_ESRI_VELOCITY: 'menu_esri_velocity',
    MENU_SQL_SERVER: 'menu_sql_server',
    MENU_ICICLE: 'menu_icicle',
    MENU_COMPLETE: 'menu_complete',

    toForwardKey: (menu) => {
        switch (menu) {
            case DeviceExplorerConstant.MENU_MQTT:
                return 'MQTT';
            case DeviceExplorerConstant.MENU_HTTP:
                return 'HTTP';
            case DeviceExplorerConstant.MENU_IOT_CORE:
                return 'AWSIoT';
            case DeviceExplorerConstant.MENU_ESRI:
                return 'ESRIArcGIS';
            case DeviceExplorerConstant.MENU_ESRI_VELOCITY:
                return 'ESRIVelocity';
            case DeviceExplorerConstant.MENU_SQL_SERVER:
                return 'SQLserver';
            case DeviceExplorerConstant.MENU_ICICLE:
                return 'ICICLE';
            default:
                return 'UNKNOWN';
        }
    },

    toScope: (menu) => {
        switch (menu) {
            case DeviceExplorerConstant.MENU_MQTT: // valid
                return 'MQTT';
            case DeviceExplorerConstant.MENU_HTTP:
                return 'HTTP';
            case DeviceExplorerConstant.MENU_IOT_CORE: // valid
                return 'IOT_CORE';
            case DeviceExplorerConstant.MENU_ESRI:
                return 'ESRIArcGIS';
            case DeviceExplorerConstant.MENU_ESRI_VELOCITY: // valid
                return 'ESRI_VELOCITY';
            case DeviceExplorerConstant.MENU_SQL_SERVER: // valid
                return 'SQL_SERVER';
            case DeviceExplorerConstant.MENU_ICICLE: // valid
                return 'ICICLE';
            default:
                return 'UNKNOWN';
        }
    }
}

export default DeviceExplorerConstant