import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import _ from 'underscore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ConfirmDialog from './ConfirmDialog';
import ShareService from './ShareService';
import CustomStyle from 'Components/CustomStyle';

export default function SetupSQLserver(props) {

    let history = useHistory();
    let errors = {
        hostname: false,
        user: false,
        password: false,
        database: false,
        description: false,
        message: ''
    }
    const setActiveStep = props.setActiveStep;

    const [txtMessageFormat, setTxtMessageFormat] = useState();
    const [companyName, setCompanyName] = useState();

    const [flowId, setFlowId] = useState();
    const [forwardTargetId, setForwardTargetId] = useState();

    const [hostname, setHostname] = useState();
    const [user, setUser] = useState();
    const [password, setPassword] = useState();
    const [database, setDatabase] = useState();
    const [description, setDescription] = useState();

    const [chkMessage, setChkMessage] = useState(false);
    const [chkThing, setChkThing] = useState(false);
    const [chkProduct, setChkProduct] = useState(false);
    const [chkGateway, setChkGateway] = useState(false);


    useEffect(() => {
        setActiveStep(1);

        ShareService.getCompany(setCompanyName)
            .then((companyName) => {
                ShareService.getFlowTargetSQLserver(companyName, setFlowId, setForwardTargetId,
                    setHostname, setUser, setPassword, setDatabase,
                    setChkMessage, setChkThing, setChkProduct, setChkGateway)
            });

        ShareService.requestMessageFormat("SQLserver", setTxtMessageFormat, [chkMessage, chkThing, chkProduct, chkGateway]);

        setDescription(ShareService.DESC_SQL_SERVER);
    }, []);

    const handleSave = () => {
        ShareService.getCompany(setCompanyName)
            .then((companyName) => {
                ShareService.getFlowTargetSQLserver(companyName, setFlowId, setForwardTargetId,
                    setHostname, setUser, setPassword, setDatabase,
                    setChkMessage, setChkThing, setChkProduct, setChkGateway)
                    .then(() => {
                        if (!forwardTargetId) {
                            // create a flow, enabled=false
                            ShareService.createFlow(companyName, "Share-SQLserver")
                                .then((flowId) => {
                                    // Insert SQLserver integration, enabled=false
                                    ShareService.insertForwardSQLserver(hostname, user, password, database, [chkMessage, chkThing, chkProduct, chkGateway])
                                        .then((savedForwardTargetId) => {
                                            // Insert Action
                                            setForwardTargetId(savedForwardTargetId);
                                            ShareService.createAction(flowId, savedForwardTargetId)
                                                .then(() => props.goCompletePage());
                                        });

                                });
                        } else {
                            // Update configuration
                            ShareService.updateForwardSQLserver(forwardTargetId, hostname, user, password, database,
                                [chkMessage, chkThing, chkProduct, chkGateway])
                                .then(() => props.goCompletePage());
                        }
                    });
            });
    }

    const handleCancel = () => {
        props.goFirstPage();
    }

    const handleDelete = () => {
        ShareService.deleteFlow(flowId);
        ShareService.deleteForward(forwardTargetId);
        props.goCompletePage();
    }

    const handleMsgFormat = (e) => {
        const value = e.target.checked;
        if (e.target.name === 'chkMessage') {
            setChkMessage(value);
            ShareService.requestMessageFormat("SQLserver", setTxtMessageFormat, [value, chkThing, chkProduct, chkGateway]);
        } else if (e.target.name === 'chkThing') {
            setChkThing(value);
            ShareService.requestMessageFormat("SQLserver", setTxtMessageFormat, [chkMessage, value, chkProduct, chkGateway]);
        } else if (e.target.name === 'chkProduct') {
            setChkProduct(value);
            ShareService.requestMessageFormat("SQLserver", setTxtMessageFormat, [chkMessage, chkThing, value, chkGateway]);
        } else if (e.target.name === 'chkGateway') {
            setChkGateway(value);
            ShareService.requestMessageFormat("SQLserver", setTxtMessageFormat, [chkMessage, chkThing, chkProduct, value]);
        }
    }

    const handleValue = (e) => {
        const value = e.target.value;

        if (e.target.name == 'txtUrl') {
            setHostname(value);
        } else if (e.target.name == 'txtUser') {
            setUser(value);
        } else if (e.target.name == 'txtPassword') {
            setPassword(value);
        } else if (e.target.name == 'txtDatabase') {
            setDatabase(value);
        }
    }

    const closeDialog = (answer) => {
        if (!answer) {
            props.goFirstPage();
        }
    }

    return (
        <>
            <Paper elevation={6} spacing={2}>
                {props.on &&
                    <ConfirmDialog callback={closeDialog}></ConfirmDialog>
                }

                <Grid container padding={2}>
                    <Grid item xs={6} sm={6}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" style={{ backgroundColor: '#9CC96B' }} onClick={() => handleSave()}>Save</Button>
                        </Stack>
                    </Grid>
                </Grid>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Connection</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={2} sm={2}>
                                <Typography>
                                    Hostname
                                </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtUrl"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={hostname}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.hostname ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>
                                    User
                                </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtUser"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={user}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.user ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>
                                    Password
                                </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtPassword"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={password}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.password ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>
                                    Database name
                                </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtDatabase"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={database}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.database ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>
                                    Description
                                </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtDescription"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    multiline
                                    minRows={4}
                                    value={description}
                                    disabled
                                    // onChange={(e) => handleValue(e)}
                                    error={errors.description ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Message format</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={3} sm={3}>
                                    <FormControlLabel control={<Checkbox name="chkMeasurement" defaultChecked disabled />} label="Measurement" />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <FormControlLabel control={<Checkbox name="chkMessage" checked={chkMessage} onClick={(e) => handleMsgFormat(e)} />} label="Message" />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <FormControlLabel control={<Checkbox name="chkThing" checked={chkThing} onClick={(e) => handleMsgFormat(e)} />} label="Thing" />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <FormControlLabel control={<Checkbox name="chkProduct" checked={chkProduct} onClick={(e) => handleMsgFormat(e)} />} label="Product" />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <FormControlLabel control={<Checkbox name="chkGateway" checked={chkGateway} onClick={(e) => handleMsgFormat(e)} />} label="Gateway" />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    name="txtMessageFormat"
                                    fullWidth
                                    margin="dense"
                                    multiline
                                    rows={15}
                                    value={txtMessageFormat}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </>
    )
}