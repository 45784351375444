import ApiService from "Services/apiService";
import UtilService from "Services/utilService";

const TempMntService = {
    requestCluster: async (companyId, setCluster, setLoading) => {
        let requestOptions = {
            url: "/v2/cluster",
            params: {
                appId: "TEMPERATURE_MONITORING",
                companyId: companyId,
            },
        };

        ApiService.getData(requestOptions)
            .then((response) => {
                let remap = response.map((c) => {
                    c.things = c.things.map((t) => {
                        let eleLow = t.customFields.find((c) => c.name == "TH_LOW;temperature");
                        let eleHigh = t.customFields.find((c) => c.name == "TH_HIGH;temperature");

                        t["threshold"] = {};
                        if (eleLow != undefined) {
                            t.threshold["low"] = {
                                celsius: parseFloat(eleLow.value),
                                name: "ALERT LOW",
                                fahrenheit: UtilService.toFahrenheit(eleLow.value, 0),
                                value: parseFloat(eleLow.value),
                            };
                        } else {
                            t.threshold["low"] = {
                                celsius: "",
                                name: "ALERT LOW",
                                fahrenheit: "",
                                value: "",
                            };
                        }

                        if (eleHigh != undefined) {
                            t.threshold["high"] = {
                                celsius: parseFloat(eleHigh.value),
                                name: "ALERT HIGH",
                                fahrenheit: UtilService.toFahrenheit(eleHigh.value, 0),
                                value: parseFloat(eleHigh.value),
                            };
                        } else {
                            // default values for UI
                            t.threshold["high"] = {
                                celsius: "",
                                name: "ALERT HIGH",
                                fahrenheit: "",
                                value: "",
                            };
                        }

                        if (t.lastMeasurements && t.lastMeasurements.measurements) {
                            if (t.lastMeasurements.measurements.Temperature) {
                                t.lastMeasurements.measurements['temperature'] = t.lastMeasurements.measurements.Temperature;
                            }
                        }

                        if (t.lastMeasurementsTimestamps && t.lastMeasurementsTimestamps.measurements) {
                            if (t.lastMeasurementsTimestamps.measurements.Temperature) {
                                t.lastMeasurementsTimestamps.measurements['temperature'] = t.lastMeasurementsTimestamps.measurements.Temperature;
                            }
                        }

                        return t;
                    });

                    return c;
                });

                setCluster(remap);
                setLoading(true);
            })
            .catch((e) => console.error(e));
    },
};

export default TempMntService;
