import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
    //   const [open, setOpen] = React.useState(false);

    // const handleClickOpen = () => {
    //     // setOpen(true);
    //     props.handleUserConfirm(false);
    // };

    const handleClose = (agree) => {
        // setOpen(false);

        if (agree) {
            props.handleUserConfirm(true);
        } else {
            props.handleUserConfirm(false);
        }
    };

    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Some sensors are already in use, Would you like to move it to {props.rackName} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)}>Disagree</Button>
                    <Button onClick={() => handleClose(true)} autoFocus>Agree</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
