import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CompanyService from "./CompanyService";
import UserService from "../../../services/userService";
import { useTranslation } from "react-i18next";

export default function CompanyBox({ loading, callback }) {
    const { t, i18n } = useTranslation();

    const [company, setCompany] = useState("");
    const [options, setOptions] = useState([]);

    useEffect(() => {
        // console.log("uef in CompanyBox");
        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u);

            if (u.authorities.indexOf("SUPER_ADMIN") != -1) {
                CompanyService.requestAllCompany().then((mapped) => {
                    // console.log("Here....");
                    // console.log(mapped);
                    setCompany(u.company);
                    callback(u.company);

                    let opt = [];
                    opt.push(u.company);
                    mapped.map((m) => {
                        opt.push(m.name);
                        // opt.push(m.name.replace("COMPANY_", ""));
                    });

                    setOptions(opt);
                });
            } else {
                CompanyService.requestSubCompanyWithMine(u.company).then((mapped) => {
                    // console.log("Here....");
                    // console.log(mapped);
                    setCompany(u.company);
                    callback(u.company);

                    let opt = mapped.map((m) => m.name);
                    setOptions(opt);
                });
            }
        });
    }, []);

    const handleCompany = (e) => {
        setCompany(e.target.value);
        callback(e.target.value);
    };

    return (
        <FormControl variant="filled" fullWidth required>
            <InputLabel id="sel-locale-label" sx={{ ml: 0, alignContent: "left" }}>
                {t("com.company")}
            </InputLabel>
            {company && (
                <Select
                    labelId="sel-locale-label"
                    id="sel-locale"
                    value={company}
                    onChange={handleCompany}
                    disabled={loading}
                    label="Locale"
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 200,
                            },
                        },
                    }}
                >
                    {options &&
                        options.map((opt) => {
                            return (
                                <MenuItem value={opt} key={opt} style={{ display: "block" }}>
                                    {opt}
                                </MenuItem>
                            );
                        })}
                </Select>
            )}
        </FormControl>
    );
}
