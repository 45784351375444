import { useEffect, useState } from 'react';
import CropService from './CropService';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, personName, theme) {
    if (!personName)
        return;

    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function CropComboBox(props) {

    const [assets, setAssets] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState([]);
    const theme = useTheme();

    const handleChange = (event, child) => {
        setSelectedAsset([event.target.value]);
        props.selectCluster(child.props.id);
    };

    useEffect(() => {
        CropService.requestAssets(receiveAsset)
            .then(() => {
                CropService.requestDefaultAsset().then((asset) => {
                    if (asset) {
                        setSelectedAsset([asset.label]);
                        props.selectCluster(asset.id);
                    } else {
                        setSelectedAsset([]);
                        props.selectCluster(undefined);
                    }
                });

            });
    }, []);

    const receiveAsset = (assets) => {
        let temp = [];
        assets.forEach(element => {
            temp.push({
                id: element.id,
                label: element.name,
                shortName: element.shortName
            })
        });
        setAssets(temp);
    }

    return (

        <FormControl fullWidth>
            <InputLabel id="input-label-cluster">Select a cluster</InputLabel>
            <Select
                labelId="cluster-name-label"
                id="cluster-name"
                value={selectedAsset}
                onChange={(event, child) => handleChange(event, child)}
                input={<OutlinedInput label="Select a cluster" />}
                MenuProps={MenuProps}
                style={{ textAlign: 'left' }}
            >
                {assets.map((asset) => (
                    <MenuItem
                        key={asset.id}
                        id={asset.id}
                        value={asset.label}
                        style={getStyles(asset.label, selectedAsset.label, theme)}
                    >
                        {asset.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
} 