import Button from '@mui/material/Button';
import TabMenu from '../tab/index';
import { useHistory } from "react-router-dom";

import style from './style.module.css';

export default function SensorNotFound(props) {
    const message = props.message;
    let history = useHistory();

    function gotoScan() {
        history.push(`${process.env.PUBLIC_URL}/device_scan/scan`);
    }

    return (
        <div className="container" style={{ marginTop: "55px" }}>
            <div className="row" >
                <TabMenu value={0}></TabMenu>
            </div>
            <div className="row">
                <div className="col">
                    <span className="mt-5 mb-3" style={{ width: "100%", fontSize: "30px", textAlign: "center", display: "inline-block" }}>Device not found.</span>
                    <span style={{ width: "100%", fontSize: "15x", textAlign: "center", display: "inline-block" }}>{message}</span>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col">
                    <Button variant="contained" style={{ width: "100%", backgroundColor: "rgb(126, 201, 217)", color: "rgb(255,255,255)", textTransform: "none" }} onClick={gotoScan} >New scan</Button>
                </div>
            </div>

        </div>
    )
};

