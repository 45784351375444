import { useState, useEffect, useContext } from 'react'
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'underscore';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SettingContext from 'Providers/SettingContext';
import ManufacturerList from './ManufacturerList';
import ProductList from './ProductList';
import ProductDetail from './ProductDetail';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
    flexShrink: 1,
    flexGrow: 0
}));


export default function Catalog({ appId, callback }) {

    const setting = useContext(SettingContext);

    const [keyword, setKeyword] = useState();
    const [selectedManufacturer, setSelectedManufacturer] = useState({});
    const [selectedProduct, setSelectedProduct] = useState({});

    useEffect(() => {
        // do nothing
    }, [keyword, selectedManufacturer, selectedProduct]);


    const handleSelectedManufacturer = (manufacturer) => {
        setSelectedManufacturer(manufacturer);
        setSelectedProduct();
    }

    const handleSelectedProduct = (product) => {
        setSelectedProduct(product);
    }

    const handleKeyword = (e) => {
        setKeyword(e.target.value);
    }

    const handleClear = (e) => {
        setKeyword('');
    }

    const handleAssociate = () => {
        if (selectedProduct) {
            callback(selectedProduct);
        }
    }

    return (
        <Box
            sx={{
                alignItems: 'stretch',
                p: 0,
                m: 0,
                bgcolor: 'background.paper',
                borderRadius: 1,
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Grid container spacing={2} sx={{ height: '100%', overflow: 'hidden' }}>
                <Grid item xs={12}>
                    <Item sx={{ width: '100%', display: 'flex' }}>
                        <div style={{
                            heigth: '400px', width: '400px', display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#F5F5F5',
                            borderRadius: 4,
                            padding: '4px 8px',
                        }}>
                            <SearchIcon sx={{ color: 'text.disabled' }} />

                            <InputBase
                                placeholder="Search by name, reference"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(e) => handleKeyword(e)}
                                style={{ width: '80%' }}
                                value={keyword}
                            />
                            <IconButton aria-label="delete" onClick={handleClear} size="large">
                                <ClearIcon />
                            </IconButton>
                        </div>
                        {callback &&
                            <div style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                                <Box>
                                    <Button
                                        variant="contained"
                                        // startIcon={<ReplyIcon style={{ fontSize: '25px', color: 'white' }} />}
                                        style={{ backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none", marginRight: '10px' }}
                                        onClick={() => handleAssociate()}
                                    // size='small'
                                    >
                                        Associate product
                                    </Button>
                                </Box>
                            </div>
                        }

                    </Item>
                </Grid>
                <Grid item xs={12} sx={{ flexGrow: 1, height: '100%' }}>
                    {/* <Item sx={{ flexGrow: 1, height: 500 }}                > */}
                    <Grid container spacing={2} style={{ height: '100%' }}>
                        <Grid item xs={4} >
                            <Item sx={{ height: '100%', width: '100%' }}>
                                <ManufacturerList callback={handleSelectedManufacturer} keyword={keyword}></ManufacturerList>
                            </Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'stretch',
                                p: 0,
                                bgcolor: 'background.paper',
                                height: '100%',
                                borderRadius: 1,
                            }}>
                                <Item sx={{ height: '100%', width: '100%' }} >
                                    <ProductList selectedManufacturer={selectedManufacturer} callback={handleSelectedProduct} keyword={keyword}></ProductList>
                                </Item>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'stretch',
                                p: 0,
                                m: 0,
                                bgcolor: 'background.paper',
                                height: '100%',
                                borderRadius: 1,
                            }}>
                                <Item sx={{ height: '100%', width: '100%' }} >
                                    <ProductDetail product={selectedProduct}></ProductDetail>
                                </Item>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
