import { useEffect, useState } from "react";
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import Skeleton from "@mui/material/Skeleton";

highchartsMore(Highcharts);
solidGauge(Highcharts);

export default function CircleUI(props) {
    const [value, setValue] = useState(0);
    const [unit, setUnit] = useState("%");
    const [date, setDate] = useState("");
    const [fixedName, setFixedName] = useState("");
    const [alertCount, setAlertCount] = useState(0);

    const [temps, setTemps] = useState({});

    useEffect(() => {
        if (props.entity != undefined) {
            setValue(parseFloat(props.entity.value));
            setUnit(props.entity.unit);
            setDate(props.entity.date);
            setFixedName(props.entity.fixedName ? props.entity.fixedName : "");
            setAlertCount(props.entity.alertCount);
        }
    }, []);

    const options = {
        chart: {
            type: "solidgauge",
            margin: [0, 0, 0, 0],
            height: 80,
            width: 80,
        },

        title: null,

        pane: {
            center: ["50%", "50%"],
            size: "100%",
            startAngle: -90,
            endAngle: 270,
            background: {
                backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
                innerRadius: "60%",
                outerRadius: "100%",
                shape: "arc",
            },
        },

        exporting: {
            enabled: false,
        },

        tooltip: {
            enabled: false,
        },

        yAxis: {
            stops: [
                [0, "#A8D071"],
                [1, "#A8D071"],
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            labels: {
                y: 16,
                enabled: false,
            },
            min: 0,
            max: 100,
        },

        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true,
                },
            },
        },

        credits: {
            enabled: false,
        },

        series: [
            {
                data: [
                    {
                        y: value,
                    },
                ],
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
            },
        ],
    };

    return (
        <div className="container m-0 p-0" style={{ textAlign: "center", width: "100%", height: "80px" }}>
            {props.default && (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100%", position: "relative" }}>
                    <div style={{ position: "absolute" }}>
                        <Skeleton variant="circular" width={80} height={80} />
                    </div>
                </div>
            )}
            {!props.default && (
                <Tooltip title={fixedName}>
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "100%", position: "relative" }}>
                        <div style={{ position: "absolute" }}>
                            <Badge overlap="rectangular" badgeContent={alertCount} max={999} color="primary">
                                <HighchartsReact highcharts={Highcharts} options={options} />
                            </Badge>
                        </div>
                        <div style={{ position: "absolute", zIndex: "1" }}>
                            <span style={{ fontSize: "15px" }}>
                                {value.toFixed(1)} {unit}
                            </span>
                            <br></br>
                            <span style={{ fontSize: "12px" }}>{date}</span>
                        </div>
                    </div>
                </Tooltip>
            )}
        </div>
    );
}
