import React, { useState, useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/ui/BootstrapDialog";
import _ from "underscore";
import Box from "@mui/material/Box";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { columns } from "./columns";
import ThingAttributeService from "./ThingAttributeService";
import { CustomButton } from "../../../components/ui/CustomButton";
import moment from "moment";
import { frFR } from "@mui/x-data-grid/locales";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function ThingListDialog({ open, setOpen, wid, setReload }) {
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 15 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [sort, setSort] = useState([{ field: "name", sort: "asc" }]);
    const { t, i18n } = useTranslation();
    const [selectedRow, setSelectedRow] = useState([]);

    useEffect(() => {
        if (open) {
            // console.log("wid=" + wid);
            ThingAttributeService.requestThingAttribute(wid, setRows, paginationModel.page, paginationModel.pageSize, setRowCount, sort);

            ThingAttributeService.requestMappedAttribute(wid, setSelectedRow);
        }
    }, [open, sort]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSortModelChange = (e) => {
        // console.log("handleSortModelChange ...");
        // console.log(e);
        setSort(e);
    };

    const handleRowClick = () => {
        // console.log("handleRowClick ...");
    };

    const handleSelectionModel = (newSelection) => {
        // console.log(newSelection);
        setSelectedRow(newSelection[newSelection.length - 1] || null);
    };

    const handleAssociate = () => {
        // console.log(selectedRow);
        // call rest api
        if (selectedRow) {
            const id = selectedRow.split(",");
            const thingId = id[0];
            const attributeId = id[1];

            ThingAttributeService.associateAttribute(thingId, attributeId, wid).then(() => {
                setReload(moment());
                setOpen(false);
            });
        }
    };

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="md" fullWidth>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Associate an attribute
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={{ mt: 3 }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sx={{ height: 400 }}>
                                <DataGridPro
                                    rows={rows}
                                    rowCount={rowCount}
                                    columns={columns}
                                    pagination={true}
                                    paginationMode="server"
                                    sortingMode="server"
                                    pageSizeOptions={[15, 50, 100]}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                    onSortModelChange={handleSortModelChange}
                                    onRowClick={handleRowClick}
                                    checkboxSelection={false}
                                    // components={{
                                    //     Toolbar: CustomToolbar,
                                    // }}
                                    onRowSelectionModelChange={handleSelectionModel}
                                    rowSelectionModel={[selectedRow]}
                                    density="compact"
                                    // filterModel={{
                                    // 	items: [
                                    // 		{ columnField: 'state', operatorValue: 'contains', value: filterState },
                                    // 	],
                                    // }}
                                    filterMode="server"
                                    disableMultipleColumnsFiltering={true}
                                    // onFilterModelChange={handleFilterModelChange}
                                    // onColumnVisibilityChange={handleVisibilityChange}
                                    disableColumnReorder={true}
                                    height="100%"
                                    sx={{
                                        "& .MuiTablePagination-selectLabel": {
                                            marginTop: 2,
                                        },
                                        "& .MuiTablePagination-displayedRows": {
                                            marginTop: 2,
                                        },
                                    }}
                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                id: false,
                                            },
                                        },
                                    }}
                                    localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <CustomButton name="Associate" callback={() => handleAssociate()} />
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
