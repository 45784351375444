import _ from "underscore";
import ApiService from "Services/apiService";

const SiteService = {
    requestThing: async (thingId) => {
        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        // console.log(requestOptions);
        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response)
                return response; // A thing object
            })
            .catch((e) => {
                console.error(e);
            });
    },

    list: async (company, setRows) => {
        let requestOptions = {
            url: `/v2/sites?company=${company}&page=0&pageSize=100`,
        };

        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);
                setRows(response.content);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    create: async (name, address, city, postalCode, latitude, longitude, company, description) => {
        let requestOptions = {
            url: `/api/sites?company=${company}`,
            data: {
                name,
                address,
                city,
                postalCode,
                latitude,
                longitude,
                description,
            },
        };

        // console.log(requestOptions);
        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    update: async (id, name, address, city, postalCode, latitude, longitude, description) => {
        let requestOptions = {
            url: `/api/sites/${id}`,
            data: {
                id,
                name,
                address,
                city,
                postalCode,
                latitude,
                longitude,
                description,
            },
        };

        // console.log(requestOptions);
        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    assign: async (thingId, siteId) => {
        let requestOptions = {
            url: `/v2/sites/assign`,
            data: {
                thingId: thingId,
                siteId: siteId,
            },
        };

        // console.log(requestOptions);
        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    delete: async (siteId) => {
        let requestOptions = {
            url: `/v2/sites/${siteId}`,
        };

        // console.log(requestOptions);
        return ApiService.deleteData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default SiteService;
