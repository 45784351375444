import React, { useEffect, useState, useContext, useRef } from 'react';
import AppContext from 'Components/AppContext'
import SettingContext from '../../providers/SettingContext';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import { AppId } from 'Components/applications/ApplicationData';
import style from "./style/report.module.css";
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

highchartsMore(Highcharts);

var ranges = [
    [1246406400000, 14.3, 27.7],
    [1246492800000, 14.5, 27.8],
    [1246579200000, 15.5, 29.6],
    [1246665600000, 16.7, 30.7],
    [1246752000000, 16.5, 25.0],
    [1246838400000, 17.8, 25.7],
    [1246924800000, 13.5, 24.8],
    [1247011200000, 10.5, 21.4],
    [1247097600000, 9.2, 23.8],
    [1247184000000, 11.6, 21.8],
    [1247270400000, 10.7, 23.7],
    [1247356800000, 11.0, 23.3],
    [1247443200000, 11.6, 23.7],
    [1247529600000, 11.8, 20.7],
    [1247616000000, 12.6, 22.4],
    [1247702400000, 13.6, 19.6],
    [1247788800000, 11.4, 22.6],
    [1247875200000, 13.2, 25.0],
    [1247961600000, 14.2, 21.6],
    [1248048000000, 13.1, 17.1],
    [1248134400000, 12.2, 15.5],
    [1248220800000, 12.0, 20.8],
    [1248307200000, 12.0, 17.1],
    [1248393600000, 12.7, 18.3],
    [1248480000000, 12.4, 19.4],
    [1248566400000, 12.6, 19.9],
    [1248652800000, 11.9, 20.2],
    [1248739200000, 11.0, 19.3],
    [1248825600000, 10.8, 17.8],
    [1248912000000, 11.8, 18.5],
    [1248998400000, 10.8, 16.1]
],
    averages = [
        [1246406400000, 21.5],
        [1246492800000, 22.1],
        [1246579200000, 23],
        [1246665600000, 23.8],
        [1246752000000, 21.4],
        [1246838400000, 21.3],
        [1246924800000, 18.3],
        [1247011200000, 15.4],
        [1247097600000, 16.4],
        [1247184000000, 17.7],
        [1247270400000, 17.5],
        [1247356800000, 17.6],
        [1247443200000, 17.7],
        [1247529600000, 16.8],
        [1247616000000, 17.7],
        [1247702400000, 16.3],
        [1247788800000, 17.8],
        [1247875200000, 18.1],
        [1247961600000, 17.2],
        [1248048000000, 14.4],
        [1248134400000, 13.7],
        [1248220800000, 15.7],
        [1248307200000, 14.6],
        [1248393600000, 15.3],
        [1248480000000, 15.3],
        [1248566400000, 15.8],
        [1248652800000, 15.2],
        [1248739200000, 14.8],
        [1248825600000, 14.4],
        [1248912000000, 15],
        [1248998400000, 13.6]
    ];

function options(title, unit) {
    return {
        title: {
            text: title
        },

        xAxis: {
            type: 'datetime'
        },

        yAxis: {
            title: {
                text: null
            }
        },

        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: unit
        },

        series: [{
            name: title,
            data: averages,
            zIndex: 1,
            marker: {
                fillColor: 'white',
                lineWidth: 2,
                lineColor: Highcharts.getOptions().colors[0]
            },
            showInLegend: false
        }, {
            name: 'Range',
            data: ranges,
            type: 'arearange',
            lineWidth: 0,
            linkedTo: ':previous',
            color: Highcharts.getOptions().colors[0],
            fillOpacity: 0.3,
            zIndex: 0,
            marker: {
                enabled: false
            }
        }]
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
}));


export default function Report(props) {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    ctx.updateTitle("Emera");
    const { t, i18n } = useTranslation();

    const componentRef = useRef();

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.EMERA,
            temperatureMonitoring: {
                celsius: true,
                language: "en"
            }
        });

        return () => { ctx.updateTitle(null) }
    }, []);

    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = event => {
        setValue(event.target.value);
    };

    const getDate = date => {
        return date.getFullYear() + "-" + (date.getMonth() + 1).toString(10).padStart(2, "0") + "-" + date.getDate().toString(10).padStart(2, "0");
    }

    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);

    return (
        <div className="container-fluid" style={{ marginTop: '76px', paddingLeft: "0px", paddingRight: "0px", width: "750px" }}>
            <div className="col-4">
                <span style={{ fontFamily: 'Days One', fontSize: '24px', fontWeight: 'normal', color: "#9B9B9B" }}>{t('emera.report_title')}</span>
            </div>
            <form>
                <Grid container spacing={1}>
                    <Grid item xs="3" className={style.reportLabel}>
                        {t('emera.board_site')}
                    </Grid>
                    <Grid item xs="9">
                        <FormControl className={classes.formControl} style={{ width: "100%" }}>
                            <Select id="site" value="all" sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 }
                            }}>
                                <MenuItem value="all">{t("emera.report_all_sites")}</MenuItem>
                                <MenuItem value="kells">Siege</MenuItem>
                                <MenuItem value="bishop">Agence</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs="3" className={style.reportLabel}>
                        {t('emera.report_room')}
                    </Grid>
                    <Grid item xs="9">
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox name="ext" />}
                                label="Salle A" />
                            <FormControlLabel
                                control={<Checkbox name="room1" />}
                                label="Salle 1" />
                            <FormControlLabel
                                control={<Checkbox name="room2" />}
                                label="Salle 2" />
                            <FormControlLabel
                                control={<Checkbox name="room3" />}
                                label="Salle 3" />
                            <FormControlLabel
                                control={<Checkbox name="room4" />}
                                label="Salle 4" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs="3" className={style.reportLabel}>
                        {t('emera.report_daterange')}
                    </Grid>
                    <Grid item xs="9">
                        <FormGroup row>
                            <FormControl className={classes.formControl} style={{ width: "50%", paddingRight: "10px" }}>
                                <TextField
                                    id="beginDate"
                                    type="date"
                                    defaultValue={getDate(lastWeek)}
                                    className={classes.textField}
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 }
                                    }} />
                            </FormControl>
                            <FormControl className={classes.formControl} style={{ width: "50%", paddingLeft: "10px" }}>
                                <TextField
                                    id="endDate"
                                    type="date"
                                    defaultValue={getDate(new Date())}
                                    className={classes.textField}
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 }
                                    }} />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs="3" className={style.reportLabel}>
                        {t('emera.report_title')}
                    </Grid>
                    <Grid item xs="9">
                        <FormControlLabel
                            control={<Checkbox name="showBattery" />}
                            label={t('emera.report_show_battery')} />
                    </Grid>
                    <Grid item xs="3" className={style.reportLabel} />
                    <Grid item xs="9">
                        <FormControlLabel
                            control={<Checkbox name="showAverages" />}
                            label={t('emera.report_show_averages')} />
                    </Grid>
                </Grid>

                <Button variant="contained" color="primary" type="submit" style={{ width: "100%", marginTop: "20px" }}>
                    {t('emera.report_submit')}
                </Button>
            </form>
        </div>
    )
};