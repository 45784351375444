import React, { useEffect, useContext } from 'react';

import AppContext from 'Components/AppContext'
import SettingContext from 'Providers/SettingContext';
import { AppId } from 'Components/applications/ApplicationData';

import Alerts from "./details/Alerts";

import _ from 'underscore';

export default function EmeraAlerts(props) {

	const ctx = useContext(AppContext);
	const setting = useContext(SettingContext);
	ctx.updateTitle("Emera");

	useEffect(() => {
		setting.setGlobalSetting({
			appId: AppId.EMERA,
			temperatureMonitoring: {
				celsius: true,
				language: "en"
			}
		});

		return () => { ctx.updateTitle(null) }
	}, [])

	return <div style={{ marginTop: "76px" }}>
		<Alerts {...props} />
	</div>;
}
