import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import LightUI from 'Components/ui/LightUI';
import BasicUI from 'Components/ui/BasicUI';
import CircleUI from 'Components/ui/CircleUI';

export function loadingUI(tabIndex, connectivity) {
    let rows = [];

    if (tabIndex == 0) {
        for (var i = 1; i < 6; i++) {
            let flexibleWidth = (connectivity == 'LORA' ? '12%' : '15%');

            rows.push(
                <TableRow key={i}>
                    <TableCell component="th" scope="row" align="center">{i}</TableCell>
                    <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}>
                        <div className="d-flex justify-content-around">
                            <LightUI entity={{ value: 0, unit: 'lux', date: '', fixedName: '', alertCount: 0 }} default />
                        </div>
                    </TableCell>
                    {connectivity == 'LORA' &&
                    <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}>
                        <div className="d-flex justify-content-around">
                            <LightUI entity={{ value: 0, unit: 'lux', date: '', fixedName: '', alertCount: 0 }} default />
                        </div>
                    </TableCell>
                    }
                    {connectivity == 'LORA' &&
                        <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}>
                            <div className="d-flex justify-content-around">
                                <LightUI entity={{ value: 0, unit: 'lux', date: '', fixedName: '', alertCount: 0 }} default />
                            </div>
                        </TableCell>
                    }
                    <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}>
                        <BasicUI default></BasicUI>
                    </TableCell>
                    <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}>
                        <CircleUI default></CircleUI>
                    </TableCell>
                    <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}>
                        <CircleUI default></CircleUI>
                    </TableCell>
                    <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}>
                        <BasicUI default></BasicUI>
                    </TableCell>
                    <TableCell align="center" style={{ width: flexibleWidth, cursor: 'pointer' }}>
                        <BasicUI default></BasicUI>
                    </TableCell>
                </TableRow>
            );
        }
    } else if (tabIndex == 1) {
        for (var i = 1; i < 6; i++) {
            rows.push(
                <TableRow key={i}>
                    <TableCell component="th" scope="row" align="center">{i}</TableCell>
                    <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}>
                        <div className="d-flex justify-content-around">
                            <BasicUI default></BasicUI>
                        </div>
                    </TableCell>
                    <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}>
                        <div className="d-flex justify-content-around">
                            <BasicUI default></BasicUI>
                        </div>
                    </TableCell>
                    <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}>
                        <BasicUI default></BasicUI>
                    </TableCell>
                    <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}>
                        <BasicUI default></BasicUI>
                    </TableCell>
                    <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}>
                        <CircleUI default></CircleUI>
                    </TableCell>
                    <TableCell align="center" style={{ width: '15%', cursor: 'pointer' }}>
                        <BasicUI default></BasicUI>
                    </TableCell>
                </TableRow>
            );
        }
    } else if (tabIndex == 2) {
        for (var i = 1; i < 6; i++) {

            if (connectivity == 'LORA') {
                rows.push(
                    <TableRow key={i}>
                        <TableCell component="th" scope="row" align="center">{i}</TableCell>
                        <TableCell align="center" style={{ width: '33%', cursor: 'pointer' }}>
                            <div className="d-flex justify-content-around">
                                <LightUI entity={{ value: 0, unit: 'lux', date: '', fixedName: '', alertCount: 0 }} default />
                            </div>
                        </TableCell>
                        <TableCell align="center" style={{ width: '33%', cursor: 'pointer' }}>
                            <div className="d-flex justify-content-around">
                                <BasicUI default></BasicUI>
                            </div>
                        </TableCell>
                        {/* <TableCeldl align="center" style={{ width: '15%', cursor: 'pointer' }}>
                            <div className="d-flex justify-content-around">
                                <BasicUI default></BasicUI>
                            </div>
                        </TableCeldl> */}
                        <TableCell align="center" style={{ width: '33%', cursor: 'pointer' }}>
                            <CircleUI default></CircleUI>
                        </TableCell>
                    </TableRow>
                );
            } else {
                rows.push(
                    <TableRow key={i}>
                        <TableCell component="th" scope="row" align="center">{i}</TableCell>
                        <TableCell align="center" style={{ width: '45%', cursor: 'pointer' }}>
                            <div className="d-flex justify-content-around">
                                <LightUI entity={{ value: 0, unit: 'lux', date: '', fixedName: '', alertCount: 0 }} default />
                            </div>
                        </TableCell>
                        <TableCell align="center" style={{ width: '45%', cursor: 'pointer' }}>
                            <div className="d-flex justify-content-around">
                                <BasicUI default></BasicUI>
                            </div>
                        </TableCell>
                    </TableRow>
                );
            }
        }
    }

    return rows;
}
