import _ from "underscore";
import moment from "moment";

const UtilService = {
    /**
     * Returns the formatted string
     * @param {string} timestamp in epoch milliseconds, ex) 1612987308000
     * @returns  {string} formatted string, ex) Feb 10 2021 3:01:48 PM
     */
    formateDate: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("MMM DD YYYY hh:mm:ss A");
    },

    formatMonth: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("MMMM");
    },

    formatDay: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("D");
    },

    formatHour: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("H");
    },

    formatMonthYear: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("MMMM YYYY");
    },

    /**
     * Returns the shortest formatted string
     * @param {string} timestamp in epoch milliseconds, ex) 1612987308000
     * @returns  {string} formatted string, ex) Nov 1 5 PM
     */
    formatYearMonthDay: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("MM/DD/YYYY");
    },

    /**
     * Returns the short formatted string
     * @param {string} timestamp in epoch milliseconds, ex) 1612987308000
     * @returns  {string} formatted string, ex) 2/10/2021 3:01:48 PM
     */
    shortFormateDate: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("MM/DD/YYYY hh:mm:ss A");
    },

    /**
     * Returns the shortest formatted string
     * @param {string} timestamp in epoch milliseconds, ex) 1612987308000
     * @returns  {string} formatted string, ex) 2/10/21 3PM
     */
    shortestFormateDate: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("MM/DD/YY hA");
    },

    /**
     * Returns the shortest formatted string
     * @param {string} timestamp in epoch milliseconds, ex) 1612987308000
     * @returns  {string} formatted string, ex)Nov 1 2021 3PM
     */
    shortestFormateDateMDYH: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;

        return moment(timestamp).format("MMM DD YYYY, HH:mm:ss");
    },

    /**
     * Returns the shortest formatted string
     * @param {string} timestamp in epoch milliseconds, ex) 1612987308000
     * @returns  {string} formatted string, ex) Nov 1 2021
     */
    shortestFormateDateMDY: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;

        return moment(timestamp).format("MMM DD YYYY");
    },

    formatM: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;

        return moment(timestamp).format("MMM");
    },

    formatMD: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;

        return moment(timestamp).format("MMM DD");
    },

    formatMDHma: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;

        return moment(timestamp).format("MMM DD, HH:mm A");
    },

    formatHmDM: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;

        return moment(timestamp).format("HH:mm DD MMM");
    },

    formatMDHms: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("MMM DD HH:mm:ss");
    },

    formatMDYHm: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("MM/DD/YYYY HH:mm");
    },

    formatDo: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("Do"); // 1st 2nd ... 30th 31st
    },

    formatHmMDY: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("HH:mm MM/DD/YYYY");
    },

    shortestFormateDateMDYHMS: (timestamp) => {
        if (timestamp == undefined || timestamp == 0)
            return;
        return moment(timestamp).format("MM/DD/YYYY HH:mm:ss");
    },

    shortestFormateDateHMMDY: (timestamp) => {
        if (timestamp == undefined || timestamp == 0)
            return;
        return moment(timestamp).format("HH:mm DD/YYYY");
    },

    formatMDYYHMS: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("MM/DD/YYYY HH:mm:ss");
    },

    formatDMYYHMS: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("DD/MM/YYYY HH:mm:ss");
    },

    formateMDYHM: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("MM/DD/YY HH:mm A");
    },

    formateMDYHMS2: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("MM/DD/YY HH:mm:ss");
    },

    formateH: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("HH") + "h";
    },

    formateHM: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("HH:mm");
    },

    formateHMA: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("HH:mm A");
    },

    formatDMY: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("DD MMM YYYY");
    },

    formatDMYHM: (timestamp) => {
        if (timestamp == undefined || timestamp == 0) return;
        return moment(timestamp).format("DD MMM YYYY, HH:mm");
    },

    isEmpty: function (element) {
        if (element === null || element === "undefined" || _.isEmpty(element) || (_.isArray(element) && element.length === 0)) {
            return true;
        }
        return false;
    },

    toFahrenheit: function (value, fractionDigits = 2) {
        return parseFloat((value * 1.8 + 32).toFixed(fractionDigits));
    },

    toCelsius: function (value, fractionDigits = 2) {
        return parseFloat(((value - 32) / 1.8).toFixed(fractionDigits));
    },

    toFromToHouly: function (timestamp) {
        let hour = moment(timestamp).get("hour");
        let anHourLater = moment(timestamp).add(1, "hour").get("hour");

        return `${hour}:00 to ${anHourLater}:00`;
    },

    truncate2Digits: function (value) {
        var floatValue = parseFloat(value);
        var truncatedValue = parseFloat(floatValue.toFixed(2));

        return truncatedValue;
    },
};

export default UtilService;
