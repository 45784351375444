import ApiService from 'Services/apiService';

const CertificateService = {

    requestCertificate: async (key, setCertificate) => {

        let requestOptions = {
            url: `/api/aws/s3`,
            data: {
                key: key
            }
        }

        let result = await ApiService.putData(requestOptions);
        // console.log(result);
        if (result && result.code == 'failure') {
            throw result.message;
        }

        setCertificate(result.certificate);
    }
}

export default CertificateService;