import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import style from "./style.module.css";
import UtilService from "Services/utilService";
import moment from "moment";
import ThingAttributeService from "./associate/ThingAttributeService";
import ThingListDialog from "./associate/ThingListDialog";
import Badge from "@mui/material/Badge";
import { useHistory } from "react-router-dom";

export default function LightCard({ wid, title, alert, callback, reload, setReload }) {
    const history = useHistory();
    const [solarRadiation, setSolarRadiation] = useState();
    const [cumulation, setCumulation] = useState();

    const [hourDiff, setHourDiff] = useState(0);
    const [dayDiff, setDayDiff] = useState(0);

    const [open, setOpen] = useState(false);
    const [thing, setThing] = useState();
    const [attribute, setAttribute] = useState();
    const [lastMeasure, setLastMeasure] = useState();
    const [countAlerts, setCountAlerts] = useState(0);
    const [url, setUrl] = useState();

    const handleClick = (type) => {
        // console.log(type);
        if (type == "body") {
            // console.log(url);
            if (url) {
                // console.log(url + "/no");
                history.push(url + "/no");
            }
        } else if (type == "alert") {
            if (url) {
                // console.log(url + "/scroll");
                history.push(url + "/scroll");
            }
        } else if (type == "refresh") {
            // callback(title, type);
            setReload(moment())
        } else {
            callback(type, thing, attribute);
        }
    };

    useEffect(() => {
        // console.log("--------------------------")
        // console.log(entity.cumulation)

        if (wid) {
            ThingAttributeService.requestLastMeasure(wid, setThing, setAttribute, setLastMeasure, setCountAlerts).then((r) => {
                // console.log("light___");
                // console.log(r);

                if (r && !r.message) {
                    const thingId = r.thing.id;
                    const thingName = r.thing.name;
                    const attributeId = r.attribute.id;
                    const attributeName = r.attribute.name;
                    const clusterName = "_";
                    const unit = r.attribute.unit;
                    const fixedName = r.thing.fixedName;
                    const tabIndex = 0;

                    let url = encodeURI(
                        `${process.env.PUBLIC_URL}/agriculture_water/detail/${thingId}/${thingName}/${attributeId}/${attributeName}/${clusterName}/${fixedName}/${tabIndex}`
                    );
                    setUrl(url);

                    setSolarRadiation(r.lastMeasure);

                    const attrbuteCumulation = r.additionalMeasure.find((a) => a.attribute.name === "cumulation");
                    setCumulation(attrbuteCumulation);

                    const attrbuteCumStartTime = r.additionalMeasure.find((a) => a.attribute.name === "cumulation_start_time");
                    calculateCumulation(attrbuteCumStartTime.value);
                }
            });
        }
    }, [reload]);

    return (
        <Badge color="secondary" badgeContent={countAlerts} max={999}>
            <Box
                sx={{
                    position: "relative",
                    width: 250,
                    height: 100 + (alert ? 10 : 0),
                    backgroundColor: "#0190B1",
                    "&:hover": {
                        backgroundColor: "primary.main",
                        opacity: [0.9, 0.8, 0.7],
                    },
                    color: "white",
                }}
                borderRadius="3px"
            >
                {/* Top */}
                <div className={style.card} style={{ paddingTop: 2 }}>
                    <div className={style.card} style={{ width: "20%" }}>
                        <IconButton size="small" style={{ color: "#FFF" }} onClick={() => handleClick("threshold")}>
                            <i className="fa fa-cog" aria-hidden="true"></i>
                        </IconButton>
                    </div>
                    <div className={style.card} style={{ width: "60%" }}>
                        <Typography variant="subtitle1" sx={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
                            {title}
                        </Typography>
                    </div>
                    <div className={style.card} style={{ flexDirection: "row", width: "20%" }}>
                        <IconButton size="small" style={{ color: "#FFF" }} onClick={() => handleClick("refresh")}>
                            <i className="fa fa-refresh" aria-hidden="true"></i>
                        </IconButton>
                        <IconButton size="small" style={{ color: "#FFF" }} onClick={() => handleClick("schedule")}>
                            <i className="fa fa-tint" aria-hidden="true" style={{ marginLeft: 5 }}></i>
                        </IconButton>
                    </div>
                </div>

                {/* Center */}
                <div className={style.card} style={{ marginTop: 5, marginBottom: 5 }} onClick={() => handleClick("body")}>
                    <div className={style.card} style={{ width: "50%" }}>
                        <Typography variant="h6">{solarRadiation && solarRadiation.value}</Typography>
                        <Typography variant="body">{solarRadiation && "w/m2"}</Typography>
                    </div>
                    <div className={style.card} style={{ width: "50%" }}>
                        <Typography variant="h6">
                            {cumulation && cumulation.value < 1000 && cumulation.value}
                            {cumulation && cumulation.value >= 1000 && Math.round(cumulation.value / 1000)}
                        </Typography>
                        <Typography variant="body">
                            {cumulation && cumulation.value < 1000 && "J"}
                            {cumulation && cumulation.value >= 1000 && "KJ"}
                        </Typography>
                    </div>
                </div>

                {/* Bottom */}
                <div className={style.card}>
                    <div className={style.card} style={{ width: "50%" }}>
                        <Typography variant="subtitle1">{solarRadiation && UtilService.formateHMA(solarRadiation.timestamp * 1000)}</Typography>
                    </div>
                    <div className={style.card} style={{ width: "50%" }}>
                        {solarRadiation && <Typography variant="subtitle1">{dayDiff}</Typography>}
                    </div>
                </div>

                <ThingListDialog open={open} setOpen={setOpen} wid={wid} setReload={setReload} />
            </Box>
        </Badge>
    );

    function calculateCumulation(value) {
        var now = moment();
        var cum = moment.unix(value);

        const hours = now.diff(cum, "hours");
        setHourDiff(hours);

        let dayDiff = "";
        const day = now.diff(cum, "days");
        if (day != 0) {
            dayDiff = day + "d ";
        }

        let hourReminder = hours % 24;
        if (hourReminder == 0) {
            dayDiff += "0h";
        } else {
            dayDiff += hourReminder + "h";
        }

        setDayDiff(dayDiff);
    }
}
