import ApiService from "Services/apiService";
import UtilService from "Services/utilService";

const AlertService = {
    requestAlert: async (thingId, setAlerts, setCount) => {
        var now = new Date();
        var rightNow = now.getTime() + now.getTimezoneOffset() * 60 * 1000;

        now.setDate(now.getDate() - 1);
        var oneDayAgo = now.getTime() + now.getTimezoneOffset() * 60 * 1000;

        let requestOptions = {
            url:
                "/api/alerts?filter=thing.id:eq_" +
                thingId +
                ",state:eq_OPENED&page=1&start=" +
                oneDayAgo +
                "&end=" +
                rightNow +
                "&limit=3&sort=creationDate&dir=DESC",
        };

        // console.log("requestOptions in requestAlert");
        // console.log(requestOptions);

        ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                var alerts = response;
                var data = alerts.data.map((row) => {
                    return {
                        id: row.id,
                        message: row.message,
                        date: UtilService.formateMDYHM(row.creationDate * 1000),
                        state: row.state === "OPENED" ? true : false,
                        level: row.level,
                        displayName: row.thing.fixedName,
                    };
                });
                data["count"] = alerts.totalSize;

                // console.log(data);

                setAlerts(data);
                setCount(alerts.totalSize);
            })
            .catch((e) => console.log(e));
    },

    updateAlertState: async (id, alerts, setAlerts, e) => {
        let preDefinedConfig = {
            url: "/api/alerts/" + id,
            data: {
                id: id,
                comment: "User has confirmed",
                state: "RESOLVED",
            },
        };

        // console.log("updateAlertState...");
        // console.log(preDefinedConfig);

        return ApiService.putData(preDefinedConfig)
            .then((response) => {
                // console.log(response);

                if (response.id === preDefinedConfig.data.id) {
                    setAlerts(
                        alerts.filter((ele) => {
                            return ele.id !== id;
                        })
                    );

                    // Replace Icon
                    e.target.className = e.target.className.replace("fa-circle", "fa-circle-o");
                }
            })
            .catch((e) => {
                console.erro(e);
            });
    },
};

export default AlertService;
