import React, { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import _ from "underscore";
import AssignService from "./AssignService";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 130,
        },
    },
};

export default function CompanyCheckBoxes({ username, groups, readOnly = false }) {
    // const [group, setGroup] = useState(["FLOWINC", "CALSUB", "HARMONY", "ABRO_MANAGEMENT", "ESTHERLAN", "STUDIO_ONE", "RIVERFRONT", "TEST" ]);
    const [group, setGroup] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(["FLOWINC"]);

    useEffect(() => {
        AssignService.requestSusidiaryWithMine(setGroup).then(() => {
            setSelectedGroup(groups);
        });
    }, [groups]);

    const handleChange = (event) => {
        const value = event.target.value;

        // setSelectedGroup(typeof value === "string" ? value.split(",") : value);
        setSelectedGroup(value);

        AssignService.changeGroup(username, "COMPANY_" + value);
    };

    return (
        <div style={{ width: "100%" }}>
            <FormControl sx={{ display: "flex", flexShrink: 1, mt: 1 }} size="small">
                <InputLabel id="chk-label"></InputLabel>
                <Select
                    labelId="chk-label"
                    id="chk"
                    multiple={false}
                    value={selectedGroup}
                    onChange={(e) => handleChange(e)}
                    input={<OutlinedInput label="" />}
                    renderValue={(selected) => {
                        return <div style={{ color: "#7B7B7B", paddingLeft: 5, fontFamily: "Open Sans", fontSize: 14 }}>{selected}</div>;
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ readOnly: readOnly }}
                >
                    {group.map((name) => (
                        <MenuItem key={name} value={name} style={{ paddingLeft: 5, fontFamily: "Open Sans", fontSize: 14, color: "#7B7B7B" }}>
                            <ListItemText primary={name} style={{ marginLeft: 5, fontFamily: "Open Sans", fontSize: 14 }} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
