import ApiService from "Services/apiService";

const UserMenuService = {
    updateUserLanguage: async (cognitoUserName, language) => {
        let requestOptions = {
            url: `/v2/cloud_user/language`,
            data: {
                cognitoUserName: cognitoUserName,
                language: language,
            },
        };

        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default UserMenuService;
