import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';
import Catalog from '../../catalog/Catalog'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../../components/ui/BootstrapDialog'

export default function CatalogDialog({ open, setOpen, callback }) {

    const handleClose = () => {
        setOpen(false);
    };

    const handleCatalog = (product) => {
        callback(product)
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="xl"
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Catalog
                </BootstrapDialogTitle>
                <DialogContent dividers sx={{ height: 800 }}>
                    <Catalog callback={handleCatalog} />
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
