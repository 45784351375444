import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import moment from "moment";
import UtilService from "Services/utilService";

export default function CompareChart({ thLow, thHigh, thRate, handlePoint, type, year1, year2, name, componentRef }) {
    const [options, setOptions] = useState({});

    // Configuration for ReactHighcharts
    const configChart = {
        title: {
            text: "",
        },
        xAxis: {
            type: "datetime",
            labels: {
                overflow: "justify",
                formatter: function () {
                    return UtilService.formatMD(this.value);
                },
            },
        },
        yAxis: [
            {
                title: {
                    text: "Temperature",
                },
                min: 0,
                plotLines: [
                    {
                        color: "#FF0000",
                        width: 2,
                        value: thLow,
                    },
                    {
                        color: "#FF0000",
                        width: 2,
                        value: thHigh,
                    },
                ],
            },
        ],
        tooltip: {
            formatter: function () {
                return this.series.userOptions.name + "<br/>" + UtilService.shortestFormateDateMDY(this.x) + "</br>" + "<b>" + this.y + "°C</b>";
            },
        },
        plotOptions: {
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3,
                    },
                },
                marker: {
                    enabled: false,
                },
            },
        },
        chart: {
            type: type,
            height: 600,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: true,
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false,
        },
        series: [
            {
                name: `${name} - ${year1}`,
                data: [
                    [Date.UTC(1971, 0, 4), 11.6],
                    [Date.UTC(1971, 0, 17), 12.55],
                    [Date.UTC(1971, 0, 24), 12.62],
                    [Date.UTC(1971, 1, 4), 12.5],
                    [Date.UTC(1971, 1, 14), 12.42],
                    [Date.UTC(1971, 2, 6), 12.74],
                    [Date.UTC(1971, 2, 14), 14.62],
                    [Date.UTC(1971, 2, 24), 22.6],
                    [Date.UTC(1971, 3, 1), 22.81],
                    [Date.UTC(1971, 3, 11), 22.63],
                    [Date.UTC(1971, 3, 27), 22.77],
                    [Date.UTC(1971, 4, 4), 22.68],
                    [Date.UTC(1971, 4, 9), 22.56],
                    [Date.UTC(1971, 4, 14), 23.39],
                    [Date.UTC(1971, 4, 19), 23.3],
                    [Date.UTC(1971, 5, 4), 23],
                    [Date.UTC(1971, 5, 9), 13.85],
                    [Date.UTC(1971, 5, 14), 13.49],
                    [Date.UTC(1971, 5, 19), 13.27],
                    [Date.UTC(1971, 5, 24), 10.99],
                    [Date.UTC(1971, 5, 29), 10.67],
                    [Date.UTC(1971, 6, 3), 10.18],
                    [Date.UTC(1971, 6, 4), 15],
                ],
                events: {
                    click: handlePoint,
                },
            },
            {
                name: `${name} - ${year2}`,
                data: [
                    [Date.UTC(1971, 0, 5), 30.85],
                    [Date.UTC(1971, 0, 11), 27.89],
                    [Date.UTC(1971, 0, 17), 21.04],
                    [Date.UTC(1971, 0, 20), 22.02],
                    [Date.UTC(1971, 0, 25), 23.03],
                    [Date.UTC(1971, 0, 30), 21.39],
                    [Date.UTC(1971, 1, 5), 20.77],
                    [Date.UTC(1971, 1, 26), 22.12],
                    [Date.UTC(1971, 3, 19), 18.1],
                    [Date.UTC(1971, 4, 9), 17.7],
                    [Date.UTC(1971, 4, 29), 10.85],
                    [Date.UTC(1971, 5, 7), 10],
                    [Date.UTC(1971, 5, 14), 14.49],
                    [Date.UTC(1971, 5, 19), 15.27],
                    [Date.UTC(1971, 5, 24), 16.99],
                    [Date.UTC(1971, 5, 29), 17.67],
                    [Date.UTC(1971, 6, 3), 19.18],
                    [Date.UTC(1971, 6, 4), 16],
                ],
                cursor: "pointer",
                events: {
                    click: handlePoint,
                },
            },
        ],
        navigator: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download",
                },
            },
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };

    useEffect(() => {
        setOptions(configChart);
    }, [type, thLow, thHigh, handlePoint, year1, year2]);

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return <HighchartsReact ref={componentRef} highcharts={Highcharts} options={options} />;
}
