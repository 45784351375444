import * as React from "react";
import Chip from "@mui/material/Chip";

export default function CustomStatusChip({ status }) {
    const lowercaseStatus = status.toUpperCase();

    return (
        <>
            {(lowercaseStatus === "ACTIVE" || lowercaseStatus === "CONNECTED") && (
                <Chip
                    label={status.replace(/_/g, " ")}
                    size="small"
                    sx={{
                        fontSize: "11px",
                        fontWeight: 400,
                        color: "#fff",
                        backgroundColor: "#9cc96b",
                        "& .MuiChip-avatar": {
                            backgroundColor: "#9cc96b",
                        },
                    }}
                />
            )}
            {(lowercaseStatus === "INACTIVE" ||
                lowercaseStatus === "PROVISIONED" ||
                lowercaseStatus === "NOT_CONNECTED" ||
                lowercaseStatus === "JOIN_RECEIVED") && (
                <Chip
                    label={status.replace(/_/g, " ")}
                    size="small"
                    sx={{
                        fontSize: "11px",
                        fontWeight: 400,
                        color: "#404044",
                        backgroundColor: "#E7E955",
                        "& .MuiChip-avatar": {
                            backgroundColor: "#E7E955",
                        },
                    }}
                />
            )}
            {lowercaseStatus === "ERROR" && (
                <Chip
                    label={status}
                    size="small"
                    sx={{
                        fontSize: "11px",
                        fontWeight: 400,
                        color: "#FFFFFF",
                        backgroundColor: "#E44959",
                        "& .MuiChip-avatar": {
                            backgroundColor: "#E44959",
                        },
                    }}
                />
            )}
            {lowercaseStatus === "CREATED" && (
                <Chip
                    label={status}
                    size="small"
                    sx={{
                        fontSize: "11px",
                        fontWeight: 400,
                        color: "#FFFFFF",
                        backgroundColor: "#E44959",
                        "& .MuiChip-avatar": {
                            backgroundColor: "#E7E955",
                        },
                    }}
                />
            )}
        </>
    );
}
