import ApiService from "Services/apiService";
import moment from "moment";

const PRODUCT_HOTDROP = "921474ed-df84-4f93-8a53-ea6ba8a7349e";
const ASSET_ID = "91cacc68-8da6-4abd-82f0-98a01addbabc";

const ChartGeneralService = {
    requestThingAttribute: async (wid, setRows, page, pageSize, setRowCount, sort) => {
        let attributeName = "";

        switch (wid) {
            case "WID_ELECTRICITY_COUNTER_1":
            case "WID_ELECTRICITY_COUNTER_2":
            case "WID_ELECTRICITY_COUNTER_3":
                attributeName = "averageAmps";
                break;

            default:
                break;
        }

        let params = {
            page: page,
            pageSize: pageSize,
            productId: [PRODUCT_HOTDROP],
            attributeName: attributeName,
            sort: sort.map((s) => s.field + ":" + s.sort).join(","),
        };

        // console.log("/////-------");
        // console.log(params);

        return ApiService.getDataWithParams("/v2/things/attributes", params).then((response) => {
            // console.log("/////-------");
            // console.log(response);

            let temp = [];

            response.content.map((t) => {
                // console.log(t);

                const attributes = t.product.model.attribute;
                const mappedAttribute = attributes.map((a) => {
                    let entity = {
                        id: t.id + "," + a.id,
                        thingId: t.id,
                        fixedName: t.fixedName,
                        company: t.company,
                        status: t.status,
                        // "attributes.id": a.id,
                        "attributes.name": a.name,
                    };

                    return entity;
                });

                // console.log("//--//--")
                // console.log(mappedAttribute);
                mappedAttribute.forEach((m) => {
                    // console.log(m);
                    temp.push(m);
                });
            });

            // console.log(temp);

            setRows(temp);
            setRowCount(response.totalElements);
        });
    },

    associateAttribute: async (thingId, attributeId, wid) => {
        let requestOptions = {
            url: `/v2/asset/associate`,
            data: {
                assetId: ASSET_ID,
                thingId: thingId,
                attributeId: attributeId,
                attributeName: wid,
            },
        };

        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                // console.error(e);
            });
    },

    requestMappedAttribute: async (wid, setSelectedRow) => {
        let requestOptions = {
            url: `/v2/asset/mapped/${ASSET_ID}/${wid}`,
        };

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            if (!r.message && r.thing && r.attribute) {
                setSelectedRow(r.thing.id + "," + r.attribute.id);
            }
        });
    },

    requestHistoryAverage: async (thingId, attributeId, handleChartData, interval) => {
        const filter = `filter=thing.id:eq_${thingId},attribute.id:eq_${attributeId}`;

        let start = moment().valueOf();
        if (interval == "hour") {
            start = moment().subtract(1, "days").valueOf();
        } else if (interval == "day") {
            start = moment().subtract(1, "months").valueOf();
        } else if (interval == "month") {
            start = moment().subtract(1, "year").valueOf();
        } else if (interval == "none") {
            // start = moment().subtract(1, 'days').valueOf();
            start = moment().subtract(2, "years").valueOf();
        }

        const period = `&start=${start}&end=${moment().valueOf()}`;

        let requestOptions = {
            url: `/stats/measurements?${filter}&interval=${interval}${period}&time=1`,
        };

        // console.log("requestHistoryAverage");
        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                let chartData = [];
                response.forEach((ele) => {
                    chartData.push([ele.last, ele.value, ele.unit]);
                });

                handleChartData(chartData);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestHistoryAll: async (thingId, attributeId, handleChartData, interval) => {
        const filter = `filter=thing.id:eq_${thingId},attribute.id:eq_${attributeId}`;

        let start = moment().subtract(1, "years").valueOf();
        const period = `&start=${start}&end=${moment().valueOf()}`;

        let requestOptions = {
            url: `/stats/measurements/all?${filter}&interval=${interval}${period}`,
        };

        // console.log("requestHistoryAll...");
        // console.log(requestOptions);
        // console.log(moment(start).format("LLLL"));

        handleChartData([]);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                let chartData = [];

                response.forEach((ele) => {
                    // console.log(UtilService.formatMDHms(ele.last))
                    chartData.push([ele.last, ele.value, ele.unit]);
                });

                // console.log(chartData);
                handleChartData(chartData);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestLastMeasure: async (wid, setThing, setAttribute, setLastMeasure, setCountAlerts) => {
        let requestOptions = {
            url: `/v2/asset/${ASSET_ID}/${wid}`,
        };

        // console.log("requestOptions....");
        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);
                if (response) {
                    if (response.thing) {
                        setThing(response.thing);
                    }
                    if (response.attribute) {
                        setAttribute(response.attribute);
                    }
                    if (response.lastMeasure) {
                        setLastMeasure(response.lastMeasure);
                    }
                    if (response.lastMeasure) {
                        setCountAlerts(response.countAlerts);
                    }
                }
                return response;
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default ChartGeneralService;
