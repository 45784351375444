import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "underscore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ConfirmDialog from "./ConfirmDialog";
import ShareService from "./ShareService";
import CustomStyle from "Components/CustomStyle";

export default function SetupIcicle(props) {
    let history = useHistory();
    let errors = {
        hostname: false,
        user: false,
        password: false,
        database: false,
        description: false,
        message: "",
    };
    const setActiveStep = props.setActiveStep;

    const [txtMessageFormat, setTxtMessageFormat] = useState();
    const [companyName, setCompanyName] = useState();

    const [flowId, setFlowId] = useState();
    const [forwardTargetId, setForwardTargetId] = useState();

    const [hostname, setHostname] = useState();
    const [user, setUser] = useState();
    const [password, setPassword] = useState();
    const [database, setDatabase] = useState();
    const [description, setDescription] = useState();

    const [chkMessage, setChkMessage] = useState(false);
    const [chkThing, setChkThing] = useState(false);
    const [chkProduct, setChkProduct] = useState(false);
    const [chkGateway, setChkGateway] = useState(false);

    useEffect(() => {
        setActiveStep(1);

        ShareService.getCompany(setCompanyName).then((companyName) => {
            ShareService.getFlowTargetIcicle(
                companyName,
                setFlowId,
                setForwardTargetId,
                setHostname,
                setUser,
                setPassword,
                setDatabase,
                setChkMessage,
                setChkThing,
                setChkProduct,
                setChkGateway
            );
        });

        ShareService.requestMessageFormat("ICICLE", setTxtMessageFormat, [chkMessage, chkThing, chkProduct, chkGateway]);

        setDescription(ShareService.DESC_SYSTEM);
    }, []);

    const handleSave = () => {
        ShareService.getCompany(setCompanyName).then((companyName) => {
            ShareService.getFlowTargetIcicle(
                companyName,
                setFlowId,
                setForwardTargetId,
                setHostname,
                setUser,
                setPassword,
                setDatabase,
                setChkMessage,
                setChkThing,
                setChkProduct,
                setChkGateway
            ).then(() => {
                if (!forwardTargetId) {
                    // create a flow, enabled=false
                    ShareService.createFlow(companyName, "Share-Icicle").then((flowId) => {
                        // Insert Icicle integration, enabled=false
                        ShareService.insertForwardIcicle(hostname, user, password, database, [chkMessage, chkThing, chkProduct, chkGateway]).then(
                            (savedForwardTargetId) => {
                                // Insert Action
                                setForwardTargetId(savedForwardTargetId);
                                ShareService.createAction(flowId, savedForwardTargetId).then(() => props.goCompletePage());
                            }
                        );
                    });
                } else {
                    // Update configuration
                    ShareService.updateForwardIcicle(forwardTargetId, hostname, user, password, database, [chkMessage, chkThing, chkProduct, chkGateway]).then(
                        () => props.goCompletePage()
                    );
                }
            });
        });
    };

    const handleCancel = () => {
        props.goFirstPage();
    };

    const handleDelete = () => {
        ShareService.deleteFlow(flowId);
        ShareService.deleteForward(forwardTargetId);
        props.goCompletePage();
    };

    const handleMsgFormat = (e) => {
        const value = e.target.checked;
        if (e.target.name === "chkMessage") {
            setChkMessage(value);
            ShareService.requestMessageFormat("ICICLE", setTxtMessageFormat, [value, chkThing, chkProduct, chkGateway]);
        } else if (e.target.name === "chkThing") {
            setChkThing(value);
            ShareService.requestMessageFormat("ICICLE", setTxtMessageFormat, [chkMessage, value, chkProduct, chkGateway]);
        } else if (e.target.name === "chkProduct") {
            setChkProduct(value);
            ShareService.requestMessageFormat("ICICLE", setTxtMessageFormat, [chkMessage, chkThing, value, chkGateway]);
        } else if (e.target.name === "chkGateway") {
            setChkGateway(value);
            ShareService.requestMessageFormat("ICICLE", setTxtMessageFormat, [chkMessage, chkThing, chkProduct, value]);
        }
    };

    const handleValue = (e) => {
        const value = e.target.value;

        if (e.target.name == "txtUrl") {
            setHostname(value);
        } else if (e.target.name == "txtUser") {
            setUser(value);
        } else if (e.target.name == "txtPassword") {
            setPassword(value);
        } else if (e.target.name == "txtDatabase") {
            setDatabase(value);
        }
    };

    const closeDialog = (answer) => {
        if (!answer) {
            props.goFirstPage();
        }
    };

    return (
        <>
            <Paper elevation={6} spacing={2} sx={{ width: "100%" }}>
                {props.on && <ConfirmDialog callback={closeDialog}></ConfirmDialog>}

                <Grid container padding={2}>
                    <Grid item xs={6} sm={6}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" style={{ backgroundColor: "#9CC96B" }} onClick={() => handleSave()}>
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                        <Typography>Connection</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={2} sm={2}>
                                <Typography>User</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtUser"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={user}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.user ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Password</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtPassword"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={password}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.password ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </>
    );
}
