import { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import ThingService from "Services/thing/ThingService";
import ChartGeneral from "Components/chart/ChartGeneral";
import AppContext from "Components/AppContext";

export default function TempDetailMain() {
    const { thingId } = useParams();
    const [thing, setThing] = useState();
    const [attribute, setAttribute] = useState();

    const ctx = useContext(AppContext);
    ctx.updateTitle("TempMon");

    useEffect(() => {
        // console.log("thingId....");
        // console.log(thingId);

        ThingService.requestThing(thingId).then((t) => {
            setThing(t);

            ThingService.requestAttributes(t.product.model.id).then((attr) => {
                // console.log(attr.data);
                setAttribute(attr.data.filter((a) => a.name.toLowerCase() == "temperature" || a.name.toLowerCase() == "humidity"));
            });
        });
    }, [thingId]);

    return (
        <Box sx={{ pt: 10, pl: 4, pr: 4 }}>
            {thing && (
                <Box sx={{ flexGrow: 1, mt: 4, width: "100%" }}>
                    <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{thing.fixedName}</span>
                </Box>
            )}
            {attribute &&
                attribute.map((attr) => {
                    return (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <ChartGeneral thingId={thingId} attributeId={attr.id} />
                        </Box>
                    );
                })}
        </Box>
    );
}
