import React from "react";
import Box from "@mui/material/Box";
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

function options(title, subtitle, unit, averages, callback) {
    return {
        chart: {
            type: "column",
        },
        title: {
            text: title,
        },
        subtitle: {
            text: subtitle,
        },
        xAxis: {
            type: "category",
            labels: {
                rotation: -45,
                style: {
                    fontSize: "13px",
                    fontFamily: "Verdana, sans-serif",
                },
            },
        },
        yAxis: {
            title: {
                text: null,
            },
            min: 0,
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: unit,
        },
        series: [
            {
                name: title,
                data: averages,
                // zIndex: 1,
                // marker: {
                //     fillColor: 'white',
                //     lineWidth: 2,
                //     lineColor: Highcharts.getOptions().colors[0]
                // },
                // showInLegend: false
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: "#FFFFFF",
                    align: "right",
                    // format: '{point.y:.1f}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: "13px",
                        fontFamily: "Arial",
                    },
                },
                events: {
                    click: function (e) {
                        // console.log(e.point.name);
                        callback(e.point.name); //  ex) Slege-Salle A
                    },
                },
            },
        ],
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };
}

export default function MaxByRoomChart({ siteName, averages, callback }) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ width: 600 }}>
                <HighchartsReact highcharts={Highcharts} options={options(siteName, "Maximum CO²", "ppm", averages, callback)} />
            </Box>
        </Box>
    );
}
