import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Item from '../../../../components/ui/Item'
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { StaticTimePicker } from '@mui/x-date-pickers-pro';
import Stack from '@mui/material/Stack';
import moment from 'moment'
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    // '& .MuiDialogContent-root': {
    //     padding: theme.spacing(2),
    // },
    // '& .MuiDialogActions-root': {
    //     padding: theme.spacing(1),
    // },
}));

// export interface DialogTitleProps {
//     id: string;
//     children?: React.ReactNode;
//     onClose: () => void;
// }

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    size="large">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function ArgiScheduleDialog({ open, setOpen }) {
    // const [value, setValue] = useState(new Date('2014-08-18T21:11:54'));
    const [value, setValue] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        // console.log("useEffect in ArgiScheduleDialog")
        // console.log(moment());

        setValue(moment());
        setStartTime(moment());
        setEndTime(moment());

        // if (endTime) {
        //     let dur = endTime.diff(startTime, 'minutes')
        //     console.log("dur=" + dur)
        // }

    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="md"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Irrigation sequence
                </BootstrapDialogTitle>

                <Box sx={{ m: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <Grid container spacing={0}>
                            <Grid item xs={6} md={6}>
                                <Item>
                                    <TextField id="txt-water-quantity" label="Water quantity​" variant="outlined" size="small" />
                                </Item>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Item>
                                    <TextField id="txt-radiation" label="Radiation" variant="outlined" value={120}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: 'right' }
                                            }
                                        }}
                                        size="small"
                                    />
                                </Item>
                            </Grid>

                            <Grid item xs={4} md={4}>
                                <Item>
                                    <Typography variant="h6" gutterBottom>
                                        Start
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Item>
                                    <Typography variant="h6" gutterBottom>
                                        <DesktopDatePicker
                                            label="Date"
                                            inputFormat="MM/dd/yyyy"
                                            value={value}
                                            onChange={handleChange}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Item>
                                    <Typography variant="h6" gutterBottom>
                                        End
                                    </Typography>
                                </Item>
                            </Grid>

                            {/* Start Clock */}
                            <Grid item xs={6} md={6}>
                                <Item>
                                    <StaticTimePicker
                                        toolbarTitle=""
                                        displayStaticWrapperAs="mobile"
                                        value={startTime}
                                        onChange={(newValue) => {
                                            let dur = moment(endTime).diff(newValue, 'minutes')

                                            if (dur > 0) {
                                                setStartTime(newValue);
                                                setDuration(dur);
                                            } else {
                                                setStartTime(endTime);
                                                setDuration(0);
                                            }
                                        }}
                                        maxDate={endTime}
                                        renderInput={(params) => <TextField {...params} size="small" />}
                                        componentsProps={{ actionBar: { actions: [] } }}
                                    />
                                </Item>
                            </Grid>

                            {/* End Clock */}
                            <Grid item xs={6} md={6}>
                                <Item>
                                    <StaticTimePicker
                                        toolbarTitle=""
                                        displayStaticWrapperAs="mobile"
                                        value={endTime}
                                        onChange={(newValue) => {
                                            let dur = moment(newValue).diff(startTime, 'minutes')

                                            if (dur > 0) {
                                                setEndTime(newValue);
                                                setDuration(dur);
                                            } else {
                                                setEndTime(startTime);
                                                setDuration(0);
                                            }
                                        }}
                                        minDate={startTime}
                                        renderInput={(params) => <TextField {...params} size="small" />}
                                        componentsProps={{ actionBar: { actions: [] } }}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4} >
                                <Stack direction={"row"} sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
                                    <TextField id="txtDuration" label="Duration" variant="outlined" value={duration}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: 'right' }
                                            }
                                        }}
                                        size="small"
                                    />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '60px' }}>
                                        Min
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </Box>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} variant='contained'>
                        Add
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
