import ApiService from "Services/apiService";
import moment from "moment";

const HistoryService = {
    requestHistoryValues: async (thingId, attributeId, processResponse) => {
        const thingRequest = {
            url: `/api/things/${thingId}`,
        };

        const thingResponse = await ApiService.getData(thingRequest);
        if (!thingResponse.lastActivityDate) {
            return;
        }

        const lastActivityDate = thingResponse.lastActivityDate;

        let now = moment.unix(lastActivityDate).add(1, "hour").valueOf();
        let since = moment.unix(lastActivityDate).subtract(2, "hours").valueOf();
 
        let request = {
            url: "/stats/measurements",
            params: {
                filter: `thing.id:eq_${thingId},attribute.id:eq_${attributeId}`,
                time: 1,
                interval: "minute",
                start: since,
                end: now,
            },
        };

        await ApiService.getData(request)
            .then((response) => {
                // console.log(response)
                processResponse(response);
            })
            .catch((e) => console.log(e));
    },
};

export default HistoryService;
