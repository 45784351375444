import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import FavoriteService from "./FavoriteService";
import AttributeWidget from "Components/widgets/AttributeWidget";

export default function FavoriteCard({ measure, user, setReload }) {
    const [entity, setEntity] = useState();

    useEffect(() => {
        // console.log("useEffect in FavoriteCard");
        // console.log(measure);

        if (!entity) {
            setEntity({
                value: measure.value,
                unit: measure.attribute.unit,
                date: measure.timestamp,
            });
        }
    }, []);

    const handleStar = (e, thingId, attributeId) => {
        e.stopPropagation();
        FavoriteService.removeFavoriteByAttribute(user.cognitoUserName, thingId, attributeId).then(() => setReload());
    };

    return (
        <Paper elevation={3} sx={{ m: 1, p: 3 }}>
            <AttributeWidget
                variant="favorite"
                title={measure.attribute.name}
                entity={entity}
                callback={(e) => handleStar(e, measure.thing.id, measure.attribute.id)}
            />
        </Paper>
    );
}
