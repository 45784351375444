import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";

export default function ExportMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { t, i18n } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleReport = (type) => {
        props.callback(type);
    };

    return (
        <div>
            <div className={style.btnContainer} style={{ width: "90px", color: "rgb(63, 81, 181)" }} onClick={(e) => handleClick(e)}>
                <i className="fa fa-download" aria-hidden="true" style={{ paddingRight: "5px" }} />
                <span style={{ fontSize: "0.8125rem" }}>{t("btn.export_upper")}</span>
            </div>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem onClick={() => handleReport("PDF")}>{t("datagrid.down_pdf")}</MenuItem>
                <MenuItem onClick={() => handleReport("XLSX")}>{t("datagrid.down_xlsx")}</MenuItem>
                <MenuItem onClick={() => handleReport("CSV")}>{t("datagrid.down_csv")}</MenuItem>
                <MenuItem onClick={() => handleReport("HTML")}>{t("datagrid.down_html")}</MenuItem>
            </Menu>
        </div>
    );
}
