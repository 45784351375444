import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import StateWidget from "./StateWidget";
import StateService from "./StateService";
import Item from "../../../components/ui/Item";

export default function StateBarMain() {
    const [states, setStates] = useState([]);

    useEffect(() => {
        StateService.requestCountValueInMeasures(setStates);
    }, []);

    return (
        <>
            {states && (
                <Item sx={{ ml: "10px", mr: "10px" }}>
                    <Box sx={{ display: "flex", gap: 1 }}>
                        {states && states.filter((s) => s.value === "OK").map((s) => <StateWidget value={s.value} count={s.count} />)}
                        {states && states.filter((s) => s.value !== "OK").map((s) => <StateWidget value={s.value} count={s.count} />)}
                    </Box>
                </Item>
            )}
        </>
    );
}
