import { useEffect } from "react";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";
import WaterIcon from "@mui/icons-material/Water";
import WaterDamageIcon from "@mui/icons-material/WaterDamage";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import Battery1BarIcon from "@mui/icons-material/Battery1Bar";

export default function StateIcon({ state }) {
    useEffect(() => {
        // do thing
    }, [state]);

    return (
        <>
            {state && state.toLowerCase() === "Backflow".toLowerCase() && (
                <>
                    <FastRewindIcon />
                </>
            )}
            {state && state.toLowerCase() === "Dry".toLowerCase() && (
                <>
                    <FormatColorResetIcon />
                </>
            )}
            {state && state.toLowerCase() === "Burst".toLowerCase() && (
                <>
                    <WaterIcon />
                </>
            )}
            {state && state.toLowerCase() === "Leakage".toLowerCase() && (
                <>
                    <WaterDamageIcon />
                </>
            )}
            {state && state.toLowerCase() === "Tamper".toLowerCase() && (
                <>
                    <LinkOffIcon />
                </>
            )}
            {state && state.toLowerCase() === "Low Battery".toLowerCase() && (
                <>
                    <Battery1BarIcon />
                </>
            )}
        </>
    );
}
