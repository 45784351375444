import * as React from "react";
import Box from "@mui/material/Box";
import { unstable_useEnhancedEffect as useEnhancedEffect } from "@mui/utils";
import { useGridApiContext, LicenseInfo } from "@mui/x-data-grid-pro";
import { SingleInputTimeRangeField } from "@mui/x-date-pickers-pro/SingleInputTimeRangeField";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export function renderTimeRange(params) {
    // console.log("renderTimeRange....");
    // console.log(params);
    return <SingleInputTimeRangeField readOnly value={params.value} />;
}

function RatingEditInputCell(props) {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef();

    const handleChange = (newValue) => {
        // console.log("handleChange...." + id);
        // console.log(newValue);
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    useEnhancedEffect(() => {
        // console.log("useEnhancedEffect....");
        // console.log(props);

        if (hasFocus && ref.current) {
            const input = ref.current.querySelector(`input[value="${value}"]`);
            input?.focus();
        }
    }, [hasFocus, value]);

    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                height: "100%",
            }}
        >
            <SingleInputTimeRangeField ref={ref} name="rating" value={value} onChange={handleChange} fullWidth sx={{ p: 0, width: "100%", height: "100%" }} />
        </Box>
    );
}
export const renderTimeRangeEditInputCell = (params) => {
    // console.log("renderTimeRangeEditInputCell...");
    // console.log(params);
    return <RatingEditInputCell {...params} />;
};
