import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import QuestionMark from "Assets/images/question_mark.png";
import StateIcon from "../thingDetail/StateIcon";
import WidgetTinyChart from "./WidgetTinyChart";

export default function WidgetComposite({ ele, user, callback }) {
    const [thing, setThing] = useState(ele);
    const [measurements, setMeasurements] = useState();
    const [unit, setUnit] = useState("");
    const [state, setState] = useState();
    const [tenant, setTenant] = useState();

    useEffect(() => {
        if (thing.lastMeasurements) {
            if (!measurements) {
                setMeasurements(thing.lastMeasurements.measurements);
                setUnit(thing.lastMeasurements.units.volume);

                if (thing.lastMeasurements.measurements.stateMessages) {
                    const state = thing.lastMeasurements.measurements.stateMessages[0];
                    setState(state);
                }
            }
        }

        if (!tenant && thing.tenant) {
            setTenant(thing.tenant.firstName + " " + thing.tenant.lastName);
        }
    }, [ele, user]);

    const handleClick = () => {
        // console.log(thing);
        callback(thing.id);
    };

    return (
        <>
            {thing && (
                <Box sx={{ width: 550, m: 1, p: 0, borderRadius: "50px", cursor: "pointer", boxShadow: "5px 5px 5px #BDBDBD" }} onClick={handleClick}>
                    <Grid container spacing={0} sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                        <Grid item xs={2} sx={{ borderRadius: "50px", p: 1 }}>
                            {/* <Item sx={{ border: "1px solid transparent", borderRadius: "50px" }}> */}
                            {thing.product && (
                                <img
                                    width="auto"
                                    height="auto"
                                    src={`/static/catalog/products/${thing.product.id}/image.png`}
                                    alt="Product"
                                    style={{ width: 80, height: 80, borderRadius: "50px" }}
                                />
                            )}

                            {!thing.product && <img src={<QuestionMark />} alt="Product" style={{ width: 100, height: 100, borderRadius: "50px" }} />}
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", pr: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                {measurements?.volume ? `${measurements.volume} ${unit}` : "No data"}
                            </Typography>

                            {/* {thing.id} */}
                        </Grid>
                        <Grid item xs={1}>
                            <WidgetTinyChart thing={thing} user={user} />
                        </Grid>
                        <Grid item xs={1}>
                            <StateIcon state={state} />
                        </Grid>
                        <Grid item xs={4} sx={{ borderRadius: "50px" }}>
                            <Typography variant="subtitle1" gutterBottom>
                                {tenant}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}
