import Highcharts from "highcharts/highcharts.js";
import moment from "moment";

export function chartOption(temps) {
    return {
        chart: {
            type: "solidgauge",
            height: 110,
        },

        title: null,

        pane: {
            center: ["50%", "85%"],
            size: "140%",
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
                innerRadius: "94%",
                outerRadius: "100%",
                shape: "arc",
            },
        },

        exporting: {
            enabled: false,
        },

        tooltip: {
            enabled: false,
        },

        yAxis: {
            min: 0,
            max: 100,
            stops: [
                [0, "#38B3B9"],
                [0.99, "#38B3B9"],
                [1, "#F05622"],
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            title: {
                y: -70,
            },
            labels: {
                y: 16,
                enabled: false,
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true,
                },
            },
        },
        series: [
            {
                name: "RPM",
                data: [
                    {
                        y: temps.percentage == undefined ? 0 : temps.percentage,
                        radius: 100,
                        innerRadius: 95,
                        outerRadius: 99,
                    },
                ],
                dataLabels: {
                    format: '<div style="text-align:center">' +
                        '<span style="font-size:25px">{y:.1f}</span><br/>' +
                        '<span style="font-size:12px;opacity:0.4">' +
                        "* 1000 / min" +
                        "</span>" +
                        "</div>",
                    enabled: false,
                },
                tooltip: {
                    valueSuffix: " revolutions/min",
                },
            },
        ],
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };
}
