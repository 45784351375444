import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ChartGeneral from "Components/chart/ChartGeneral";
import { useParams } from "react-router-dom";
import ThingService from "Services/thing/ThingService";

export default function ChartGeneralMain() {
    const { thingId, attributeId } = useParams();
    const [thing, setThing] = useState();

    useEffect(() => {
        ThingService.requestThing(thingId).then((t) => {
            setThing(t);
        });
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 10, ml: 4, mr: 4 }}>
            {thing && (
                <Box sx={{ flexGrow: 1, mt: 4, width: "100%" }}>
                    <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{thing.fixedName}</span>
                </Box>
            )}

            <ChartGeneral thingId={thingId} attributeId={attributeId} />
        </Box>
    );
}
