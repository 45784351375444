import { createRef } from 'react';
import { useState, useEffect } from 'react'
import style from './style.module.css';

export default function ToggleButton(props) {

    const [on, setOn] = useState(props.on);
    const handleToggle = props.callback;

    useEffect(() => {
        //handleToggle(on);
    }, [on])

    const handleClick = () => {
        setOn(() => {
            handleToggle(!on);
            return !on;
        });
    }

    return (
        <div className="border border-danger" className={style.btnContainer} style={{ width: '125px', color: 'rgb(63, 81, 181)' }}
            onClick={() => handleClick()}>
            {on && <i className="fa fa-bell" aria-hidden="true" style={{ paddingRight: '5px' }}></i>}
            {!on && <i className="fa fa-bell-o" aria-hidden="true" style={{ paddingRight: '5px' }}></i>}
            <span style={{ fontSize: '0.8125rem' }}>{props.on ? 'On' : 'Off'}{(props.all ? '(ALL)' : '')}</span>
        </div>
    )
};

