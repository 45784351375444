import ApiService from "Services/apiService";
import moment from "moment-timezone";
import UtilService from "Services/utilService";
import { ATTRIBUTE_AMP_CONSUMPTION } from "./DetailService";

const GraphService = {
    requestHourlyDeltaVolume: async (thingId, interval, startDate, user) => {
        let timezone = user.zoneinfo;

        let attributeId = ATTRIBUTE_AMP_CONSUMPTION;
        const filter = `filter=thing.id:eq_${thingId},attribute.id:eq_${attributeId}`;

        let start = startDate;
        let end = startDate;

        start = moment(start).tz(timezone).startOf("day");
        end = moment(startDate).tz(timezone).add(1, "days").startOf("day");

        let intervalVal = "";
        if (interval == "day") {
            start = moment(start).tz(timezone).startOf("day");
            end = moment(startDate).tz(timezone).add(1, "days").startOf("day");
            intervalVal = "hours";
        } else if (interval == "week") {
            start = moment(start).tz(timezone).startOf("week");
            end = moment(startDate).tz(timezone).endOf("week");
            intervalVal = "days";
        } else if (interval == "month") {
            start = moment(start).tz(timezone).startOf("month");
            end = moment(startDate).tz(timezone).endOf("month");
            intervalVal = "days";
        } else if (interval == "year") {
            start = moment(start).tz(timezone).startOf("year");
            end = moment(startDate).tz(timezone).endOf("year");
            intervalVal = "months";
        }

        const period = `&start=${moment(start).valueOf()}&end=${moment(end).valueOf()}`;

        let requestOptions = {
            url: `/stats/measurements/all?${filter}&interval=${intervalVal}${period}&time=1`,
        };

        // console.log("requestHourlyDeltaVolume.requestOptions...........................");
        // console.log("thingId=" + thingId);
        // console.log("attributeId=" + attributeId);
        // console.log(start + ", " + moment(start).format("LLLL"));
        // console.log(end + ", " + moment(end).format("LLLL"));

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                let chartData = [];

                if (interval == "day") {
                    response.forEach((ele) => {
                        // const baseTime = moment.utc(ele.start).tz(timezone);
                        // chartData.push([UtilService.formateH(baseTime), parseFloat(parseFloat(ele.value).toFixed(1)), ele.unit, baseTime]);
                    });

                    const sumByHor = {};

                    response.forEach((data) => {
                        // America/Los_Angeles or America/Vancouver
                        // America/New_York
                        const date = moment.utc(data.start).tz(timezone);
                        const hour = date.format("HH");

                        // let mark = data.start == '1689400087000' ? "-> " : "";
                        // console.log(mark + "" + data.start + ", " + date.format('LLLL') + ", " + data.value);

                        if (sumByHor[hour]) {
                            sumByHor[hour].value += data.value;
                        } else {
                            sumByHor[hour] = {
                                value: data.value,
                                start: data.start,
                                unit: data.unit,
                            };
                        }
                    });

                    // console.log(sumByDay)

                    for (const hour in sumByHor) {
                        // console.log(`Date: ${day}, Sum: ${sumByDay[day].value} gallons`);
                        const baseTime = sumByHor[hour].start;
                        chartData.push([
                            UtilService.formateH(baseTime),
                            parseFloat(parseFloat(sumByHor[hour].value).toFixed(1)),
                            sumByHor[hour].value,
                            baseTime,
                        ]);
                    }

                    // console.log("---------");
                    // console.log(chartData);
                } else if (interval == "month") {
                    const sumByDay = {};

                    response.forEach((data) => {
                        // America/Los_Angeles or America/Vancouver
                        // America/New_York
                        const date = moment.utc(data.start).tz(timezone);
                        const day = date.format("YYYY-MM-DD");

                        // let mark = data.start == '1689400087000' ? "-> " : "";
                        // console.log(mark + "" + data.start + ", " + date.format('LLLL') + ", " + data.value);

                        if (sumByDay[day]) {
                            sumByDay[day].value += data.value;
                        } else {
                            sumByDay[day] = {
                                value: data.value,
                                start: data.start,
                                unit: data.unit,
                            };
                        }
                    });

                    // console.log(sumByDay)

                    for (const day in sumByDay) {
                        // console.log(`Date: ${day}, Sum: ${sumByDay[day].value} gallons`);
                        const baseTime = sumByDay[day].start;
                        chartData.push([UtilService.formatDo(baseTime), parseFloat(parseFloat(sumByDay[day].value).toFixed(1)), sumByDay[day].value, baseTime]);
                    }
                } else if (interval == "year") {
                    const someByMonth = {};
                    response.forEach((data) => {
                        // America/Los_Angeles or America/Vancouver
                        // America/New_York
                        const date = moment.utc(data.start).tz(timezone);
                        const month = date.format("YYYY-MM");

                        // let mark = data.start == '1689400087000' ? "-> " : "";
                        // console.log(mark + "" + data.start + ", " + date.format('LLLL') + ", " + data.value);

                        if (someByMonth[month]) {
                            someByMonth[month].value += data.value;
                        } else {
                            someByMonth[month] = {
                                value: data.value,
                                start: data.start,
                                unit: data.unit,
                            };
                        }
                    });

                    // console.log(someByMonth)

                    for (const month in someByMonth) {
                        // console.log(`Date: ${month}, Sum: ${someByMonth[month].value} gallons`);
                        const baseTime = someByMonth[month].start;

                        // console.log(`baseTime: ${baseTime}`);
                        chartData.push([
                            UtilService.formatM(baseTime),
                            parseFloat(parseFloat(someByMonth[month].value).toFixed(1)),
                            someByMonth[month].value,
                            baseTime,
                        ]);
                    }
                }
                // console.log(chartData);

                return chartData;
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default GraphService;
