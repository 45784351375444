import ApiService from "Services/apiService";

const SensorDetailService = {
    requestThing: async (thingId) => {
        // console.log("requestThing...");
        // console.log(thingId);

        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        return ApiService.getData(requestOptions).then((r) => {
            return r;
        });
    },

    saveThingName: async (thingId, thingName) => {
        let requestOptions = {
            url: `/v2/things`,
            data: {
                thingId: thingId,
                thingName: thingName,
            },
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default SensorDetailService;
