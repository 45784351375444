import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Item from "../../../components/ui/Item";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import StateIcon from "../measurements/thingDetail/StateIcon";

export default function StateWidget({ value, count }) {
    return (
        <>
            {value && (
                <Box sx={{ mr: 1, width: 140 }}>
                    <Stack direction={"column"}>
                        <Paper>
                            <Item>{value === "OK" ? "Normal" : value}</Item>
                            <Item>
                                <Stack direction={"row"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {value.toLowerCase() === "OK".toLowerCase() && (
                                        <>
                                            <Typography variant="h5" color="#14B25C">
                                                {count}
                                            </Typography>
                                        </>
                                    )}

                                    {value.toLowerCase() !== "OK".toLowerCase() && (
                                        <>
                                            <StateIcon state={value} />
                                            <Box sx={{ width: 20 }}></Box>
                                            <Typography variant="h5">{count}</Typography>
                                        </>
                                    )}
                                </Stack>
                            </Item>
                        </Paper>
                    </Stack>
                </Box>
            )}
        </>
    );
}
