import { useEffect, useState } from "react";
import _ from "underscore";
import Box from "@mui/material/Box";
import { DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo } from "@mui/x-data-grid-pro";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import LinearProgress from "@mui/material/LinearProgress";
import { BoilerColumns } from "./BoilerColumns";
import { frFR } from "@mui/x-data-grid/locales";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function BoilerPlateTable() {
    const { t, i18n } = useTranslation();

    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 20,
    });
    const [sort, setSort] = useState([{ field: "name", sort: "asc" }]);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
        // console.log("useEffect in BoilerPlateTable");
        setRows([
            { id: 1234, name: "abcd" },
            { id: 4567, name: "efg" },
        ]);
    }, []);

    const handleSelectionModel = (model) => {
        // console.log("handleSelectionModel ...");
        // console.log(model);
    };

    const handleCellClick = (params) => {
        if (params.field == "__check__") {
            return;
        }
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                {/* <GridToolbarExport /> */}
                {/* <ExportMenu callback={handleReport} /> */}
                {/* <ToggleButton callback={handleToggle} on={on}></ToggleButton> */}
                {/* <DeleteButton callback={() => setRefresh(moment())} selectionModel={selectionModel}></DeleteButton> */}
            </GridToolbarContainer>
        );
    }
    const handleColumnOrderChange = (param) => {};

    const columns = BoilerColumns(t);

    return (
        <Box sx={{ mt: 3 }}>
            <Grid container spacing={0}>
                <DataGridPro
                    columns={columns}
                    rows={rows}
                    rowCount={rowCount}
                    disableColumnFilter
                    pagination={true}
                    paginationMode="server"
                    sortingMode="server"
                    sortModel={sort}
                    paginationModel={pagination}
                    onPaginationModelChange={setPagination}
                    pageSizeOptions={[20, 50]}
                    onSortModelChange={setSort}
                    style={{ height: "calc(100vh - 80px)" }}
                    slots={{
                        toolbar: CustomToolbar,
                        loadingOverlay: LinearProgress,
                    }}
                    checkboxSelection
                    onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                    density="compact"
                    onCellClick={handleCellClick}
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                    sx={{
                        "& .MuiTablePagination-selectLabel": {
                            marginTop: 2,
                        },
                        "& .MuiTablePagination-displayedRows": {
                            marginTop: 2,
                        },
                    }}
                    columnVisibilityModel={{
                        id: false,
                    }}
                    onColumnOrderChange={handleColumnOrderChange}
                    localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                />
            </Grid>
        </Box>
    );
}
