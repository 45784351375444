export function CompanyListColumn(t) {
    return [
        {
            field: "id",
            headerName: t("col.id"),
            hide: true,
        },
        {
            field: "name",
            headerName: t("col.organization"),
            flex: 1,
            editable: false,
        },
    ];
}
