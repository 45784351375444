import React, { useState, Suspense } from "react";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Header from "./Header";
import style from "./style.module.css";
import { Auth } from "aws-amplify";
import validator from "validator";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Item from "../../components/ui/Item";

function LoginPage() {
    let history = useHistory();
    const { t, i18n } = useTranslation();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // first login
    const [firstLoginUser, setFirstLoginUser] = useState();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [info, setInfo] = useState();

    const submit = (e) => {
        e.preventDefault();

        setLoading(true);
        setError(null);

        Auth.signIn({ username: email, password: password })
            .then((user) => {
                if (user.challengeName == "NEW_PASSWORD_REQUIRED") {
                    setLoading(false);
                    setFirstLoginUser(user);
                } else {
                    // sucesss
                    setLoading(false);
                    history.push("/apps");
                    // history.push("/apps/water_metering/water_metering");
                }
            })
            .catch((e) => {
                setLoading(false);
                setError("Incorrect username or password");
            });
    };

    const handleForgot = () => {
        if (validator.isEmail(email)) {
            Auth.forgotPassword(email, {})
                .then((u) => {
                    history.push(`/forgot/${encodeURIComponent(email)}`);
                })
                .catch((e) => {
                    console.log(e);
                    setError(t("login.user-notfound"));
                });
        } else {
            setError(t("login.invalid-email"));
        }
    };

    const handleReset = () => {
        if (validator.isEmpty(newPassword)) {
            setError("login.password-cannottbe-empty");
            return;
        } else if (newPassword != confirmPassword) {
            setError(t("login.passward-not-same"));
            return;
        }

        Auth.completeNewPassword(firstLoginUser, newPassword)
            .then((u) => {
                // sucesss
                setLoading(false);
                setFirstLoginUser(undefined);
            })
            .catch((e) => {
                console.log(e);
                setInfo(t("login.pssword-policy"));
            });
    };

    return (
        <>
            <div className={style.background}>
                <Box sx={{ backgroundColor: "white", border: "solid 1px black" }}>
                    <Suspense fallback={<span />}>
                        <Header />
                    </Suspense>

                    {!firstLoginUser && (
                        <Box sx={{ p: 10, pb: 5 }}>
                            {/* <img src="/apps/full-logo.png" alt="Pilot Things" /> */}
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Stack justifyContent={"center"} justifyItems={"center"}>
                                    <Item>
                                        <Typography variant="h5" gutterBottom component="div">
                                            iFLOW H2O Mobile
                                        </Typography>
                                    </Item>
                                    <Item elevation={0}>
                                        <img src="/apps/iflowinc_logo.png" alt="Pilot Things" width={95} height={93} />
                                    </Item>
                                </Stack>
                            </div>

                            {/* <h1>{t('login.connect')}</h1> */}
                            <Stack component="form" spacing={2} onSubmit={submit}>
                                <TextField
                                    variant="standard"
                                    label={t("login.email")}
                                    type="email"
                                    fullWidth
                                    required
                                    disabled={loading}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <TextField
                                    variant="standard"
                                    label={t("login.password")}
                                    type="password"
                                    fullWidth
                                    required
                                    value={password}
                                    disabled={loading}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {/* opacity is a clever trick to hide the error message while still reserving space for it. */}
                                <Alert severity="error" sx={{ opacity: error ? 1 : 0 }}>
                                    {error}
                                </Alert>
                                <LoadingButton loading={loading} loadingPosition="end" variant="contained" type="submit" endIcon={<LockOpenIcon />} fullWidth>
                                    {t("login.login")}
                                </LoadingButton>
                            </Stack>
                            <Box sx={{ mt: 3 }} textAlign="center">
                                <Link to="" component={RouterLink} onClick={() => handleForgot()}>
                                    {t("login.forgot-password")}
                                </Link>
                            </Box>
                        </Box>
                    )}

                    {firstLoginUser && (
                        <Box sx={{ p: 10, pb: 5 }}>
                            {/* <img src="/apps/full-logo.png" alt="Pilot Things" /> */}
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Stack justifyContent={"center"} justifyItems={"center"}>
                                    <Item>
                                        <Typography variant="h5" gutterBottom component="div">
                                            iFLOW H2O Mobile
                                        </Typography>
                                    </Item>
                                    <Item>
                                        <img src="/apps/iflowinc_logo.png" alt="Pilot Things" width={95} height={93} />
                                    </Item>
                                </Stack>
                            </div>

                            <h4>{t("login.change-password")}</h4>
                            <Stack component="form" spacing={2} onSubmit={submit}>
                                <TextField
                                    variant="standard"
                                    label={t("login.password")}
                                    type="password"
                                    fullWidth
                                    required
                                    value={newPassword}
                                    disabled={loading}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <TextField
                                    variant="standard"
                                    label={t("login.enter-password-again")}
                                    type="password"
                                    fullWidth
                                    required
                                    value={confirmPassword}
                                    disabled={loading}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />

                                {/* opacity is a clever trick to hide the error message while still reserving space for it. */}
                                <Alert severity="error" sx={{ opacity: error ? 1 : 0 }}>
                                    {error}
                                </Alert>
                                {/* <LoadingButton loading={loading} loadingPosition="end" variant="contained" type="submit" endIcon={<LockOpenIcon />} fullWidth>{t('login.login')}</LoadingButton> */}
                                <Alert severity="info" sx={{ opacity: info ? 1 : 0 }}>
                                    {info}
                                </Alert>

                                <Button variant="contained" onClick={handleReset}>
                                    {t("login.send")}
                                </Button>
                            </Stack>
                        </Box>
                    )}

                    <div style={{ display: "flex", justifyContent: "right", marginRight: 10, marginBottom: 10 }}>
                        <img src="/apps/iflowinc_new_log.png" alt="iFlowinc" />
                    </div>
                </Box>
            </div>
        </>
    );
}

export default LoginPage;
