import { createContext } from "react";


const settingContext = createContext({
    globalSetting: {
        appId: null,
        temperatureMonitoring: {
            celsius: true,
            language: "en"
        },
        notifyStateChange: null,
    },
    setGlobalSetting: () => { },
});

export default settingContext;