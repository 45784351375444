import Checkbox from "@mui/material/Checkbox";

export function CompanyListColumn(t, handleCheckBox) {
    return [
        {
            field: "id",
            headerName: t("col.id"),
        },
        {
            field: "subscriptionId",
            headerName: "SubscriptionId",
        },
        {
            field: "name",
            headerName: t("col.organization"),
            flex: 1,
            editable: false,
        },
        {
            field: "startDate",
            headerName: t("col.start"),
            flex: 1,
            editable: false,
        },
        {
            field: "endDate",
            headerName: t("col.end"),
            flex: 1,
            editable: false,
        },
        {
            field: "autoRenewal",
            headerName: t("col.renewal"),
            flex: 1,
            editable: false,
            renderCell: (params) => {
                // console.log(params);
                if (!params.row.subscriptionId) {
                    return <></>;
                }

                return (
                    <Checkbox
                        inputProps={{ "aria-label": "Renweal" }}
                        defaultChecked={params.value}
                        onClick={(e) => handleCheckBox(params.row.subscriptionId, e.target.checked)}
                    />
                );
            },
        },
    ];
}
