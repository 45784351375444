import { useEffect } from "react";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import validator from "validator";

export default function AlertDialog({ message, setOpen, ...other }) {
    const [comment, setComment] = useState(message);
    const [errComment, setErrComment] = useState(false);

    const handleCancel = () => {
        setOpen();
    };

    const handleOk = () => {
        if (validator.isEmpty(comment)) {
            setErrComment(true);
            return;
        }
        setErrComment(false);

        setOpen(comment);
    };

    useEffect(() => {
        if (message !== comment) {
            setComment(message);
        }
    }, [message]);

    return (
        <Dialog fullWidth {...other}>
            <DialogTitle>{"Alerts follow-up"}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="comment"
                    label="Comment"
                    multiline
                    type="text"
                    fullWidth
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    error={errComment}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    {"Cancel"}
                </Button>
                <Button onClick={handleOk} color="primary">
                    {"Validate"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
