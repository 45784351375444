import ApiService from "Services/apiService";
import _ from "underscore";
import SuperviotConstants from "../SuperviotConstants";

const AttributeService = {
    requestThing: async (thingId, setThing) => {
        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        return ApiService.getData(requestOptions)
            .then((r) => {
                setThing(r);

                if (r.product && r.product.model) {
                    return r.product.model.id;
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestAttributes: async (modelId) => {
        let requestOptions = {
            url: `/api/models/${modelId}`,
        };

        return ApiService.getData(requestOptions)
            .then((r) => {
                return r.attributes ? r.attributes : undefined;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestRanges: async (thingId, attributes, setUserDefined) => {
        let requestOptions = {
            url: `/v2/thing_range/${thingId}/setting`,
            params: {
                attributeIds: attributes.join(","),
            },
        };

        // console.log(requestOptions);
        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        let userDefinedJson = {};
        result.map((r) => {
            let markJson = {};

            JSON.parse(r.marks).forEach((m, index, array) => {
                if (index === 0 || index === array.length - 1) {
                    markJson[m] = ""; // In oder to hide the first and last element on the slider
                } else {
                    markJson[m] = m;
                }
            });

            // console.log(markJson);

            userDefinedJson[r.attribute.id] = {
                name: r.attribute.name,
                marks: markJson,
                value: JSON.parse(r.marks),
            };
        });

        // console.log(userDefinedJson);
        setUserDefined(userDefinedJson);
    },

    updateRange: async (siteName, thingId, attributeId, trackColors, values, reverse) => {
        // console.log(trackColors)
        const bgColor = trackColors.map((t) => SuperviotConstants.toString(t.backgroundColor));

        let requestOptions = {
            url: `/v2/thing_range`,
            data: {
                thingId: thingId,
                attributeId: attributeId,
                marks: JSON.stringify(values),
                trackColors: JSON.stringify(bgColor),
                siteName: siteName,
                reverse: reverse,
            },
        };
        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                // console.error(e);
            });
    },
};
export default AttributeService;
