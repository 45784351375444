import { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import ColorService from "Services/colorService";
import Badge from "@mui/material/Badge";
import BrightnessHighIcon from "@mui/icons-material/BrightnessHigh";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import Brightness6Icon from "@mui/icons-material/Brightness6";
import FlareIcon from "@mui/icons-material/Flare";

export default function LightUI(props) {
    // console.log("//////////////");
    // console.log(props);

    const [value, setValue] = useState("");
    const [unit, setUnit] = useState("");
    const [date, setDate] = useState("");
    const [fixedName, setFixedName] = useState("");
    const [alertCount, setAlertCount] = useState(0);

    // const [color, setColor] = useState('#EAE196');
    const [color, setColor] = useState("rgba(56,179,185,0.6)");

    useEffect(() => {
        if (props.entity != undefined) {
            setValue(parseFloat(props.entity.value).toFixed(0));
            setUnit(props.entity.unit);
            setDate(props.entity.date);
            setFixedName(props.entity.fixedName);
            setAlertCount(props.entity.alertCount);
        }

        setColor(ColorService.getGradientRgba(value, unit));
    }, []);

    return (
        <div className="container m-0 p-0" style={{ width: "80px", height: "80px" }}>
            {/* default view */}
            {props.default && (
                <div className="d-flex justify-content-center" style={{ position: "relative" }}>
                    <div style={{ textAlign: "center", position: "absolute", width: "80px" }}>{displayIcon(value, "#D2D2D2")}</div>
                    <div style={{ position: "absolute", zIndex: "1", paddingTop: "26px", marginTop: "8px" }}>
                        <span style={{ fontSize: "15px" }}>{value}</span>
                        <span style={{ fontSize: "15px" }}>&nbsp;</span>
                        <span style={{ fontSize: "12px" }}>{unit}</span>
                        <br></br>
                        <span style={{ fontSize: "12px" }}>{date}</span>
                    </div>
                </div>
            )}
            {/* data view */}
            {!props.default && (
                <Tooltip title={fixedName}>
                    <div className="d-flex justify-content-center" style={{ height: "100%", position: "relative" }}>
                        <div style={{ textAlign: "center", position: "absolute", width: "80px" }}>{displayIcon(value)}</div>
                        <div style={{ position: "absolute", zIndex: "1", paddingTop: "26px", marginTop: "8px" }}>
                            <span style={{ fontSize: "15px" }}>{value == "" ? "Inactive" : value}</span>
                            <span style={{ fontSize: "15px" }}>&nbsp;</span>
                            <span style={{ fontSize: "12px" }}>{unit}</span>
                            <br></br>
                            <span style={{ fontSize: "12px" }}>{date}</span>
                        </div>
                        <div style={{ position: "absolute", zIndex: "2", paddingTop: "0px", marginTop: "-10px", marginLeft: "30px" }}>
                            <Badge overlap="rectangular" badgeContent={alertCount} color="primary" max={999}></Badge>
                        </div>
                    </div>
                </Tooltip>
            )}
        </div>
    );
}

function displayIcon(value, color) {
    let iconColor = "#BF4510";
    if (color) {
        iconColor = color;
    }

    if (value == "") {
        return <BrightnessHighIcon fontSize="large" style={{ color: "#D2D2D2" }} />;
    } else if (value < 1) {
        return <BrightnessHighIcon fontSize="large" style={{ color: iconColor }} />;
    } else if (1 <= value && value < 5000) {
        return <Brightness6Icon fontSize="large" style={{ color: iconColor }} />;
    } else if (5000 <= value && value < 25000) {
        return <Brightness4Icon fontSize="large" style={{ color: iconColor }} />;
    } else if (25000 <= value && value < 50000) {
        return <Brightness5Icon fontSize="large" style={{ color: iconColor }} />;
    } else if (value >= 50000) {
        return <FlareIcon fontSize="large" style={{ color: iconColor }} />;
    }
}
