import { Snackbar, Alert } from "@mui/material";

export default function CustomSnackbar({ open, setOpen, message, severity }) {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
            <Alert onClose={() => setOpen(false)} severity={severity} variant="filled" sx={{ width: "100%" }}>
                {message}
            </Alert>
        </Snackbar>
    );
}
