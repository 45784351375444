import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MeasurementService from "../common/MeasurementService";

export default function AttributeComboBox({ open, selectedThingId, callbackSelectedAttributeId, callbackAllAttributes }) {
    const [attributes, setAttributes] = useState([{ id: "all", label: "All" }]);

    useEffect(() => {
        if (open) {
            MeasurementService.requestModel(selectedThingId, setModel).then(() => MeasurementService.requesCustomtModel(selectedThingId, setModel));
        }
    }, []);

    const setModel = (attributes) => {
        setAttributes(attributes);
        if (callbackAllAttributes) {
            callbackAllAttributes(attributes);
        }
    };

    const handleChange = (event, value, reason) => {
        if (reason == "selectOption") {
            callbackSelectedAttributeId(value.id);
        }
    };

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={attributes}
            sx={{ width: 250 }}
            size="small"
            renderInput={(params) => <TextField {...params} label="Measurement" />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleChange}
            defaultValue={attributes != undefined ? attributes[0] : ""}
        />
    );
}
