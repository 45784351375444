import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import SideBar from "./home/sidebar/SideBar";
import RiotappConstants from "./RiotappConstants";
import AppContext from "Components/AppContext";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SviChartMain from "./thingsdetail/SviChartMain"; // Add this line
import IslandMapV2 from "./home/IslandMapV2";

export default function RiotappMain() {
    const [positions, setPositions] = useState();
    const [selectedName, setSelectedName] = useState("");

    const ctx = useContext(AppContext);
    ctx.updateTitle("Riot app demo");
    let { path } = useRouteMatch();

    useEffect(() => {
        // console.log("uef in RiotappMain")
        setPositions(RiotappConstants.positions);
    }, [selectedName]);

    const handleName = (name) => {
        // console.log("hadleName in RiotappMain");
        setSelectedName(name);
    };

    return (
        <Switch>
            {/* Main page */}
            <Route exact path={path}>
                <Box sx={{ pt: 10, ml: 2, mr: 2, display: "flex" }}>
                    <IslandMapV2 positions={positions} callback={(name) => handleName(name)} />
                    {/* <IslandMap positions={positions} callback={(name) => handleName(name)} /> */}
                    <SideBar name={selectedName} callback={() => setSelectedName("")} />
                </Box>
            </Route>
            {/* Things details page */}
            <Route exact path={`${path}/thingsdetail/:thingId`}>
                <SviChartMain />
            </Route>
        </Switch>
    );
}
