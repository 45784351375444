import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        width: '25ch',
    },
}));

export default function Note(props) {

    const { t, i18n } = useTranslation();

    const classes = useStyles();
    const [value, setValue] = useState('Controlled');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    useEffect(() => {
    }, []);


    return (
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: "column", height: '100%' }}>
            <Paper elevation={3} sx={{ height: '100%' }}>
                <Stack sx={{ height: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ fontFamily: 'Days One', fontSize: '24px', fontWeight: 'normal', color: "#9B9B9B", marginLeft: '5px' }}>{t('w_mgt.notes')}</span>
                    </Box>
                    <TextField
                        id="outlined-multiline-static"
                        label=""
                        // style={{ margin: 8 }}
                        multiline
                        fullWidth
                        margin="normal"
                        rows={12}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            "& legend": { display: "none" },
                            flex: 1,
                            // overflow: 'auto'
                            p: 2
                        }}
                        style={{ height: '100%' }}
                    />
                </Stack>
            </Paper>
        </Box>
    )
};
