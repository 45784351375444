import { React, useEffect, useContext } from "react";
import AppContext from "Components/AppContext";
import ConsumptionHistory from "./home/consumptionHistory​/ConsumptionHistory​";
import AnomalyDetection from "./home/detection/AnomalyDetection";
import Top15 from "./home/top15/Top15";
import ConsumptionWarnings from "./home/consumption/ConsumptionWarnings";
import SettingContext from "../../providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Item from "../../components/ui/Item";

export default function WaterManagement(props) {
    const { t, i18n } = useTranslation();

    const ctx = useContext(AppContext);
    ctx.updateTitle("Water Management");

    const setting = useContext(SettingContext);

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.WATER_MANAGEMENT,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });
    }, []);

    return (
        <Box sx={{ flexGrow: 1, mt: 8 }}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Item>
                        <ConsumptionHistory />
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <Top15 />
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <ConsumptionWarnings />
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <AnomalyDetection></AnomalyDetection>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}
