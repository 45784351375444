import ApiService from "Services/apiService";

const CompareSecondService = {

    requestAttributes: async (modelId) => {
        // console.log("requestThing...");
        // console.log(thingId);

        let requestOptions = {
            url: `/api/models/${modelId}/attributes`,
        };

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);
            return r;
        });
    },

};

export default CompareSecondService;