import { useEffect, useState, useCallback } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CustomStyle from "../../share/CustomStyle";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DetailService from "./DetailService";
import CloudUserService from "../common/CloudUserService";
import Box from "@mui/material/Box";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SiteDialogue from "./site/SiteDialogue";
import moment from "moment";
import UserService from "../../../../services/userService";
import TenantDialog from "./tenant/TenantDialog";
import LogoutIcon from "@mui/icons-material/Logout";
import CheckOutDialog from "./tenant/CheckOutDialog";
import TenantService from "./tenant/TenantService";
import Grid from "@mui/material/Grid";
import { debounce } from "lodash";
import Divider from "@mui/material/Divider";

export default function TabPlace({ selectedThingId }) {
    let defaltError = {
        size: false,
        radio: false,
        firstname: false,
        lastname: false,
        location: false,
        unit: false,
        email: false,
        message: "",
    };
    const [errors, setError] = useState(defaltError);

    const [size, setSize] = useState(0);
    const [radio, setRadio] = useState(false);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [location, setLocation] = useState("");
    const [unit, setUnit] = useState(""); // Unit #No, This is not unit for attribute or measurement.
    const [email, setEmail] = useState("");

    const [openSite, setOpenSite] = useState(false);
    const [openTenant, setOpenTenant] = useState(false);
    const [thing, setThing] = useState();
    const [reload, setReload] = useState();
    const [user, setUser] = useState();

    // const [age, setAge] = useState('');

    const [isSaving, setIsSaving] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState();

    const [openCheckOut, setOpenCheckOut] = useState(false);
    const [checkIn, setCheckIn] = useState();
    const [attUnits, setAttUnits] = useState();

    useEffect(() => {
        // console.log("uef in TabPlace");

        DetailService.requestThing(selectedThingId, setThing).then((t) => {
            // console.log("uef in MeterSummary")
            // console.log(t)
            if (t.site) {
                setLocation(t.site.name);
            } else {
                setLocation("");
            }
            if (t.lastMeasurements && t.lastMeasurements.units) {
                setAttUnits(t.lastMeasurements.units);
            }
            setSelectedProduct(t.product);
        });

        DetailService.getWaterMeteringAttributes(selectedThingId, setSize, setRadio, setUnit);

        CloudUserService.requestTenant(selectedThingId).then((u) => {
            // console.log(u);
            setFirstname(u && u.firstname ? u.firstname : "");
            setLastname(u && u.lastname ? u.lastname : "");
            setEmail(u && u.email ? u.email : "");
        });

        UserService.getAuthenticatedUser().then((u) => {
            if (u.authorities === "TENANTS") {
                setIsAdmin(false);
            } else if (["MANAGER", "CLIENT_ADMIN", "SUPER_ADMIN"].includes(u.authorities)) {
                setIsAdmin(true);
            }
            setUser(u);
        });

        TenantService.requestLastCheckIn(selectedThingId)
            .then((r) => {
                // console.log(r)
                setCheckIn(r);
            })
            .catch((e) => console.error(e));
    }, [isSaving, reload, selectedThingId]);

    // debouncedUpdateTenantInfo is memoized using useCallback to ensure it doesn't get recreated on every render.
    const debouncedUpdateTenantInfo = useCallback(
        debounce((key, value) => {
            CloudUserService.updateTenantInfo(selectedThingId, key, value);
        }, 500),
        [selectedThingId]
    );

    const handleValue = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "txtFirstname":
                setFirstname(value);
                debouncedUpdateTenantInfo("firstname", value);
                break;
            case "txtLastname":
                setLastname(value);
                debouncedUpdateTenantInfo("lastname", value);
                break;
            case "txtLocation":
                setLocation(value);
                break;
            case "txtEmail":
                setEmail(value);
                debouncedUpdateTenantInfo("email", value);
                break;
            case "txtUnit":
                setUnit(value);
                debouncedUpdateTenantInfo("unit", value);
                break;
            default:
                break;
        }
    };

    return (
        <Box>
            <Paper elevation={3} sx={{ p: 1 }}>
                <Grid container spacing={0} sx={{ alignItems: "center" }}>
                    <Grid item xs={3} sx={{ pl: 2 }}>
                        <Typography>Location</Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: "flex" }}>
                        <TextField
                            required
                            name="txtLocation"
                            fullWidth
                            margin="dense"
                            size="small"
                            value={location}
                            onChange={(e) => handleValue(e)}
                            error={errors.location ? true : false}
                            sx={CustomStyle.RemoveLabel}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <IconButton aria-label="delete" size="large" variant="contained" onClick={() => setOpenSite(true)}>
                            <MoreHorizIcon fontSize="inherit" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={3} sx={{ pl: 2 }}>
                        <Typography>Unit#</Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: "flex" }}>
                        <TextField
                            required
                            name="txtUnit"
                            fullWidth
                            margin="dense"
                            size="small"
                            value={unit}
                            onChange={(e) => handleValue(e)}
                            error={errors.unit ? true : false}
                            sx={CustomStyle.RemoveLabel}
                            // inputProps={{ readOnly: true }}
                            focused={false}
                            // disabled
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={3} sx={{ pl: 2 }}>
                        <Typography>Tenant</Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Typography>
                            {checkIn && (
                                <IconButton aria-label="btnCheckOut" size="large" variant="contained" onClick={() => setOpenCheckOut(true)}>
                                    <LogoutIcon fontSize="inherit" />
                                </IconButton>
                            )}
                            {isAdmin && (
                                <Button
                                    aria-label="btnMore"
                                    size="small"
                                    variant="contained"
                                    onClick={() => setOpenTenant(true)}
                                    style={{ backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }}
                                >
                                    {/* <MoreHorizIcon fontSize="inherit" /> */}
                                    Select existing user...​
                                </Button>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ pl: 2 }}>
                        <Typography sx={{ pl: 3 }}>First name</Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: "flex" }}>
                        <TextField
                            required
                            name="txtFirstname"
                            fullWidth
                            margin="dense"
                            size="small"
                            value={firstname}
                            onChange={(e) => handleValue(e)}
                            error={errors.firstname ? true : false}
                            sx={CustomStyle.RemoveLabel}
                            // inputProps={{ readOnly: true }}
                            focused={false}
                            // disabled
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ pl: 2 }}>
                        <Typography sx={{ pl: 3 }}>Last name</Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: "flex" }}>
                        <TextField
                            required
                            name="txtLastname"
                            fullWidth
                            margin="dense"
                            size="small"
                            value={lastname}
                            onChange={(e) => handleValue(e)}
                            error={errors.lastname ? true : false}
                            sx={CustomStyle.RemoveLabel}
                            // inputProps={{ readOnly: true }}
                            focused={false}
                            // disabled
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ pl: 2 }}>
                        <Typography sx={{ pl: 3 }}>Email</Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: "flex" }}>
                        <TextField
                            required
                            name="txtEmail"
                            fullWidth
                            margin="dense"
                            size="small"
                            value={email}
                            onChange={(e) => handleValue(e)}
                            error={errors.owner ? true : false}
                            sx={CustomStyle.RemoveLabel}
                            // inputProps={{ readOnly: true }}
                            focused={false}
                            // disabled
                        />
                    </Grid>
                </Grid>
            </Paper>

            <SiteDialogue
                open={openSite}
                setOpen={setOpenSite}
                selectedThingId={selectedThingId}
                setReloadParent={() => {
                    setReload(moment());
                }}
            />

            <TenantDialog
                open={openTenant}
                setOpen={setOpenTenant}
                selectedThingId={selectedThingId}
                setReloadParent={() => {
                    setReload(moment());
                }}
                user={user}
            />

            <CheckOutDialog
                open={openCheckOut}
                setOpen={setOpenCheckOut}
                selectedThingId={selectedThingId}
                data={checkIn}
                attUnits={attUnits}
                setReload={setReload}
            />
        </Box>
    );
}
