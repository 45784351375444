import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { CustomButton } from "Components/ui/CustomButton";
import Stack from "@mui/material/Stack";
import DetailService from "./DetailService";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

export default function SendDownlink({ thingDetail }) {
    const [operations, setOperations] = useState([]);
    const [anchorEl, setAnchorEl] = useState();
    const open = Boolean(anchorEl);

    const [payload, setPayload] = useState();
    const [fport, setFport] = useState();

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        // console.log(thingDetail);
        if (thingDetail && thingDetail.product && thingDetail.product.model) {
            let modelId = thingDetail.product.model.id;

            DetailService.requestOperations(modelId, setOperations);
        }
    }, []);

    const handleFrequency = (opId, opName) => {
        DetailService.sendDownlink(thingDetail.id, opId, opName);
    };

    return (
        <Paper elevation={2} variant={"elevation"} sx={{ p: 2, pt: 5 }}>
            <Typography variant="subtitle1" gutterBottom>
                Device must be registered on Sensing Network to receive commands
            </Typography>
            <Stack spacing={2} direction={"row"}>
                {operations.map((op) => {
                    let tooltip = op.payload + "</br>" + op.fport;

                    return (
                        <CustomButton
                            name={op.name}
                            callback={() => handleFrequency(op.id, op.name)}
                            tooltip={tooltip}
                            onMouseEnter={(e) => {
                                setPayload(op.payload);
                                setFport(op.fport);
                                handlePopoverOpen(e);
                            }}
                            onMouseLeave={handlePopoverClose}
                        />
                    );
                })}

                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Stack sx={{ p: 1 }}>
                        <Typography variant="subtitle2">Payload: {payload}</Typography>
                        <Typography variant="subtitle2">Fport: {fport}</Typography>
                    </Stack>
                </Popover>
            </Stack>
        </Paper>
    );
}
