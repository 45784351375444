import { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import TagService from "./TagService";
import _ from "underscore";
import { v4 as uuidv4 } from "uuid";

export default function Tags(props) {
    const selectedThingId = props.selectedThingId;
    const selectedCompany = props.selectedCompany;

    const [allTags, setAllTags] = useState([]);
    const [thingTags, setThingTags] = useState();

    useEffect(() => {
        TagService.requestAllTags(receiveAllTags);
    }, [props.thingTags]);

    const receiveAllTags = (tags) => {
        setAllTags(tags);
        setThingTags(props.thingTags);
    };

    const handleTags = (event, value) => {
        var updatedTags = value.map((name) => {
            if (typeof name == "string") {
                const exist = allTags.find((t) => t.tag == name);

                return {
                    id: exist == undefined ? uuidv4() : exist.id,
                    label: name,
                };
            }
            return name;
        });
        // console.log(updatedTags);
        setThingTags(updatedTags);

        TagService.updateTags(selectedThingId, selectedCompany, updatedTags);
    };

    return (
        <Stack spacing={0} sx={{ width: "100%" }}>
            <Autocomplete
                multiple
                id="tags-filled"
                options={allTags.map((ele) => {
                    return {
                        id: ele.id,
                        label: ele.tag,
                    };
                })}
                freeSolo
                value={thingTags == undefined ? props.thingTags : thingTags}
                renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />)}
                renderInput={(params) => <TextField {...params} variant="filled" label="Tags" />}
                onChange={(event, value) => handleTags(event, value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
            />
        </Stack>
    );
}
