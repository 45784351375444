import React, { useEffect, useContext, useRef, useState } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import Box from "@mui/material/Box";
import Highcharts from "highcharts/highstock";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import Button from "@mui/material/Button";
import ThresholdDialogV2 from "../../../smartAgriculture/details/ThresholdDialogV2";
import { ChartGeneralOptions } from "./ChartGeneralOptions";
import SensorDetailService from "./SensorDetailService";
import ChartGeneralService from "./ChartGeneralService";
highchartsMore(Highcharts);

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

export default function ChartGeneral({ thingId, attributeId }) {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    const { t, i18n } = useTranslation();

    const componentRef = useRef();

    const [selected, setSelected] = useState(false);
    const [type, setType] = useState("spline");
    const [isOpenThresholdModal, setIsOpenThresholdModal] = useState(false);

    const [name, setName] = useState("Humidity");

    // const [room, setRoom] = useState();
    const [chartData, setChartData] = useState([]);
    const [cursor, setCursor] = useState("default");
    const [fixedName, setFixedName] = useState("");
    const [groupEnable, setGroupEnable] = useState(true);

    useEffect(() => {
        // console.log("uef in ChartGeneral");
        // console.log("thingId:" + thingId);
        // console.log("attributeId:" + attributeId);

        componentRef.current.chart.showLoading();
        window.scrollTo(0, 0);

        SensorDetailService.requestThing(thingId).then((r) => {
            // console.log(r);
            setFixedName(r.fixedName);
        });

        // const hotDropAmpAttId = "0941b1c2-442a-4619-a14d-5a381b50b42f";

        let promises = [];
        promises.push(ChartGeneralService.requestHistoryAll(thingId, attributeId, setChartData, "none"));

        Promise.all(promises).then(() => {
            if (componentRef.current) {
                componentRef.current.chart.hideLoading();
            }
        });

        setting.setGlobalSetting({
            appId: AppId.COMPOST,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });
        ctx.updateTitle(ApplicationData.find((a) => a.id == AppId.COMPOST).title);

        return () => {
            ctx.updateTitle(null);
        };
    }, []);

    const handleChartType = () => {
        setSelected(!selected);

        if (selected) {
            setType("spline");
        } else {
            setType("column");
        }
    };

    const handleThreshold = () => {
        setIsOpenThresholdModal(true);
    };

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        <Box sx={{ flexGrow: 1, marginTop: 10 }}>
            <Grid container spacing={2} sx={{ pl: 5, pr: 5 }}>
                <Grid item xs={12}>
                    <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{fixedName}</span>
                </Grid>
                <Grid item xs={12}>
                    <ToggleButton value="check" selected={!selected} onChange={() => handleChartType()} size="small">
                        <ShowChartIcon />
                    </ToggleButton>
                    <ToggleButton value="check" selected={selected} onChange={() => handleChartType()} size="small">
                        <EqualizerIcon />
                    </ToggleButton>
                    <Button variant="contained" color="primary" style={{ textTransform: "none", margin: "5px" }} onClick={() => handleThreshold()}>
                        {t("btn.threshold")}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <HighchartsReact
                        ref={componentRef}
                        highcharts={Highcharts}
                        constructorType={"stockChart"}
                        options={ChartGeneralOptions(t("emera_v2.analysis_electricity"), type, chartData, groupEnable, setGroupEnable)}
                    />
                </Grid>
            </Grid>

            <ThresholdDialogV2
                onClose={() => setIsOpenThresholdModal(false)}
                open={isOpenThresholdModal}
                thingId={thingId}
                attributeId={attributeId}
                title="Alerts"
                attributeName={name}
            />
        </Box>
    );
}
