import ApiService from "Services/apiService";

const ThingService = {
    requestThing: async (thingId) => {
        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        return ApiService.getData(requestOptions).then((r) => {
            return r;
        });
    },

    requestAttributes: async (modelId, setAttributes) => {
        let requestOptions = {
            url: `/api/models/${modelId}/attributes`,
        };

        return ApiService.getData(requestOptions).then((response) => {
            // console.log("/////-------");
            // console.log(response);
            if (setAttributes) {
                setAttributes(response.data);
            } else {
                return response;
            }
        });
    },
};

export default ThingService;
