import { useEffect, useState } from "react";
import _ from "underscore";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

export default function HttpsButtons({ callback, filesOnCloud }) {
    const { t, i18n } = useTranslation();

    const [certAuth, setCertAuth] = useState();
    const [cert, setCert] = useState();
    const [privKey, setPrivKey] = useState();

    useEffect(() => {
        // console.log("uef in HttpsButtons");
        // console.log(filesOnCloud);
        if (filesOnCloud && filesOnCloud.length == 3) {
            setCertAuth(filesOnCloud[0]);
            setCert(filesOnCloud[1]);
            setPrivKey(filesOnCloud[2]);
        }
    }, [filesOnCloud]);

    const handleFile = (e, type) => {
        // console.log(type);
        let file = e.target.files[0];

        switch (type) {
            case "certAuth":
                setCertAuth(file);
                callback(file, cert, privKey);
                break;
            case "cert":
                setCert(file);
                callback(certAuth, file, privKey);
                break;
            case "privKey":
                setPrivKey(file);
                callback(certAuth, cert, file);
                break;
            default:
                break;
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1, pt: 1, pb: 1 }}>
            <Box sx={{ display: "block", alignItems: "center", p: 1 }}>
                <Typography sx={{ textAlign: "center" }}>Client certificate</Typography>
                <Typography sx={{ textAlign: "center" }}>(optional)</Typography>
            </Box>
            <FileUploadButton label="Certificate authority" type="certAuth" file={certAuth} handleFile={handleFile} />
            <FileUploadButton label="Certificate" type="cert" file={cert} handleFile={handleFile} />
            <FileUploadButton label="Private Key" type="privKey" file={privKey} handleFile={handleFile} />
        </Box>
    );
}

function FileUploadButton({ label, type, file, handleFile }) {
    return (
        <Box>
            <Button component="label" variant="contained" sx={{ backgroundColor: "#5694B5", color: "#FFF", textTransform: "none" }}>
                {label}
                <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, type)} />
            </Button>
            {!file ? <Typography variant="subtitle1">No file selected</Typography> : <Typography variant="subtitle1">{file.name}</Typography>}
        </Box>
    );
}
