import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import SubscriptionService from "./SubscriptionService";

export default function NoSubscriptionInitiallyDialog({ open, setOpen, subId, companyId, callback, appId }) {
    const { t, i18n } = useTranslation();

    const handleClose = () => {
        // setOpen(false);
    };

    const handleSubmit = () => {
        SubscriptionService.renew(subId, companyId, appId).then(() => {
            callback();
            setOpen(false);
        });
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{t("tm.init_subs")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="body1" gutterBottom>
                            {t("tm.init_description_1")}
                            <br />
                            {t("tm.init_description_2")}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }} onClick={handleSubmit}>
                        {t("btn.subscribe")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
