import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Item from '../../../components/ui/Item';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CertificateService from './CertificateService';
import _ from 'underscore'
import Alert from '@mui/material/Alert';

export default function CertificateDownload() {

    const [key, setKey] = useState("");
    const [certificate, setCertificate] = useState("");
    const [errKey, setErrKey] = useState(false);

    useEffect(() => {
        CertificateService.requestCertificate(key)
    }, []);

    const handleCertificate = () => {
        if (_.isEmpty(key)) {
            setErrKey(true);
            return;
        }
        setErrKey(false);
        setCertificate("");

        CertificateService.requestCertificate(key, setCertificate)
            .catch(err => setErrKey(err));
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        // link.href = "file.txt";

        const file = new Blob([certificate], { type: 'text/plain' });
        link.href = URL.createObjectURL(file);
        link.download = key;

        document.body.appendChild(link);
        link.click();
    }

    return (
        <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ pt: 10, maxWidth: 'md', width: '100%' }}>
                <Paper elevation={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <Item>
                                <TextField id="txtkey" label="S3 Key"
                                    value={key}
                                    onChange={(e) => setKey(event.target.value)}
                                    variant="outlined" fullWidth
                                    error={errKey}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item sx={{ display: 'flex', justifyContent: 'right' }}>
                                <Button variant="contained"
                                    onClick={handleCertificate}
                                    style={{ backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }}
                                >Request certificate</Button>
                            </Item>
                        </Grid>
                        {errKey && errKey != "" &&
                            <Grid item xs={12}>
                                <Alert severity="error">{errKey}</Alert>
                            </Grid>
                        }
                        {certificate && certificate != "" &&
                            <>
                                <Grid item xs={12}>
                                    <Item>
                                        <TextField
                                            id="txtCertificate"
                                            label="Certificate"
                                            multiline
                                            defaultValue=""
                                            fullWidth
                                            disabled
                                            value={certificate}
                                        />
                                    </Item>
                                </Grid>
                                <Grid item xs={12}>
                                    <Item>
                                        <Button variant="contained"
                                            style={{ backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }}
                                            onClick={handleDownload}
                                        >Download</Button>
                                    </Item>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Paper>
            </Box>
        </Box>
    );
};
