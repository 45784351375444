import { useEffect } from "react";
import style from "./style.module.css";
import { Box } from "@mui/material";

export default function Map(props) {
    useEffect(() => { }, []);

    return (
        // <div className="container p-3" style={{ backgroundColor: '#EFEFEF' }}>
        <Box sx={{ flexGrow: 1, p: 3 }} style={{ backgroundColor: '#EFEFEF' }}>
            <table style={{ borderCollapse: 'collapse', marginLeft: 'auto', marginRight: 'auto', width: '100%', backgroundColor: '#EFEFEF' }}>
                <tr style={{ border: 'none' }}>
                    <td style={{ border: 'none', width: '50%', fontSize: '20', fontWeight: 'bold', color: '#9B9B9B' }}>
                        ID 454878
                    </td>
                    <td style={{ border: 'none', textAlign: 'right' }}>
                        <span className={"badge " + style.active}>ACTIVE</span>
                    </td>
                </tr>
            </table>

            <table style={{ borderCollapse: 'collapse', marginLeft: 'auto', marginRight: 'auto', width: '100%', backgroundColor: '#EFEFEF', fontSize: '15px', color: '#9B9B9B' }}>
                <tr style={{ border: 'none' }}>
                    <td style={{ border: 'none', width: '50%' }}>
                        Installation
                    </td>
                    <td style={{ border: 'none', width: '50%' }}>
                        12/5/2021
                    </td>
                </tr>
                <tr style={{ border: 'none' }}>
                    <td style={{ border: 'none' }}>
                        Manuf
                    </td>
                    <td style={{ border: 'none' }}>
                        Kamstruct
                    </td>
                </tr>
                <tr style={{ border: 'none' }}>
                    <td style={{ border: 'none' }}>
                        Type
                    </td>
                    <td style={{ border: 'none' }}>
                        Ultrasonic
                    </td>
                </tr>
                <tr style={{ border: 'none' }}>
                    <td style={{ border: 'none' }}>
                        Diameter
                    </td>
                    <td style={{ border: 'none' }}>
                        15mm
                    </td>
                </tr>
                <tr s style={{ border: 'none' }}>
                    <td className="pt-2" style={{ border: 'none', colSpan: '2' }}>
                        13 run de la conche, Agen
                    </td>
                </tr>
            </table>
            {/* </div > */}

        </Box >
    );
}
