import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    size="large">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function Help(params) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <>
        <IconButton aria-label="help" onClick={handleClickOpen} size="large">
            <i className="fa fa-info-circle" aria-hidden="true" style={{ color: 'white' }}></i>
        </IconButton>
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="md"
            fullWidth
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Help
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    H2O Mobile main screen is composed of 2 main sections. Search field to look for water meter by name, property or site, or serial number,
                    Meter list<br></br><br></br>

                    In the list, the main column are :<br></br><br></br>

                    <b>Radio</b><br></br>
                    Possible values on/off:<br></br>
                    This indicates that radio transmission in the meter is either on or off.  Currently says false…should be on/off<br></br>
                    When the meter is first installed, the radio will normally display “OFF”. After the
                    Meter has had water run through the meter for at least 30 to 60 seconds the radio will  turn “ON”<br></br><br></br>

                    <b>Size,serial Number and Mfr</b><br></br>

                    Size of the meter installed i.e. ¾”, 1”<br></br>
                    Serial Number: serial number of the meter or radio unit depending on meter type. Typically
                    Located on the meter register.<br></br>
                    Mfr: Meter manufacturer. Typically designated on the meter face or body.<br></br><br></br>

                    <b>Tenant Name, Address:</b><br></br>
                    Property owner or tenant, address<br></br><br></br>

                    <b>Last message, Readout and Status</b><br></br>
                    Last message is the time we receive a readout message for the water meter. The status should be active or inactive if we have not received any message in the last hour.<br></br><br></br>

                    <b>Properties and site</b><br></br>
                    You can change these values to add properties that are associated with the corresponding meter. Click on list line to open up the meter detailed page where you can set them up.<br></br>
                    You can arrange the column as you like by drag and drop them.<br></br><br></br>

                    <b>Export</b><br></br>
                    With the export button you can download water counters data.<br></br>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </BootstrapDialog>
    </>;

};
