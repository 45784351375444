import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import TabMenu from '../tab/index';

import style from './style.module.css';

import ApiService from '../../../services/apiService';


const handleClick = (setMessage, timer) => {
	sendMail(setMessage, timer);
}

export default function DeviceList(props) {

	const [message, setMessage] = useState('');
	const [counts, setCounts] = useState({ active: 0 });
	let timer = null;

	useEffect(() => {
		requestCountDevices(setCounts);

		return () => clearTimeout(timer);
	}, []);


	return (
		<div className="container" style={{ marginTop: "55px" }}>
			<div className="row">
				<TabMenu value={1}></TabMenu>
			</div>
			<div className="row">
				<div className="col">
					<span style={{ marginTop: "110px", marginBottom: "110px", width: "100%", fontSize: "20px", textAlign: "center", display: "inline-block" }}>Activated : {counts.active}</span>
				</div>
			</div>
			<div className="row">
				<div className="col text-center">
					<Button variant="contained" style={{ width: "100%", backgroundColor: "rgb(126, 201, 217)", color: "rgb(255,255,255)", textTransform: "none" }} onClick={() => handleClick(setMessage, timer)}>Mail the list</Button>
					<span>{message}</span>
				</div>
			</div>
		</div>
	)
};


function sendMail(setMessage, timer) {

	let requestOptions = {
		url: "/api/app/devicescan/email"
	};

	ApiService.getData(requestOptions)
		.then((response) => {
			switch (response.CODE) {
				case 'OK':
					setMessage("An email has been sent.");
					break;
			}
		})
		.catch((e) => console.log(e));

	timer = setTimeout(() => setMessage(""), 5000);
}


function requestCountDevices(setCounts) {

	let requestOptions = {
		url: "/api/app/devicescan/countDevices"
	};

	ApiService.getData(requestOptions)
		.then((response) => {
			setCounts({
				active: response.active
			})
		})
		.catch((e) => console.log(e));
}
