export function ChartLangOption(i18n) {
    return i18n.language === "fr"
        ? {
            contextButtonTitle: "Menu contextuel",
            decimalPoint: ",",
            downloadCSV: "Télécharger CSV",
            downloadJPEG: "Télécharger JPEG",
            downloadPDF: "Télécharger PDF",
            downloadPNG: "Télécharger PNG",
            downloadSVG: "Télécharger SVG",
            downloadXLS: "Télécharger XLS",
            drillUpText: "Retour à {series.name}",
            loading: "Chargement...",
            months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
            noData: "Pas de données",
            printChart: "Imprimer le graphique",
            resetZoom: "Réinitialiser le zoom",
            resetZoomTitle: "Réinitialiser le niveau de zoom 1:1",
            shortMonths: ["Janv", "Févr", "Mars", "Avr.", "Mai", "Juin", "Juil", "Août", "Sept.", "Oct.", "Nov", "Déc"],
            thousandsSep: " ",
            weekdays: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
            viewData: "Voir le tableau de données",
            viewFullscreen: "Voir en plein écran",
        }
        : {};
}
