import _ from "underscore";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import ApiService from "Services/apiService";

const AddrService = {
    getAwsConfig: async () => {
        try {
            const credentials = await ApiService.putData({
                url: `/v2/credentials_react_app`,
            });

            return {
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                region: credentials.region,
            };
        } catch (error) {
            console.error("Error fetching AWS config:", error);
            throw error;
        }
    },

    verifyAddress: async (addr) => {
        if (!addr || addr.trim() === "") {
            return [];
        }

        try {
            const awsConfig = await AddrService.getAwsConfig();
            AWS.config.update(awsConfig);
            const geocoder = new AWS.Location();

            const params = {
                IndexName: process.env.REACT_APP_AWS_LOCATION_INDEX_NAME || "explore.place.Esri",
                Text: addr,
                MaxResults: 10,
            };

            const response = await geocoder.searchPlaceIndexForText(params).promise();

            return response.Results.map((r) => ({
                key: uuidv4(),
                label: r.Place.Label,
                ...r.Place,
            }));
        } catch (error) {
            console.error("Error verifying address:", error);
            return [];
        }
    },
};

export default AddrService;
