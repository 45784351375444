import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import style from "./style.module.css";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import RoomElectricityCardService from "./RoomElectricityCardService";
import { useState } from "react";
import AttributeWidget from "../../../components/widgets/AttributeWidget";

export default function RoomCard({ asset, entity }) {
    const { t, i18n } = useTranslation();
    let history = useHistory();
    // const [moyenne, setMoyenne] = useState(false);

    const [humidity, setHumidity] = useState();
    const [temperature, setTemperature] = useState();
    const [co2, setCo2] = useState();
    const [site, setSite] = useState(null);

    useEffect(() => {
        // console.log("uef in RoomCard");
        // console.log(asset);

        // console.log(asset.assetAttribute.find((a) => a.thing.id).thing.id);
        // console.log(asset.assetAttribute.map((a) => a.attribute.id));

        const thingId = asset.assetAttribute.find((a) => a.thing.id).thing.id;
        const attributeIds = asset.assetAttribute.map((a) => a.attribute.id);

        RoomElectricityCardService.requestLastMeasures(thingId, attributeIds).then((r) => {
            // console.log(r);
            r.forEach((ele) => {
                // console.log(ele);

                let element = {
                    thingId: ele.thingId,
                    attributeId: ele.attribute.id,
                    value: ele.value,
                    unit: ele.attribute.unit,
                    date: ele.timestamp,
                };

                switch (ele.attribute.name) {
                    case "humidity":
                        setHumidity(element);
                        break;
                    case "temperature":
                        setTemperature(element);
                        break;
                    case "co2":
                        setCo2(element);
                        break;
                    default:
                        break;
                }
            });

            setSite(asset.site ? asset.site : "");
        });
    }, [asset]);

    const handleButton = (thingId, attributeId) => {
        if (!thingId && !attributeId) {
            return;
        }
        history.push(`${process.env.PUBLIC_URL}/emera/v2/chart/${thingId}/${attributeId}`);
    };

    const handleFloor = (floor) => {
        history.push(`${process.env.PUBLIC_URL}/emera/v2/floor/${floor}`);
    };

    return (
        <Box sx={{ margin: 3, width: "300px", minHeight: 270 }}>
            <Paper elevation={8} sx={{ borderRadius: "15px", p: 2 }}>
                <Grid container>
                    {/* Room name */}
                    <Grid item xs={7}>
                        <span className={style.box} onClick={() => handleFloor(entity.floor)}>
                            {site}
                        </span>
                    </Grid>

                    {/* Battery icon and Calendar icon */}
                    <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton
                            aria-label="btn-battery"
                            sx={{
                                "&.MuiButtonBase-root:hover": {
                                    cursor: "default",
                                },
                            }}
                            size="small"
                        >
                            <i className="fa fa-battery-full" aria-hidden="true"></i>
                        </IconButton>
                    </Grid>

                    {/* Daily average */}
                    {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", height: 30 }}>
                        <Typography variant="h6" component="h6">
                            {t("emera_v2.room_daily_average")}
                        </Typography>
                    </Grid> */}

                    <Grid item xs={12}>
                        <AttributeWidget
                            variant="co2"
                            title="Co2"
                            entity={co2}
                            thingId={co2 ? co2.thingId : undefined}
                            attributeId={co2 ? co2.attributeId : undefined}
                            callback={handleButton}
                        />
                    </Grid>

                    {/* Temperature */}
                    <Grid item xs={6} sx={{ mt: 2 }}>
                        <AttributeWidget
                            variant="temperature"
                            title={t("emera_v2.room_temperature")}
                            entity={temperature}
                            thingId={temperature ? temperature.thingId : undefined}
                            attributeId={temperature ? temperature.attributeId : undefined}
                            callback={handleButton}
                        />
                    </Grid>

                    {/* humidity */}
                    <Grid item xs={6} sx={{ mt: 2 }}>
                        <AttributeWidget
                            variant="humidity"
                            title={t("emera_v2.room_humidity")}
                            entity={humidity}
                            thingId={humidity ? humidity.thingId : undefined}
                            attributeId={humidity ? humidity.attributeId : undefined}
                            callback={handleButton}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}
