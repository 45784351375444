import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ThingsDialog from "./popup/ThingsDialog";
import TempMntService from "./TempMntService";
import SensorCard from "./sensor/SensorCard";
import UserService from "Services/userService";
import moment from "moment";
import { CustomButton } from "Components/ui/CustomButton";
import { useTranslation } from "react-i18next";
import ClusterCreateDialog from "./cluster/ClusterCreateDialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomConfirmDialog from "Components/ui/CustomConfirmDialog";
import ClusterService from "./cluster/ClusterService";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
}));

export default function ClusterContainer() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    let history = useHistory();

    const [openDialog, setOpenDialog] = useState(false);
    const [cluster, setCluster] = useState([]);
    const [selectedClusterId, setSelectedClusterId] = useState();
    const [reload, setReload] = useState();
    const [loading, setLoading] = useState(false);

    const [openCluster, setOpenCluster] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    useEffect(() => {
        UserService.getAuthenticatedUser().then((u) => {
            TempMntService.requestCluster(u.companyId, setCluster, setLoading);
        });
    }, [reload]);

    const handleDialog = (cluster_id) => {
        setSelectedClusterId(cluster_id);
        setOpenDialog(true);
    };

    const handleDelete = () => {
        ClusterService.removeCluster(selectedClusterId).then(() => {
            setReload(moment());
        });
    };

    return (
        <>
            {cluster.map((c) => {
                return (
                    <Accordion defaultExpanded={true} key={`accordion-${c.cluster.id}}`}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id={`panel1a-header-${c.cluster.id}}`}>
                            <Typography className={classes.heading}>{c.cluster.name}</Typography>
                            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                                <IconButton
                                    aria-label="close"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setSelectedClusterId(c.cluster.id);
                                        setOpenDelete(true);
                                    }}
                                    size="small"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {c.things.map((t) => {
                                        return (
                                            <SensorCard
                                                clusterId={c.cluster.id}
                                                thing={t}
                                                low={t.threshold.low}
                                                high={t.threshold.high}
                                                callback={() => setReload(moment())}
                                                key={`sensor-${t.id}`}
                                            ></SensorCard>
                                        );
                                    })}
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display={"flex"} justifyContent="center">
                                        <Fab color="primary" aria-label="add" onClick={() => handleDialog(c.cluster.id)} size={"small"}>
                                            <AddIcon />
                                        </Fab>
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            })}

            {loading && cluster && cluster.length == 0 && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 20 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CustomButton name={t("tm.add_first_cluster")} callback={() => setOpenCluster(true)} />
                    </Box>
                </Box>
            )}

            {loading && cluster && cluster.length > 0 && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CustomButton name={t("tm.add_cluster")} callback={() => setOpenCluster(true)} />
                    </Box>
                </Box>
            )}

            <ThingsDialog
                open={openDialog}
                setOpenDialog={setOpenDialog}
                selectedClusterId={selectedClusterId}
                callback={() => setReload(moment())}
            ></ThingsDialog>

            <ClusterCreateDialog open={openCluster} setOpen={setOpenCluster} callback={() => setReload(moment())} />

            <CustomConfirmDialog
                open={openDelete}
                setOpen={setOpenDelete}
                title="Delete group"
                message={["Would you like to delete this group?", "All things in this group will be unassigned as well."]}
                callback={handleDelete}
            />
        </>
    );
}
