import React, { useEffect, useContext, useRef, useState } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "../../providers/SettingContext";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Highcharts from "highcharts/highstock";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { AppId } from "Components/applications/ApplicationData";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import Button from "@mui/material/Button";
import ThresholdDialogV2 from "../smartAgriculture/details/ThresholdDialogV2";
import EmeraConstants from "./EmeraConstants";
import moment from "moment";
import UtilService from "Services/utilService";
highchartsMore(Highcharts);

var ranges = [
        [1625101200000, 25, 35],
        [1625104800000, 28, 39],
        [1625108400000, 29, 40],
        [1625112000000, 29, 43],
        [1625115600000, 29, 45],
        [1625119200000, 29, 44],
        [1625122800000, 34, 48],
        [1625126400000, 41, 51],
        [1625130000000, 41, 51],
        [1625133600000, 37, 48],
        [1625137200000, 38, 48],
        [1625140800000, 41, 50],
        [1625144400000, 45.3, 53.3],
        [1625148000000, 42.4, 51.4],
        [1625151600000, 38, 49],
        [1625155200000, 40, 50],
        [1625158800000, 34, 47],
        [1625162400000, 38, 53],
        [1625166000000, 39, 48],
        [1625169600000, 38, 49],
        [1625173200000, 35, 46],
        [1625176800000, 32, 41],
        [1625180400000, 33, 43],
    ],
    averages = [
        [1625101200000, 30],
        [1625104800000, 34],
        [1625108400000, 34],
        [1625112000000, 36],
        [1625115600000, 37],
        [1625119200000, 39],
        [1625122800000, 40],
        [1625126400000, 45],
        [1625130000000, 44],
        [1625133600000, 43],
        [1625137200000, 45],
        [1625140800000, 46],
        [1625144400000, 48.3],
        [1625148000000, 46.4],
        [1625151600000, 43],
        [1625155200000, 43],
        [1625158800000, 42],
        [1625162400000, 45],
        [1625166000000, 43],
        [1625169600000, 41],
        [1625173200000, 40],
        [1625176800000, 37],
        [1625180400000, 38],
    ];

function options(title, unit, type) {
    return {
        chart: {
            type: type,
        },
        title: {
            text: title,
        },

        xAxis: {
            type: "datetime",
            labels: {
                overflow: "justify",
                formatter: function () {
                    return UtilService.formatHour(this.value);
                },
            },
        },

        yAxis: {
            title: {
                text: null,
            },
        },

        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: unit,
        },

        series: [
            {
                name: title,
                data: averages,
                zIndex: 1,
                marker: {
                    fillColor: "white",
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[0],
                },
                showInLegend: false,
            },
            {
                name: "Range",
                data: ranges,
                type: "arearange",
                lineWidth: 0,
                linkedTo: ":previous",
                color: Highcharts.getOptions().colors[0],
                fillOpacity: 0.3,
                zIndex: 0,
                marker: {
                    enabled: false,
                },
            },
        ],

        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function ChartHumidity(props) {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    ctx.updateTitle("Emera");
    const { t, i18n } = useTranslation();

    const componentRef = useRef();

    const [selected, setSelected] = useState(false);
    const [type, setType] = useState("spline");
    const [periodIdx, setPeriodIdx] = useState(3);
    const [cursor, setCursor] = useState("default");
    const [isOpenThresholdModal, setIsOpenThresholdModal] = useState(false);

    const [name, setName] = useState("Humidity");
    const [thingId, setThingId] = useState(EmeraConstants.thingId);
    const [attributeId, setAttributeId] = useState(EmeraConstants.humidityAttributeId);

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.EMERA,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        return () => {
            ctx.updateTitle(null);
        };
    }, []);

    const handleChartType = () => {
        setSelected(!selected);

        if (selected) {
            setType("spline");
        } else {
            setType("column");
        }
    };

    const handlePeriod = (periodIdx) => {
        setPeriodIdx(periodIdx);
        // setCursor('wait');
    };

    const handleThreshold = () => {
        setIsOpenThresholdModal(true);
    };

    return (
        <Box sx={{ flexGrow: 1, marginTop: 15 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginLeft: 5 }}>
                    <ToggleButton value="check" selected={!selected} onChange={() => handleChartType()} size="small">
                        <ShowChartIcon />
                    </ToggleButton>
                    <ToggleButton value="check" selected={selected} onChange={() => handleChartType()} size="small">
                        <EqualizerIcon />
                    </ToggleButton>
                    <Button variant="contained" color="primary" style={{ textTransform: "none", margin: "5px" }} onClick={() => handleThreshold()}>
                        {t("btn.threshold")}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} options={options(t("emera.analysis_humidity"), "%", type)} />
                </Grid>
            </Grid>

            <ThresholdDialogV2
                onClose={() => setIsOpenThresholdModal(false)}
                open={isOpenThresholdModal}
                thingId={thingId}
                attributeId={attributeId}
                title="Alert's Thresholds"
                attributeName={name}
            />
        </Box>
    );
}
