import { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import AppContext from "Components/AppContext";
import { AppId } from "Components/applications/ApplicationData";
import TopToolBar from "./toolBar/TopToolBar";
import MeasurementView from "./measurements/main/MeasurementView";
import Box from "@mui/material/Box";
import UserService from "Services/userService";
import SubMeasurementView from "./measurements/sub/SubMeasurementView";
import moment from "moment";
import SettingContext from "Providers/SettingContext";
import NoSubscriptionDialog from "../../components/subscription/NoSubscriptionDialog";
import NoSubscriptionInitiallyDialog from "../../components/subscription/NoSubscriptionInitiallyDialog";
import StateBarMain from "./statusBar/StateBarMain";
import Item from "../../components/ui/Item";

export default function WaterMetering() {
    const { WATER_METERING } = AppId;

    const params = useParams();
    const appId = params.appId;

    const [keyword, setKeyword] = useState();
    const [company, setCompany] = useState();
    const [companyId, setCompanyId] = useState();
    const [username, setUsername] = useState();
    const [authorities, setAuthorities] = useState();
    const [reload, setReload] = useState();

    const [noSub, setNoSub] = useState(false);
    const [expired, setExpired] = useState(false);
    const [subId, setSubId] = useState();

    const [viewMode, setViewMode] = useState("grid"); // grid or widget

    let appCtx = useContext(AppContext);
    const setting = useContext(SettingContext);

    useEffect(() => {
        switch (appId) {
            case WATER_METERING:
                appCtx.updateTitle("H2O Mobile");
                break;
            default:
                break;
        }

        setting.setGlobalSetting({
            appId: AppId.WATER_METERING,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u.company);
            setCompany(u.company);
            setCompanyId(u.companyId);
            setUsername(u.username);
            setAuthorities(u.authorities);

            if (u.lastSubscription && u.lastSubscription.expired) {
                setNoSub(true);
                setExpired(true);
                setSubId(u.lastSubscription.id);
            }

            if (!u.lastSubscription) {
                setNoSub(true);
            }

            if (u.authorities == "CLIENT_ADMIN" || u.authorities == "SUPER_ADMIN") {
                setNoSub(false);
            }
        });
    }, [keyword]);

    const searchKeyword = useCallback((keyword) => {
        setKeyword(keyword);
    });

    const handleRenewSubscription = () => {
        setNoSub(false);
        window.location.href = "/apps"; // Reload the page to get the subscription which was made just before
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                flexShrink: 0,
                height: "100%",
            }}
        >
            <Item sx={{ mt: "45px" }} elevation={0}>
                &nbsp;
            </Item>

            {/* 8/24/2023, Comment out below. Gene requested remove it and Eric has confirmed   */}
            {/* <FavoriteWrapper reload={reload} />                                             */}

            <Item sx={{ height: 100, ml: "10px", mr: "10px" }} elevation={0}>
                <TopToolBar callbackKeyword={searchKeyword} appId={appId} callbackMode={(mode) => setViewMode(mode)}></TopToolBar>
            </Item>

            {/* Only Iflowinc members can see the state count  */}
            {company && company === "FLOWINC" && <StateBarMain />}

            <Item sx={{ height: "100%", m: "10px" }} elevation={0}>
                {/* iFlowinc : admin */}
                {company && authorities != "TENANTS" && authorities != "MANAGER" && (
                    <MeasurementView keyword={keyword} appId={appId} callback={() => setReload(moment().unix())} viewMode={viewMode}></MeasurementView>
                )}
                {/* Sub companies */}
                {company && (authorities == "TENANTS" || authorities == "MANAGER") && (
                    <SubMeasurementView
                        keyword={keyword}
                        appId={appId}
                        username={username}
                        callback={() => setReload(moment().unix())}
                        viewMode={viewMode}
                    ></SubMeasurementView>
                )}
            </Item>

            {expired && (
                <NoSubscriptionDialog
                    open={noSub}
                    setOpen={setNoSub}
                    subId={subId}
                    companyId={companyId}
                    callback={handleRenewSubscription}
                    appId={AppId.WATER_METERING}
                />
            )}

            {!expired && (
                <NoSubscriptionInitiallyDialog
                    open={noSub}
                    setOpen={setNoSub}
                    subId={subId}
                    companyId={companyId}
                    callback={handleRenewSubscription}
                    appId={AppId.WATER_METERING}
                />
            )}
        </Box>
    );
}
