import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import ShareService from "./ShareService";
import DeviceExplorerConstant from "./DeviceExplorerConstant";
import Item from "../../../components/ui/Item";

export default function ButtonSwitch(props) {
    const [on, setOn] = useState(false);
    const [lastSentTimestamp, setLastSentTimestamp] = useState();

    const handleButton = (e) => {
        props.clickButton(props.id);
    };

    const handleSwitch = (e) => {
        props.clickSwitch(props.name, e.target.checked);
        setOn(e.target.checked);
    };

    useEffect(() => {
        setOn(props.checked);

        ShareService.requestLastSentTimestamp(DeviceExplorerConstant.toScope(props.id), setLastSentTimestamp);
    }, [props.checked, props.companyName, props.id]);

    return (
        <>
            <FormGroup>
                <Stack spacing={0}>
                    <Item>
                        <Button
                            variant="contained"
                            onClick={(e) => handleButton(e)}
                            fullWidth
                            sx={{ backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }}
                        >
                            {props.name}
                        </Button>
                    </Item>
                    <Item style={{ width: "120px", display: "flex", justifyContent: "center" }}>
                        <FormControlLabel
                            control={<Switch checked={on} size="small" onChange={(e) => handleSwitch(e)} disabled={props.disableSwitch} />}
                            label={on ? "ON" : "OFF"}
                            labelPlacement="start"
                            sx={{
                                m: 0,
                                p: 0,
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                    color: "#5694B5",
                                },
                            }}
                        />
                    </Item>
                    {lastSentTimestamp && <Item>{lastSentTimestamp}</Item>}
                </Stack>
            </FormGroup>
        </>
    );
}
