const ThresholdColumns = {
    getColumns: function (t) {
        const threshold_columns = [
            { field: "id", headerName: "ID", hide: true },
            {
                field: "startTime",
                headerName: t("col.start_time"),
                flex: 1,
                editable: false,
                align: "left",
                headerAlign: "left",
            },
            {
                field: "endTime",
                headerName:t("col.end_time"),
                flex: 1,
                editable: false,
                sortable: true,
                align: "left",
                headerAlign: "left",
            },
            {
                field: "isAllTime",
                headerName: t("col.all_time"),
                type: "boolean",
                flex: 1,
                editable: false,
                sortable: true,
                hide: true,
            },
            {
                field: "lowValue",
                headerName: t("col.low"),
                flex: 1,
                editable: false,
                sortable: true,
                align: "left",
                headerAlign: "left",
            },
            {
                field: "highValue",
                headerName: t("col.high"),
                flex: 1,
                editable: false,
                sortable: true,
                align: "left",
                headerAlign: "left",
            },
        ];
        return threshold_columns;
    },
};

export default ThresholdColumns;
