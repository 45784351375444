import React, { useEffect, useState, useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import UtilService from "../../../services/utilService";
import withStyles from "@mui/styles/withStyles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useTranslation } from "react-i18next";
import SettingContext from "Providers/SettingContext";
import style from "./style.module.css";
import HistoryService from "./HistoryService";
import { chartOption } from "./chartOption";
import Box from "@mui/material/Box";

import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";

// Apply the modules to Highcharts
Exporting(Highcharts);
ExportData(Highcharts);

const HistoryValues = ({ thingId, attributeId }) => {
    const { t, i18n } = useTranslation();

    // Temperature scales
    // const [settingCelsius, setSettingCelsius] = useState(props.settingCelsius);

    // Data for chart
    const [ave, setAve] = useState([]);
    const [min, setMin] = useState([]);
    const [max, setMax] = useState([]);

    const [expanded, setExpanded] = useState(false);
    const setting = useContext(SettingContext);
    const [isCelsius, setIsCelsius] = useState(false);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);

        if (newExpanded) {
            HistoryService.requestHistoryValues(thingId, attributeId, processResponse);
        }
    };

    useEffect(() => {
        setIsCelsius(setting.globalSetting.temperatureMonitoring.celsius);
    }, [setting]);

    function processResponse(response) {
        let arrMin = [];
        let arrMax = [];
        let arrAve = [];

        response.forEach((element) => {
            // let baseTime = element.end * 1000;
            let baseTime = element.start * 1000;

            if (isCelsius) {
                if (element.unit === "°C" || element.unit === "") {
                    arrMin.push([baseTime, element.min]);
                    arrMax.push([baseTime, element.max]);
                    arrAve.push([baseTime, element.value]);
                } else {
                    arrMin.push([baseTime, parseFloat(UtilService.toCelsius(element.min))]);
                    arrMax.push([baseTime, parseFloat(UtilService.toCelsius(element.max))]);
                    arrAve.push([baseTime, parseFloat(UtilService.toCelsius(element.value))]);
                }
            } else {
                if (element.unit === "°F") {
                    arrMin.push([baseTime, element.min]);
                    arrMax.push([baseTime, element.max]);
                    arrAve.push([baseTime, element.value]);
                } else {
                    arrMin.push([baseTime, parseFloat(UtilService.toFahrenheit(element.min))]);
                    arrMax.push([baseTime, parseFloat(UtilService.toFahrenheit(element.max))]);
                    arrAve.push([baseTime, parseFloat(UtilService.toFahrenheit(element.value))]);
                }
            }
        });

        // set data for the chart
        setMin(arrMin);
        setMax(arrMax);
        setAve(arrAve);
    }

    // Configuration for ReactHighcharts
    const configChart = chartOption(isCelsius, t, min, ave, max);

    return (
        <Box sx={{ m: 2 }}>
            <Accordion square expanded={expanded === "panel1"} onChange={handleChange("panel1")} className={style.accordion}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className={style.div_title}>
                        <span className={style.title}>{t("history.title")}</span>
                    </div>
                    <div className={style.arrow}>
                        {expanded ? <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={style.collapse}>
                        <HighchartsReact highcharts={Highcharts} options={configChart}></HighchartsReact>
                    </div>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

// Styles for Accordion
const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    // root: {
    // 	padding: theme.spacing(2),
    // },
}))(MuiAccordionDetails);

export default HistoryValues;
