import ApiService from "Services/apiService";

const DownlinkService = {
    requestDefaultConfig: async (productId) => {
        let options = {
            url: `/v2/downlink/${productId}`,
        };
        // console.log("requestDefaultConfig", options);

        return ApiService.getData(options)
            .then((response) => {
                // console.log(response);
                // setThing(response);
                return response;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestConfig: async (thingId, productId) => {
        let options = {
            url: "/v2/downlink/one",
            params: {
                thingId,
                productId,
            },
        };

        return ApiService.getData(options)
            .then((response) => {
                // console.log(response);
                // setThing(response);
                return response;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    sendDownlink: async (thingId, productModelId, keepAlive, tempHigh, tempLow, humidityHigh, humidityLow) => {
        const requestOptions = {
            url: "/v2/downlink",
            data: {
                thingId,
                productModelId,
                config: {
                    keep_alive_period: {
                        value: keepAlive,
                    },
                    temperature: {
                        high: {
                            value: tempHigh,
                        },
                        low: {
                            value: tempLow,
                        },
                    },
                    humidity: {
                        high: {
                            value: humidityHigh,
                        },
                        low: {
                            value: humidityLow,
                        },
                    },
                },
            },
        };
        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
                // setThing(response);
                return response;
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default DownlinkService;
