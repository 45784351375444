import { useEffect, useContext, useState } from 'react';
import { styled, adaptV4Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CropComboBox from './CropComboBox';
import CropChart from './CropChart';
import CropService from './CropService';
import GrowthService from '../plant/GrowthService';
import AppContext from 'Components/AppContext'
import SettingContext from 'Providers/SettingContext';
import { AppId } from 'Components/applications/ApplicationData';
import _ from 'underscore';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
}));


export default function Analysis(props) {

    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    ctx.updateTitle("Smart Agriculture");

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const [selectedCropGrowthIds, setSelectedCropGrowthIds] = useState([]);
    const [selectedAssetId, setSelectedAssetId] = useState();
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.SMART_AGRICULTURE,
            temperatureMonitoring: {
                celsius: true,
                language: "en"
            }
        });

        // console.log("useEffect in Analysis")

        CropService.requestCrop(0, 20, setRows, setRowCount, undefined, setSelectedCropGrowthIds);

        GrowthService.requestAllAsset(selectedCropGrowthIds, selectedAssetId, setChartData);
    }, []);


    const selectCluster = (assetId) => {
        setSelectedAssetId(assetId);

        setTimeout(() => {
            setSelectedCropGrowthIds((id) => {
                GrowthService.requestAllAsset(id, assetId, setChartData);
                return id;
            })
        }, 500);
    }

    const handleButtonClick = (cropId) => {
        let current = [];
        if (_.indexOf(selectedCropGrowthIds, cropId) == -1) {
            current = selectedCropGrowthIds;
            current.push(cropId);
            setSelectedCropGrowthIds(current);
            GrowthService.requestAllAsset(current, selectedAssetId, setChartData);
        } else {
            current = _.without(selectedCropGrowthIds, cropId);
            setSelectedCropGrowthIds(current);
        }

        GrowthService.requestAllAsset(current, selectedAssetId, setChartData);
    }

    const theme = createTheme(adaptV4Theme({
        palette: {
            active: {
                main: '#A7C573',
                contrastText: '#fff',
            },
            finished: {
                main: '#C45A58',
                contrastText: '#fff',
            },
        },
    }));

    return (
        <Box sx={{ flexGrow: 1, mt: 15, ml: 4, mr: 4 }} >
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Item>
                        <CropComboBox selectCluster={selectCluster}></CropComboBox>
                    </Item>
                </Grid>
                <Grid item xs={10}>
                    {/* <Item> */}
                    &nbsp;
                    {/* </Item> */}
                </Grid>
                {/* {selectedAssetId !== undefined &&
                    <Grid item xs={12}>
                        <Item>
                            <Stack direction="row" spacing={2}>
                                <ThemeProvider theme={theme}>
                                    {
                                        generateButtons(rows, handleButtonClick, selectedCropGrowthIds)
                                    }
                                </ThemeProvider>
                            </Stack>
                        </Item>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Item>
                        <CropChart chartData={chartData} ></CropChart>
                    </Item>
                </Grid> */}
            </Grid>

            <Item sx={{ mt: 4 }}>
                <Stack direction="row" spacing={2}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            {
                                generateButtons(rows, handleButtonClick, selectedCropGrowthIds)
                            }
                        </ThemeProvider>
                    </StyledEngineProvider>
                </Stack>
            </Item>

            <Item>
                <CropChart chartData={chartData} ></CropChart>
            </Item>
        </Box>
    );
}


function generateButtons(rows, handleButtonClick, selectedCropGrowthIds) {
    let elements = [];

    console.log("///------")
    console.log(rows)

    rows.forEach(row => {
        elements.push(<Button onClick={() => handleButtonClick(row.id)}
            variant={_.indexOf(selectedCropGrowthIds, row.id) != -1 ? "contained" : "outlined"}
            color={row.status == 'FINISHED' ? "finished" : "active"}> {row.name}</Button >);
    });
    return elements;
}