import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

export default function CustomConfirmDialog({ open, setOpen, title, message, callback, hideNo }) {
    const { t, i18n } = useTranslation();

    const handleYes = () => {
        setOpen(false);
        callback();
    };

    useEffect(() => {
        // console.log("uef in CustomConfirmDialog");
        // console.log(message);
        // console.log(Array.isArray(message));
    }, []);

    return (
        <div>
            <Dialog open={open} onClose={setOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="xs" fullWidth>
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                    <DialogContentText id="alert-dialog-description">
                        {Array.isArray(message) &&
                            message.map((m, idx) => {
                                return (
                                    <p key={idx} style={{ fontWeight: "normal", fontFamily: "inherit" }}>
                                        {m}
                                    </p>
                                );
                            })}
                        {!Array.isArray(message) && message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!hideNo && <Button onClick={() => setOpen(false)}>{t("btn.no")}</Button>}
                    <Button onClick={() => handleYes()} autoFocus>
                        {t("btn.yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
