import ApiService from "Services/apiService";
import moment from "moment-timezone";
import DashboardService from "../table/DashboardService";

const DetailService = {
    requestHistory: async (thingName, thingId, attributeId, attributeName, handleChartData, start) => {
        const now = moment().valueOf();
        if (start == undefined) {
            start = moment().subtract(1, "years").valueOf(); // aYearAgo
        }

        const filter = `filter=thing.id:eq_${thingId},attribute.id:eq_${attributeId}`;
        const period = `&time=1&interval=day&start=${start}&end=${now}`;

        let requestOptions = {
            url: `/stats/measurements?${filter}${period}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                let chartData = [];
                response.forEach((ele) => {
                    chartData.push([ele.last, parseFloat(parseFloat(ele.value).toFixed(1)), ele.unit]);
                });

                handleChartData(chartData);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestHistoryAverage: async (thingName, thingId, attributeId, attributeName, handleChartData, interval) => {
        const filter = `filter=thing.id:eq_${thingId},attribute.id:eq_${attributeId}`;

        let start = moment().valueOf();
        if (interval == "hour") {
            start = moment().subtract(1, "days").valueOf();
        } else if (interval == "day") {
            start = moment().subtract(1, "months").valueOf();
        } else if (interval == "month") {
            start = moment().subtract(1, "year").valueOf();
        } else if (interval == "none") {
            // start = moment().subtract(1, 'days').valueOf();
            start = moment().subtract(2, "years").valueOf();
        }

        const period = `&start=${start}&end=${moment().valueOf()}`;

        let requestOptions = {
            url: `/stats/measurements?${filter}&interval=${interval}${period}&time=1`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                let chartData = [];
                response.forEach((ele) => {
                    chartData.push([ele.last, parseFloat(parseFloat(ele.value).toFixed(1)), ele.unit]);
                });

                handleChartData(chartData);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestHistoryAll: async (thingName, thingId, attributeId, attributeName, handleChartData, interval) => {
        const filter = `filter=thing.name:eq_${thingName},attribute.id:eq_${attributeId}`;

        let start = moment().valueOf();
        if (interval == "hour") {
            start = moment().subtract(1, "days").valueOf();
        } else if (interval == "day") {
            start = moment().subtract(1, "months").valueOf();
        } else if (interval == "month") {
            start = moment().subtract(1, "years").valueOf();
        } else if (interval == "none") {
            start = moment().subtract(3, "months").valueOf(); // 1 year : OutofMemory error occurs on server
            // start = moment().subtract(1, 'days').valueOf();
        }

        const period = `&start=${start}&end=${moment().valueOf()}`;

        let requestOptions = {
            url: `/stats/measurements/all?${filter}&interval=${interval}${period}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                let chartData = [];
                response.forEach((ele) => {
                    chartData.push([ele.last, parseFloat(parseFloat(ele.value).toFixed(1)), ele.unit]);
                });

                // console.log(chartData);

                handleChartData(chartData);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestDailyHistory: async (thingName, attributeId, selectedDate, handleDailyChartData) => {
        let start = moment(selectedDate).hours("0").minutes("0").seconds("0").unix() + "000";
        let end = moment(selectedDate).hours("23").minutes("59").seconds("59").unix() + "000";

        const filter = `filter=thing.name:eq_${thingName},attribute.id:eq_${attributeId}`;
        const period = `&time=1&interval=hour&start=${start}&end=${end}`;

        let requestOptions = {
            url: `/stats/measurements?${filter}${period}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                let chartData = [];
                response.forEach((ele) => {
                    if (ele.unit == undefined) {
                        chartData.push([moment(ele.last).add(4, "hour").format("HH:MM:SS"), ele.value]);
                    } else if (ele.unit == "GWC") {
                        chartData.push([moment(ele.last).add(4, "hour").format("HH:MM:SS"), DashboardService.changeReadableFormat2(ele.value)]);
                    } else {
                        chartData.push([moment(ele.last).add(4, "hour").format("HH:MM:SS"), ele.value]);
                    }
                });

                handleDailyChartData(chartData);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default DetailService;
