import ApiService from "Services/apiService";

const ClusterService = {
    createCluster: async (companyId, clusterName) => {
        let requestOptions = {
            url: `/v2/cluster`,
            data: {
                companyId: companyId,
                clusterName: clusterName,
                pthApplicationId: 'TEMPERATURE_MONITORING'
            },
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.log("createCluster...Response");
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    removeCluster: async (clusterId) => {
        let requestOptions = {
            url: `/v2/cluster/${clusterId}`,
        };

        return ApiService.deleteData(requestOptions)
            .then((response) => {
                // console.log("removeCluster...Response");
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });

    }
};

export default ClusterService;
