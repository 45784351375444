import _ from "underscore";
import UtilService from "Services/utilService";
import moment from "moment";

export default function ChartOption(yAxisTitle, type, unit, chartData, interval, categories) {
    return {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: categories,
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: yAxisTitle
            }
        },
        tooltip: {
            shared: true,
            useHTML: true,
            formatter: function () {
                // console.log("///------points---")
                // console.log(this)

                const firstKey = this.points[0].key;
                const filterdFirstData = chartData.filter(d => d[0] == firstKey)
                    .map(d => {
                        let formattedDate = "";
                        let attributeName = "";

                        if (interval == "hour") {
                            formattedDate = UtilService.formatMD(d[3]) + ' - ' + UtilService.toFromToHouly(d[3]);
                            attributeName = "Volumn";
                        } else if (interval == "day") {
                            formattedDate = UtilService.formatMD(d[3]);
                            attributeName = "Consumption";
                        } else {
                            formattedDate = UtilService.formatM(d[3]);
                            attributeName = "Consumption";
                        }

                        return formattedDate + `</br>${attributeName}: ` + d[1];
                    });

                // console.log(filterdFirstData)

                let html = [];

                html.push(filterdFirstData)
                html.push('Irrigation:' + this.y)
                // console.log(chartData)
                // console.log(data)
                // console.log(chartDataLastWeek)

                return html.join("</br");
            },
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: (interval == 'week' ? true : false)
        },
        exporting: {
            enabled: false
        },
        series: [{
            name: `current week`,
            unit: `${unit == undefined ? "" : unit}`,
            data: chartData,
        }],
        time: {
            timezone: moment.tz.guess(),
            useUTC: false
        },
    };
}
