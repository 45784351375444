import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import moment from "moment";
import UtilService from "Services/utilService";

export default function MasterDetailLightMulti(props) {
    const { thLow, thHigh, thRate, handlePoint, type, attributeName, seriesData, chkName, chLow, chHigh, chRate, periodIdx } = props;

    // console.log("MasterDetailLightMulti...-------------------------");

    const [detailOptions, setDetailOptions] = useState({});
    const [masterOptions, setMasterOptions] = useState({});
    const [filterBegin, setFilterBegin] = useState(0);
    const [filterEnd, setFilterEnd] = useState(9999999999999);

    // Configuration for ReactHighcharts
    const detailChart = {
        title: {
            text: "",
        },
        xAxis: {
            type: "datetime",
        },
        yAxis: [
            {
                title: {
                    text: `${attributeName}`,
                },
                min: 0,
                plotLines: [
                    {
                        color: "#FF0000",
                        width: chLow ? 2 : 0,
                        value: thLow,
                    },
                    {
                        color: "#FF0000",
                        width: chHigh ? 2 : 0,
                        value: thHigh,
                    },
                ],
            },
        ],
        tooltip: {
            formatter: function () {
                return (
                    this.series.userOptions.name +
                    "<br/>" +
                    (periodIdx < 1 ? UtilService.shortestFormateDateMDYH(this.x) : UtilService.shortestFormateDateMDY(this.x)) +
                    "</br>" +
                    "<b>" +
                    (periodIdx < 4 ? "Value" : "Average") +
                    " : " +
                    this.y +
                    " " +
                    this.series.userOptions.unit +
                    "</b>"
                );
                // return this.series.userOptions.name + "<br/>" +
                // 	UtilService.shortestFormateDateMDY(this.x) +
                // 	'</br>' + '<b>Average : ' + this.y + ' ' + this.series.userOptions.unit + '</b>';
            },
        },
        plotOptions: {
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3,
                    },
                },
                marker: {
                    enabled: false,
                },
            },
        },
        chart: {
            type: type,
            height: 500,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
        },
        credits: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false,
        },
        series: [],
        navigator: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download",
                },
            },
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };

    const masterChart = {
        title: {
            text: "",
        },
        xAxis: {
            type: "datetime",
            showLastTickLabel: true,
            labels: {
                overflow: "justify",
                formatter: function () {
                    return UtilService.formatMD(this.value);
                },
            },
        },
        yAxis: [
            {
                title: {
                    text: null,
                },
                labels: {
                    enabled: false,
                },
                showFirstLabel: false,
                min: 0,
                plotLines: [
                    {
                        color: "#FF0000",
                        width: chLow ? 2 : 0,
                        value: thLow,
                    },
                    {
                        color: "#FF0000",
                        width: chHigh ? 2 : 0,
                        value: thHigh,
                    },
                ],
            },
            {
                title: {
                    text: null,
                },
                labels: {
                    enabled: false,
                },
                showFirstLabel: false,
                min: 0,
                opposite: true,
            },
        ],
        tooltip: {
            formatter: function () {
                return false;
            },
        },
        plotOptions: {
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3,
                    },
                },
                marker: {
                    enabled: false,
                },
            },
            series: {
                fillColor: {
                    linearGradient: [0, 0, 0, 70],
                    stops: [
                        [0, "rgba(102,170,243,200)"],
                        [1, "rgba(102,170,243,0)"],
                    ],
                },
                lineWidth: 1,
                marker: {
                    enabled: false,
                },
                shadow: false,
                states: {
                    hover: {
                        lineWidth: 1,
                    },
                },
                enableMouseTracking: false,
            },
        },
        chart: {
            type: "area",
            height: 100,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
            events: {
                selection: (event) => {
                    const { min, max } = event.xAxis[0];
                    setFilterBegin(min);
                    setFilterEnd(max);

                    return false;
                },
            },
            zoomType: "x",
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        series: [],
        navigator: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
    };

    if (seriesData.length > 0) {
        for (var i = 0; i < seriesData.length; i++) {
            masterChart.series.push({
                name: i,
                data: seriesData[i].series,
                yAxis: 1,
            });

            let visible = true;
            chkName.forEach((c) => {
                if (c.fixedName == seriesData[i].fixedName) {
                    visible = c.checked;
                }
            });

            detailChart.series[i] = {
                name: seriesData[i].fixedName,
                unit: "lux",
                data: seriesData[i].series.filter((point) => point[0] > filterBegin && point[0] < filterEnd),
                events: {
                    click: handlePoint,
                },
                thingName: seriesData[i].thingName,
                attributeId: seriesData[i].attributeId,
                visible: visible,
            };
            // console.log(detailChart);
        }
    } else {
        // for (var i = 0; i < detailChart.series.length; i++) {
        detailChart.series = [];
        // }
        masterChart.series = [];
    }

    if (chLow) {
        masterChart.yAxis[0].plotLines[0].lineWidth = 1;
    } else {
        masterChart.yAxis[0].plotLines[0].lineWidth = 0;
    }
    if (chHigh) {
        masterChart.yAxis[0].plotLines[1].lineWidth = 1;
    } else {
        masterChart.yAxis[0].plotLines[1].lineWidth = 0;
    }

    useEffect(() => {
        setDetailOptions(detailChart);
        setMasterOptions(masterChart);
    }, [type, thLow, thHigh, handlePoint, filterBegin, filterEnd]);

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={detailOptions} />
            <HighchartsReact highcharts={Highcharts} options={masterOptions} />
        </div>
    );
}
