const threshold_columns = [
    { field: 'id', headerName: 'ID', hide: true },
    {
        field: 'startTime',
        headerName: 'Start time',
        flex: 1,
        editable: false,
        align: 'left',
        headerAlign: "left"
    },
    {
        field: 'endTime',
        headerName: 'End time',
        flex: 1,
        editable: false,
        sortable: true,
        align: 'left',
        headerAlign: "left"
    },
    {
        field: 'isAllTime',
        headerName: 'All Time',
        type: 'boolean',
        flex: 1,
        editable: false,
        sortable: true,
        hide: true,
    },
    {
        field: 'lowValue',
        headerName: 'Low',
        flex: 1,
        editable: false,
        sortable: true,
        align: 'left',
        headerAlign: "left"
    },
    {
        field: 'highValue',
        headerName: 'High',
        flex: 1,
        editable: false,
        sortable: true,
        align: 'left',
        headerAlign: "left"
    },
];

export default threshold_columns;;