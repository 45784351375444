import ApiService from "Services/apiService";

const TimoutService = {
    findDefaultTimeout: (company) => {

        let requestOptions = {
            url: `/v2/setting/${company}/company`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                return response;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateTimeout: (company, timeout) => {
        let requestOptions = {
            url: `/v2/things/timeout`,
            data: {
                company: company,
                timeout: timeout,
            },
        };

        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default TimoutService;
