import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CompareSecondService from "./CompareSecondService";
import { useTranslation } from "react-i18next";

export const ATTRIBUTE_BATTERY = "53c1fceb-9fea-4b22-a3c6-2a6d0147c74a";

export default function CompareSecondDialog({ open, setOpen, thing, exceptAttributeId, callback }) {
    const { t, i18n } = useTranslation();
    const [rows, setRows] = useState([]);
    const [selectedAttributeId, setSelectedAttributeId] = useState("NONE");
    const [error, setError] = useState(false);

    useEffect(() => {
        // console.log(thing);

        if (thing && thing.product) {
            const { model } = thing.product;

            // console.log(model.id);
            CompareSecondService.requestAttributes(model.id).then((r) => {
                // console.log(r);
                if (r && r.data) {
                    const filteredAttributes = r.data.filter((c) => c.id !== exceptAttributeId && c.id !== ATTRIBUTE_BATTERY);
                    // console.log(filteredAttributes);
                    setRows(filteredAttributes);
                }
            });
        }
    }, [thing, exceptAttributeId]);

    const handleCancel = () => {
        setOpen(false);
    };

    const handleOk = () => {
        // console.log(selectedAttributeId);
        callback(selectedAttributeId);
        setOpen(false);

        setSelectedAttributeId("NONE");
    };

    const handleSelect = (e) => {
        // console.log(e.target.value);
        setSelectedAttributeId(e.target.value);
        setError(false);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedAttributeId("NONE");
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
            <DialogTitle>{t("superviot.second_measure")}</DialogTitle>
            <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
                <FormControl>
                    <InputLabel htmlFor="grouped-native-select"></InputLabel>
                    <Select native id="grouped-native-select" defaultValue={"NONE"} onChange={(e) => handleSelect(e)} autoWidth error={error}>
                        <option value="NONE" key="NONE">
                            NONE
                        </option>
                        {rows &&
                            rows.map((row) => {
                                return (
                                    <option key={row.id} value={row.id}>
                                        {row.name}
                                    </option>
                                );
                            })}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    {t("btn.cancel")}
                </Button>
                <Button onClick={handleOk} color="primary">
                    {t("btn.ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
